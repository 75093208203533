import './MainHome.scss';
import Categoryitem from '../../app-components/Categoryitem';
import Storeitem from '../../app-components/Storeitem';
import ShimmerC from '../../app-components/ShimmerC';
import CFooter from '../../app-components/CFooter';
import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel'
import { useHistory } from 'react-router-dom'
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import 'firebase/messaging';
import Category from '../../app-components/Category';
import banner from '../../assets/BannerRetail.png'
import banner3 from '../../assets/banner.jpg'
import HeaderforRetail from '../../app-components/HeaderforRetail';
import google from '../../assets/google-app (1).png'
import appstore from '../../assets/appstore.png'
import telephone from '../../assets/shopgray 1.png'
import whatsapp from '../../assets/kisspng-brand-management-inspiral-design-ltd-computer-icon-5ae0cc3f688981 2.png'
import emaildd from '../../assets/3 1.png'
import banner2 from '../../assets/getTheApp.png'
import StoreItemsFive from '../../app-components/StoreItemsFive';

function RetailHomes(props: any) {

    const history = useHistory();
    const [ProdutCategory, setProdutCategory] = useState<any>([])
    const [categoryImgHeight2, setcategoryImgHeight2] = useState<any>('')
    const [ShowThanksmsg, setShowThanksmsg] = useState<any>('')
    const [categoryImgHeight, setcategoryImgHeight] = useState<any>('')
    const [categoryCards, setcategoryCards] = useState<any[]>([])
    const [popularProduct, setpopularProduct] = useState<any[]>([])
    const [email, setemail] = useState('')
    const [NoStore, setNoStore] = useState<any>('')
    const [featuredItems, setfeaturedItems] = useState<any[]>([])
    const [Brands, setBrands] = useState<any>([])
    const [PreLovesStoresList, setPreLovesStoresList] = useState<any>([])
    const [sliderImages, setsliderImages] = useState<any[]>([])
    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const myRef = useRef(null)
    const getTheApp = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const executeScrollApp = () => scrollToRef(getTheApp)
    var nofeaturedstore: string = 'No';
    var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    useEffect(() => {
        categoryImg(window.innerWidth)
        history.listen((location, action) => {
            window.location.reload()
        })
        if (props.location.state == 'review') {
            executeScroll()
        } else if (props.location.state == 'gettheapp') {
            executeScrollApp()
        } else {
            window.scrollTo(0, 0)
        }
        getAnnoucments()
        categoryImg2(window.innerWidth)
        getPreLovedStores()
        getcategoryCard()
        PopularProductsV1()
        FeturedItems()
        TopBrands()
        Categories()
        getMyStoreDetail()
    }, []);

    const categoryImg2 = (data: any) => {
        if (data > 799) {
            setcategoryImgHeight2('43vh')
        } else {
            setcategoryImgHeight2('20vh')
        }
    };


    const getMyStoreDetail = () => {
        if (token) {
            axios
                .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
                .then(response => {
                    if (response.data.store_details[0].store_address == undefined) {
                        setNoStore('yes')
                    } else {
                        setNoStore('no')
                    }
                })
                .catch(function (error) {
                    console.log('response if the api is errror =', error)
                });
        } else {
            setNoStore('yes')
        }

    }

    const Categories = () => {
        axios
            .get(Global.GetCategory + '?is_luxury=0')
            .then(response => {
                setProdutCategory(response.data.product_categories)
            })
            .catch(function (error) {
                console.log('response djfkjsdf', error)
            });
    }

    const TopBrands = () => {
        axios
            .get(Global.GetTopBrands + '?is_luxury=0')
            .then(response => {
                setBrands(response.data.brands)
            })
            .catch(function (error) {
                console.log('response djfkjsdf', error)
            });
    }

    const getcategoryCard = () => {
        axios
            .get(Global.WebGetAppShortcutCards + '?is_luxury=0')
            .then(response => {
                setcategoryCards(response.data.app_shortcut_cards)
            })
            .catch(function (error) {
                console.log('response djfkjsdf', error)
            });
    }

    const PopularProductsV1 = () => {
        axios
            .get(Global.GetPopularProductsV1 + '?is_luxury=0')
            .then(response => {
                let luxury = response.data.products.filter(
                    (Industrydata: any, idx: any) => idx < 5,
                );
                setpopularProduct(luxury)
            })
            .catch(function (error) {
                console.log('respinsedddd', error)
            });
    }

    const FeturedItems = () => {
        axios
            .get(Global.GetFeturedItems + '?is_luxury=0')
            .then(response => {
                setfeaturedItems(response.data.featured_items)
            })
            .catch(function (error) {
                console.log('respinsedddd', error)
            });
    }

    const getAnnoucments = () => {
        axios
            .get(Global.GetLesfemmesBanner + '?is_luxury=0')
            .then(response => {
                setsliderImages(response.data.slider_array)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getPreLovedStores = () => {
        axios
            .get(Global.GET_ALL_STORES_TOP_ITEM)
            .then(response => {
                let stores = response.data.stores.filter(
                    (Industrydata: any, idx: any) => idx < 5,
                );
                setPreLovesStoresList(stores)
            })
            .catch(function (error) {
                console.log('preloves stores', error)
            });
    }

    const categoryImg = (data: any) => {
        if (data > 799) {
            setcategoryImgHeight('55vh')
        } else {
            setcategoryImgHeight('20vh')
        }
    };

    const moveToDetailScreen = (data: any) => {
        history.push('/product-detail/' + data.id + '/' + slugify(data.name), [data]);
    }

    const movetoAllProducts = (data: any) => {
        if (data.name == '@ALL# -Dresses/ ^ & + * $ =') {
            history.push('/all-products', ['AllCatSubCat', data.category_id, data.sub_category_id]);
        } else if (data.name == '@DAILY# -DEALS/') {
            history.push('/all-products', ['AllSale']);
        } else if (data.name == '@ALL# -Accessories/') {
            history.push('/all-products', ['itemCat', data.category_id]);
        } else if (data.name == '-60% OFF/ ^ALL BAGS& +UPTO*') {
            history.push('/all-products', ['AllSaleSpecificPercentage', 60, data.category_id]);
        } else if (data.name == '<SALE> $ALL SHOES=') {
            history.push('/all-products', ['AllCat', data.category_id]);
        } else if (data.name == '@ALL# -Beauty/ ') {
            history.push('/all-products', ['itemCat', data.category_id]);
        }
    }

    const NewsLetter = () => {
        if (mailformat.test(email)) {
            axios
                .post(Global.NewsLetter + '?email=' + email)
                .then(response => {
                    if (response.data.status == 200) {
                        setShowThanksmsg('Thank you for Subscribing')
                        setTimeout(() => {
                            setShowThanksmsg('')
                        }, 10000);
                    } else {
                        setShowThanksmsg('Please try again latter')
                        setTimeout(() => {
                            setShowThanksmsg('')
                        }, 10000);
                    }
                })
                .catch(function (error) {
                    alert(error)
                });
        } else {
            setShowThanksmsg('Please add a valid email address')
            setTimeout(() => {
                setShowThanksmsg('')
            }, 10000);
        }
    }

    return (
        <div>

            <HeaderforRetail />

            {sliderImages.length >= 1 ? (
                <Carousel>
                    {sliderImages?.map((images, index) => (
                        <Carousel.Item key={index} >
                            <img onClick={() => history.push('/all-products', ['AllProducts'])} className="header-image-styling touchAble img-responsive" src={images} alt="First slide" />
                        </Carousel.Item>
                    ))}
                </Carousel>
            ) : (
                <div>
                    <ShimmerC smallshimer='no' />
                </div>
            )}

            <div className='CategoryDiv' >
                {categoryCards.map((data: any) =>
                    <div onClick={() => movetoAllProducts(data)} className='col-md-4 paddings  col-6' style={{ paddingTop: 24 }} >
                        <Category imageheight={categoryImgHeight} dataofCategory={data} />
                    </div>
                )}
            </div>
            <div className='contentStyle page-left-padding page-right-padding cm-t-30' >
                <div className='main-font mostPopularFont' >MOST POPULAR</div>
                <div className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
                    <label onClick={() => history.push('/popular-products', ['NotLuxury'])} className="main-font f-15 cp-l-20 cp-r-20  main-font label touchAble hoverUnderLine" style={{ color: 'white' }} >View All</label>
                </div>
            </div>
            <div>
                <div className='cm-r-40 cm-l-75' >
                    {popularProduct.length >= 1 ? (
                        <div className='row webdisplay'>
                            {popularProduct.map((person: any, index) => (
                                <div key={person.id} style={{ width: '18%', marginRight: '2%' }} onClick={() => moveToDetailScreen(person)} >
                                    <StoreItemsFive data={person} />
                                </div>

                            ))}
                        </div>
                    ) : (
                        <div className='row '>
                            <ShimmerC homeproducts='yes' />
                        </div>
                    )}

                </div>
                <div className='mobiledisplay' >
                    {popularProduct.length >= 1 ? (
                        <div className='mobiledisplay cp-r-13 cp-l-13  d-flex' style={{overflow:'scroll'}}>
                            {popularProduct.map((person: any, index) => (
                                <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)} >
                                    <StoreItemsFive heigth={150} data={person} />
                                </div>

                            ))}
                        </div>
                    ) : (
                        <div className='row '>
                            <ShimmerC homeproducts='yes' />
                        </div>
                    )}
                </div>
                {token !== '' ? (
                    <div onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} style={{ marginTop: 60 }} >
                        <img className="touchAble" alt='img' style={{ width: '100vw' }} src={banner} ></img>
                    </div>
                ) : (
                    <div onClick={() => history.push('./login')} style={{ marginTop: 60 }} >
                        <img className="touchAble" alt='img' style={{ width: '100vw' }} src={banner} ></img>
                    </div>
                )}
                <div className='cp-t-50 cp-b-30' style={{ justifyContent: 'center', display: 'flex', backgroundColor: '#F3F3F3' }} >
                    <label className=" mostPopularFont label main-font" style={{ textAlign: 'center' }} >FEATURED ITEMS</label>
                </div>
                <div className='webdisplay' >
                    {featuredItems.length >= 1 ? (
                        <div className='cp-r-40 cp-l-75 cp-b-50 ' style={{ backgroundColor: '#F3F3F3' }} >
                            <div className='row '>
                                {featuredItems.map((person: any) => (
                                    <div key={person.id} style={{ width: '18%', marginRight: '2%', marginBottom: '2%' }} onClick={() => moveToDetailScreen(person)}>
                                        <StoreItemsFive data={person} />
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    ) : (
                        <div className='cp-b-50' style={{ backgroundColor: '#F3F3F3' }} >
                            <div className='row  page-left-padding page-right-padding' >
                                <ShimmerC homeproducts='yes' />
                            </div>
                            <div className='row  page-left-padding page-right-padding cm-t-20' >
                                <ShimmerC homeproducts='yes' />
                            </div>
                        </div>
                    )}
                </div>
                <div className='mobiledisplay' >
                    {featuredItems.length >= 1 ? (
                        <div className='mobiledisplay cp-r-13 cp-l-13  d-flex flex-wrap'>
                            {featuredItems.map((person: any) => (
                                <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)}>
                                    <StoreItemsFive heigth={150} data={person} />
                                </div>
                            ))
                            }
                        </div>
                    ) : (
                        <div className='cp-b-50' style={{ backgroundColor: '#F3F3F3' }} >
                            <div className='row  page-left-padding page-right-padding' >
                                <ShimmerC homeproducts='yes' />
                            </div>
                            <div className='row  page-left-padding page-right-padding cm-t-20' >
                                <ShimmerC homeproducts='yes' />
                            </div>
                        </div>
                    )}
                </div>
                <div className='contentStyle page-left-padding page-right-padding'>
                    <div className='mostPopularFont  main-font'>
                        RETAIL STORES
                    </div>
                    <div className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
                        <label onClick={() => history.push('/stores', ['Retail Stores'])} className="main-font f-15 cp-l-20 cp-r-20  main-font label hoverUnderLine touchAble" style={{ color: 'white' }} >View All</label>
                    </div>
                </div>
                {PreLovesStoresList.length >= 1 ? (
                    <div className='cp-r-40 cp-l-75 cp-b-50 webdisplay' >
                        <div className='row '>
                            {PreLovesStoresList.map((data: any) => (
                                <div onClick={() => history.push('/StoreDetail', [data.id])} key={data.id} style={{ width: '18%', marginRight: '2%' }}>
                                    <Storeitem DataofProduct={data} FeaturedStore={nofeaturedstore} />
                                </div>
                            ))}
                        </div>

                    </div>
                ) : (
                    <div className='row page-left-padding page-right-padding cp-b-60 '>
                        <ShimmerC homeproducts='yes' />
                    </div>
                )}
                <div className='mobiledisplay' >
                   {PreLovesStoresList.length >= 1 ? (
                        <div className='cp-r-13 cp-l-13  d-flex' style={{overflow:'scroll'}}>
                            {PreLovesStoresList.map((data: any) => (
                                <div onClick={() => history.push('/StoreDetail', [data.id])} key={data.id} className='col-6 paddingsMobile ' style={{ justifyContent: 'center' }}>
                                    <Storeitem height={150} DataofProduct={data} FeaturedStore={nofeaturedstore} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='row page-left-padding page-right-padding cp-b-60 '>
                            <ShimmerC homeproducts='yes' />
                        </div>
                    )}
                </div>
                <div className='mostPopularDiv  flex-wrap ' style={{ display: 'flex', marginTop: 20 }} >
                    {ProdutCategory.map((data: any) =>
                        <div onClick={() => history.push('/all-products', ['itemCat', data.id])} className='col-md-3 col-6 paddingwws cp-t-10'  >
                            <Category imageheight={categoryImgHeight2} text7={data.name} dataofCategory={data} />
                        </div>
                    )}
                </div>
                {Brands.length >= 1 &&
                    <div className="cp-t-60 cp-b-20 " >
                        <div className='mainHeading main-font d-flex justify-content-center label'>RETAIL BRANDS</div>
                        <Categoryitem Dataof={Brands} brands={true} border={true} retail={true} />
                    </div>
                }
            </div>
            <div>
                <div className='webdisplay' style={{ display: 'flex', alignItems: 'center', background: `url(${banner2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '65vh', width: '100%', backgroundColor: '#eee' }} >
                    <div className='col-md-6' style={{ marginBottom: 60, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                        <label className='label' style={{ color: '#7F1D36', fontSize: '45px', fontWeight: 700, lineHeight: 1, }} >GET 15% OFF</label>
                        <label className='label ' style={{ color: 'black', fontSize: '28px', textAlign: 'center', lineHeight: 1.3 }} >on your First App Order <br /> Enter code <label className='' style={{ color: '#7F1D36', fontSize: '28px', fontWeight: 700, }}>LFAPP15</label>  at checkout</label>
                        <div style={{ marginTop: 25 }}>
                            <a href="https://play.google.com/store/apps/details?id=com.lesfemmes&hl=en" target="_blank" >  <img className="touchAble" alt='img' height="60rem" src={google} ></img></a>
                            <a href="https://apps.apple.com/pk/app/lesfemmes/id1396175773" target="_blank">  <img className="touchAble" alt='img' height="60rem" src={appstore} ></img></a>
                        </div>
                    </div>
                    <div >
                    </div>
                </div>
                <div className='mobiledisplay' style={{ paddingTop: '15px' }} >
                    <img alt='img' width={'100%'} src={banner3} ></img>
                </div>
                <div className='joinnewsLetterDiv' >
                    <div className='col-md-6' style={{ display: 'flex', paddingLeft: '10%' }} >
                        <div className='cp-t-2rem  cp-b-2rem '>
                            <div className='mainHeading main-font'>
                                Join Our NewsLetter
                            </div>
                            <div className='cp-b-1rem main-font'>
                                Sign up for our newsletter and don't miss a thing!
                            </div>
                            <input id='email' className='subscriptinput' onChange={(text: any) => setemail(text.target.value)} style={{ backgroundColor: 'white' }} placeholder='Enter your email address'></input>
                            <span onClick={() => NewsLetter()} className='sub-btn capitalize font-weight-bold main-font' style={{}}>Subscribe</span>
                            <div className='cp-b-1rem main-font' style={{ color: '#7F1D36' }}>
                                {ShowThanksmsg}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6  pad ' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >
                        <div className='paddingright' style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', alignItems: 'center' }} >
                            <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                                <div  >
                                    <img className="touchAble" alt='img' height="100rem" src={telephone} ></img>
                                </div>
                                <label className='f-12 cp-t-15 text-center main-font' >LOCAL UAE STORES</label>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                                <div  >
                                    <img className="touchAble" alt='img' height="100rem" src={whatsapp} ></img>
                                </div>
                                <label className='f-12 cp-t-15 text-center main-font' >RETAIL BRANDS</label>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                                <div  >
                                    <img className="touchAble" alt='img' height="100rem" src={emaildd} ></img>
                                </div>
                                <label className='f-12 cp-t-15  text-center main-font' >WORLDWIDE DELIVERY <br /> & EASY RETURNS</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={getTheApp}></div>
            <div className="c-border-top-light">
                < CFooter />
            </div>
        </div  >
    );
}

export default RetailHomes;
