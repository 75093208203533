import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import CFooter from '../app-components/CFooter';
import { BiCaretDown, BiCaretRight } from "react-icons/bi";
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function Faqs(props: any) {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [firstFaqs, setfirstFaqs] = useState(false);
    const [secondFaqs, setsecondFaqs] = useState(false);
    const [thirdFaqs, setthirdFaqs] = useState(false);
    const [forthFaqs, setforthFaqs] = useState(false);
    const [fifthFaqs, setfifthFaqs] = useState(false);
    const [sixFaqs, setsixFaqs] = useState(false);
    const [sevenFaqs, setsevenFaqs] = useState(false);
    const [eightFaqs, seteightFaqs] = useState(false);
    const [nineFaqs, setnineFaqs] = useState(false);
    const [tenFaqs, settenFaqs] = useState(false);
    const [elevenFaqs, setelevenFaqs] = useState(false);
    const [twelveFaqs, settwelveFaqs] = useState(false);
    const [thirteenFaqs, setthirteenFaqs] = useState(false);
    const [fortenFaqs, setfortenFaqs] = useState(false);
    const [fiftenFaqs, setfiftenFaqs] = useState(false);
    const [sixtenFaqs, setsixtenFaqs] = useState(false);
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const history = useHistory();


    useEffect(() => {
        history.listen((location, action) => {
            window.location.reload();
        })
        if (props.location.state[0] === 'customer') {
            executeScroll()
        } else {
            window.scrollTo(0, 0)
        }
    }, [])

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding" >
                <div className="d-flex flex-column cp-t-30" >
                    <label className="secHeading main-font  primary-color " >Help Center</label>
                    <div className="cp-b-5" >
                        <label className=" labelText main-font hoverUnderLine   cp-r-30 " >FAQS</label>
                        <label className="labelText main-font hoverUnderLine  " onClick={() => executeScroll()} >Help & Support</label>
                    </div>
                    <label className="labelHeading  main-font  primary-color" >FAQs</label>
                    <div onClick={() => setfirstFaqs(!firstFaqs)} style={{ backgroundColor: firstFaqs === true ? '#f2c9d0' : 'white', color: firstFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-left border-right cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How do I sell on Les Femmes?</label>
                        <div className="d-flex  align-self-center" >
                            {firstFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {firstFaqs === true ? (
                        <div className="d-flex   flex-column  border-left border-top border-right cp-40" >
                            <ul>
                                <li className="text-muted">Create Store</li>
                                <li className="text-muted">List an Item</li>
                                <li className="text-muted">Start Selling</li>
                            </ul>
                        </div>
                    ) : null}
                    <div onClick={() => setsecondFaqs(!secondFaqs)} style={{ backgroundColor: secondFaqs === true ? '#f2c9d0' : 'white', color: secondFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How to create a store? </label>
                        <div className="d-flex  align-self-center" >
                            {secondFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {secondFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label  >After you create your account, you can go back and edit your profile picture, and My Store details. </label>
                            <label>To edit My Store: </label>
                            <ol>
                                <li className="text-muted">Go to your My Store on the main page.</li>
                                <li className="text-muted">Select Store</li>
                                <li className="text-muted">From the right-hand menu, select Edit Store</li>
                                <li className="text-muted">Fill out the following details (Name, Description, Contact Number, Store Address(Country, City))</li>
                                <li className="text-muted">Click the Camera icon to upload your My Store photo</li>
                                <li className="text-muted">Click Edit Bank Account Info to add you bank details</li>
                                <li className="text-muted">Then save all details, for easy transfer once payments are ready.</li>
                            </ol>
                        </div>
                    ) : null}
                    <div onClick={() => setthirdFaqs(!thirdFaqs)} style={{ backgroundColor: thirdFaqs === true ? '#f2c9d0' : 'white', color: thirdFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How to list your items?  </label>
                        <div className="d-flex  align-self-center" >
                            {thirdFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {thirdFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label>To list your products on Les Femmes:</label>
                            <ol>
                                <li className="text-muted">1.	Tap Camera Sell icon on the main page at the bottom of the screen.
                                    Or if you are on the My Store click the Sell Item
                                </li>
                                <label>Note: Do not forget to fill all your details on My Store before listing an item.</label>
                                <li className="text-muted">Take or upload up to 8 photos of the item.</li>
                                <li className="text-muted">Fill in the required information:
                                    <ul>
                                        <li>Choose “Luxury Item” if you are uploading a luxury products and “Not Luxury” for any other regular top brands.</li>
                                        <li>Title</li>
                                        <li>Category</li>
                                        <li>Sub Category</li>
                                        <li>Product Description</li>
                                        <li>Brand Name</li>
                                        <li>Selling Price</li>
                                        <li>Discounted Price (Optional)</li>
                                        <li>Color</li>
                                        <li>Size</li>
                                        <li>Condition</li>
                                        <li>Quantity</li>
                                        <li>Estimated Weight </li>
                                        <li>Product Height, Width, and Length</li>
                                        <li>If you will upload 2-3 different items (ex.Dress+Top) that you want to bundle for one price, you can choose “Bundle”</li>
                                    </ul>
                                </li>
                                <li className="text-muted">Tap Save Product</li>
                            </ol>
                        </div>
                    ) : null}
                    <div onClick={() => setforthFaqs(!forthFaqs)} style={{ backgroundColor: forthFaqs === true ? '#f2c9d0' : 'white', color: forthFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How long do I have to send an item once it's sold?</label>
                        <div className="d-flex  align-self-center" >
                            {forthFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {forthFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label>First of all - Congratulations on your sale!</label>
                            <label className="text-muted" >Once a sale has been confirmed, you have 3 days to send the item.</label>
                            <label className="text-muted" >If you don't send the item within those 3 days, the sale could be canceled and the buyer reimbursed the full purchase amount</label>
                            <label className="text-muted" >Remember, the quicker you send your item, the sooner you will be paid. And the more positive reviews you will get. Positive feedback adds to your value as a seller</label>
                        </div>
                    ) : null}
                    <div onClick={() => setfifthFaqs(!fifthFaqs)} style={{ backgroundColor: fifthFaqs === true ? '#f2c9d0' : 'white', color: fifthFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">Ready to prepare your sold item for shipping?</label>
                        <div className="d-flex  align-self-center" >
                            {fifthFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {fifthFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label>Follow these simple steps to make sure your item arrives in top condition</label>
                            <label className="label labelText">Clean your item</label>
                            <label className="text-muted" >Whether it's clothes, bag, shoes, accessories, we recommend that you carefully clean your item before shipping it. If you’re sending clothes, ensure they're washed and ironed. Clean the soles of shoes, or change the battery of a watch, use any dust bags that you have that may have come with the product originally</label>
                            <label className="label labelText">Pack with care</label>
                            <label className="text-muted" >Choose sustainable packing materials that will properly protect the item you're sending. Don't choose a box that is too small or large, and fill any empty space inside the package with suitable packing material. If your item arrives damaged and the packaging is found to be inadequate, we cannot guarantee compensation.</label>
                            <label className="text-muted" >Remember! Only send one item per package with the invoice and mark the item on the package. Each item has to be sent in its own parcel and marked with its own Shipping Label. This is unless someones has bought multiple items from you, in that case they may be packed together, labeled individually. Wrong packaging which leads to losses is not compensated.</label>
                            <label className="label labelText">Include all the items mentioned in your description</label>
                            <label className="text-muted" >Don't forget to include any additional items like a dust bag, authenticity certificate, or even a nice message to your buyer</label>
                            <label className="label labelText">Download the Invoice </label>
                            <label className="text-muted" >Print and then stick Invoice you received from Les Femmes on your parcel where it is flat and easily visible.</label>
                            <label className="text-muted" >Your item is now perfectly packaged and ready to be shipped!  </label>
                            <label className="label labelText">Pick Up and Delivery</label>
                            <label className="text-muted" >Les Femmes provide free pick up and delivery once you sold an item. Kindly, reply to the email we sent you for the time, day, and location for pick up of your item. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setelevenFaqs(!elevenFaqs)} style={{ backgroundColor: elevenFaqs === true ? '#f2c9d0' : 'white', color: elevenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">What are the fees for selling on Les Femmes?</label>
                        <div className="d-flex  align-self-center" >
                            {elevenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {elevenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >To start selling and get paid, you must fill your bank details on My Store</label>
                            <label className="text-muted" >This is really easy to do- head to My Store Edit Store Add Bank Account Info.</label>
                            <label className="text-muted" >Don’t forget to fill out all the details for you to get paid. And click save.</label>
                        </div>
                    ) : null}
                    <div onClick={() => settwelveFaqs(!twelveFaqs)} style={{ backgroundColor: twelveFaqs === true ? '#f2c9d0' : 'white', color: twelveFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">What about fees?</label>
                        <div className="d-flex  align-self-center" >
                            {twelveFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {twelveFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >When you sell an item, Les Femmes will automatically charge the 15% commission fee on every item sold. This charge will be taken directly from Account on Les Femmes on the card you are using for payments before the remainder of the money you have made is paid to you.</label>
                            <label className="text-muted" >If you are an Individual Seller, not a business owner (without a license) selling pre-loved items or new items, we deduct 15% Les Femmes Fee for every sale you made.</label>
                            <label className="text-muted" >If you are a Retail Business Owner with a license, we deduct 10% of Les Femmes Fee for every sale you made. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setthirteenFaqs(!thirteenFaqs)} style={{ backgroundColor: thirteenFaqs === true ? '#f2c9d0' : 'white', color: thirteenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left border-bottom cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">When payment will be available on my account? </label>
                        <div className="d-flex  align-self-center" >
                            {thirteenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {thirteenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-bottom border-right cp-40" >
                            <label className="text-muted" >Once your sale has been delivered and received by your buyer, the money will be on your Les Femmes wallet. Once you redeemed the amount on Les Femmes wallet, we release the money to your Les Femmes account within 10 days. You can spend your earnings within the app as a wallet earnings or transfer your money as cash whenever you like. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setsixFaqs(!sixFaqs)} style={{ backgroundColor: sixFaqs === true ? '#f2c9d0' : 'white', color: sixFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">What payment method does Les Femmes accepts?</label>
                        <div className="d-flex  align-self-center" >
                            {sixFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {sixFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="" >Les Femmes accepts the following forms of payment: </label>
                            <label className="text-muted" >Credit or Debit Cards (Visa, MasterCard)<br></br>Cash on Delivery (COD Available <strong>ONLY IN UAE</strong> )</label>
                        </div>
                    ) : null}
                    <div onClick={() => setsevenFaqs(!sevenFaqs)} style={{ backgroundColor: sevenFaqs === true ? '#f2c9d0' : 'white', color: sevenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">Can I trust buying and selling on Les Femmes?</label>
                        <div className="d-flex  align-self-center" >
                            {sevenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {sevenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >We want you to feel secure when buying or selling on Les Femmes!</label>
                        </div>
                    ) : null}
                    <div onClick={() => seteightFaqs(!eightFaqs)} style={{ backgroundColor: eightFaqs === true ? '#f2c9d0' : 'white', color: eightFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">Buyer’s Protection</label>
                        <div className="d-flex  align-self-center" >
                            {eightFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {eightFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >You are protected every time you place an order on Les Femmes. When you purchase an item on Les Femmes, we keep your payment safe and secure. </label>
                            <label className="text-muted" >If your item does not match the description, or order is damaged, please open a case by sending an email at support@lesfemmes.net within 3 days of delivery, before the order is marked as received.  </label>
                            <label className="text-muted" >After we verify your claim, we'll refund payment. If we don't hear from you by 3 days after your order is delivered, we'll assume everything is OK and release your payment to the seller. Once we release payment, all sales are final and no refunds will be available. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setnineFaqs(!nineFaqs)} style={{ backgroundColor: nineFaqs === true ? '#f2c9d0' : 'white', color: nineFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">Seller’s Protection</label>
                        <div className="d-flex  align-self-center" >
                            {nineFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {nineFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >Once your order has been delivered and accepted by your buyer, we will release your funds within 10 days and you can redeem your earnings from Les Femmes.</label>
                            <label className="text-muted" >If a buyer reports a problem with their order, we will ask them to upload details and photos for their return request. The Les Femmes team then carefully reviews the case and offers a resolution.</label>
                        </div>
                    ) : null}
                    <div onClick={() => settenFaqs(!tenFaqs)} style={{ backgroundColor: tenFaqs === true ? '#f2c9d0' : 'white', color: tenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">When can I get my order?</label>
                        <div className="d-flex  align-self-center" >
                            {tenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {tenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >You can get your order within 2-5 days. We will send you an email once the item is shipped. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setfortenFaqs(!fortenFaqs)} style={{ backgroundColor: fortenFaqs === true ? '#f2c9d0' : 'white', color: fortenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How to make a return?</label>
                        <div className="d-flex  align-self-center" >
                            {fortenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {fortenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top border-left border-right cp-40" >
                            <label className="text-muted" >A return request can typically take our team 2-5 business days to process. Please note that the length of time to review a case can vary depending on the proof we have on hand, as well as the response time it takes for a buyer or a seller to get back to us. </label>
                            <label className="text-muted" >Kindly send us an email at <strong>support@lesfemmes.net</strong> for the reason of the return and attached a photo if required depending on the reason if it’s damaged item, wrong item received, missing item, or description not match on the order received. </label>
                            <label className="text-muted" >When we have proof that the item was misrepresented, we will send the buyer a return label and have them ship the item back to the seller. Once the order is delivered back to the seller, we will begin to process the buyer’s refund, which is the full amount they paid; this includes shipping that was charged for the purchase. Please note questions on the item can be asked on the app before purchase.</label>
                        </div>
                    ) : null}
                    <div onClick={() => setfiftenFaqs(!fiftenFaqs)} style={{ backgroundColor: fiftenFaqs === true ? '#f2c9d0' : 'white', color: fiftenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">How to cancel an accidental purchase?</label>
                        <div className="d-flex  align-self-center" >
                            {fiftenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {fiftenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-top   border-left border-right cp-40" >
                            <label className="text-muted" >Canceling an order can be done if the item is not yet shipped. Kindly send us an email at <strong> support@lesfemmes.net</strong> to cancel an order. </label>
                        </div>
                    ) : null}
                    <div onClick={() => setsixtenFaqs(!sixtenFaqs)} style={{ backgroundColor: sixtenFaqs === true ? '#f2c9d0' : 'white', color: sixtenFaqs === true ? 'rgb(218, 95, 115)' : 'black', }} className="d-flex justify-content-between border-top border-bottom border-right border-left cp-t-10 cp-b-10 cp-r-30 cp-l-30" >
                        <label className="label labelText">What are the shipping rates on Les Femmes?</label>
                        <div className="d-flex  align-self-center" >
                            {sixtenFaqs === true ? (
                                <BiCaretDown color="black" />
                            ) : (
                                <BiCaretRight color="black" />
                            )}
                        </div>
                    </div>
                    {sixtenFaqs === true ? (
                        <div className="d-flex  flex-column  border-left border-bottom border-top border-left border-right cp-40" >
                            <label className="text-muted" >Order 100 AED and above will have free delivery and order below 100 AED will have a flat rate shipping charge of 15 AED in the <strong> UAE ONLY</strong>. Any other international shipping rates will depend on the country you want your order to be delivered. </label>
                        </div>
                    ) : null}
                    <div ref={myRef} className=" d-flex flex-column cm-50 cp-b-50 " >
                        <label className="cp-t-15 cp-b-25 label secHeading text-center primary-color" >Help & Support</label>
                        <label className="cp-t-5  label labelHeading text-center text-muted" >Do you have any <label className="label " style={{ color: 'black' }} >Questions?</label></label>
                        <label className=" cp-b-15 label labelText text-center text-muted" >Say Hello to our support team.</label>
                        <label className="   labelText text-center primary-color" ></label>
                        <div className='cm-t-5 cp-7 text-center touchAble col-12 col-md-6 align-content-center d-flex  align-self-center justify-content-between' style={{ borderRadius: 5 }} >
                            <a className='label border cp-t-7 cp-b-7 cp-r-30 cp-l-30 pText font-weight-bold touchAble  text-uppercase ' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="tel:+971588241922"> Call Us</a>
                            <a className='label border cp-t-7 cp-b-7 cp-r-30 cp-l-30 pText font-weight-bold touchAble  text-uppercase ' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0"> Whats App</a>
                            <a className='label border cp-t-7 cp-b-7 cp-r-30 cp-l-30 pText font-weight-bold touchAble  text-uppercase ' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"> email</a>
                        </div>
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default Faqs
