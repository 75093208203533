import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import { globleAPI as Global } from '../globals'
import axios from "axios";
import { BsChevronUp } from "react-icons/bs";
import { NeedHelp } from '../app-components/SmallComponents';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';


function Categories() {
    const [AllCategories, setAllCategories] = React.useState([]);
    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [SubCategoryID, setSubCategoryID] = React.useState('');
    const [AllCategoriesLuxury, setAllCategoriesLuxury] = React.useState([]);
    const [SubCategories, setSubCategories] = React.useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllCategoriesRegular()
        getAllCategoriesLuxury()
    }, []);

    const getAllCategoriesRegular = () => {
        axios
            .get(Global.GET_ALL_CATEGORIES + "?luxury=" + 0 + "&regular=" + 1,)
            .then(response => {
                setAllCategories(response.data.categories)
            })
            .catch(function (error) {
                console.log('no is showing or not', error)
            });
    };

    const getAllCategoriesLuxury = () => {
        axios
            .get(Global.GET_ALL_CATEGORIES + "?luxury=" + 1 + "&regular=" + 0,)
            .then(response => {
                setAllCategoriesLuxury(response.data.categories)
            })
            .catch(function (error) {
                console.log('no is showing or not', error)
            });
    };

    const getsubCategory = (id: any) => {
        if (SubCategoryID === '') {
            setSubCategoryID(id)
            axios
                .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=" + id)
                .then(response => {
                    setSubCategories(response.data.subcategories)
                })
                .catch(function (error) {
                    console.log('responnnse of cate', error)
                });
        } else {
            setSubCategories([])
            setSubCategoryID('')
        }
    }

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding" >
                <div className="cm-t-30 cm-b-30" >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
                        <Typography className="primary-color main-font" color="textPrimary">All Categories</Typography>
                    </Breadcrumbs>
                </div>
                <div className="d-flex flex-column cm-b-50 cp-b-50" >
                    <label className="secHeading main-font  primary-color " >All Categories</label>
                    <div className=" d-flex flex-column border-top cp-b-15" >
                        <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Luxury Items</label>
                        <div>
                            {AllCategoriesLuxury.map((category: any) =>
                                <div>
                                    <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                                        <label onClick={() => getsubCategory(category.id)} className=" label  labelText hoverUnderLine" >{category.name}</label>
                                        {SubCategoryID === category.id ? (
                                            <div className="cp-l-50" onClick={() => setSubCategoryID('')} >  <BsChevronUp className='f-10   ' /></div>
                                        ) : null}
                                    </div>
                                    {SubCategoryID === category.id ? (
                                        <div className="cp-l-30 cp-t-5">
                                            {SubCategories.map((category: any) =>
                                                <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                                                    <label onClick={() => getsubCategory(category.id)} className=" label text-muted  labelText hoverUnderLine" >{category.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=" d-flex flex-column border-top cp-b-15" >
                        <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Retail & Preloved Items</label>
                        <div>
                            {AllCategories.map((category: any) =>
                                <div>
                                    <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                                        <label onClick={() => getsubCategory(category.id)} className=" label  labelText hoverUnderLine" >{category.name}</label>
                                        {SubCategoryID === category.id ? (
                                            <div className="cp-l-50" onClick={() => setSubCategoryID('')} >  <BsChevronUp className='f-10   ' /></div>
                                        ) : null}
                                    </div>
                                    {SubCategoryID === category.id ? (
                                        <div className="cp-l-30 cp-t-5">
                                            {SubCategories.map((category: any) =>
                                                <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                                                    <label onClick={() => getsubCategory(category.id)} className=" label text-muted  labelText hoverUnderLine" >{category.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NeedHelp />
            <CFooter />
        </div>
    );
}
export default Categories