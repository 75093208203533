import Skeleton from 'react-loading-skeleton';

function ShimmerC(props: any) {

  const numbers = [1, 2, 3, 4, 5];
  const small = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className='flex-row' style={{ backgroundColor: '', overflow: 'hidden' }} >
      {props.smallshimer === 'no' ? (
        <div>
          <Skeleton height='550px' width='100vw' count={1} />
        </div>
      ) : props.smallshimer === 'yes' ? (
        <div className='col-12 d-flex flex-row' style={{ justifyContent: 'space-evenly' }} >
          {small.map((no: any) => (
            <div key={no} className=' cp-10'  >
              <Skeleton className='foobar' height={130} width={130} count={1} />
            </div>
          ))}
        </div>
      ) : props.allproducts === 'yes' ? (
        <div className='col-12 d-flex flex-row '  >
          {numbers.map((no: any) => (
            <div className='col-sm-3 col-6 col-md-3' style={{ backgroundColor: '', overflow: 'hidden' }}  >
              <Skeleton className='foobar' height={250} width='100vh' count={1} />
            </div>
          ))}
        </div>
      ) : props.allproductsFilters === 'yes' ? (
        <div>
          <div className='label' style={{ height: '20px', width: "100px" }} ><Skeleton width={200} count={1} /></div>
          {numbers.map((no: any) => (
            <div className='d-flex flex-row cm-t-10 ' >
              <div className='border d-flex align-self-center cm-t-2' style={{ height: '20px', width: "20px", backgroundColor: '#f5f5f5', color: 'red', borderRadius: 100, }} ></div>
              <div className='' style={{ width: "5px" }} ></div>
              <div className='label' style={{ height: '20px', width: "100px" }} ><Skeleton width={200} count={1} /></div>
            </div>
          ))}
        </div>
      ) : props.homeproducts === 'yes' ? (
        <div className='col-12 d-flex flex-row ' >
          {numbers.map((no: any) => (
            <div key={no} className='col-sm-4 col-6 col-md cm-r-25' style={{ overflow: 'hidden' }}  >
              <Skeleton className='foobar' height={200} width='100vh' count={1} />
              <div className=' d-flex flex-column  align-items-center'>
              </div>
            </div>
          ))}
        </div>
      ) : props.productdetailIMG === 'yes' ? (
        <div className='col-12' style={{ overflow: 'hidden' }}  >
          <Skeleton className='foobar ' height='500px' width='100vh' count={1} />
        </div>
      ) : props.LuxuryCate === 'yes' ? (
        <div className='col-12' style={{ overflow: 'hidden' }}  >
          <Skeleton className='foobar cm-5' height='50vh' width='27vw' count={6} />
        </div>
      ) : props.pictureIcon === 'yes' ? (
        <div className="d-flex justify-content-between" >
          <Skeleton className='foobar cm-10' style={{ borderRadius: 100 }} height='90px' width='90px' count={1} />
          <Skeleton className='foobar cm-t-40' style={{}} height='45px' width='200px' count={1} />
        </div>
      ) : null}
    </div>
  );
}

export default ShimmerC;

