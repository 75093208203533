import { useEffect, useState, useRef } from 'react';
import './MySales.scss'
import { useHistory } from 'react-router-dom'
import { NeedHelp } from '../../app-components/SmallComponents'
import CFooter from '../../app-components/CFooter';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import StoreDetails from '../../app-components/StoreDetails';
import Orders from '../../app-components/Orders';
import Redeem from '../../app-components/Redeem';
import RedeemHistory from '../../app-components/RedeemHistory';
import HolidayModescreen from '../../app-components/HolidayModescreen';
import Alertss from '../../app-components/Alertss';
import OrderDetailC from '../../app-components/OrderDetailC';
import OfferRequest from '../../app-components/OfferRequest';
import OffersDetailPage from '../../app-components/OffersDetailPage';
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function MySales(props: any) {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [displayUi, setdisplayUi] = useState('My Store')
  const [target, setTarget] = useState(null);
  const [targetWalet, settargetWalet] = useState(null);
  const [show, setShow] = useState(false);
  const [showWalet, setshowWalet] = useState(false);
  const history = useHistory();
  const [status] = useState('')
  const [showAlert] = useState(false)
  const [orderid, setorderid] = useState('')
  const [DetailViewData, setDetailViewData] = useState('')
  const [DetailViewData2, setDetailViewData2] = useState('')
  const [DetailViewMsgs, setDetailViewMsgs] = useState('')
  const ref = useRef(null);

  useEffect(() => {
    if (props.location.state?.status == 2) {
      setdisplayUi('Orders')
    } else if (props.location.state?.status == 3) {
      setdisplayUi('Sales')
    } else if (props.location.state?.status == 4) {
      setdisplayUi('Sales Offer Request')
    }
  }, [])


  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const sendsomeData = (data: any, data2: any) => {
    if (data == 'orderDetail') {
      setorderid(data2)
      setdisplayUi('Order Detail')
    }
  };

  const sendDetailview = (data: any, data2: any, data3: any) => {
    setDetailViewData(data)
    setDetailViewData2(data2)
    setDetailViewMsgs(data3)
    setdisplayUi('Sales Offer Requests')
  }

  const movetoSaleProduct = () => {
    history.push('/sale-product');
  };

  const handleClickWalet = (event: any) => {
    setshowWalet(!showWalet);
    settargetWalet(event.target);
  };


  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="d-flex justify-content-center  " >
        {showAlert == true && <Alertss type='success' status={status} />}
      </div>
      <div className="padding bg-color-light cp-b-60"  >
        <div className=" cp-t-40 cp-b-20"   >
          <label className="label secHeading primary-color" >My Sales</label>
          <div className="d-flex" >
            <div className='  main-font label   pointer f-13  text-color   '>
              <label onClick={handleClick} className=" hoverUnderLine touchAble label labelText cp-r-35 cp-t-10 cp-b-10 "  >My Store</label>
              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={50}
              >
                <Popover id="popover-contained">
                  <Popover.Content >
                    <div className=' d-flex flex-column cp-l-15 cp-r-15' >
                      <label className="primary-color hoverUnderLine labelText" onClick={() => {
                        setdisplayUi('My Store');
                        setShow(!show)
                      }} > Store   </label>
                      <label className="primary-color hoverUnderLine labelText" onClick={() => {
                        setdisplayUi('Orders');
                        setShow(!show)
                      }}  > Orders  </label>
                      <label className="primary-color hoverUnderLine labelText" onClick={() => {
                        setdisplayUi('Sales');
                        setShow(!show)
                      }}  > Sales </label>
                      <label className="primary-color hoverUnderLine labelText" onClick={() => movetoSaleProduct()}  > Sell Item </label>
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </div>
            <div className='  main-font label   pointer f-13  text-color   '>
              <label onClick={handleClickWalet} className=" hoverUnderLine touchAble label labelText cp-r-35 cp-t-10 cp-b-10 "  >My Wallet</label>
              <Overlay
                show={showWalet}
                target={targetWalet}
                placement="bottom"
                container={ref.current}
                containerPadding={50}
              >
                <Popover id="popover-contained">
                  <Popover.Content >
                    <div className=' d-flex flex-column cp-l-15 cp-r-15' >
                      <label className="primary-color hoverUnderLine labelText" onClick={() => {
                        setdisplayUi('My Balance');
                        setshowWalet(!showWalet)
                      }} > My Balance   </label>
                      <label className="primary-color hoverUnderLine labelText" onClick={() => {
                        setdisplayUi('Redeemed History');
                        setshowWalet(!showWalet)
                      }}  > Redeemed History  </label>
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </div>
            <label onClick={() => setdisplayUi('Sales Offer Request')} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Sales Offer Requests</label>
            <label onClick={() => setdisplayUi('Holiday')} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Holiday Mode</label>
          </div>
          {displayUi == 'Order Detail' ? (
            <div  >
              <label onClick={() => setdisplayUi('Orders')} className="label touchAble labelHeading primary-color cp-t-10 cp-r-5 " >Order </label>
              <label className="label labelHeading primary-color cp-t-10 " > / </label>
              <label className="label labelHeading primary-color cp-t-10 cp-l-5 " > {displayUi}</label>
            </div>
          ) : (
            <label className="label labelHeading primary-color cp-t-10 " >{displayUi}</label>
          )}
        </div>
        {displayUi == 'My Store' && <StoreDetails edit="true" />}
        {displayUi == 'Orders' && <Orders data='orders' sendsomeData={sendsomeData} />}
        {displayUi == 'Sales' && <Orders data='sales' sendsomeData={sendsomeData} />}
        {displayUi == 'Sales Offer Requests' && <OffersDetailPage data3="sales" data={DetailViewData} data2={DetailViewData2} massages={DetailViewMsgs} />}
        {displayUi == 'My Balance' && <Redeem />}
        {displayUi == 'Redeemed History' && <RedeemHistory />}
        {displayUi == 'Holiday' && <HolidayModescreen />}
        {displayUi == 'Sales Offer Request' && <OfferRequest data="sales" sendDetailview={sendDetailview} />}
        {displayUi == 'Order Detail' && <OrderDetailC data='orderDetailforMySales' orderId={orderid} />}
      </div>
      <NeedHelp />
      <CFooter />
    </div>
  );
}

export default MySales;