import './LoginScreen.scss';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import CheaderHalf from '../../app-components/CheaderHalf';
import { globleAPI as Global } from '../../globals'
import Alertss from '../../app-components/Alertss';

function ForgotPassword() {

  const history = useHistory();
  const [ALertType, setALertType] = useState('')
  const [status, setstatus] = useState<any>()
  const [showAlert, setshowAlert] = useState(false)
  const [email, setemail] = useState('')
  const [emailN, setemailN] = useState(false)
  var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;;

  useEffect(() => {
    if (history.location.state == 'singupalert') {
      setstatus('A verification email has been sent to your emial go and Verify')
      setshowAlert(true)
      setTimeout(() => {
        setshowAlert(false)
      }, 10000);
    }
  }, [])

  const Login = () => {
    if (email === '') {
      emailF()
    } else {
      axios.post(Global.FORGOT_PASSWORD, {
        email: email,
      })
        .then((response) => {
          if (response.data.status === 200) {
            setstatus(response.data.message)
            setshowAlert(true)
            setALertType('danger')
            setTimeout(() => {
              setshowAlert(false)
            }, 5000);
          }
        }, (error) => {
          console.log('login api response error', error);
        });
    }
  }

  const emailF = () => {
    if (mailformat.test(email)) {
      console.log('email is match')
      setemailN(false)
    } else {
      setemailN(true)
    }
  }

  return (
    <div >
      <CheaderHalf />
      {showAlert == true && <Alertss type={ALertType} status={status} />}
      <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
          <p className='  f-15 ' style={{ color: 'gray', textAlign: 'center' }} >Enter your email or phone number <br /> to recover your account</p>
          <div style={{ marginTop: '1%' }}>
          </div>
        </ div>
        <form >
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
            <div className='col-md-3 col-lg-3 col-8 d-flex cm-t-10 flex-column' >
              <input id="email" onBlur={() => emailF()} type="text" className="form-control" name="email" placeholder="Email" value={email} onChange={(text: any) => setemail(text.target.value)} />
              {emailN == true ? (<p className='label text-danger f-13 main-font' >Email is not valid</p>) : null}
            </div>
          </div>
        </form>
        <div className='margintop' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <div className='col-md-3 col-8 col-xl-3' >
            <div className='col-12' >
              <label style={{ width: '100%', backgroundColor: '#732F3E', color: 'white' }} onClick={() => Login()} className="btn border-0"  >Send Code</label>
            </div>
          </ div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
