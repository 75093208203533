import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { globleAPI as Global } from '../globals'
import Alertss from '../app-components/Alertss';
import dummyPic from '../assets/emojiwindle.png';
import Moment from 'react-moment';
import slugify from 'react-slugify';

function OffersDetailPage(props: any) {

    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')
    const [ProductDetails, setProductDetails] = useState<any>([])
    const [offer_logs, setoffer_logs] = useState<any>([])
    const [status, setstatus] = useState('')
    const [showAlert, setshowAlert] = useState(false)
    const [Amount, setAmount] = useState<any>('')
    const [errorofAmount, seterrorofAmount] = useState<any>(false)
    const [CounterModel, setCounterModel] = useState<any>(false)
    const [VendorMakeAnOffer, setVendorMakeAnOffer] = useState<any>(true)
    const [BuyerMakeAnOffer, setBuyerMakeAnOffer] = useState<any>(true)
    const [messags, setmessags] = useState<any>('')
    const [notice, setnotice] = useState<any>(false)
    const history = useHistory();

    useEffect(() => {
        GetCustomerOfferLogs()
    }, [])

    const moveToDetailScreen = (data: any) => {
        history.push('/product-detail/' + data.id + '/' + slugify(data.name))
      }


    const GetCustomerOfferLogs = () => {
        axios
            .get(Global.GetCustomerOfferLogs + token + '&product_id=' + props?.data.product_id + '&offer_id=' + props?.data.price_offer_logs[0].price_offer_id)
            .then(response => {
                setProductDetails(response.data.product_details)
                setmessags(response.data.price_offer)
                setoffer_logs(response.data.offer_logs)
                console.log('hello response e', response)
                let userID = response.data.offer_logs.filter((data: any) => data.creator_id == userid)
                if (userID.length >= 2) {
                    if (userid == response.data.product_details.supplier_id) {
                        setVendorMakeAnOffer(false)
                    } else {
                        if (userID.length == 3) {
                            setBuyerMakeAnOffer(false)
                        }
                    }
                }
                if (response.data.offer_logs[0].status == 25 ||response.data.offer_logs[0].status == 10 ) {
                    let reciverid = response.data.offer_logs[0].receiver_id
                    let creatorid = response.data.offer_logs[0].creator_id
                  let copyofarray  = JSON.parse(JSON.stringify(response.data.offer_logs[0]))
                  copyofarray.creator_chat_message = copyofarray.status == 10 ? copyofarray.offer_type == 2 ? 'Counter offer rejected':'Offer rejected':  copyofarray.offer_type == 2 ? 'Counter offer accepted':'Offer accepted'
                  copyofarray.receiver_chat_message = copyofarray.status == 10 ? copyofarray.offer_type == 2 ? 'Counter offer rejected':'Offer rejected':  copyofarray.offer_type == 2 ? 'Counter offer accepted':'Offer accepted'
                  copyofarray.creator_id = reciverid
                  copyofarray.receiver_id = creatorid
                  copyofarray.profile_pic  = null
                    setoffer_logs([copyofarray, ...response.data.offer_logs])
                }

            })
            .catch(function (error) {
                console.log('reposne of api', error)
            });
    }

    const offerDecion = (data: any) => {
        if (data == 'Accept') {
            let formdata = new FormData();
            formdata.append("price_offer_log_id", offer_logs[0].id);
            formdata.append("status", '25');
            formdata.append("response_time_in_hors", '24');
            axios({
                method: "post",
                url: Global.ChangeOfferStatusV1 + token,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    GetCustomerOfferLogs()
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                    setstatus(response.data.message)
                    console.log('response', response)
                })
                .catch(function (error) {
                    console.log('response', error)
                });
        } else {
            let formdata = new FormData();
            formdata.append("price_offer_log_id", offer_logs[0].id);
            formdata.append("status", JSON.stringify(10));
            formdata.append("response_time_in_hors", JSON.stringify(24));
            axios({
                method: "post",
                url: Global.ChangeOfferStatusV1 + token,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    GetCustomerOfferLogs()
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                    setstatus(response.data.message)
                })
                .catch(function (error) {
                    console.log('response', error)
                });
        }
    }

    const modelCLose = () => {
        if (Amount == '') {
            seterrorofAmount(!errorofAmount)
        } else {
            setCounterModel(!CounterModel)
            makeCounterOffer()
        }
    }

    const enterAmount = (data: any) => {
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (rx_live.test(data)) {
            setAmount(data)
        }
    }

    const makeCounterOffer = () => {
        let offer_typeVar = offer_logs[0].offer_type,
            user_type = userid == offer_logs[0].user_id ? "seller" : "buyer";
        let formdata = new FormData();
        formdata.append("offer_price", Amount);
        formdata.append("offer_type", JSON.stringify(2));
        formdata.append("creator_id", userid);
        formdata.append("receiver_id", offer_logs[0].creator_id);
        formdata.append("price_offer_id", offer_logs[offer_logs.length - 1].price_offer_id);
        formdata.append("parent_offer_id", offer_logs[0].id);
        formdata.append("product_id", ProductDetails?.product_id);

        if (offer_logs[0]?.creator_id != userid) {
            if (offer_typeVar == 2)
                if (user_type == "buyer") formdata.append("response_time_in_hors", JSON.stringify(24));
                else formdata.append("response_time_in_hors", JSON.stringify(48));
            else {
                if (user_type == "buyer") formdata.append("response_time_in_hors", JSON.stringify(24));
            }
        }

        fetch(Global.MakeCounterOfferV1 + token, {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: formdata,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (
                    offer_logs[0]?.status == 24 &&
                    offer_logs.filter(function (item: any) {
                        return (
                            item?.offer_type == 2 && item?.creator_id == userid
                        );
                    }).length > 1
                ) {
                    setnotice(true)
                } else console.log("Condition Failed:");
                GetCustomerOfferLogs()

            })
            .catch((error) => {
                alert("Error: " + error);
            });
    };


    return (
        <div>
            {showAlert == true && <Alertss type='success' status={status} />}
            <div className="cp-15" style={{ backgroundColor: 'white', width: '45vw' }} >
                <div className="d-flex c-border-bottom-light cp-b-15" >
                    <div className=" d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                        <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + ProductDetails.image} ></img>
                    </div>
                    <div className="align-self-center cp-l-15 d-flex  flex-column " >
                        <label className=" main-font labelText primary-color label" >{ProductDetails.name}</label>
                        <label className=" main-font text-muted pText label" >{ProductDetails.manufacturer_name}</label>
                        <label className=" main-font text-muted pText label" >Retail Price: AED {ProductDetails.unit_price}</label>
                        <label className=" main-font primary-color labelText label " >  {props?.data3 == 'purchase' ? ('OFFER SENT:') : ('OFFER RECIEVED:')}  {offer_logs[0]?.offer_price}</label>
                    </div>
                </div>
                {notice == true &&
                    <label>Please note, this is your final offer.</label>
                }
                {offer_logs.length >= 1 &&
                    <div>
                        {props?.data2 == 'inprogress' &&
                            <div>
                                {offer_logs[0]?.creator_id != userid &&
                                    <div>
                                        {offer_logs[0].status == 24 &&

                                            <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                                                <div onClick={() => offerDecion('Accept')} className='cm-t-10 cp-7 text-center touchAble  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '35%', backgroundColor: '#732F3E', borderRadius: 3 }} >
                                                    <p className='label labelText  touchAble' style={{ color: 'white' }}>ACCEPT</p>
                                                </div>
                                                <div onClick={() => offerDecion('Decline')} className='cm-t-10 cp-7 text-center touchAble  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '35%', borderRadius: 3, borderColor: '#732F3E', borderWidth: 1 }} >
                                                    <p className='label labelText  touchAble label' style={{}}>DECLINE</p>
                                                </div>
                                            </div>
                                        }
                                        {(CounterModel == false && (BuyerMakeAnOffer == true && VendorMakeAnOffer == true)) ? (
                                            <div>
                                                {offer_logs[0].status == 24 &&
                                                    <div className="d-flex c-border-bottom-light cp-b-15  cp-t-10 justify-content-center" >
                                                        <div onClick={() => setCounterModel(!CounterModel)} className='cm-t-10 cp-7 text-center touchAble  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '85%', borderColor: '#732F3E' }} >
                                                            <p className='label labelText  touchAble  ' style={{}}>MAKE A COUNTER OFFER</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ) : (CounterModel == false && VendorMakeAnOffer == true) ? (
                                            <div></div>
                                        ) : null}
                                        {CounterModel == true &&
                                            <div className="c-border-bottom-light cp-b-15  cp-t-10 justify-content-center" >
                                                <p className='label labelText  cp-t-20 touchAble  ' style={{ textAlign: 'center' }}>MAKE A COUNTER OFFER</p>

                                                <div className="d-flex cp-b-15  cp-t-10 justify-content-center" >
                                                    <div className='cm-t-10  text-center touchAble align-content-center   align-self-center justify-content-center' style={{ width: '85%' }} >
                                                        <input className="form-control" placeholder="Enter Amount" value={Amount} onChange={(text: any) => enterAmount(text.target.value)} />
                                                        {(errorofAmount == true && Amount == '') &&
                                                            <p className='label labelText   touchAble  ' style={{ textAlign: 'left', color: 'red' }}>Please enter the Amount first </p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex  justify-content-center" >
                                                    <div onClick={() => modelCLose()} className='cm-t-10 cp-7 text-center touchAble  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '85%', backgroundColor: '#732F3E', borderRadius: 3 }} >
                                                        <p className='label labelText  touchAble' style={{ color: 'white' }}>CONFIRM</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="d-flex cm-t-10 flex-column " style={{ alignItems: 'center' }} >
                                    <label style={{ textAlign: 'center', paddingTop: 20, width: '60%' }} >
                                        <span dangerouslySetInnerHTML={{ __html: userid == offer_logs[0].receiver_id ? messags.receiver_message : messags.creator_message }} />
                                    </label>
                                </div>
                                {(messags.status == 25 && messags.user_id == userid) &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                                        <div onClick={()=>moveToDetailScreen(ProductDetails)} className='cm-t-10 cp-10  text-center touchAble bg-color-primary  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '85%', borderColor: 'rgb(218, 95, 115)' }} >
                                            <p className='label labelText  touchAble  ' style={{ color: 'white' }}>BUY NOW</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {props?.data2 == 'finished' &&
                    <div className="d-flex flex-column justify-content-center align-item-center " >
                        <div className="d-flex cm-t-10 flex-column " style={{ alignItems: 'center' }} >
                            <label style={{ textAlign: 'center', paddingTop: 20, width: '50%' }} >
                                <span dangerouslySetInnerHTML={{ __html: userid == offer_logs[0].receiver_id ? messags.receiver_message : messags.creator_message }} />
                            </label>
                        </div>
                        <div className='cm-t-10 cp-7 text-center touchAble bg-color-primary  align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '85%', borderColor: 'rgb(218, 95, 115)' }} >
                            <p className='label labelText  touchAble  ' style={{ color: 'white' }}>BUY NOW</p>
                        </div>
                    </div>
                }
                <div className="d-flex cp-b-15  cp-t-10 justify-content-center" >
                    <div className='cm-t-10 cp-7 text-center touchAble  bg-color-light align-content-center d-flex  align-self-center justify-content-center c-border' style={{ width: '85%', borderRadius: 3 }} >
                        <p className='label labelText  touchAble    '>History</p>
                    </div>
                </div>
                <div className="justify-content-center" >
                    {offer_logs.map((data: any) =>
                        <div style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >
                            <div className=" cp-t-15" style={{ width: '85%', height: 'auto', display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }}>
                                <div className=" flex-column-reverse d-flex align-item-center" style={{ width: "60px", borderRadius: 100 }} >
                                    <div className=" d-flex align-item-center justify-content-center" style={{ width: "60px", borderRadius: 100, height: '60px' }} >
                                        <img className='border' style={{ width: "60px", height: '60px', borderRadius: 100, borderWidth: 1 }} src={data.profile_pic == null ? dummyPic : Global.IMAGE_PATH + data.profile_pic} ></img>
                                    </div>
                                </div>
                                <div className="d-flex flex-column   bg-color-light cp-10 cm-l-12" style={{ width: "100%" }} >
                                    <label className=" main-font pText label text-muted " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} > <Moment format="D MMM YYYY" withTitle>{data.created_at}</Moment></label>

                                    <label className=" main-font labelText label text-muted " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >{userid == data.receiver_id ? data.receiver_chat_message : data.creator_chat_message}  </label>
                                    {data.status == 25

                                    }
                                    {/* {(data.offer_type == 2 && data.status == 24) &&
                                        <label className=" main-font labelText label text-muted " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >Counter offer sent</label>
                                    }
                                    {(data.offer_type == 2 && data.status == 25) &&
                                        <label className=" main-font labelText label text-muted " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >Counter offer accepted</label>
                                    }
                                    {(data.offer_type == 2 && data.status == 10) &&
                                        <label className=" main-font labelText label text-muted " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >Counter Offer Declined</label>
                                    } */}
                                    <label className=" main-font text-muted labelText uppercase label " style={{ display: 'flex', flexDirection: (data.creator_id == ProductDetails.supplier_id) ? 'row-reverse' : 'row' }} >AED {data.offer_price}</label>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OffersDetailPage;



