import { useState } from 'react';
import google from '../assets/google-app (1).png'
import appstore from '../assets/appstore.png'
import telephone from '../assets/uniqueProducts.png'
import whatsapp from '../assets/2.png'
import emaildd from '../assets/3.png'
import banner2 from '../assets/getTheApp.png'
import banner from '../assets/banner.jpg'

function JoinOurNewsLetter(props: any) {

    const [email, setemail] = useState('')

    const senddataToParent = () => {
        if (email !== '') {
            props.methode(email)
        }
    }

    return (
        <div>
            <div className='webdisplay' style={{ display: 'flex', alignItems: 'center', background: `url(${banner2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '65vh', width: '100%', backgroundColor: '#eee' }} >
                <div className='col-md-6' style={{ marginBottom: 60, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                    <label className='label' style={{ color: '#7F1D36', fontSize: '45px', fontWeight: 700, lineHeight: 1, }} >GET 15% OFF</label>
                    <label className='label ' style={{ color: 'black', fontSize: '28px', textAlign: 'center', lineHeight: 1.3 }} >on your First App Order <br /> Enter code <label className='' style={{ color: '#7F1D36', fontSize: '28px', fontWeight: 700, }}>LFAPP15</label>  at checkout</label>
                    <div style={{ marginTop: 25 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.lesfemmes&hl=en" target="_blank" >  <img className="touchAble" alt='img' height="60rem" src={google} ></img></a>
                        <a href="https://apps.apple.com/pk/app/lesfemmes/id1396175773" target="_blank">  <img className="touchAble" alt='img' height="60rem" src={appstore} ></img></a>
                    </div>
                </div>
                <div style={{}} >
                </div>
            </div>
            <div className='mobiledisplay' style={{ paddingTop: '15px' }} >
                <img alt='img' width={'100%'} src={banner} ></img>
            </div>
            <div className='joinnewsLetterDiv' >
                <div className='col-md-6' style={{ display: 'flex', paddingLeft: '10%' }} >
                    <div className='cp-t-2rem  cp-b-2rem '>
                        <div className='mainHeading main-font'>
                            Join Our NewsLetter
                        </div>
                        <div className='cp-b-1rem main-font'>
                            Sign up for our newsletter and don't miss a thing!
                        </div>
                        <input id='email' className='subscriptinput' onChange={(text: any) => setemail(text.target.value)} style={{ backgroundColor: 'white' }} placeholder='Enter your email address'></input>
                        <span onClick={() => senddataToParent()} className='sub-btn capitalize font-weight-bold main-font' style={{}}>Subscribe</span>
                        <div className='cp-b-1rem main-font' style={{ color: '#7F1D36' }}>
                            {props?.text}
                        </div>

                    </div>
                </div>
                <div className='col-md-5  cp-b-20 ' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                    <div className='' style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }} >
                        <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                            <div  >
                                <img className="touchAble" alt='img' height="100rem" src={telephone} ></img>
                            </div>
                            <label className='f-12  cp-t-15 text-center main-font' >AUTHENTICATED UNIQUE <br /> LUXURY ITEMS</label>
                        </div>
                        <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                            <div  >
                                <img className="touchAble" alt='img' height="100rem" src={whatsapp} ></img>
                            </div>
                            <label className='f-12 cp-t-15 text-center main-font' >AFFORDABLE LUXURY</label>
                        </div>
                        <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                            <div  >
                                <img className="touchAble" alt='img' height="100rem" src={emaildd} ></img>
                            </div>
                            <label className='f-12 cp-t-15 text-center main-font' >WORLDWIDE DELIVERY <br /> & EASY RETURNS</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinOurNewsLetter;

