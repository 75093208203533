import {useHistory } from 'react-router-dom'
import React, { useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import slugify from 'react-slugify';
import { globleAPI as Global } from '../globals'
import girl from '../assets/emojiwindle.png'
import Moment from 'react-moment';

function NotificationSystem(props: any) {
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });
  const [imageProfile] = useState(localStorage.getItem('imageProfile') || '')
  const history = useHistory();

  const changereadstatus = (data: any) => {
 props.methode(data)        
  }
  
  const movetoNotification = (item: any) => {
    changereadstatus(item.notification_id);
    if (item.type === 22 || item.type === 31 || item.type === 29 || item.type === 30) {
      history.push('/my-sales', { status: '2' });
    } else if (item.type === 18) {
      props.navigation.navigate("AdminPostDetail", {
        id: parseInt(item.post_id),
      });
    } else if (item.type === 23) {
    } else if (item.type === 24 || item.type === 25 || item.type === 26 || item.type === 27 || item.type === 28) {
      window.open('/product-detail/' + item.order_id + '/' + slugify(item.product_name.name));
    } else if (item.type === 32) {
      history.push('/my-sales', { status: '3' });

    } else if (
      item.type === 33 ||
      item.type === 34 ||
      item.type === 35 ||
      item.type === 36 ||
      item.type === 37 ||
      item.type === 38
    ) {
      history.push(item?.creator_id != item?.supplier_id ? 'my-sales' : 'purchases', { status: 4 });
    }
  }

  return (
    <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', position: 'absolute', width: '100%', zIndex: 1 }} >
          <div style={{ backgroundColor: 'white', width: '35vw', alignSelf: 'flex-end', height: '100vh', padding: 20 }}>
            <div >
              <label style={{ fontSize: 22, color: 'black', fontWeight: 'bold' }} >Notifications</label>
              <div className='overflow-auto ' style={{ height: '90vh' }} >
                {props?.data.map((data: any) =>
                  <div onClick={() => movetoNotification(data)} className='label touchAble' style={{ paddingTop: 10, paddingBottom: 7, paddingRight: 20, display: 'flex', borderBottomWidth: 0.2, borderBlockColor: 'lightgray', borderBottomStyle: 'solid',backgroundColor:data.status == 3 ?'rgba(00, 00, 00, 0.1)':'white'}} >
                    <div style={{ width: '18%' }} >
                      <img className="touchAble" style={{ height: 55, width: 55, backgroundColor: 'blue', borderRadius: 100 }} alt='img' src={imageProfile !== 'null' ? Global.IMAGE_PATH + imageProfile : girl} ></img>
                    </div>
                    <div className='touchAble' style={{ width: '64%', height: 60, display: 'flex', flexDirection: 'column' }} >
                      <div style={{ display: 'flex', lineHeight: '20px', height: 60, }} >
                        <label  className='label f-13 touchAble' style={{ paddingLeft: 5, color: 'black' }}  > <label className='label' style={{ color: 'black' }}  ></label><span dangerouslySetInnerHTML={{ __html: data.web_notification_message }} /> </label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column-reverse' }} >
                        <label className='cp-l-5 touchAble' > <Moment style={{ fontSize: 14, color: 'gray' }} format="D MMM YYYY" withTitle>{data.created_at}</Moment></label>
                      </div>
                    </div>
                    <div style={{ width: '18%', height: 60, display: 'flex', flexDirection: 'row-reverse' }} >
                      {data.product_pic !== null &&
                        <img className="touchAble" style={{ height: 60, width: 60, borderRadius: 5 }}  alt='img' src={Global.IMAGE_PATH + data.product_pic} ></img>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default NotificationSystem;
