import { useEffect, useState } from 'react';
import '../screens/sellers-side/MySales.scss'
import Select from 'react-select'
import 'react-date-picker/dist/DatePicker.css';


function MultiselectDropDown(props: any) {

  const [Selectedoptions, setSelectedoptions] = useState<any>([])
  const [notification, setnotification] = useState(false)
  const [notificationExcede, setnotificationExcede] = useState(false)

  useEffect(() => {
    if (props?.blur == true) {
      setnotification(true)
    }
    
  })

  const selectdata = (data: any) => {
    if (data.length > 10) {
      setnotificationExcede(true)
      setSelectedoptions(data)
    } else if (data.length <= 0) {
      setnotification(true)
      props?.methode(data)
    } else {
      setSelectedoptions(data)
      props?.methode(data)
      setnotification(false)
      setnotificationExcede(false)
    }
  }

     const fruits:any = props?.value 
 
  return (
    <div >
     
      <p className='label primary-color text-capitalize labelText cp-t-20 cp-b-10 f-600 main-font' >{props?.placeholder}</p>
      <Select
        name="form-field-name"
        value={fruits}
        isDisabled={props?.props?.value?.value == '' ? true : false}
        placeholder={props.placeHolder !== '' ? props.placeHolder : ''}
        isMulti={props.multiselect == 'true' ? true : false}
        isSearchable={true}
        options={props?.DropDownData}
        onChange={selectdata}
        tabSelectsValue={true}
      />
      {(props?.value?.length < 1 && notification) ? (<p className='label text-danger f-13 main-font' >Required</p>) : null}
      {notificationExcede == true ? (<p className='label text-danger f-13 main-font' >YOu can chsod</p>) : null}
    </div>
  );
}

export default MultiselectDropDown;

