import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import axios from "axios";
import { globleAPI as Global } from '../globals'
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function AllBrandsT() {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [brandsList, setbrandsList] = useState([])
    const [brandsLista] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z'])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (Selected == 'Luxury') {
            let data = "?luxury=1&regular=0"
            getBrands(data)
        } else {
            let data = "?luxury=0&regular=1"
            getBrands(data)
        }
    }, []);

    const getBrands = (data: any) => {
        axios
            .get(Global.GET_BRAND_FILTER + data)
            .then(response => {
                setbrandsList(response.data.brands);
            })
            .catch(function (error) {
                console.log('response.data.brands', error)
            });
    }

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding" >
                <div className="cm-t-30 cm-b-30" >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
                        <Typography className="primary-color main-font" color="textPrimary">All Brands</Typography>
                    </Breadcrumbs>
                </div>
                <div className="d-flex flex-column" >
                    <label className="secHeading main-font  primary-color " >All Brands</label>
                </div>
                {brandsLista?.map((datadaf: any) =>
                    <div className="border-top d-flex flex-row cp-t-10 cp-b-5" >
                        <label className=" labelHeading cp-l-5 cp-r-5" >{datadaf}</label>
                        <div className="d-flex flex-row flex-wrap  col-12 "  >
                            {brandsList.map((data: any) =>
                                datadaf === data.name.charAt(0) && <label className="col-md-4 pText main-font"   >{data.name.trim()}</label>)}
                        </div>
                    </div>
                )}
            </div>
            <CFooter />
        </div>
    );
}
export default AllBrandsT