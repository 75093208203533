import React, { useState, useEffect } from 'react';
import axios from "axios";
import './SignUp.scss';
import { FaFacebook } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import CheaderHalf from "../../app-components/CheaderHalf";
import { useHistory } from "react-router-dom";
import { globleAPI as Global } from '../../globals'
import Alertss from '../../app-components/Alertss';
import MultiselectDropDown from '../../app-components/MultiselectDropDown';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';


function SignUp() {

    const [value, onChange] = useState<any>(new Date());
    const [status, setstatus] = useState('')
    const [showAlert, setshowAlert] = useState(false)
    const [alertType, setalertType] = useState('success')
    const [FirstName, setFirstName] = useState<any[]>([])
    const [lastName, setlastName] = useState<any[]>([])
    const [email, setemail] = useState<any[]>([])
    const [password, setpassword] = useState<any[]>([])
    const [repassword, setrepassword] = useState<any[]>([])
    const [retailStore, setretailStore] = useState(false)
    const [female, setfemale] = useState('no')
    const [agree, setagree] = useState('no')
    const [Country, setCountry] = useState<any>('')
    const [idNumber, setidNumber] = useState()
    const [expDate, setexpDate] = useState('')
    const [Bname, setBname] = useState('')
    const [idType, setidType] = useState('')
    const [Blicense, setBlicense] = useState('')
    const [Badress, setBadress] = useState('')
    const [firstN, setfirstN] = useState(false)
    const [lastN, setlastN] = useState(false)
    const [emailN, setemailN] = useState(false)
    const [passwordvalid, setpasswordvalid] = useState(false)
    const [blurOption, setblurOption] = useState(false);
    const [retypePasswordvalid, setretypePasswordvalid] = useState(false)
    const [agreeError, setagreeError] = useState(false)
    const [phoneNumber, setphoneNumber] = useState('')
    const [businessname, setbusinessname] = useState(false)
    const [businessadress, setbusinessadress] = useState(false)
    const [businessId, setbusinessId] = useState(false)
    const [idCardno, setidCardno] = useState(false)
    const [proprietor, setproprietor] = useState(true)
    const [token] = useState(localStorage.getItem('token') || '')
    const history = useHistory();
    const [AllCountries, setAllCountries] = useState<any>([])
    var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;;
    const nationality = [{ value: 'National ID', label: 'National ID' }, { value: 'Residence ID', label: 'Residence ID' }]

    useEffect(() => {
        if (token !== '') {
            history.push('/')
        }
        GetCountriesListV2()
    }, [])

    const idCard = (data: any) => {
        var numbers = /^[0-9\b]+$/;
        if (Number(data)) {
            setidNumber(data)
        }
    }

    const GetCountriesListV2 = () => {
        axios.get(Global.WebGetCountriesListV2)
            .then(response => {
                setAllCountries(response.data.countires)
            })
            .catch(function (error) {
                console.log('data of product id =ffffff rtertewrtew', error)
            });
    }

    const retailstore = () => {
        setretailStore(!retailStore)
    }

    const iamFemale = () => {
        if (female == 'no') {
            setfemale('yes')
        } else {
            setfemale('no')
        }
    }

    const isagree = () => {
        console.log('is agrree running')
        if (agree == 'no') {
            setagree('yes')
        } else {
            setagree('no')
        }
    }

    const propiriter = () => {
        setproprietor(!proprietor)
    }

    const firstName = () => {
        if (FirstName.length < 1) {
            setfirstN(true)
        } else {
            setfirstN(false)
        }

    }

    const lastNa = () => {
        if (lastName.length < 1) {
            setlastN(true)
        } else {
            setlastN(false)
        }

    }

    const passwordFunc = () => {
        if (password.length < 6) {
            setpasswordvalid(true)
        } else {
            setpasswordvalid(false)
        }
    }

    const repasswordFunc = () => {
        if (repassword !== password || repassword.length < 6) {
            setretypePasswordvalid(true)
        } else {
            setretypePasswordvalid(false)
        }
    }

    const emailF = () => {
        if (mailformat.test(email)) {
            console.log('email is match')
            setemailN(false)
        } else {
            setemailN(true)
        }
    }

    const BusName = () => {
        if (Bname.length < 1) {
            setbusinessname(true)
        } else {
            setbusinessname(false)
        }
    }

    const Busadress = () => {
        if (Badress.length < 1) {
            setbusinessadress(true)
        } else {
            setbusinessadress(false)
        }
    }

    const Busid = () => {
        if (Blicense.length < 1) {
            setbusinessId(true)
        } else {
            setbusinessId(false)
        }
    }

    const idCardNumber = () => {
        if (idNumber == '') {
            setidCardno(true)
        } else {
            setidCardno(false)
        }
    }

    const countryFunction = (data: any) => {
        setCountry(data)
    }

    const signUp = () => {
        window.scrollTo(0, 0)
        if (retailStore == true) {
            if (FirstName.length < 1 || lastName.length < 1 || email.length < 1 || password.length < 6 || repassword != password || agree == 'no' || idType == '' || Country == '' || idNumber == '' || value == '' || Bname == '' || Blicense == '' || Badress == '') {
                setagreeError(true)
                firstName()
                lastNa()
                passwordFunc()
                repasswordFunc()
                emailF()
                setblurOption(true)
                setTimeout(() => {
                    setblurOption(false)
                }, 10);
                idCardNumber()
                BusName()
                Busid()
                Busadress()
            } else {
                let data;
                data = {
                    first_name: FirstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                    phone_number: phoneNumber,
                    dob: 'null',
                    is_retailer: 1,
                    business_license_type: 'fda',
                    id_type: idType,
                    id_card_number: idNumber,
                    id_card_expiry: expDate,
                    id_card_issuance_country: Country,
                    registered_business_name: Bname,
                    business_license_number: Blicense,
                    business_address: Badress,
                    middle_name: null,
                }

                axios.post(Global.REGISTER, data)
                    .then((response) => {
                        console.log('login api response error', response);
                        // console.log(response)
                        if (response.data.status == 402 || response.data.status == 401) {
                            setstatus(response.data.Message)
                            setalertType('danger')
                            setshowAlert(true)
                            setTimeout(() => {
                                setshowAlert(false)
                            }, 5000);
                        } else if (response.data.status == 200) {
                            history.push('/login', ['singupalert'])
                        }
                    }, (error) => {
                        console.log('login api response error', error);
                    });
            }
        } else {
            if (FirstName.length < 1 || lastName.length < 1 || email.length < 1 || password.length < 6 || repassword != password || agree == 'no') {
                setagreeError(true)
                firstName()
                lastNa()
                passwordFunc()
                repasswordFunc()
                emailF()
            } else {
                let data;
                data = {
                    first_name: FirstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                    phone_number: phoneNumber,
                    dob: 'null',
                }

                axios.post(Global.REGISTER, data)
                    .then((response) => {
                        console.log(response)
                        if (response.data.status == 402 || response.data.status == 401) {
                            setstatus(response.data.Message)
                            setshowAlert(true)
                            setalertType('danger')
                            setTimeout(() => {
                                setshowAlert(false)
                            }, 5000);
                        } else if (response.data.status == 200) {
                            history.push('/login', ['singupalert'])
                        }
                    }, (error) => {
                        console.log('login api response error', error);
                    });
            }


        }
    }

    const responseGoogle = (data: any) => {
        console.log('data', data.profileObj)
        saveGoogleLoginData(data.profileObj)
        console.log('data', data.Os.ET)
        console.log(data.Os.GR)
        console.log(data.Os.zt)
    }

    const saveGoogleLoginData = (data: any) => {
        console.log(data.givenName)
        console.log(data.familyName)
        console.log(data.email)

        axios.post(Global.GOOGLE_LOGIN, {
            first_name: data.givenName,
            last_name: data.familyName,
            email: data.email,
        })
            .then((response) => {
                if (response.data.status == 200) {
                    localStorage.setItem('userid', response.data.user.id);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('first_name', response.data.user.first_name);
                    history.push('/')
                    setalertType('success')
                    setstatus(response.data.message)
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                } else {
                    setstatus(response.data.message)
                    setshowAlert(true)
                    setalertType('danger')
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                }
            }, (error) => {
                console.log('login api response error', error);
            });
    }

    const savefacebookRegister = (data: any) => {
        axios.post(Global.GOOGLE_LOGIN, {
            first_name: data.name,
            last_name: data.familyName,
            email: data.email,
        })
            .then((response) => {
                if (response.data.status == 200) {
                    localStorage.setItem('userid', response.data.user.id);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('first_name', response.data.user.first_name);
                    history.push('/')
                    setalertType('success')
                    setstatus(response.data.message)
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                } else {
                    setalertType('danger')
                    setstatus(response.data.message)
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                }
            }, (error) => {
                console.log('login api response error', error);
            });
    }

    const responseGoogleFail = (data: any) => {
        setstatus('There is a problem please try again later')
        setshowAlert(true)
        setTimeout(() => {
            setshowAlert(false)
        }, 5000);
    }

    const responseFacebook = (response: any) => {
        savefacebookRegister(response)
    }

    return (
        <div >
            <div className="" style={{ zIndex: 1 }} >
                {showAlert == true && <Alertss type={alertType} status={status} />}
            </div>
            <CheaderHalf />
            <div className='d-flex justify-content-center flex-row col-12  cp-t-29 cp-b-29  '  >
                <div className='col-md-4 col-sm-12 col-lg-4 border-radius div-box-shadow' style={{ backgroundColor: 'white' }} >
                    <p className='text-center f-18 f-600 cp-t-15' >Create an Account</p>
                    <p className='text-center f-14 cm-l-50 cm-r-50  ' >Les Femmes: Buy and sell fashion, authentic luxury, top brands, preloved & more </p>
                    <div className='d-flex flex-wrap  justify-content-center ' >
                        <div>
                            <GoogleLogin
                                clientId="937559180864-6fq71ahipnkpkgnpl6ht1emnutqc6knd.apps.googleusercontent.com"
                                buttonText="Continue with google"
                                onSuccess={() => responseGoogle}
                                onFailure={() => responseGoogleFail}
                            />
                        </div>
                        <div className="  " style={{ height: '5px' }} >
                            <FacebookLogin
                                containerStyle={{ height: '10px' }}
                                appId="1051922581635540"
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                cssClass="  stylinssg cp-8  bg-white  div-box-shadow c-border-bottom-light border-0 cm-t-2 cm-l-10 f-14 text-muted  "
                                icon={<FaFacebook size={25} color={'#3b5998'} style={{ marginRight: 10 }} />}
                            />
                        </div>
                    </div>
                    <p className='text-center f-500 text-truncate ' style={{ margin: '3%', color: 'lightgray' }}>――――――― or sign up with email ―――――――</p>
                    <div className='d-flex flex-wrap  ' >
                        <div className='col-md-6 col-lg-6 col-12' >
                            <input onBlur={() => firstName()} type="text" className=" input form-control" required={true} placeholder="First Name" value={FirstName} onChange={(text: any) => setFirstName(text.target.value)} />
                            {firstN == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                        </div>
                        <div className='col-md-6 col-lg-6 col-12  ' >
                            <input onBlur={() => lastNa()} type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(text: any) => setlastName(text.target.value)} />
                            {lastN == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 col-12 d-flex cm-t-10 flex-column' >
                        <input id="email" onBlur={() => emailF()} type="text" className="form-control" name="email" placeholder="Email" value={email} onChange={(text: any) => setemail(text.target.value)} />
                        {emailN == true ? (<p className='label text-danger f-13 main-font' >Email is not valid</p>) : null}
                    </div>
                    <div className='col-md-12 col-lg-12 col-12 d-flex cm-t-10 flex-column' >
                        <PhoneInput
                            inputStyle={{ width: '100%', padding: 18, paddingLeft: 60 }}
                            country={'ae'}
                            value={phoneNumber}
                            onChange={phone => setphoneNumber(phone)}
                        />
                    </div>
                    <div className='col-md-12 col-lg-12 col-12  flex-column d-flex cm-t-10'  >
                        <input onBlur={() => passwordFunc()} type="password" className="form-control" placeholder="Password" value={password} onChange={(text: any) => setpassword(text.target.value)} />
                        {passwordvalid == true ? (<p className='label text-danger f-13 main-font' >Must be at least 6 characters</p>) : null}
                    </div>
                    <div className='col-md-12 col-lg-12 col-12 flex-column  d-flex  cm-t-10'  >
                        <input onBlur={() => repasswordFunc()} id='password' type="password" className="form-control" placeholder="Retype Password" value={repassword} onChange={(text: any) => setrepassword(text.target.value)} />
                        {retypePasswordvalid == true ? (<p className='label text-danger f-13 main-font' >password should be matched</p>) : null}
                    </div>
                    <div className="form-check">
                        <div className="form-check col-md-12 col-lg-12 col-12  d-flex cm-t-10" onClick={() => iamFemale()} >
                            <input className="form-check-input largerCheckbox" type="checkbox" height='100' width='100' value="" id="defaultCheck1" />
                            <label className="form-check-label f-14  cp-l-15 main-font" style={{ color: 'gray' }} >I am a Female</label>
                        </div>
                    </div>
                    <div className="form-check ">
                        <div className="form-check col-md-12 col-lg-12 col-12  d-flex align-content-center cm-t-10" >
                            <input className="form-check-input largerCheckbox" style={{ marginTop: '2%' }} type="checkbox" height='100' width='100' value="" id="defaultCheck1" onClick={() => retailstore()} />
                            <div className='d-flex flex-column'  >
                                <label className="form-check-label f-14  cp-l-15 main-font" style={{ color: 'gray' }} >Retail Store</label>
                                <label className="form-check-label f-13  cp-l-15 main-font" style={{ color: 'gray' }} >only check for seller with in a business license</label>
                            </div>
                        </div>
                    </div>
                    {retailStore == true ? (
                        <div>
                            <p className='text-center f-18 f-600 cp-t-15 primary-color' >Business Verification</p>
                            <div className='d-flex marginLR'  >
                                <p className='text-center f-14  ' >I am managing this account on the behalf of comapny or business owner that has availed business license </p>
                            </div>
                            <div className='col-md-12 col-lg-12 col-12 d-flex flex-row cm-t-10 justify-content-around' >
                                <div className='d-flex center align-items-center' >
                                    <input type="radio" onChange={() => propiriter()} className='largerCheckbox ' id="male" name="gender" value="male" />
                                    <label className='label cm-l-10  f-15 main-font' style={{ color: 'gray' }} >Propriter</label>
                                </div>
                                <div className='d-flex center align-items-center'>
                                    <input onChange={() => propiriter()} className='largerCheckbox ' type="radio" id="female" name="gender" value="female" />
                                    <label className='label cm-l-10 f-15 main-font' style={{ color: 'gray' }} >Legal Representative</label>
                                </div>
                            </div>
                            <div className='d-flex marginLR cm-t-22'  >
                                <p className='f-18 f-500 primary-color main-font label'  >Identity Information</p>
                            </div>
                            <div className=' d-flex flex-row  flex-wrap ' >
                                <div className='col-md-6 col-lg-6 col-12  '>
                                    <div className='d-flex flex-column' >
                                        <MultiselectDropDown value={idType} placeHolder="Select id Type" DropDownData={nationality} securityRequired='true' blur={blurOption} methode={(data: any) => setidType(data)} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 col-12' style={{ width: '100%' }} >
                                    <div className='d-flex flex-column' >
                                        <MultiselectDropDown value={Country} placeHolder="Country of Issue" DropDownData={AllCountries} securityRequired='true' blur={blurOption} methode={(data: any) => countryFunction(data)} />
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex flex-wrap  ' >
                                <div className='col-md-6 col-lg-6 col-12 cm-t-10 ' >
                                    <input onBlur={() => idCardNumber()} type="text" className="form-control" placeholder="ID Number " value={idNumber} onChange={(text: any) => idCard(text.target.value)} />
                                    {idCardno == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                                </div>
                                <div className=' col-md-6 col-lg-6  col-12 cm-t-10 ' >
                                    <div className="c-border" style={{ width: '100%', borderColor: 'lightGray', borderRadius: 5, }} >

                                        <DatePicker
                                            className='dddddd'
                                            calendarClassName='calendercol'
                                            clearIcon={null}
                                            calendarIcon={null}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex marginLR cm-t-22'  >
                                <p className='f-18 f-500 primary-color ' >Business Information</p>
                            </div>
                            <div className='col-md-12 col-lg-12 col-12 flex-column  d-flex' style={{}} >
                                <input onBlur={() => BusName()} type="text" className="form-control" placeholder="Business Name" value={Bname} onChange={(text: any) => setBname(text.target.value)} />
                                {businessname == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                            </div>
                            <div onBlur={() => Busid()} className='col-md-12 col-lg-12 col-12 flex-column d-flex cm-t-10'  >
                                <input id="email" type="text" className="form-control" placeholder="Business License Number" value={Blicense} onChange={(text: any) => setBlicense(text.target.value)} />
                                {businessId == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                            </div>
                            <div onBlur={() => Busadress()} className='col-md-12 col-lg-12 col-12 flex-column  d-flex cm-t-10' >
                                <input id="email" type="text" className="form-control" placeholder="Business Adress" value={Badress} onChange={(text: any) => setBadress(text.target.value)} />
                                {businessadress == true ? (<p className='label text-danger f-13 main-font' >Should not be empty</p>) : null}
                            </div>
                        </div>
                    ) : null}
                    <div className="form-check">
                        <div className="form-check col-md-12 col-lg-12 col-12  d-flex cm-t-10" >
                            <input onBlur={() => setagreeError(true)} onClick={() => isagree()} className="form-check-input largerCheckbox" type="checkbox" height='100' width='100' value="" id="defaultCheck1" />
                            <label className="form-check-label f-14  cp-l-15 main-font" style={{ color: 'gray' }} >I've read and agree to lesFemmes <p style={{ color: '#732F3E' }}>Terms of Services and Privacy Policy</p></label>
                        </div>
                        {agree == 'no' &&
                            <div>
                                {agreeError == true && <p className='label text-danger f-13 main-font' >please agree to ower terms and codition</p>}
                            </div>
                        }
                    </div>
                    <div className='col-md-12 col-lg-12 col-12  d-flex cp-b-29 cp-t-29'  >
                        <button type="button" style={{ width: '100%' }} className="btn btn-primary border-0" onClick={() => signUp()} >SIGN UP</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp;