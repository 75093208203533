import './CheaderHalf.scss';
import { AiOutlineSearch, AiOutlineShoppingCart, AiOutlineBell } from "react-icons/ai";
import logo from '../assets/clogo.png';
import { Link, useHistory} from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react';
import { HiMenu } from "react-icons/hi"
import { useModal } from 'react-hooks-use-modal';
import { FiChevronDown } from "react-icons/fi"
import axios from "axios"
import { globleAPI as Global } from '../globals'
import girl from '../assets/emojiwindle.png'
import UserOptionsPopOver from './UserOptionsPopOver';
import NotificationSystem from './NotificationSystem';

function CheaderHalf(props: any) {
  const [userid] = useState(localStorage.getItem('userid') || '')
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });
  const [NoStore, setNoStore] = useState<any>('')
  const [SugestionBox, setSugestionBox] = useState(false);
  const [menu, setmenu] = useState(false);
  const [notification, setnotification] = useState(false);
  const [SearchedValue, setSearchedValue] = useState('');
  const [SuggestionData, setSuggestionData] = useState<any>([]);
  const [token] = useState(localStorage.getItem('token') || '')
  const [first_name] = useState(localStorage.getItem('first_name') || '')
  const [last_name] = useState(localStorage.getItem('last_name') || '')
  const [imageProfile] = useState(localStorage.getItem('imageProfile') || '')
  const [show, setShow] = useState(false);
  const [notificationData, setnotificationData] = useState([]);
  const [target, setTarget] = useState(null);
  const [Count, setCount] = useState(0);
  const ref = useRef(null);
  const [cart, setcart] = useState('')
  const history = useHistory();


  useEffect(() => {
    if (token !== '') {
      getMyStoreDetail()
      getCartCount()
      showAllNotification()
    }
  }, [])

  const getCartCount = () => {
    axios
      .get(Global.cartCount + token)
      .then(response => {
        setcart(response.data.cart_count)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  const getMyStoreDetail = () => {
    if (token) {
      axios
        .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
        .then(response => {
          if (response.data.store_details[0].store_address == undefined) {
            setNoStore('yes')
          } else {
            setNoStore('no')
          }
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    } else {
      setNoStore('yes')
    }

  }
  const showAllNotification = () => {
    if (token) {
      axios
        .post(Global.showAllNotification + token)
        .then(response => {
          setnotificationData(response.data.notification_data)
          let data = response.data.notification_data
          data.map((data: any) => data.status == 3 ? setCount(Count => Count + 1) : null)
        })
        .catch(function (error) {
          console.log('notifications', error)
        });
    } else {
      setNoStore('yes')
    }

  }

  if (props.cartcheck === true) {
    getCartCount()
  }

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  const mobileMenu = (event: any) => {
    setmenu(!menu);
    setTarget(event.target);
  };

  const move = (data: any) => {
    if (data === 'home') {
      history.push('/', [data])
    } else if (data === 'cart') {
      history.push('/cart')
    }
  };

  const goDown = () => {
    if (window.location.pathname == '/') {
      props.methode('get the app')
      setTimeout(() => {
        props.methode('')
      }, 1);
    } else {
      history.push('/', ['gettheApp']);
      setTimeout(() => {
        history.push('/', ['']);
      }, 1);
    }
  };

  const searchedDataState = () => {
    if (SearchedValue === '') {
      alert('please add keyword')
    } else {
      history.push('/all-products', ['searchData', SearchedValue]);
    }
  };

  const searchedData = (data: any) => {
    history.push('/all-products', ['searchData', data]);
  };

  const suggestion = (searchedKeyword: any) => {
    if (searchedKeyword == '') {
      setSuggestionData(searchedKeyword)
      setSugestionBox(false)
      setSearchedValue('')
    } else {
      setSearchedValue(searchedKeyword)
      window.scrollTo(0, 0)
      axios
        .post(Global.WebSearchFromAllProducts + "?search_keyword=" + searchedKeyword + "&is_luxury=0",)
        .then(response => {
          let Allsuggestion = response.data.AllProducts.data.filter((data: any, id: any) => id < 5)
          setSuggestionData(Allsuggestion)
          setSugestionBox(true)
        })
        .catch(function (error) {
          console.log('response of the api ', error)
        });
    }
  };

  const movingLuxuryProducts = () => {
    history.push('/luxury');
    localStorage.setItem('SelectedOption', 'Luxury');
  };

  const movingRetailProduct = () => {
    history.push('/retail');
    localStorage.setItem('SelectedOption', 'retail');
  };

  const changereadstatus = (data: any) => {

    axios.post(Global.NOTIFICATION_STATUS + token + "&notification_id=" + data)
      .then((response) => {
        showAllNotification()
      }, (error) => {
        alert(error)
      });
  }

  return (
    <div >
      <div className='webdisplay' style={{ height: '55px', backgroundColor: 'rgba(115, 47, 62, 0.24)', display: 'flex', alignItems: 'center', alignSelf: 'center' }} >
        <div className=' col-md-5' style={{ display: 'flex', justifyContent: 'center' }} >
          <label onClick={() => movingLuxuryProducts()} className='label main-font hoverUnderLine' style={{ paddingRight: '16px', fontWeight: 'bold' }} >LUXURY ITEMS</label>
          <label onClick={() => movingRetailProduct()} className='label main-font hoverUnderLine' style={{ paddingRight: '16px', fontWeight: 'bold' }} >RETAIL ITEMS</label>
          {token !== '' ? (
            <label onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} className='label hoverUnderLine main-font' style={{ fontWeight: 'bold' }} >SELL WITH US</label>
          ) : (
            <label onClick={() => history.push('login')} className='label main-font hoverUnderLine' style={{ fontWeight: 'bold' }} >SELL WITH US</label>
          )}
        </div>
        <div className='col-md-7' style={{ display: 'flex', justifyContent: 'center' }} >
          <label className='label main-font' style={{ fontSize: 14 }} >RETAIL BRANDS IN THE UAE</label>
          <div className='label main-font ' style={{ height: 4, width: 4, borderWidth: 10, backgroundColor: 'black', borderRadius: 100, marginTop: 8, marginLeft: 8, marginRight: 8 }} ></div>
          <label className='label main-font ' style={{ fontSize: 14 }} >WORLDWIDE DELIVERY & EASY RETURNS</label>
          <div className='label main-font' style={{ height: 4, width: 4, borderWidth: 10, backgroundColor: 'black', borderRadius: 100, marginTop: 8, marginLeft: 8, marginRight: 8 }} ></div>
          <label className='label main-font' style={{ fontSize: 14 }} >LUXURY ITEMS IN THE UAE</label>
        </div>
      </div>
      <div className='mobiledisplay col-12' >
        <div className='f-30 d-flex flex-row' style={{ width: '100%', backgroundColor: 'white', height: '75px', justifyContent: 'space-between', alignItems: 'center' }}>
          {userid!== ''?(
            <div className="pointer material-icons " onClick={handleClick}>
            <HiMenu size="2.1rem" color={'#732F3E'} />
          </div>
          ):(
            <div className="pointer material-icons " onClick={mobileMenu}>
            <HiMenu size="2.1rem" color={'#732F3E'} />
          </div>
          )}
       <div>
            <img className="touchAble" onClick={() => move('home')} alt='img' height="50rem" src={logo} ></img>
          </div>
          <div onClick={() => move('cart')} className="pointer  material-icons">
            <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: 'rgb(197, 77, 97)',fontSize:14 }} className=' cp-l-6 cp-r-6 ' >{cart}</p>
            <AiOutlineShoppingCart size="2rem" color={'#732F3E'} />
          </div>
        </div>
      </div>
      {menu &&
            <UserOptionsPopOver show={menu} target={target} />
      }
      <div className='col-12 webdisplay ' >
        <nav className="navbar navbar-expand">
          <div className="container-fluid ">
            <div className='col-4'>
              <div className='' style={{ width: '85%' }}  >
                <div className=' primary-color c-border ' style={{ paddingLeft: 10, borderWidth: 2, borderRadius: 10, borderColor: '#C4C4C4' }}>
                  <AiOutlineSearch size='1.8rem' color='#732F3E' onClick={() => searchedDataState()} />
                  <input className='c-searchfield pText f-14 mian-font ' onChange={(text: any) => suggestion(text.target.value)} placeholder='Search by brand, item, name...' style={{ padding: 15, width: '18vw' }}></input>
                </div>
                {SugestionBox === true ? (
                  <div className=" d-flex flex-column position-absolute  cp-l-50 cp-r-50 cp-b-10 cm-l-25  " style={{ marginTop: '4%', zIndex: 1, backgroundColor: 'white', width: '70%' }} >
                    {SuggestionData.map((data: any) =>
                      <label className='touchAble' onClick={() => searchedData(data.name)} >{data.name}</label>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-4' >
              <div className='webdisplay  main-font primary-color  ' style={{ display: 'flex', justifyContent: 'center' }}>
                <img className="touchAble" onClick={() => move('home')} width="300px" alt='img' src={logo} ></img>
              </div>
            </div>
            <div className='col-4' style={{ display: 'flex', flexDirection: 'row-reverse' }} >
              <div style={{ display: 'flex' }} className=' webdisplay'>
                <div style={{ height: 35, borderRadius: 10 }} className='pointer cm-t-5  cm-r-1rem  bg-color-secondary page-color  main-font f-200 border-radius cp-l-15 cp-r-15 d-flex align-item-center'>
                  <label onClick={() => goDown()} className="f-12 label touchAble hoverUnderLine" >
                    GET THE APP
                  </label>
                </div>
                <div style={{ height: 35, borderRadius: 10 }} className='pointer cm-t-5  cm-r-1rem  bg-color-secondary page-color  main-font f-200 border-radius cp-l-15 cp-r-15 d-flex align-item-center'>
                  {token !== '' ? (
                    <label className="f-12 label touchAble hoverUnderLine" onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} style={{}} >
                      SELL
                    </label>
                  ) : (
                    <Link to="/login" className="f-12 label touchAble hoverUnderLine" style={{ color: 'white', border: 0 }} >SELL</Link>
                  )}
                </div>
                <div>
                  {userid !== '' ? (
                    <div className='d-flex'>
                      <img alt="img" className="border" style={{ height: 40, width: 40, borderRadius: 100, marginRight: 2 }} src={imageProfile !== 'null' ? Global.IMAGE_PATH + imageProfile : girl}></img>
                      <div className="cp-r-10 d-flex align-self-center" >
                        <FiChevronDown onClick={handleClick} className='touchAble' size='1.6rem' />
                      </div>
                      <UserOptionsPopOver show={show} target={target} />
                    </div>
                  ) : (
                    <div className="d-flex flex-row" >
                      <div className='cp-t-9  cp-l-8 cp-r-8 f-15 main-font label'>
                        <Link to="/signup" className="link" style={{ color: 'rgb(27, 27, 27)' }} >Sign Up </Link>
                      </div>
                      <div className='cp-t-9  cp-l-8 cp-r-8 f-15 main-font label'>
                        <Link to="/login" className="link" style={{ color: 'rgb(27, 27, 27)', border: 0 }} > Login</Link>
                      </div>
                    </div>
                  )}
                </div>
                <div onClick={() => setnotification(!notification)} className="pointer  cp-t-5 material-icons primary-color cp-r-8 cp-l-8">
                  <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: '#732F3E', fontSize: 11 }} className=' cp-l-4 f-13 cp-r-4 ' >{notificationData?.length > 0 && Count}</p>
                  <AiOutlineBell color={'#732F3E'} size="1.9rem" />
                </div>
                <div onClick={() => move('cart')} className="pointer  cp-t-5 material-icons primary-color">
                  <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: '#732F3E', fontSize: 11 }} className=' cp-l-4 f-13 cp-r-4 ' >{cart}</p>
                  <AiOutlineShoppingCart color={'#732F3E'} size="1.9rem" />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {(notificationData.length >= 1 && notification) &&
        <NotificationSystem data={notificationData} methode={(data: any) => changereadstatus(data)} />
      }
      <div className='cp-3 page-color text-center w100p ' style={{ backgroundColor: '#732F3E', height: '65px', justifyContent: 'center', display: 'flex', alignItems: 'center' }} >
        <label className='label freeSHipingText main-font ' style={{ fontStretch: 'ultra-expanded', letterSpacing: 4 }} > FREE SHIPPING FOR ORDERS ABOVE 100 AED</label>
      </div>
    </div>
  );
}

export default CheaderHalf;
