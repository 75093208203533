import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function PrivacyPolicy() {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  window.scrollTo(0, 0)

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="padding" >
        <div className="cm-t-30 cm-b-30" >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">Privacy Policy</Typography>
          </Breadcrumbs>
        </div>
        <div className="d-flex flex-column" >
          <label className="secHeading main-font  primary-color " >Privacy Policy</label>
          <label className=" label  labelText" >This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</label>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >What personal information do we collect from the people that visit our blog, website or app?</label>
            <label className="   labelText" >When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address or other details to help you with your experience.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >When do we collect information?</label>
            <label className="   labelText" >We collect information from you when you register on our site or enter information on our site.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >How do we use your information?</label>
            <label className="   labelText" >We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</label>
            <ul>
              <li> To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
              <li>  To allow us to better service you in responding to your customer service requests.</li>
              <li> To follow up with them after correspondence (live chat, email or phone inquiries)</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >How do we protect your information?</label>
            <label className="   labelText" >We do not use vulnerability scanning and/or scanning to PCI standards.
              An external PCI compliant payment gateway handles all CC transactions.
              We use regular Malware Scanning. <br />
              Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
              <br />
              We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.
              <br />
              All transactions are processed through a gateway provider and are not stored or processed on our servers.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Do we use 'cookies'?</label>
            <label className="   labelText" >We do not use cookies for tracking purposes <br /> You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.<br />If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.that make your site experience more efficient and may not function properly.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Third-party disclosure</label>
            <label className="   labelText" >We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Google</label>
            <label className="   labelText" >Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en  We have not enabled Google AdSense on our site but we may do so in the future.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >California Online Privacy Protection Act</label>
            <label className="   labelText" >CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >According to CalOPPA, we agree to the following:</label>
            <label className="   labelText" >Users can visit our site anonymously.
              Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.
              Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</label>
            <ul>
              You will be notified of any Privacy Policy changes:
              <li>On our Privacy Policy Page</li>
              Can change your personal information:
              <li>By logging in to your account</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >How does our site handle Do Not Track signals?</label>
            <label className="   labelText" >We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Does our site allow third-party behavioral tracking?</label>
            <label className="   labelText" >It's also important to note that we do not allow third-party behavioral tracking</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >COPPA (Children Online Privacy Protection Act)</label>
            <label className="   labelText" >When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.</label>
            <label className="   labelText" >We do not specifically market to children under the age of 13 years old.
              Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Fair Information Practices</label>
            <label className="   labelText" >The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</label>
            <ul>e will notify you via email
              <li>Within 7 business days</li>
            </ul>
            <label className="   labelText" >We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >CAN SPAM Act</label>
            <label className="   labelText" >The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >We collect your email address in order to:</label>
            <ul>
              <li>Send information, respond to inquiries, and/or other requests or questions</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >To be in accordance with CANSPAM, we agree to the following:</label>
            <ul>
              <li>Not use false or misleading subjects or email addresses.</li>
              <li>Identify the message as an advertisement in some reasonable way.</li>
              <li>Include the physical address of our business or site headquarters.</li>
              <li> Monitor third-party email marketing services for compliance, if one is used.</li>
              <li>Honor opt-out/unsubscribe requests quickly.</li>
              <li> Allow users to unsubscribe by using the link at the bottom of each email.</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >If at any time you would like to unsubscribe from receiving future emails, you can email us at</label>
            <ul><li>Follow the instructions at the bottom of each email. and we will promptly remove you from ALL correspondence.</li></ul>
          </div>
          <div className=" d-flex flex-column">
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Contacting Us</label>
            <label className="   labelText" >If there are any questions regarding this privacy policy, you may contact us using the information below.</label>
          </div>
          <div className=" d-flex flex-column">
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Lesfemmes</label>
            <label className="   labelText" >718 Al Hudaiba Awards Building Block B, Dubai, UAE </label>
            <label className="   labelText " >store@lesfemmes.net</label>
          </div>
        </div>
      </div>
      <CFooter />
    </div>
  );
}
export default PrivacyPolicy