import { BiPhoneCall } from "react-icons/bi";
import { RiWhatsappLine } from "react-icons/ri";
import { GoMail } from "react-icons/go";

  export const  NeedHelp = () => {
return(
    <div className='d-flex justify-content-start padding  cp-t-10 cp-b-7  flex-row align-items-center' style={{backgroundColor:'lightgray'}} >
    <label className=' text-uppercase f-14 font-weight-bold  label cp-l-5 cp-r-15  main-font'>Need help?</label>
    <div className=" d-flex flex-row" >
    <div className="d-flex  justify-content-center align-self-center " >
    <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="tel:+971588241922"><BiPhoneCall size='17' />Call us</a>
    </div>
    <div className="d-flex justify-content-center align-self-center" >
    <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0"><RiWhatsappLine size='17' />WhatsApp</a>
    </div>
    <div className="d-flex justify-content-center align-self-center" >
    <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"><GoMail size='17' /> Email</a>
    </div>
    </div>
</div>
)
}
