

import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { FiChevronDown } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import Moment from 'react-moment';
import girl from '../assets/emojiwindle.png'
import { IoChevronForwardOutline } from "react-icons/io5";
import { globleAPI as Global } from '../globals'



function Orders(props: any) {

    const [token] = useState(localStorage.getItem('token') || '')
    const [Histroy, setHistroy] = useState<any>([])
    const [purchse, setpurchse] = useState<any>(true)
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.data == 'sales') {
            getVendorSaleHistroy()
        } else if (props.data == 'orders') {
            getVendorOrdersHistroy()
        } else {
            getPurchaseHistroy()
        }
    }, [])

    const orderDetails = (data: any) => {
        props?.sendsomeData('orderDetail', data)
    }

    const getVendorOrdersHistroy = () => {
        axios
            .get(Global.GET_ALL_ORDERS + token)
            .then(response => {
                setHistroy(response.data.order_ids)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getPurchaseHistroy = () => {
        axios
            .get(Global.PURCHASE_HISTORY + token)
            .then(response => {
                setHistroy(response.data.my_orders)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getVendorSaleHistroy = () => {
        axios
            .get(Global.GET_ALL_DATA_SOLD + token)
            .then(response => {
                setHistroy(response.data.order_ids)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    return (
        <div  >
            {purchse &&
                <div>
                    <div className="d-flex justify-content-end cp-b-10" >
                        <label className="label labelText text-muted primary-color cp-7 cp-r-10  " >Sort Orders   </label>
                        <label className="label pText text-muted primary-color cp-t-8  cp-l-10 cp-r-2 " style={{ backgroundColor: 'white' }} >By Date <label className="cp-l-20" >  <FiChevronDown size='20' className=" border-left cp-l-5" /></label></label>
                    </div>
                    {Histroy.length ? (
                        <div className="cm-b-40 cp-b-40"   >
                            {
                                Histroy.map((data1: any) =>
                                    <div className="cm-b-40 " style={{ backgroundColor: 'white' }} >
                                        <div className="d-flex col-12 justify-content-between c-border-bottom cp-b-10 " style={{ borderColor: 'gray', borderWidth: 2 }} >
                                            <div className="d-flex flex-column" >
                                                <label className="label pText text-muted primary-color font-weight-bold cp-t-10 " > <label className="label font-weight-bold" >Order ID:</label> {data1.id}</label>
                                                <label className="label pText text-muted primary-color  font-weight-bold  "  > <label className="label font-weight-bold" >Date Ordered:</label>    <Moment format="D MMM YYYY" withTitle>{data1.order_date?.substring(0, 10)}</Moment>   </label>
                                                {(props.data == 'sales' || props.data == 'orders') && <label className="label pText  font-weight-bold   "  > {data1.first_name} {data1.last_name} </label>}
                                            </div>
                                            <div className=" cp-t-10" >
                                                {data1.status !== 'Canceled' && <a className="label labelText   cp-t-6 cp-b-6 cp-l-10 cp-r-10  bg-color-primary " style={{ color: 'white' }} href="https://my.ccs-uae.com/track">Track Order</a>}
                                                <BsThreeDotsVertical size="25" />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row   overflow-auto  col-md-12 " >
                                            <div className="d-flex flex-row col-11 " >
                                                {Histroy.map((data2: any) =>
                                                    <div className="d-flex flex-row  overflow-auto"  >
                                                        {(data2.id == data1.id) && (
                                                            <div className='d-flex flex-row cp-t-30   cp-r-10' style={{ backgroundColor: 'white' }}>
                                                                <div className='d-flex flex-row justify-content-between ' >
                                                                    <div className='d-flex flex-row' >
                                                                        {data2.image == '' || data2.image == undefined ? (
                                                                            <img className='carosal-image-Web' style={{ height: 90, width: 90 }} src={Global.IMAGE_PATH + data2.profile_pic} alt="First slide" />
                                                                        ) : (
                                                                            <img className='carosal-image-Web' style={{ height: 90, width: 90 }} src={Global.IMAGE_PATH + data2.image} alt="First slide" />
                                                                        )}

                                                                        <div className='d-flex flex-column cp-l-10 ' >
                                                                            {(props.data == 'orders' || props.data == 'sales') && <label className=' labelText label  text-muted  main-font'   >No of Products {data2.quantity} </label>}
                                                                            <label className=' labelText label primary-color  main-font'   >{data2.name} {props.data == 'orders' && 'AED ' + data2.total} </label>
                                                                            <label className='pText f-600  label main-font text-muted'  >{data2.manufacturer_name}</label>
                                                                            <label className=' labelHeading font-weight-bold cp-t-15 label main-font'  >{data2.status}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-1 d-flex flex-row-reverse touchAble cp-t-15" onClick={() => orderDetails(data1.id)} ><IoChevronForwardOutline size="38" color="lightgray" /></div>
                                        </div>
                                        <div className="d-flex justify-content-between cp-30" >
                                            {(data1.status == 'Delivered' && props.data !== 'sales') &&
                                                <label className="label labelText   cp-t-3 cp-b-3 cp-l-10 cp-r-10  bg-color-primary  touchAble" onClick={() => orderDetails(data1.id)} style={{ color: 'white' }} >Review This Order</label>
                                            }
                                            <label></label>
                                            <label className=' pText font-weight-bold cp-t-10 label main-font text-muted '  ><Moment fromNow >{data1.order_date.substring(0, 10)}</Moment></label>
                                        </div>
                                    </div>
                                )}
                        </div>
                    ) : null}
                </div>
            }
            {Histroy.length <= 0 &&
                <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh', backgroundColor: 'white' }} >
                    <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                    <label className="text-muted text-center" style={{ width: '30%' }} >No sales found</label>
                </div>
            }
        </div>
    );
}

export default Orders;


