import React, { useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import { NeedHelp } from '../app-components/SmallComponents';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function OrderShipping() {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  window.scrollTo(0, 0)

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="padding" >
        <div className="cm-t-30 cm-b-30" >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">Orders & Shipping</Typography>
          </Breadcrumbs>
        </div>
        <div className="d-flex flex-column" >
          <label className="secHeading main-font  primary-color " >Orders & Shipping</label>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Les Femmes accepts the following forms of payment: </label>
            <label className=" label  labelText" >Credit or Debit Cards (Visa, MasterCard)</label>
            <div className="d-flex flex-row" >
              <label className="   labelText cp-r-3" >Cash on Delivery (COD Available </label><strong  > ONLY IN UAE</strong><label className="labelText" >)</label>
            </div>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Can I trust buying and selling on Les Femmes?</label>
            <label className="   labelText" >We want you to feel secure when buying or selling on Les Femmes!</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >Buyer’s Protection</label>
            <label className="   labelText" >You are protected every time you place an order on Les Femmes. When you purchase an item on Les Femmes, we keep your payment safe and secure.
              If your item does not match the description, or order is damaged, please open a case by sending an email at <label className="primary-color" > support@lesfemmes.net</label>  within 3 days of delivery, before the order is marked as received.
              After we verify your claim, we'll refund payment. If we don't hear from you by 3 days after your order is delivered, we'll assume everything is OK and release your payment to the seller. Once we release payment, all sales are final and no refunds will be available.
            </label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >When can I get my order?</label>
            <label className="   labelText" >You can get your order within 2-5 days. We will send you an email once the item is shipped. </label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >How to cancel an accidental purchase?</label>
            <label className="   labelText" >Canceling an order can be done if the item is not yet shipped. Kindly send us an email at <label className="primary-color" > support@lesfemmes.net</label> to cancel an order. </label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >What are the shipping rates on Les Femmes?</label>
            <label className="   labelText" >Order 100 AED and above will have free delivery and order below 100 AED will have a flat rate shipping charge of 15 AED in the UAE ONLY. Any other international shipping rates will depend on the country you want your order to be delivered. </label>
          </div>
          <div className=" d-flex flex-column cm-50 cp-50" >
            <label className="cp-t-15 cp-b-15 label secHeading text-center" >Further questions?</label>
            <Link className="main-font labelText  primary-color text-center" to="/faqs">Click here to read the Frequently asked questions </Link>
            <label className="   labelText text-center primary-color" ></label>
            <div className='cm-t-20 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
              <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Start Selling</p>
            </div>
          </div>
        </div>
      </div>
      <NeedHelp />
      <CFooter />
    </div>
  );
}
export default OrderShipping