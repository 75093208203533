import {Route, Switch } from 'react-router-dom';
import AllBrandsT from './FooterPages/AllBrandsT';
import Categories from './FooterPages/Categories';
import Faqs from './FooterPages/Faqs';
import HowItWorks from './FooterPages/HowItWorks';
import OrderShipping from './FooterPages/OrderShipping';
import PrivacyPolicy from './FooterPages/PrivacyPolicy';
import ReturnPolicy from './FooterPages/ReturnPolicy';
import AboutUS from './FooterPages/AboutUS';
import TermofServices from './FooterPages/TermofServices';
import LoginScreen from './screens/AuthScreens/LoginScreen';
import SignUp from './screens/AuthScreens/SignUp';
import AllBrands from './screens/HomeScreens/AllBrands';
import AllProductLuxury from './screens/HomeScreens/AllProductLuxury';
import AllProducts from './screens/HomeScreens/AllProducts';
import AllStores from './screens/HomeScreens/AllStores';
import Cart from './screens/HomeScreens/Cart';
import LuxuryProductsCategory from './screens/HomeScreens/LuxuryProductsCategory';
import MyAccount from './screens/HomeScreens/MyAccount';
import PopularProduct from './screens/HomeScreens/PopularProduct';
import ProductDetail from './screens/HomeScreens/ProductDetail';
import StoreDetail from './screens/HomeScreens/StoreDetail';
import ProhabitedItem from './FooterPages/ProhabitedItem';
import Purchases from './screens/HomeScreens/Purchases';
import PriceOfferRequest from './screens/HomeScreens/PriceOfferRequest';
import OrderDetailss from './screens/HomeScreens/OrderDetailss';
import SettingsScreen from './screens/HomeScreens/SettingsScreen';
import CardThanks from './app-components/CardThanks';
import MySales from './screens/sellers-side/MySales';
import SellerOrderDetail from './screens/sellers-side/SellerOrderDetail';
import AddProduct from './screens/sellers-side/AddProduct';
import MakeAnOffer from './screens/sellers-side/MakeAnOffer';
import Community from './FooterPages/Community';
import EditStore from './screens/sellers-side/EditStore';
import { getToken } from '@firebase/messaging';
import Home from './screens/HomeScreens/Home';
import LuxuryHome from './screens/HomeScreens/LuxuryHome';
import RetailHomes from './screens/HomeScreens/RetailHomes';
import ForgotPassword from './screens/AuthScreens/ForgotPassword';
// import firebase from './firebase';

function App() {
  return (
    <Switch>
    {/* <Route path="/" component={MainHome} exact /> */}
    <Route path="/retail" component={RetailHomes} exact />
    <Route path="/" component={Home} exact />
    <Route path="/luxury" component={LuxuryHome} exact />
    <Route path="/login" component={LoginScreen} exact />
    <Route path="/forgot-password" component={ForgotPassword} exact />
    <Route path="/signup" component={SignUp} exact />
    <Route path="/all-products" component={AllProducts} exact />
    <Route path="/all-deals" component={AllProducts} exact />
    <Route path="/product-detail/:id/:name" component={ProductDetail} exact />
    <Route path="/stores" component={AllStores} exact />
    <Route path="/StoreDetail" component={StoreDetail} exact />
    <Route path="/cart" component={Cart} exact />
    <Route path="/MyAccount" component={MyAccount} exact />
    <Route path="/brands" component={AllBrands} exact />
    <Route path="/popular-products" component={PopularProduct} exact />
    <Route path="/luxury-products-category" component={LuxuryProductsCategory} exact />
    <Route path="/luxury-products" component={AllProductLuxury} exact />
    <Route path="/orders-and-shipping" component={OrderShipping} exact />
    <Route path="/return-policy" component={ReturnPolicy} exact />
    <Route path="/faqs" component={Faqs} exact />
    <Route path="/term-of-services" component={TermofServices} exact />
    <Route path="/privacy-policy" component={PrivacyPolicy} exact />
    <Route path="/categories" component={Categories} exact />
    <Route path="/all-brands" component={AllBrandsT} exact />
    <Route path="/how-it-works" component={HowItWorks} exact />
    <Route path="/how-to-sell" component={HowItWorks} exact />
    <Route path="/why-lesfemmes" component={HowItWorks} exact />
    <Route path="/about-us" component={AboutUS} exact />
    <Route path="/prohibited-items" component={ProhabitedItem} exact />
    <Route path="/purchases" component={Purchases} exact />
    <Route path="/price-offer-request" component={PriceOfferRequest} exact />
    <Route path="/order-detail" component={OrderDetailss} exact />
    <Route path="/setting" component={SettingsScreen} exact />
    <Route path="/checkout-thanks" component={CardThanks} exact />
    <Route path="/my-sales" component={MySales} exact />
    <Route path="/order-details" component={SellerOrderDetail} exact />
    <Route path="/sale-product" component={AddProduct} exact />
    <Route path="/community" component={Community} exact />
    <Route path="/edit_store" component={EditStore} exact />
    <Route path="/make-an-offer/:id/:name" component={MakeAnOffer} exact />

</Switch>
  );
}

export default App;
