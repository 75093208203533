import React, { useEffect, useRef, useState } from 'react';
import './HowItWorks.scss';
import { useHistory, Link } from 'react-router-dom'
import CFooter from '../app-components/CFooter';
import image1 from '../assets/LISTING.png'
import image2 from '../assets/COMMUNITY OF WOMEN.png'
import image3 from '../assets/SHOP ON EASE.png'
import image4 from '../assets/SECURED & FAST DELIVERY.png'
import image5 from '../assets/Advert.png'
import image6 from '../assets/Support.png'
import shop1 from '../assets/4.jpg'
import shop2 from '../assets/2.jpg'
import shop3 from '../assets/3.jpg'
import shop4 from '../assets/1.jpg'
import shop5 from '../assets/FOLLOW UP.png'
import shop6 from '../assets/INTERACT.png'
import shop7 from '../assets/MAKE AN OFFER.png'
import buying1 from '../assets/BUYING 1.png'
import buying2 from '../assets/BUYING 2.png'
import buying3 from '../assets/BUYING 3.png'
import selling1 from '../assets/SELLING 1.png'
import selling2 from '../assets/SELLING 2.png'
import selling3 from '../assets/SELLING 3.png'
import { NeedHelp } from '../app-components/SmallComponents';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function HowItWorks(props: any) {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [Buying, setBuying] = React.useState(false);
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const history = useHistory();

    useEffect(() => {
        history.listen((location, action) => {
            window.location.reload();
        })
        if (props.location.state[0] === 'why') {
            executeScroll()
        } else {
            window.scrollTo(0, 0)
        }
    }, []);

    const faqs = (data: any) => {
        history.push('/faqs', [data]);
    };

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding" >
                <div className="d-flex justify-content-center" >
                    <label className="mainHeading main-font  primary-color headingShort cp-b-15" >How It Works?</label>
                </div>
                <div className=" d-flex justify-content-center bg-light cp-t-20 " >
                    <label className="secHeading main-font  label text-center  cp-r-25 touchAble" style={{ color: Buying === false ? 'rgb(218, 95, 115)' : 'gray' }} onClick={() => setBuying(false)} >Selling</label>
                    <label className="secHeading main-font label  text-center cp-l-25 touchAble" style={{ color: Buying === true ? 'rgb(218, 95, 115)' : 'gray' }} onClick={() => setBuying(true)} >Buying</label>
                </div>
                {Buying === true ? (
                    <div className=" bg-light " >
                        <div className="stepers cp-l-30 cp-r-30 " >
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Discover Items</label>
                                <label className=" main-font labelText" >New and preloved items that are trendy and vintage. Choose from a diverse collection of styles, brands, or price.</label>
                            </div>
                            <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '45vh' }} src={buying1} ></img>
                            </div>
                        </div>
                        <div className="stepers cp-l-30 cp-r-30 " >
                            <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '45vh' }} src={buying2} ></img>
                            </div>
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Get Deals</label>
                                <label className=" main-font labelText" >Get deals from our luxury section or on the retail section. Wide rang of items to choose from. Free Delivery over 100AED and above  in the UAE. </label>
                            </div>
                        </div>
                        <div className="stepers cp-l-30 cp-r-30 " >
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Secured & Fast Delivery</label>
                                <label className=" main-font labelText" >We deliver in the UAE and International. Making sure your order is secured and deliver fast.</label>
                            </div>
                            <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '45vh' }} src={buying3} ></img>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-center cp-t-30 cp-b-30" >
                            <div className=' cp-7 text-center touchAble col-12 col-md-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                <Link style={{ color: 'white' }} className='label labelText font-weight-bold touchAble' to="/sale-product">Start Selling</Link>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className=" bg-light " >
                        <div className="stepers cp-l-30 cp-r-30  " >
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Create Your Store </label>
                                <label className=" main-font labelText" >Create your store for free. Just fill  up all necessary details and start listing your items.</label>
                            </div>
                            <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '50vh' }} src={selling1} ></img>
                            </div>
                        </div>
                        <div className="stepers cp-l-30 cp-r-30 " >
                            <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '54vh' }} src={selling2} ></img>
                            </div>
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Sell Your Items</label>
                                <label className=" main-font labelText" >Take a photo and upload it to your Les Femmes store in less than 60 seconds, right from your phone.</label>
                            </div>
                        </div>
                        <div className="stepers cp-l-30 cp-r-30 " >
                            <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                                <label className="secHeading main-font  label primary-color" >Earn Cash</label>
                                <label className=" main-font labelText" >Earn cash right into your Les Femmes wallet. Redeem it anytime  or used it to pay for your future order.</label>
                            </div>
                            <div className="steperImg cp-l-25 col-md-4 justify-content-center d-flex " >
                                <img alt='img' className="img-responsive" style={{ height: '55vh' }} src={selling3} ></img>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-center cp-b-30" >
                            <div className=' cp-7 text-center touchAble col-12 col-md-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                <Link style={{ color: 'white' }} className='label labelText font-weight-bold touchAble' to="/sale-product">Start Selling</Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-center" >
                    <label ref={myRef} className=" mainHeading main-font cp-b-5  primary-color   headingShort" >Why Les Femmes?</label>
                </div>
                <div className="  cp-20 cp-r-35" >
                    <div className="  paddingLeft " >
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: '80px' }} src={image1} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >Listing items in minutes</label>
                            <label className="pText  text-center sidepadding" >Upload your product in just a few minutes on your iPhone or Android devices, fill in a description, set the price, size, and you're done.</label>
                        </div>
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: 'auto' }} src={image2} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >Community of Women</label>
                            <label className="pText  text-center sidepadding" >Build your network by joining groups in your community from fashion,health & fitness, shopping, travel, business, clothing and many more. You can create your own groups too.</label>
                        </div>
                    </div>
                    <div className="  paddingLeft " >
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: '80px' }} src={image3} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >SHOP ON EASE</label>
                            <label className="pText  text-center sidepadding" >Choose from various collections of styles, brands, sizes, and price points. Whether you are shopping for something new or pre-loved, you're sure to score a deal on Les Femmes.</label>
                        </div>
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <div style={{ height: '80px', width: 'auto' }} >
                                <img alt='img' className="img-responsive" style={{ height: '60px', width: 'auto' }} src={image4} ></img>
                            </div>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >SECURED & FAST DELIVERY</label>
                            <label className="pText  text-center sidepadding" >Your safety on buying and trust are important to us. You are protected every time you place an order on Les Femmes. We want to provide you fast delivery and our customer service team is always here to help.</label>
                        </div>
                    </div>
                    <div className="  paddingLeft " >
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: '80px' }} src={image5} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >ADVERTISE</label>
                            <label className="pText text-center sidepadding" >Make your product visible to buyer. Advertise your preloved or brand new items on Les Femmes App.</label>
                        </div>
                        <div className="d-flex col-md-6 col-12 bg-light  cm-5 flex-column align-item-center cp-t-60  cp-b-60" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: 'auto' }} src={image6} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >INSTANT SUPPORT</label>
                            <label className="pText  text-center sidepadding" >Get instant support from Les Femmes Team through WhatsApp, call, or email.</label>
                        </div>
                    </div>
                </div>
                <div className=" bg-light" >
                    <div className="d-flex flex-column justify-content-center" >
                        <label className="mainHeading main-font  primary-color  label text-center d-flex justify-content-center  cp-30" >What to Sell?</label>
                        <label className="pText  text-center sidepaddingwhatSell" >Our community is always looking for the most beautiful timeless icons, vintage treasures, and on-trend pieces: clothes, bags, shoes, accessories, jewellery, and watches. We also look for new items that are sustainable to the community.</label>
                    </div>
                    <div className=" d-flex justify-content-center cp-20 cp-b-30" >
                        <div className=' cp-7 text-center touchAble col-12 col-md-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                            <Link style={{ color: 'white' }} className='label labelText font-weight-bold touchAble' to="/sale-product">Start Selling</Link>
                        </div>
                    </div>
                    <div className="d-flex bg-light cm-r-30 cm-l-30 flex-wrap" >
                        <div className='col-md-3 col-lg-3 col-sm-6 col-6  d-flex justify-content-center'    >
                            <img alt='img' className="img-responsive" style={{ height: '180px', width: 'auto' }} src={shop1} ></img>
                        </div>
                        <div className='col-md-3 col-lg-3 col-sm-6 col-6 d-flex justify-content-center  '    >
                            <img alt='img' className="img-responsive" style={{ height: '180px', width: 'auto' }} src={shop2} ></img>
                        </div>
                        <div className='col-md-3 col-lg-3 col-sm-6 col-6  d-flex justify-content-center '    >
                            <img alt='img' className="img-responsive" style={{ height: '180px', width: 'auto' }} src={shop3} ></img>
                        </div>
                        <div className='col-md-3 col-lg-3 col-sm-6 col-6 d-flex justify-content-center  '    >
                            <img alt='img' className="img-responsive" style={{ height: '180px', width: 'auto' }} src={shop4} ></img>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" >
                        <label className="secHeading main-font  primary-color  label text-center d-flex justify-content-center cm-t-30  headingShort" >Maximise your chances of selling</label>
                    </div>
                    <div className=" FollowUp col-12 " >
                        <div className="d-flex col-md-4 col-12 bg-light  flex-column align-item-center headingShort" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: 'auto' }} src={shop5} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >FOLLOW UP</label>
                            <label className="pText  text-center sidepadding" >Enable notifications: it is the easiest way to keep track of what your potential buyers are interested in.</label>
                        </div>
                        <div className="d-flex col-md-4 col-12 bg-light  flex-column align-item-center headingShort" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: 'auto' }} src={shop6} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >INTERACT</label>
                            <label className="pText text-center sidepadding" >Answer all user questions related to your product on the comment section of your product, as soon as possible.</label>
                        </div>
                        <div className="d-flex col-md-4 col-12 bg-light  flex-column align-item-center headingShort" >
                            <img alt='img' className="img-responsive" style={{ height: '80px', width: 'auto' }} src={shop7} ></img>
                            <label className="labelHeading text-uppercase sidepadding cp-t-20 " >MAKE AN OFFER</label>
                            <label className="pText  text-center sidepadding" >Buyers can make price offers. Be open to these offers as they allow you to sell faster. You can also make a price offer (counter offer) to users who made a comment or price offer on your item.</label>
                        </div>
                    </div>
                    <div className=" d-flex flex-column cm-20 cp-b-30 " >
                        <label className="cp-t-15 cp-b-15 label secHeading text-center" >Further questions?</label>
                        <label onClick={() => faqs('faqs')} className="main-font primary-color text-center labelText hoverUnderLine touchAble" >Click here to read the Frequently asked questions </label>
                        <label className="   labelText text-center primary-color" ></label>
                        <div className='cm-t-20 cp-7 text-center touchAble col-12 col-md-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                            <Link style={{ color: 'white' }} className='label labelText font-weight-bold touchAble' to="/sale-product">Start Selling</Link>
                        </div>
                    </div>
                </div>
            </div>
            <NeedHelp />
            <CFooter />
        </div>
    );
}
export default HowItWorks