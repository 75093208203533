import { useEffect, useState } from 'react';
import '../screens/sellers-side/MySales.scss'

function InputField(props: any) {

  const [notification, setnotification] = useState(false)

  useEffect(() => {
    if (props?.blur == true) {
      setnotification(true)
    }
  })

  const saveInputData = (data: any, data2: any) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (props?.onlyNumber == 'true') {
      if (rx_live.test(data)) {
        setnotification(false)
        props?.methode(data, data2)
      }
    } else
      if (data !== '') {
        setnotification(false)
        props?.methode(data, data2)
      }
  }

  const checkSecurity = (data: any) => {
    if (data !== '') {
      setnotification(false)
    } else {
      setnotification(true)
    }
  }

  return (
    <div>
      <p className='label primary-color text-capitalize labelText cp-t-20 cp-b-10 f-600 main-font' >{props?.placeholder}</p>
      <input placeholder={props?.hints} value={props?.value}  maxLength={props?.maxlengt} onBlur={(e: any) => props.security == 'true' ? checkSecurity(e.target.value) : null} onChange={(text: any) => saveInputData(text.target.value, props?.placeholder)} id="email" type={props?.type} className="form-control" name={props?.name} />
      {(!props?.value && notification) ? (<p className='label text-danger f-13 main-font' >Required</p>) : null}
    </div>
  );
}

export default InputField;

