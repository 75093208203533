import Alert from 'react-bootstrap/Alert'

function Alertss(props:any) {

    return (
        <div style={{}} >
                <div className='d-flex justify-content-center' >
                    <Alert style={{ marginTop: '1%', position: 'absolute', width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant={props?.type} show={true}  >
                        <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
                        <p>{props?.status}</p>
                    </Alert>
                </div>
        </div>
    );
}
export default Alertss