import React, { useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import { NeedHelp } from '../app-components/SmallComponents';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function ProhabitedItem() {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  window.scrollTo(0, 0)

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="padding" >
        <div className="cm-t-30 cm-b-30" >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">Prohibited Items</Typography>
          </Breadcrumbs>
        </div>
        <div className="d-flex flex-column" >
          <label className="secHeading main-font  primary-color " >Prohibited Items</label>
          <label className=" label  labelHeading" >What are Les Femmes Prohibited Items?</label>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading primary-color" >LIST OF PROHIBITED ITEMS OR CONTENT</label>
            <label className="   labelText cp-t-20" >It is the Seller’s responsibility to ensure that their proposed item complies with all laws and is allowed to be listed for sale in accordance with Les Femmes terms and policies before listing the item on the selling platform.</label>
            <label className="   labelText cp-t-20" >Before listing, do take note of the content that is prohibited and restricted here at Les Femmes. This list of prohibited items is not exhaustive and is intended only as a guide. </label>
            <label className="   labelText cp-t-20" >Some certain items or services are not allowed on Les Femmes at all. They may not necessarily be illegal, however, they do not fit our selling criteria, and aren't in the spirit of Les Femmes. Les Femmes reserves the right to determine what items are or are not allowed to be listed on our app at any time. We may remove items that are not listed below if we decide that they are offensive, or are in any other way a risk that we're not prepared to take.</label>
            <label className="   labelText cp-t-20" >If Les Femmes determines that a listing is in violation or is otherwise inappropriate, Les Femmes may remove the listing and cancel any related transactions up to and including termination or suspension of the account. Les Femmes will update this guideline from time to time where necessary. Please visit this page regularly for updates.</label>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >1.	Alcohol, Drugs & Tobacco and illegal items</label>
            <ul>
              <li>	Alcohol</li>
              <li>	E-Vaporizers (E-Cigarettes) and related products</li>
              <li>	Tobacco products and other smokeable products </li>
              <li>	Items that resemble smoking products or promote smoking</li>
              <li>	Drugs and drug paraphernalia </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >2.	Adult & Mature Content</label>
            <ul>
              <li>	Pornography and adult material including, but not limited to, pornographic books, magazines, pictures, and videos </li>
              <li>	Adult toys, sexual wellness, and enhancement products</li>
              <li>	Adult industry services and jobs, including escort services </li>
              <li>	Nudity and sexually-oriented material </li>
              <li>	Used undergarments and intimate items </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >3.	Animals & Animal Products</label>
            <ul>
              <li>	Live animals and insects </li>
              <li>	Items made from endangered/exotic animals</li>
              <li> 	Human beings, human remains and body parts</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >4.	Counterfeit & Replica Items infringing on a third party's intellectual property rights</label>
            <ul>
              <li>	Listings of non-brand, non-genuine, imitation, fake, or replica </li>
              <li>	Items in violation of a copyright, including handmade, or other items with copyrighted characters, brand logos, etc. <br />NOTE :For brand-name products, serial numbers or receipts must be available when listing the item.</li>
              <li>	Items that infringe copyright laws including, but not limited to, unauthorized sales of eBooks </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >5.	Items not in your possession</label>
            <ul>
              <li>	Dropshipping. Items listed using stock images, customer photos and/or supplier photos </li>
              <li>	Advertisements or listings for objects being sought</li>
              <li>	Items you do not currently have that are on order </li>
              <li> 	Coupons to purchase products</li>
              <br />The sale of the following is allowed:
              <li> 	Handcrafted items designed by the seller</li>
              <li>	Items produced or printed on-demand that the seller has designed </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >6.	Weapons</label>
            <ul>
              <li>	Knives, Swords and Hand Weapons, unless used for non-lethal sports or display/dress-up purposes </li>
              <li>	Firearms, Imitation Firearms, Ammunition and Explosives, unless determined to be toy weapons/ammunition or used for sporting purposes</li>
              <li>	Others (e.g. Tasers) </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >7.	Illegal Items or Encouraging Illegal Activity</label>
            <ul>
              <li>	Stolen or illegal goods </li>
              <li>	Items or listings that promote, support, or seek to engage users in illegal activity</li>
              <li>	Items used for identity theft (such as account credentials, user login information, and/or account access codes) </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >8.	Voucher, Gift Cards. Tickets, Digital and Non-Material Goods</label>
            <ul>
              <li>	Vouchers </li>
              <li>	Gift cards, store or merchandise credits and all items equivalent to cash</li>
              <li>	All products made available by digital sale or download, including games and game accounts, in-game currency, items, and characters </li>
              <li> 	Lottery ticket, football, transport service, event or show ticket</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >9.  Food or Edible Items and Electronic Items</label>
            <ul>
              <li>	Perishable or past expiration date food and edible items </li>
              <li>	Non-perishable food and edible items that do not have a clear expiry date</li>
              <li>	Any kinds of foods </li>
              <li> 	Any kinds of electronic items</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >10. Medical & Healthcare Items</label>
            <ul>
              <li>	Prescription drugs, medical devices and over-the-counter medical products) </li>
              <li>	Counterfeit medicines</li>
              <li> 	Used and unsealed healthcare products</li>
              <li> 	Past expiration date products</li>
              <li> 	Weight loss products with warnings or recalls</li>
              <li>	Herbal and holistic remedies imply any non-approved treatments </li>
              <li> 	Contact lenses and other optometry products</li>
              <li>	Medicine, medicinal instruments, and items with objectionable medical claims </li>
              <li>	Injectable health or cosmetic substances </li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >11. Offensive Materials and Listings</label>
            <ul>
              <li>	Content that promotes, supports or glorifies acts of violence. </li>
              <li>	Items, listings, photos or content that seeks to shame others and/or reveal personal information of others or that promote or glorify hatred, violence, racism or discrimination aren't allowed and/or demean based on race, religion, gender, gender identity, disability or sexual orientation.</li>
            </ul>
          </div>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading " >12. Others</label>
            <ul>
              <li>	Recalled items </li>
              <li>	Hazardous and flammable items</li>
              <li>	Any item that contains a computer virus, malware, or spyware </li>
              <li>		Listings promoting Multi-Level-Marketing programs, Get-Rich-Quick schemes, pyramid selling schemes, gambling and Commission-only schemes. </li>
              <li>		This is a women’s application buy and sell community only. Any men’s items listed are subject to removal. </li>
            </ul>
          </div>
          <div className=" d-flex flex-column cp-b-60 cm-b-60" >
            <label className="cp-t-15 cp-b-15 label labelHeading" >NOTE: <label className="cp-t-15 cp-b-15 label labelText"></label>Any item may be subject to removal at the discretion of the company. <br />If you have more questions, you can contact us at  <label className="primary-color" > support@lesfemmes.net</label>.</label>
          </div>
        </div>
      </div>
      <NeedHelp />
      <CFooter />
    </div>
  );
}
export default ProhabitedItem
