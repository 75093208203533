import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { globleAPI as Global } from '../globals'
import slugify from 'react-slugify';

function OrderDetailC(props: any) {

  const [token] = useState(localStorage.getItem('token') || '')
  const [orderDetail, setorderDetail] = useState<any>([])
  const [ui, setui] = useState<any>('')
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
    axios
      .get(Global.GET_ORDER_DETAIL + token + '&order_id=' + props?.orderId)
      .then(response => {
        setorderDetail(response.data.my_orders)
      })
      .catch(function (error) {
        console.log('response if the api ifdsfdfs', error)
      });

    if (props.data == 'orderDetailforMySales' || props.data == 'sales') {
      setui('sales')
    } else {
      setui('purchase')
    }


  }, [])


  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.product_id + '/' + slugify(data.name));
  }

  return (
    <div>
      {ui == 'purchase' &&
        <div className="" >
          {orderDetail?.map((data: any) => (
            <div className=' cp-t-30  cp-l-17 cp-b-20 cm-b-40  ' style={{ backgroundColor: 'white' }} >
              <div className='d-flex flex-row' >
                <div className='d-flex flex-row col-6'>
                  <img className='carosal-image-Web' style={{ height: 90, width: 90 }} src={Global.IMAGE_PATH + data.image} alt="First slide" />
                  <div className='d-flex flex-column cp-l-10 ' >
                    <label className='labelHeading label primary-color   '   >{data.name}</label>

                    <label className='pText f-600 label text-muted'  >{data.manufacturer_name}</label>
                    <label className='labelHeading label cp-t-15 text-muted'  >{data.status} on <label className=" labelText primary-color" > {data.created_at.substring(0, 10)}</label></label>
                    {data.is_returnable == 0 &&
                      <label className="labelText label text-muted" style={{ color: 'black' }} >* This item is <label style={{ color: 'black' }} > NOT</label> eligible for return</label>
                    }
                    <label className='labelText label cp-t-5 text-muted'  >Sold by: <label className="labelText label" style={{ color: 'black' }} >{data.store_name}</label> </label>
                  </div>
                </div>
                <div className='col-4 d-flex justify-content-end  ' >
                  <div className='d-flex flex-column' >
                    <div>
                      <label className='d-flex justify-content-center labelHeading primary-color label'  >AED {data.price?.toFixed(2)}</label>
                      <label className='d-flex  labelHeading primary-color text-muted'  >QTY: {data.quantity}</label>
                    </div>
                  </div>
                </div>

              </div>
              <div onClick={() => moveToDetailScreen(data)} style={{ flexDirection: 'row-reverse', display: 'flex' }} >
                <label className="label labelText cm-r-20   cp-t-3 cp-b-3 cp-l-10 cp-r-10  bg-color-primary  touchAble" style={{ color: 'white' }} >Review This Item</label>
              </div>
            </div>
          ))}
        </div>
      }
      {ui == 'sales' &&
        <div className="" >
          {orderDetail?.map((data: any) => (
            <div className=' cp-t-30  cp-l-17 cp-b-20 cm-b-40  ' style={{ backgroundColor: 'white' }} >
              <div className='d-flex flex-row' >
                <div className='d-flex flex-row col-6'>
                  <img className='carosal-image-Web' style={{ height: 90, width: 90 }} src={Global.IMAGE_PATH + data.image} alt="First slide" />
                  <div className='d-flex flex-column cp-l-10 ' >
                    <label className='labelHeading label primary-color   '   >{data.name}</label>

                    <label className='pText f-600 label text-muted'  >{data.manufacturer_name}</label>
                    <label className=' labelText primary-color label'  >AED {data.price?.toFixed(2)}</label>
                  </div>
                </div>
                <div className='col-4 d-flex justify-content-end  ' >
                  <div className='d-flex flex-column' >

                    <label className='  pText main-font  label'  >Order Quantity: <label className="text-muted label" >{data.quantity}</label></label>
                    <label className=' pText main-font label'  >Order Color: <label className="text-muted label" >{data.color}</label></label>
                    <label className='  pText main-font label'  >Order Size: <label className="text-muted label" >{data.size}</label></label>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      }

    </div>
  );
}

export default OrderDetailC;
