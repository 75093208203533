import { useEffect, useState } from 'react';
import axios from "axios";
import {Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import HeaderforRetail from '../../app-components/HeaderforRetail';

function AllBrands(props: any) {
  
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [Brands, setBrands] = useState<any>([])
  window.scrollTo(0, 0)
  useEffect(() => {
    axios
      .get(Global.Web_GET_ALL_BRANDS)
      .then(response => {
        setBrands(response.data.brands.data)
      })
      .catch(function (error) {
      });
  }, [])

  return (
    <div>
      {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light"  >
        <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-20"   >
          <div className="cp-b-40" >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
              <Typography className="primary-color main-font" color="textPrimary">All Brands</Typography>
            </Breadcrumbs>
          </div>
          <div className=" d-flex flex-row cm-b-40 cp-b-40" style={{ backgroundColor: 'white', minHeight: '60vh' }} >
            <div className=" d-flex flex-row cm-10 flex-wrap" style={{ justifyContent: 'space-evenly' }}  >
              {Brands?.map((data: any) =>
                <div className="d-flex flex-column" >
                  <img className=' cm-t-40  cm-l-10 cm-r-10 ' style={{ height: '130px', width: '130px', borderRadius: 100 }} alt='img' src={Global.IMAGE_PATH+data.image} ></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CFooter />
    </div>
  );
}

export default AllBrands;
