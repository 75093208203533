import Productitem from '../../app-components/Productitem';
import { AiOutlineDown } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import Pagination from 'react-bootstrap/Pagination'
import CFooter from '../../app-components/CFooter';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import './AllProducts.scss';
import { Link, useHistory } from 'react-router-dom'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import ShimmerC from '../../app-components/ShimmerC';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { globleAPI as Global } from '../../globals'
import girl from '../../assets/emojiwindle.png';
import 'react-picky/dist/picky.css';
import MultiSelect from '../../app-components/MultiSelect';
import slugify from 'react-slugify';
import HeaderforRetail from '../../app-components/HeaderforRetail';

function AllProducts(props: any) {

  const [PopularProducts, setPopularProducts] = useState([])
  const [categoryImgHeight, setcategoryImgHeight] = useState<any>('')
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [Page, setPage] = useState('')
  const [Pagedata, setPagedata] = useState<any>([])
  const [lastPage, setlastPage] = useState('')
  const [colorsList, setcolorsList] = useState([])
  const [brandsList, setbrandsList] = useState([])
  const [NameOfSorting, setNameOfSorting] = React.useState('Newest List');
  const [Price, setPrice] = React.useState([0, 100]);
  const [dataofcheckbox, setdataofcheckbox] = React.useState(false);
  const [loadingTrue, setloadingTrue] = React.useState(false);
  const [AllCategories, setAllCategories] = React.useState([]);
  const [SubCategories, setSubCategories] = React.useState([]);
  const [categoryName, setcategoryName] = React.useState('');
  const [categoryId, setcategoryId] = React.useState('');
  const [subCategory, setsubCategory] = React.useState('');
  const [selectedBrands, setselectedBrands] = React.useState<any>([]);
  const [selectedColors, setselectedColors] = React.useState<any>([]);
  const [selectedSizes, setselectedSizes] = React.useState<any>([]);
  const [ItemonSAle, setItemonSAle] = React.useState<any>([]);
  const [Productconditon, setProductconditon] = React.useState('');
  const [subCategoryName, setsubCategoryName] = React.useState('');
  const [selectedSeleName, setselectedSeleName] = React.useState('');
  const [HighttoLow, setHighttoLow] = React.useState<any>();
  const [lowToHigh, setlowToHigh] = React.useState<any>();
  const [MostPopular, setMostPopular] = React.useState<any>();
  const [NewListed, setNewListed] = React.useState<any>(1);
  const [product_sizes, setproduct_sizes] = React.useState<any>();
  const [oldListed, setoldListed] = React.useState<any>();
  const [lastPageCondition, setlastPageCondition] = React.useState(false);
  const [nextpageNo, setnextpageNo] = React.useState<any>('1');
  const [categoreyFilter, setcategoreyFilter] = React.useState(true);
  const [saleFilter, setsaleFilter] = React.useState(true);
  const [subcategoreyFilter, setsubcategoreyFilter] = React.useState(true);
  const [BrandsFilter, setBrandsFilter] = React.useState(true);
  const [sizesFilter, setsizesFilter] = React.useState(false);
  const [noProduct, setnoProduct] = React.useState(false);
  const [cOlorsFilter, setcOlorsFilter] = React.useState(true);
  const [sortingTick, setsortingTick] = React.useState('');
  const [Itemsale, setItemsale] = React.useState('');
  const [ItemonSaleValue, setItemonSaleValue] = React.useState<any>('');
  const [BrandsDataBEforeSearch, setBrandsDataBEforeSearch] = React.useState<any>([]);
  const [ConditionFilter, setConditionFilter] = React.useState(true);
  const [NoProductStatus, setNoProductStatus] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      window.location.reload();
      window.scrollTo(0, 0)
    })
    window.scrollTo(0, 0)
    categoryImg(window.innerWidth)
    setloadingTrue(true)
    getAllProducts();
    getColors()
    getBrands()
    getfilters()
    GetSaleFilters()

  }, []);
  const categoryImg = (data: any) => {
    if (data > 799) {
      setcategoryImgHeight(220)
    } else {
      setcategoryImgHeight(150)
    }
  };

  const methodMultiselect = (data: any, data2: any) => {
    if (data2 == 'brands') {
      setselectedBrands(data)
      getrelatedData(categoryId, subCategory, data, Productconditon, Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)
    } else if (data2 == 'colors') {
      setselectedColors(data)
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, Itemsale, data, selectedSizes, ItemonSaleValue)
    } else if (data2 == 'sizes') {
      setselectedSizes(data)
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, Itemsale, selectedColors, data, ItemonSaleValue)
    }
  }

  const GetSaleFilters = () => {
    axios
      .get(Global.GetSaleFilters)
      .then(response => {
        if (props.location.state[0] == 'AllSale') {
          response.data.store_filters && response.data.store_filters.forEach((item: any) => item.checked = false);
          let selected: any;
          selected = response.data.store_filters.find((item: any) => item.name === 'See All');
          selected.checked = !selected.checked;
          setItemonSAle(response.data.store_filters)
        } else {
          setItemonSAle(response.data.store_filters)
          console.log('response.data.store_filters', response.data.store_filters)
        }

      })
      .catch(function (error) {
        console.log('response of the api ', error)
      });
  }

  const AllProduct = (page: any) => {
    window.scrollTo(0, 0)
    axios
      .get(Global.WebGetAllProductsV1 + '?page=' + page)
      .then(response => {
        setPopularProducts(response.data.AllProducts.data)
        if (response.data.AllProducts.total == '0') {
          setNoProductStatus(true)
        } else {
          setNoProductStatus(false)
        }
        setPage(response.data.AllProducts.current_page)
        setPagedata(response.data.AllProducts)
        setlastPage(response.data.AllProducts.last_page)
        setloadingTrue(false)
      })
      .catch(function (error) {
        console.log('response of the api ', error)
      });
  }

  const AllProductsearched = (searchkeyWord: any) => {
    window.scrollTo(0, 0)
    axios
      .post(Global.WebSearchFromAllProducts + "?search_keyword=" + searchkeyWord + "&is_luxury=0",)
      .then(response => {
        setPopularProducts(response.data.AllProducts.data)
        if (response.data.AllProducts.data.length == '0') {
          setNoProductStatus(true)
        } else {
          setNoProductStatus(false)
        }
        setloadingTrue(false)
      })
      .catch(function (error) {
        console.log('rdskflsdfkapi ', error)
      });
  }

  const getAllProducts = () => {
    if (props.location.state[0] == 'AllProducts') {
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, 'No', selectedColors, selectedSizes, ItemonSaleValue)
      getAllCategoriesN()
    } else if (props.location.state[0] == 'AllSale') {
      console.log('120 is working')
      getAllCategoriesN()
      setItemsale('Yes')
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, 'Yes', selectedColors, selectedSizes, ItemonSaleValue)
    } else if (props.location.state[0] == 'AllCat') {

      console.log('121 is working')
      getAllCategories()
      setItemsale('Yes')
      getrelatedData(props.location.state[1], subCategory, selectedBrands, Productconditon, Price, 1, 'Yes', selectedColors, selectedSizes, ItemonSaleValue)

    } else if (props.location.state[0] == 'AllCatSubCat') {
      getAllCategories()
      setcategoryId(props.location.state[1])
      getsubCategory(props.location.state[1])
      setsubCategory(props.location.state[2])
      getrelatedData(props.location.state[1], props.location.state[2], selectedBrands, Productconditon, Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)

    } else if (props.location.state[0] == 'itemCat') {
      console.log('123 is working')

      getsubCategory(props.location.state[1])
      setcategoryId(props.location.state[1])
      getAllCategories()
      getrelatedData(props.location.state[1], subCategory, selectedBrands, Productconditon, Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)

    } else if (props.location.state[0] == 'usedItems') {
      console.log('used item is running')
      setProductconditon('Used')
      getAllCategoriesN()
      getrelatedData(categoryId, subCategory, selectedBrands, "Used", Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)

    } else if (props.location.state[0] == 'AllSalePercentage') {
      getAllCategoriesN()
      setItemsale('Yes')
      setItemonSaleValue(props.location.state[1])
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, 'Yes', selectedColors, selectedSizes, props.location.state[1])
    } else if (props.location.state[0] == 'searchData') {
      getAllCategoriesN()
      AllProductsearched(props.location.state[1])
    } else if (props.location.state[0] == 'AllSaleSpecificPercentage') {
      getsubCategory(props.location.state[2])
      setcategoryId(props.location.state[2])
      getAllCategories()
      setItemsale('Yes')
      setItemonSaleValue(props.location.state[1])
      getrelatedData(props.location.state[2], subCategory, selectedBrands, Productconditon, Price, 1, 'Yes', selectedColors, selectedSizes, props.location.state[1])
    }

  }

  const getSortingData = (ph: any, pl: any, lh: any, hl: any, mp: any) => {
    axios.post(Global.WebSearchFromAllProducts + '?page=' + 1, {
      is_luxury: 0,
      parent_category_id: categoryId,
      category_id: subCategory,
      sizes: selectedSizes,
      discount: ItemonSaleValue,
      manufacturer_names: selectedBrands,
      colors: selectedColors,
      product_condition: Productconditon,
      price_from: Price[0] * 50,
      price_to: Price[1] * 50,
      item_on_sale: Itemsale,
      price_high_to_low: ph,
      price_low_to_high: pl,
      latest_to_old: lh,
      old_to_lates: hl,
      most_popular: mp,
    })
      .then(response => {
        window.scrollTo(0, 0)

        console.log('after data', response)
        setPopularProducts(response.data.AllProducts.data)
        if (response.data.AllProducts.total == '0') {
          setNoProductStatus(true)
        } else {
          setNoProductStatus(false)
        }
        setlastPage(response.data.AllProducts.last_page)
        setPage(response.data.AllProducts.current_page)
        setPagedata(response.data.AllProducts)
        setloadingTrue(false)

      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });

  }

  const getrelatedData = (catId: any, subCatId: any, brands: any, conditon: any, price: any, page: any, itemsale: any, colors: any, sizes: any, discount: any) => {
    axios.post(Global.WebSearchFromAllProducts + '?page=' + page, {
      is_luxury: 0,
      parent_category_id: catId,
      category_id: subCatId,
      sizes: sizes,
      discount: discount,
      manufacturer_names: brands,
      colors: colors,
      product_condition: conditon,
      price_from: price[0] * 50,
      price_to: price[1] * 50,
      most_popular: MostPopular,
      item_on_sale: itemsale,
      latest_to_old: NewListed,
      old_to_lates: oldListed,
      price_low_to_high: lowToHigh,
      price_high_to_low: HighttoLow,
    })
      .then(response => {
        window.scrollTo(0, 0)

        console.log('after data', response)
        setPopularProducts(response.data.AllProducts.data)
        if (response.data.AllProducts.total == '0') {
          setNoProductStatus(true)
        } else {
          setNoProductStatus(false)
        }
        setlastPage(response.data.AllProducts.last_page)
        setPage(response.data.AllProducts.current_page)
        setPagedata(response.data.AllProducts)
        setloadingTrue(false)

      })
      .catch(function (error) {
        console.log('response if the api is errror =DDDDDDD', error)
      });

  }

  const getAllCategoriesN = () => {
    axios
      .get(Global.GET_ALL_CATEGORIES + "?luxury=" + 0 + "&regular=" + 1,)
      .then(response => {
        setAllCategories(response.data.categories)
      })
      .catch(function (error) {
        console.log('no is showing or not', error)
      });
  };

  const getAllCategories = () => {
    axios
      .get(Global.GET_ALL_CATEGORIES + "?luxury=" + 0 + "&regular=" + 1,)
      .then(response => {
        response.data.categories && response.data.categories.forEach((item: any) => item.checked = false);
        let selected: any;
        selected = response.data.categories.find((item: any) => item.id === (props.location.state[1]) || (props.location.state[2]));
        selected.checked = !selected.checked;
        setcategoryName(selected.name)
        setAllCategories(response.data.categories)
      })
      .catch(function (error) {
        console.log('no is showing or not', error)
      });
  };

  const getsubCategory = (id: any) => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=" + id)
      .then(response => {
        if (props.location.state[2] != undefined) {
          response.data.subcategories && response.data.subcategories.forEach((item: any) => item.checked = false);
          let selected: any;
          selected = response.data.subcategories.find((item: any) => item.id === props.location.state[2]);
          selected.checked = !selected.checked;
          setsubCategoryName(selected.name)
          setSubCategories(response.data.subcategories)
        } else {
          setSubCategories(response.data.subcategories)
        }

      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getfilters = () => {
    if (props.location.state == 'Sop All') {
      setdataofcheckbox(true)
    }
  }

  const getColors = () => {
    axios
      .get(Global.GET_ALL_COLOR + "?is_luxury=0")
      .then(response => {
        response.data.colors && response.data.colors.forEach((item: any) => item.checked = false);
        setcolorsList(response.data.colors)
      })
      .catch(function (error) {
        console.log('resofsodf', error)
      });
  }

  const getBrands = () => {
    axios
      .get(Global.GET_BRAND_FILTER + "?luxury=0&regular=1")
      .then(response => {
        response.data.brands && response.data.brands.forEach((item: any) => item.checked = false);
        setbrandsList(response.data.brands);
        setBrandsDataBEforeSearch(response.data.brands)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  const getAllSizes = (cat_id: any) => {
    console.log('Global.GET_ALL_SIZES + "?category_id" + cat_id + "&is_luxury=0"', Global.GET_ALL_SIZES + "?category_id=" + cat_id + "&is_luxury=0")
    axios
      .get(Global.GET_ALL_SIZES + "?category_id=" + cat_id + "&is_luxury=0")
      .then(response => {
        setproduct_sizes(response.data.product_sizes);
        setsizesFilter(true)
      })
      .catch(function (error) {
        console.log('responseresponseresponse', error)
      });
  }

  const Paginationfunction = (page: any) => {
    if (props.location.state[1] == '118' && categoryId == '' && subCategory == '' && selectedBrands == '' && Productconditon == '' && Itemsale == '' && selectedColors == '' && ItemonSaleValue == '' && selectedSizes == '') {
      AllProduct(page)
    } else {
      getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, page, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)
    }
  }

  const RiChecke = (page: any) => {
    let data
    if (page == 'NextPage') {
      if (lastPage > Page) {
        data = Page + 1
        Paginationfunction(data)
      } else {
        data = lastPage
        setlastPageCondition(true)
      }
      data = data
    } else if (page == 'PreviousPage') {
      let dataofnextPage: any = Page;
      dataofnextPage = dataofnextPage - 1
      if (dataofnextPage >= 1) {
        Paginationfunction(dataofnextPage)
      } else {
        console.log('reached 1st page')
      }
      data = dataofnextPage
    } else {
      setnextpageNo(page)
      Paginationfunction(page)
    }
  }

  const rangeSelector = (event: object, newValue: any) => {
    setPrice(newValue);
    getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, newValue, nextpageNo, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)

  };

  const CgRadioCheck = (name: any) => {
    AllCategories && AllCategories.forEach((item: any) => item.checked = false);
    let selected: any;
    selected = AllCategories.find((item: any) => item.id === name.id);
    selected.checked = !selected.checked;
    setcategoryName(name.name)
    setcategoryId(name.id)
    getsubCategory(name.id)
    getrelatedData(name.id, subCategory, selectedBrands, Productconditon, Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)
    setsubCategory('')
  }

  const getSubCategoryAPI = (name: any) => {
    SubCategories && SubCategories.forEach((item: any) => item.checked = false);
    let selected: any;
    selected = SubCategories.find((item: any) => item.id === name.id);
    selected.checked = !selected.checked;
    setsubCategory(name.id)
    setsubCategoryName(name.name)
    getAllSizes(name.id)
    getrelatedData(categoryId, name.id, selectedBrands, Productconditon, Price, 1, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)
  }

  const getCondition = (name: any) => {
    setProductconditon(name)
    getrelatedData(categoryId, subCategory, selectedBrands, name, Price, nextpageNo, Itemsale, selectedColors, selectedSizes, ItemonSaleValue)

  }

  const moveToDetailScreen = (data: any) => {
    window.open('/product-detail/' + data.id + '/' + slugify(data.name));
  }

  const clearFilters = () => {
    window.location.reload();
    AllProduct(1)
  }

  const CheckSale = (data: any) => {
    ItemonSAle && ItemonSAle.forEach((item: any) => item.checked = false);
    let selected: any;
    selected = ItemonSAle.find((item: any) => item.name === data.name);
    selected.checked = !selected.checked;
    setselectedSeleName(data.name)
    setItemsale('Yes')
    setItemonSaleValue(data.discount)
    getrelatedData(categoryId, subCategory, selectedBrands, Productconditon, Price, 1, 'Yes', selectedColors, selectedSizes, data.discount)
  }

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const Sorting = (data: any) => {
    setsortingTick(data)
    if (data == 'hl') {
      setNameOfSorting('Highest Price')
      setHighttoLow(1)
      setlowToHigh(0)
      setMostPopular(0)
      setNewListed(0)
      setoldListed(0)
      getSortingData(1, 0, 0, 0, 0)
      setShow(!show);
    } else if (data == 'lh') {
      setNameOfSorting('Lowest Price')
      setHighttoLow(0)
      setlowToHigh(1)
      setMostPopular(0)
      setNewListed(0)
      setoldListed(0)
      getSortingData(0, 1, 0, 0, 0)
      setShow(!show);
    } else if (data == 'mp') {
      setNameOfSorting('Popular Product')
      setHighttoLow(0)
      setlowToHigh(0)
      setMostPopular(1)
      setNewListed(0)
      setoldListed(0)
      getSortingData(0, 0, 0, 0, 1)
      setShow(!show);
    } else if (data == 'nl') {
      setNameOfSorting('Latest Product')
      setHighttoLow(0)
      setlowToHigh(0)
      setMostPopular(0)
      setNewListed(1)
      setoldListed(0)
      getSortingData(0, 0, 1, 0, 0)
      setShow(!show);
    } else if (data == 'ol') {
      setNameOfSorting('Oldest Product')
      setHighttoLow(0)
      setlowToHigh(0)
      setMostPopular(0)
      setNewListed(0)
      setoldListed(1)
      getSortingData(0, 0, 0, 1, 0)
      setShow(!show);
    }
  };

  const Filters = (filter: any) => {
    if (filter == 'categorey') {
      if (categoreyFilter == true) {
        setcategoreyFilter(false)
      } else {
        setcategoreyFilter(true)
      }
    } else if (filter == 'subcategorey') {
      if (subcategoreyFilter == true) {
        setsubcategoreyFilter(false)
      } else {
        setsubcategoreyFilter(true)
      }
    } else if (filter == 'brands') {
      if (BrandsFilter == true) {
        setBrandsFilter(false)
      } else {
        setBrandsFilter(true)
      }
    }
    else if (filter == 'colors') {
      if (cOlorsFilter == true) {
        setcOlorsFilter(false)
      } else {
        setcOlorsFilter(true)
      }
    } else if (filter == 'condition') {
      if (ConditionFilter == true) {
        setConditionFilter(false)
      } else {
        setConditionFilter(true)
      }
    } else if (filter == 'sizes') {
      if (sizesFilter == true) {
        setsizesFilter(false)
      } else {
        setsizesFilter(true)
      }
    } else if (filter == 'sale') {
      if (saleFilter == true) {
        setsaleFilter(false)
      } else {
        setsaleFilter(true)
      }
    }
  }

  const find = (data: any) => {
    if (data == '') {
      console.log('if  us rubbubg', BrandsDataBEforeSearch)
      setbrandsList(BrandsDataBEforeSearch);
    } else {

      console.log('else us rubbubg')
      let searchThrough: any = [];
      BrandsDataBEforeSearch.map((item: any) => {
        const check = item.name.toLowerCase().includes(
          data.toLowerCase(),
        );
        if (check == true) searchThrough.push(item);
        else setnoProduct(true);
      });
      setbrandsList(searchThrough);
    }
  };

  return (
    <div>
      <HeaderforRetail />
      <div className='mobiledisplay ' style={{}}>
        <label className='main-font cp-t-25 f-25 cm-10' style={{ fontWeight: 'bold' }} >Popular Products</label>
      </div>
      <div className=' d-flex  '  >
        <div className='col-md-3 webdisplay col-lg-3 cm-t-10 ' >
          <div className="d-flex flex-column" >
            {props.location.state[1] == 'All Deals' ? (<label className="form-check-label secHeading primary-color  cp-l-35 cp-t-10 label main-font" >All Deals</label>) : (<label className="form-check-label secHeading   cp-l-35 cp-t-10 label main-font" style={{ color: 'white' }} >All Deals</label>)}
            <label className="form-check-label f-13  cp-l-35 cp-t-52 cp-b-10 label main-font " style={{ color: 'gray' }} >Filter by</label>
          </div>
          <div className='cp-l-30 border-top' >
            <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
              <label className='main-font  f-17 f-500 label text-color ' style={{}} >Categories</label>
              <div className='cp-r-35' onClick={() => Filters('categorey')} >
                {categoreyFilter == true ? (
                  <BsChevronUp className='f-10  ' />
                ) : (
                  <BsChevronDown className='f-10  ' />
                )
                } </div>
            </div>
            {categoreyFilter == true ? (
              <div>
                {AllCategories.length >= 1 ? (
                  <div>
                    {AllCategories.map((category: any, index) =>
                      <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                        <input type="radio" onClick={() => CgRadioCheck(category)} checked={category.checked} className='largerCheckbox touchAble' id="Category" name="gender" value="male" />
                        <label className="form-check-label f-13  cp-l-15 label main-font " style={{ color: 'gray' }} >{category.name}</label>
                        <label className="form-check-label f-13  cp-l-5 label  main-font" style={{ color: 'gray' }} >({category.count})</label>
                      </div>
                    )}
                  </div>
                ) : (
                  <ShimmerC allproductsFilters='yes' />
                )}
              </div>
            ) : null}

          </div>
          {SubCategories.length >= 1 ? (
            <div className='border-top cm-t-20 cm-b-20 cp-l-30' >
              <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
                <label className='main-font  f-17 f-500 label text-color ' style={{}} >Sub Categorey</label>
                <div className='cp-r-35' onClick={() => Filters('subcategorey')} >
                  {subcategoreyFilter == true ? (
                    <BsChevronUp className='f-10' />
                  ) : (
                    <BsChevronDown className='f-10' />
                  )
                  } </div>
              </div>
              {subcategoreyFilter == true ? (
                <div>
                  {SubCategories.length >= 1 ? (
                    <div>
                      {SubCategories.map((Subcategory: any) =>
                        <div className='d-flex center align-items-center cp-t-4 cp-b-4 ' >
                          <input type="radio" onClick={() => getSubCategoryAPI(Subcategory)} checked={Subcategory.checked} className='largerCheckbox touchAble' id="SUbcategory" name="subCat" value="subCat" />
                          <label className="form-check-label f-13 cp-l-15  label main-font" style={{ color: 'gray' }} >{Subcategory.name}</label>
                          <label className="form-check-label f-13  cp-l-5 label main-font" style={{ color: 'gray' }} >({Subcategory.count})</label>
                        </div>
                      )}
                    </div>
                  ) : (
                    <ShimmerC allproductsFilters='yes' />
                  )}
                </div>
              ) : null}
            </div>
          ) : null
          }

          {product_sizes?.length >= 1 ? (
            <div className=' border-top cm-t-20 cm-b-20 cp-l-30' >

              {sizesFilter == true ? (
                <div>
                  <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
                    <label className='main-font  f-17 f-500 label text-color '>Sizes</label>
                    <div className='cp-r-35' onClick={() => Filters('sizes')} >
                      {sizesFilter == true ? (
                        <BsChevronUp className='f-10' />
                      ) : (
                        <BsChevronDown className='f-10' />
                      )
                      } </div>
                  </div>
                  {product_sizes?.length >= 1 ? (
                    <div className='d-flex flex-column'  >

                      <div className='overflow-auto' >
                        <MultiSelect method={methodMultiselect} data={product_sizes} for="sizes" />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

          ) : null}



          <div className='border-top cm-t-20 cm-b-20 cp-l-30' >
            <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
              <label className='main-font  f-17 f-500 label text-color '>Brands</label>
              <div className='cp-r-35' onClick={() => Filters('brands')} >
                {BrandsFilter == true ? (
                  <BsChevronUp className='f-10' />
                ) : (
                  <BsChevronDown className='f-10' />
                )
                } </div>
            </div>
            {BrandsFilter == true ? (
              <div>
                <div className='border cp-5 cm-b-19 ' style={{ borderRadius: 5, width: '95%' }} >
                  <input className='inputsearch' type="text" placeholder="Search.." onChange={(text: any) => { find(text.target.value) }} name="search" />
                </div>
                {brandsList.length >= 1 ? (
                  <div className='d-flex flex-column' style={{ height: '45vh' }} >

                    <div className='overflow-auto' >
                      <MultiSelect method={methodMultiselect} data={brandsList} for="brands" />
                    </div>
                  </div>
                ) : (
                  <div>
                    {noProduct == true ? (
                      <label className='main-font  pText label text-color  cp-l-10'>No Brands found</label>
                    ) : (
                      <ShimmerC allproductsFilters='yes' />
                    )

                    }
                  </div>
                )

                }
              </div>
            ) : null}
          </div>





          <div className='border-top cm-t-20 cm-b-20 cp-l-30' >
            <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
              <label className='main-font  f-17 f-500 label text-color '>Colors</label>
              <div className='cp-r-35' onClick={() => Filters('colors')} >
                {cOlorsFilter == true ? (
                  <BsChevronUp className='f-10' />
                ) : (
                  <BsChevronDown className='f-10' />
                )
                } </div>
            </div>
            {cOlorsFilter == true ? (
              <div>
                {colorsList?.length >= 1 ? (
                  <div className='d-flex flex-column    ' style={{ height: '45vh' }} >
                    <div className='border cp-5 cm-b-19 ' style={{ borderRadius: 5, width: '95%' }} >
                      <input className='inputsearch' type="text" placeholder="Search.." name="search" />
                    </div>
                    <div className='overflow-auto' >
                      <MultiSelect method={methodMultiselect} data={colorsList} for="colors" />
                    </div>
                  </div>
                ) : (
                  <ShimmerC allproductsFilters='yes' />
                )

                }
              </div>
            ) : null

            }


          </div>

          <div className='d-flex flex-column border-top cm-b-20 cp-l-30' >
            <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
              <label className='main-font  f-17 f-500 label text-color '>Condition</label>
              <div className='cp-r-35' onClick={() => Filters('condition')} >
                {ConditionFilter == true ? (
                  <BsChevronUp className='f-10  ' />
                ) : (
                  <BsChevronDown className='f-10  ' />
                )
                } </div>
            </div>
            {ConditionFilter == true ? (
              <div className=" flex-column" >
                <div className='d-flex center align-items-center cp-t-4 cp-b-4' >
                  <input type="radio" onClick={() => getCondition('New')} className='largerCheckbox touchAble' id="new" name="new" value="new" />
                  <label className="form-check-label f-14  cp-l-15 label main-font" style={{ color: 'gray' }} >New</label>
                </div>
                <div className='d-flex center align-items-center cp-t-4 cp-b-4' >
                  <input type="radio" onClick={() => getCondition('Used')} className='largerCheckbox touchAble' id="new" name="new" value="new" />
                  <label className="form-check-label f-14  cp-l-15 label main-font" style={{ color: 'gray' }} >Used</label>
                </div>
              </div>
            ) : null}

          </div>
          <div className='d-flex flex-column border-top cm-t-20 cm-b-20 cp-l-30' >
            <label className='main-font  f-17 f-500 label text-color cp-t-15 cp-b-15' style={{}} >Price</label>
            <div className="form-check col-12  d-flex align-content-center  justify-content-between flex-column" >
              <div style={{
                display: 'block',
                width: 'fit-content'
              }}>
                <Typography id="range-slider" gutterBottom>
                  <p className="text-muted text-center  pText" >Select Price Range:</p>
                </Typography>
                <Slider
                  style={{ color: 'rgb(218, 95, 115)' }}
                  value={Price}
                  onChange={rangeSelector}
                />
                {/* <div className="d-flex " >
                  <div className=" col-6" >
                  <input   className="border col-12 "  style={{height:40}}  placeholder="0"  />
                  <p className=" pText text-center text-muted " >FROM (AED) </p>
                  </div>
                  <div className=" col-6" >
                  <input  className="border col-12"  style={{height:40}}  placeholder="5000" />
                 <p className=" pText text-center text-muted  " >TO (AED) </p>
                  </div>
                </div> */}
                <p className="text-muted text-center  pText" >price  {Price[0] * 50} and {Price[1] * 50}</p>
              </div>
            </div>
          </div>
          <div className='cp-l-30 border-top' >
            <div className='d-flex justify-content-between cp-b-15 cp-t-15' >
              <label className='main-font  f-17 f-500 label text-color ' style={{}} >Item on Sale</label>
              <div className='cp-r-35' onClick={() => Filters('sale')} >
                {saleFilter == true ? (
                  <BsChevronUp className='f-10  ' />
                ) : (
                  <BsChevronDown className='f-10  ' />
                )
                } </div>
            </div>
            {saleFilter == true ? (
              <div>
                {ItemonSAle.length >= 1 ? (
                  <div>
                    {ItemonSAle.map((sale: any) =>
                      <div className='d-flex  align-items-center cp-t-4 cp-b-4' >
                        <input type="radio" onClick={() => CheckSale(sale)} checked={sale.checked} className='largerCheckbox touchAble' />
                        <label className="form-check-label f-13  cp-l-15 label main-font " style={{ color: 'gray' }} >{sale.name}</label>
                      </div>
                    )}
                  </div>
                ) : (
                  <ShimmerC allproductsFilters='yes' />
                )}
              </div>
            ) : null}

          </div>
        </div>
        <div className='col-md-9 col-12 col-lg-9' style={{}} >
          <div className='webdisplay ' >
            <div className='  flex-column d-flex'>
              <div className="cm-t-30 cm-b-30" >
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
                  <Typography className="primary-color main-font" color="textPrimary">Shop All</Typography>
                  <Typography className="primary-color main-font" color="textPrimary">{props.location.state[0] == '118' ? 'All Products ' : 'All Products'}</Typography>
                  {categoryName != '' ? (<Typography className="primary-color main-font" color="textPrimary">{categoryName}</Typography>) : null}
                  {subCategoryName != '' ? (<Typography className="primary-color main-font" color="textPrimary">{subCategoryName}</Typography>) : null}
                </Breadcrumbs>
              </div>
              {categoryName != '' || Price[0] > 1 || selectedSeleName != '' || Productconditon !== '' &&
                <label className='main-font cp-t-10  pText d-flex flex-row ' style={{ color: 'gray' }} >Searched Filters<p className='primary-color cp-l-5  pText' > {categoryName} /</p> <p className='primary-color cp-l-5 cp-r-5 pText' >{subCategoryName}</p>  {Price[0] > 1 ? (<p className='border cp-l-5 cp-r-5 primary-color pText' style={{ borderRadius: 100, }} > / {'  Price  ' + Price[0] * 50} - {Price[1] * 50}</p>) : null} <p className='primary-color  cp-r-5 pText' >{selectedSeleName}</p> <p className='primary-color cp-l-5  pText' > {Productconditon} /</p>   <p className="touchAble underline" style={{ color: 'black', display: 'flex', flexDirection: 'row', marginLeft: '0.5%', alignSelf: 'center' }} onClick={() => clearFilters()} > Clear All </p> </label>
              }
              <div className='d-flex flex-row justify-content-between'>

                <label className='main-font  f-15 f-500 d-flex flex-row' style={{ color: 'gray' }} ><p className='primary-color cp-l-10 cp-r-10 f-600 main-font' ></p> </label>

                <div className='d-flex flex-row ' >
                  <label className='main-font  f-15 f-500 d-flex flex-row label align-items-center ' style={{ color: 'gray', height: '4.5vh' }} >Sort By:</label>
                  <div onClick={handleClick} className='touchAble border cp-l-20  cp-r-20 justify-content-between d-flex flex-row  align-items-center cm-l-5 cm-r-20 ' style={{ height: '4.5vh' }}>
                    <label className='main-font  f-15 f-500 label text-color touchAble cp-r-4'>{<label className='main-font  f-15 f-500 label text-color touchAble cp-r-4'  >{NameOfSorting}</label>}</label>
                    <AiOutlineDown />
                  </div>
                  <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref.current}
                    containerPadding={50}
                  >
                    <Popover style={{ width: '100%', marginTop: '1.5%' }} id="popover-contained">
                      {/* <Popover.Title as="h3">Popover bottom</Popover.Title> */}
                      <Popover.Content >


                        <div onClick={() => Sorting('mp')} className=' cp-10 border-bottom d-flex justify-content-between' >
                          <label className='touchAble f-15 f-500 main-font cp-l-10 label' >Most Popular</label>
                          {sortingTick == 'mp' ? (
                            <TiTick size={25} />
                          ) : null}
                        </div>
                        <div onClick={() => Sorting('nl')} className='cp-10 border-bottom d-flex justify-content-between' >
                          <label className='touchAble f-15 f-500 main-font cp-l-10 label' >Newest List</label>
                          {sortingTick == 'nl' ? (
                            <TiTick size={25} />
                          ) : null}
                        </div>
                        <div onClick={() => Sorting('ol')} className='cp-10 border-bottom d-flex justify-content-between' >
                          <label className='touchAble f-15 f-500 main-font cp-l-10 label' >Oldest List</label>
                          {sortingTick == 'ol' ? (
                            <TiTick size={25} />
                          ) : null}
                        </div>
                        <div onClick={() => Sorting('hl')} className='cp-10 border-bottom d-flex justify-content-between' >
                          <label className='f-15 f-500 main-font cp-l-10 label touchAble' >Price High To Low</label>
                          {sortingTick == 'hl' ? (
                            <TiTick size={25} />
                          ) : null}
                        </div>
                        <div onClick={() => Sorting('lh')} className='cp-10 border-bottom d-flex justify-content-between' >
                          <label className='touchAble f-15 f-500 main-font cp-l-10 label' >Price Low To Hight  </label>
                          {sortingTick == 'lh' ? (
                            <TiTick size={25} />
                          ) : null}
                        </div>

                      </Popover.Content>
                    </Popover>
                  </Overlay>

                  {PopularProducts.length >= 1 ? (
                    <div className=' cp-r-25 '  >
                      <Pagination size="sm" >
                        {Pagedata.current_page > '1' ? (<Pagination.Prev onClick={() => RiChecke('PreviousPage')} />) : null}
                        <Pagination.Item onClick={() => RiChecke(1)} >{1}</Pagination.Item>
                        {Pagedata.last_page > '1' ? (<Pagination.Item onClick={() => RiChecke(2)} >{2}</Pagination.Item>) : null}
                        {Pagedata.last_page > '2' ? (<Pagination.Item onClick={() => RiChecke(3)} >{3}</Pagination.Item>) : null}
                        {Pagedata.last_page > '3' ? (<Pagination.Item onClick={() => RiChecke(4)} >{4}</Pagination.Item>) : null}
                        {Pagedata.last_page > '4' ? (<Pagination.Ellipsis />) : null}
                        {Pagedata.last_page > '5' ? (<Pagination.Item onClick={() => RiChecke(Pagedata.last_page)} >{Pagedata.last_page}</Pagination.Item>) : null}
                        {Pagedata.last_page > '5' ? (<Pagination.Next onClick={() => RiChecke('NextPage')} />) : null}
                      </Pagination>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <label className='main-font cp-t-10 f-15 f-500 d-flex flex-row' style={{color:'gray' }} >Filtered By :<p className='primary-color cp-l-10 cp-r-10 f-500 border cm-l-10 cm-r-10' >Gucchi</p><p className='primary-color f-13'>CLEAR ALL</p></label> */}
            </div>
          </div>

          {PopularProducts.length >= 1 &&
            <div className='d-flex webPaddings flex-wrap ' >
              {PopularProducts.map((data: any) => (
                <div className='col-md-3 col-6 paddingsMobile' onClick={() => moveToDetailScreen(data)}   >
                  <Productitem heigth={categoryImgHeight} DataofProduct={data} />
                </div>
              ))
              }
            </div>
          }
          {NoProductStatus == true &&
            <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
              <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
              <label className="text-muted text-center" style={{ width: '30%' }} >No Item found</label>
            </div>
          }



          {loadingTrue == true ? (
            <div>
              <div className='cm-t-20' >

                <ShimmerC allproducts='yes' />
              </div>
              <div className='cm-t-20' >

                <ShimmerC allproducts='yes' />
              </div>
              <div className='cm-t-20' >

                <ShimmerC allproducts='yes' />
              </div>
            </div>
          ) : null

          }
          {/* <div className='row '>
            {PopularProducts.map((data: any) =>
              <div className='col-md-4 col-lg-3 col-sm-6 col-6  ' onClick={() => moveToDetailScreen(data)}   >
                <Productitem DataofProduct={data} />
              </div>
            )
            }
          </div> */}
          <div>
            {lastPageCondition == true ? (
              <div>
                <p className='d-flex justify-content-center f-500 f-20 cm-t-50' >Last page Reached</p>
              </div>
            ) : null}
          </div>
          {PopularProducts.length >= 1 ? (
            <div className=' d-flex justify-content-lg-end  cp-40'  >
              <Pagination >
                {Pagedata.current_page > '1' ? (<Pagination.Prev onClick={() => RiChecke('PreviousPage')} />) : null}
                <Pagination.Item onClick={() => RiChecke(1)} >{1}</Pagination.Item>
                {Pagedata.last_page > '1' ? (<Pagination.Item onClick={() => RiChecke(2)} >{2}</Pagination.Item>) : null}
                {Pagedata.last_page > '2' ? (<Pagination.Item onClick={() => RiChecke(3)} >{3}</Pagination.Item>) : null}
                {Pagedata.last_page > '3' ? (<Pagination.Item onClick={() => RiChecke(4)} >{4}</Pagination.Item>) : null}
                {Pagedata.last_page > '4' ? (<Pagination.Ellipsis />) : null}
                {Pagedata.last_page > '5' ? (<Pagination.Item onClick={() => RiChecke(Pagedata.last_page)} >{Pagedata.last_page}</Pagination.Item>) : null}
                {Pagedata.last_page > '5' ? (<Pagination.Next onClick={() => RiChecke('NextPage')} />) : null}


                {/* <Pagination.Last /> */}
              </Pagination>
            </div>
          ) : null}

          <div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10%' }} >
        <CFooter />
      </div>
    </div>
  )
}
export default AllProducts;








