import './AllProducts.scss';
import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory, Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Storeitem from '../../app-components/Storeitem';
import ShimmerC from '../../app-components/ShimmerC';
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals'
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function AllStores(props: any) {

  const [storesData] = useState<any>([])
  const [storesPage, setstoresPage] = useState<any>('')
  const history = useHistory();
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  var nofeaturedstore: string = 'No';

  useEffect(() => {
    getStoresItems(1)
    window.scrollTo(0, 0)
  }, [])

  const getStoresItems = (data: any) => {
    if (props.location.state[0] == 'Retail Stores') {
      axios
        .get(Global.WebGetRetailStoresListV1 + '?page=' + data)
        .then(response => {
          let arrayData = response.data.stores.data
          storesData.push(...arrayData)
          setstoresPage(response.data.stores)
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    } else {
      axios
        .get(Global.WebGetStoresListV1 + '?page=' + data)
        .then(response => {
          let arrayData = response.data.stores.data
          storesData.push(...arrayData)
          setstoresPage(response.data.stores)
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    }
  }

  const move = (data: any) => {
    history.push('/StoreDetail', [data]);
  }

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light"  >
        <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-20"   >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">All {props.location.state[0]}</Typography>
          </Breadcrumbs>
          <label className="f-23 cp-t-15 cp-b-15 font-weight-bold main-font primary-color" >All {props.location.state[0]}</label>
          {storesData.length >= 1 ? (
            <div style={{ backgroundColor: 'white' }} className='row '>
              {storesData.map((stores: any) => (
                <div onClick={() => move(stores.id)} className='col-md-3 col-6 paddingss' style={{}}>
                  <Storeitem DataofProduct={stores} FeaturedStore={nofeaturedstore} />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ backgroundColor: 'white', height: '170vh' }}>
              <ShimmerC allproducts='yes' />
              <ShimmerC allproducts='yes' />
              <ShimmerC allproducts='yes' />
              <ShimmerC allproducts='yes' />
            </div>
          )}
        </div>
        {storesPage.last_page > storesPage.current_page &&
          <div onClick={() => getStoresItems(storesPage.current_page + 1)} className="d-flex justify-content-center" >
            <label className="f-17 cp-b-60 font-weight-bold main-font primary-color hoverUnderLine" >See more</label>
          </div>
        }
      </div>
      <CFooter />
    </div>
  );
}

export default AllStores;
