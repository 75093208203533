import React, { useState } from 'react';
import CFooter from '../app-components/CFooter';
import image1 from '../assets/WhatsApp Image 2021-10-27 at 17.31.00.jpeg';
import image2 from '../assets/WhatsApp Image 2021-10-27 at 17.31.10.jpeg';
import image3 from '../assets/WhatsApp Image 2021-10-27 at 17.31.16.jpeg';
import image4 from '../assets/WhatsApp Image 2021-10-27 at 17.31.22.jpeg';
import image5 from '../assets/WhatsApp Image 2021-10-27 at 17.31.42.jpeg';
import image6 from '../assets/WhatsApp Image 2021-10-27 at 17.31.46.jpeg';
import image7 from '../assets/WhatsApp Image 2021-10-27 at 17.31.55.jpeg';
import image8 from '../assets/WhatsApp Image 2021-10-27 at 17.31.58.jpeg';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function Community() {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')

    window.scrollTo(0, 0)

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding" >
                <div className="d-flex justify-content-center cp-t-50" >
                    <div className="d-flex flex-column  align-item-center" >
                        <p className=" secHeading primary-color " >Our Community</p>
                        <p className="  labelText label " >Meet some of our amazing community members</p>
                        <p className=" labelText " >retail and preloved sellers in the UAE</p>
                    </div>
                </div>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image1} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image2} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image3} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image4} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image5} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image6} ></img>
                <img className="cm-t-20" style={{ height: 'auto', width: '100%' }} src={image7} ></img>
                <img className="cm-t-20 cm-b-60" style={{ height: 'auto', width: '100%' }} src={image8} ></img>
            </div>
            <CFooter />
        </div>
    );
}
export default Community