import { globleAPI as Global } from '../../globals'
import axios from "axios";
import { useEffect, useState, useRef } from 'react';
import InputField from '../../app-components/InputField';
import CFooter from '../../app-components/CFooter';
import { useHistory } from "react-router-dom";
import Alertss from '../../app-components/Alertss';
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';


function MakeAnOffer(props: any) {
    
    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [token] = useState(localStorage.getItem('token') || '')
    const override = css` display: block; margin: 0 auto; border-color: red;`;
    let [color] = useState("rgb(218, 95, 115)");
    let [loading, setLoading] = useState(false);
    const [productDetail, setproductDetail] = useState<any>([])
    const [Amount, setAmount] = useState<any>('');
    const [blurOption, setblurOption] = useState(false);
    const [showAlert, setshowAlert] = useState(false)
    const [status, setstatus] = useState('')
    const history = useHistory();

    useEffect(() => {
        setproductDetail(props.location.state[0])
    }, [])

    const MakeAnOffer = (data: any) => {
        if (Amount == '') {
            alert('empoty')
            setblurOption(true)
            setTimeout(() => {
                setblurOption(false)
            }, 10);
        } else {
            setLoading(true)
            let formdata = new FormData();
            formdata.append("vendor_id", data.supplier_id);
            formdata.append("product_id", data.id);
            formdata.append("price", data.unit_price);
            formdata.append("offer_price", Amount);
            formdata.append("offer_type", '1');
            formdata.append("receiver_id", data.supplier_id);
            formdata.append("response_time_in_hors", '48');
            axios({
                method: "post",
                url: Global.MakeAnOfferV1 + token,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    setAmount('')
                    setLoading(false)
                    setstatus('Your Offer has been sent')
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                        history.goBack()
                    }, 4000);
                })
                .catch(function (error) {
                    setLoading(false)
                    setshowAlert(true)
                    setstatus(error)
                });
        }
    };

    return (
        <div className=" bg-color-light" >
      {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}
            <div className="padding">
                {showAlert == true && <Alertss type='success' status={status} />}
                <div className=" d-flex position-absolute justify-content-center" style={{ width: '100%' }} > <ScaleLoader color={color} loading={loading} css={override} height={40} width={6} /></div>
                <label className=" main-font secHeading label cp-t-30 cp-b-30 " >Make An Offer</label>
                <div className=" cm-t-10 cm-b-60 cp-15 " style={{ backgroundColor: 'white', width: '45vw' }} >
                    <div className="d-flex border-bottom cp-b-15" >
                        <div className="d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                            <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + productDetail.image} ></img>
                        </div>
                        <div className=" cp-l-15 d-flex  flex-column align-self-center" >
                            <label className=" main-font labelText primary-color label" >{productDetail.name}</label>
                            <label className=" main-font text-muted pText label" >{productDetail.manufacturer_name}</label>
                            <label className=" main-font pText text-muted label" >Retail Price: AED {productDetail.unit_price}</label>
                        </div>
                    </div>
                    <div className="d-flex cp-30 justify-content-center" >
                        <label className="text-muted " >You want to make an offer to the seller for the following item :</label>
                    </div>
                    <div className="d-flex justify-content-center" >
                        <label className=" text-center text-muted" >YOUR OFFER :</label>
                    </div>
                    <InputField type="text" onlyNumber="true" value={Amount} name="text" hints="Amount" security='true' blur={blurOption} methode={(data: any) => setAmount(data)} />
                    <div className="d-flex justify-content-center" >
                        <div onClick={() => MakeAnOffer(productDetail)} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-12 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                            <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>CONFIRM</p>
                        </div>
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default MakeAnOffer;
