import './MainHome.scss';
import Categoryitem from '../../app-components/Categoryitem';
import Storeitem from '../../app-components/Storeitem';
import ShimmerC from '../../app-components/ShimmerC';
import CFooter from '../../app-components/CFooter';
import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel'
import { useHistory } from 'react-router-dom'
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import 'firebase/messaging';
import Category from '../../app-components/Category';
import banner from '../../assets/Banner.png'
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import JoinOurNewsLetter from '../../app-components/JoinOurNewsLetter';
import StoreItemsFive from '../../app-components/StoreItemsFive';

function LuxuryHome(props: any) {

  const history = useHistory();
  const getTheApp = useRef(null)
  const [Brands, setBrands] = useState<any>([])
  const [categoryImgHeight, setcategoryImgHeight] = useState<any>('')
  const [categoryImgHeight2, setcategoryImgHeight2] = useState<any>('')
  const [featuredItems, setfeaturedItems] = useState<any[]>([])
  const [ShowThanksmsg, setShowThanksmsg] = useState<any>('')
  const [ProdutCategory, setProdutCategory] = useState<any>([])
  const [categoryCards, setcategoryCards] = useState<any[]>([])
  const [popularProduct, setpopularProduct] = useState<any[]>([])
  const [NoStore, setNoStore] = useState('')
  const [sliderImages, setsliderImages] = useState<any[]>([])
  const [token] = useState(localStorage.getItem('token') || '')
  const [userid] = useState(localStorage.getItem('userid') || '')
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
  const [PreLovesStoresList, setPreLovesStoresList] = useState<any>([])
  var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  useEffect(() => {
    history.listen((location, action) => {
      window.location.reload()
    })
    window.scrollTo(0, 0)
    categoryImg(window.innerWidth)
    categoryImg2(window.innerWidth)
    getAnnoucments()
    getPreLovedStores()
    getcategoryCard()
    PopularProductsV1()
    FeturedItems()
    TopBrands()
    Categories()
    getMyStoreDetail()
  }, []);

  const categoryImg = (data: any) => {
    if (data > 799) {
      setcategoryImgHeight('55vh')
    } else {
      setcategoryImgHeight('20vh')
    }
  };
  const categoryImg2 = (data: any) => {
    if (data > 799) {
      setcategoryImgHeight2('43vh')
    } else {
      setcategoryImgHeight2('20vh')
    }
  };


  const getMyStoreDetail = () => {
    if (token) {
      axios
        .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
        .then(response => {
          if (response.data.store_details[0].store_address == undefined) {
            setNoStore('yes')
          } else {
            setNoStore('no')
          }
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    } else {
      setNoStore('yes')
    }

  }

  const Categories = () => {
    axios
      .get(Global.GetCategory + '?is_luxury=1')
      .then(response => {
        setProdutCategory(response.data.product_categories)
      })
      .catch(function (error) {
        console.log('response djfkjsdf', error)
      });
  }

  const TopBrands = () => {
    axios
      .get(Global.GetTopBrands + '?is_luxury=1')
      .then(response => {
        setBrands(response.data.brands)
      })
      .catch(function (error) {
        console.log('response djfkjsdf', error)
      });
  }

  const getcategoryCard = () => {
    axios
      .get(Global.WebGetAppShortcutCards + '?is_luxury=1')
      .then(response => {
        setcategoryCards(response.data.app_shortcut_cards)
      })
      .catch(function (error) {
        console.log('response djfkjsdf', error)
      });
  }

  const PopularProductsV1 = () => {
    axios
      .get(Global.GetPopularProductsV1 + '?is_luxury=1')
      .then(response => {
        let luxury = response.data.products.filter(
          (Industrydata: any, idx: any) => idx < 5,
        );
        setpopularProduct(luxury)
      })
      .catch(function (error) {
        console.log('respinsedddd', error)
      });
  }

  const FeturedItems = () => {
    axios
      .get(Global.GetFeturedItems + '?is_luxury=1')
      .then(response => {
        setfeaturedItems(response.data.featured_items)
      })
      .catch(function (error) {
        console.log('respinsedddd', error)
      });
  }

  const getAnnoucments = () => {
    axios
      .get(Global.GetLesfemmesBanner + '?is_luxury=1')
      .then(response => {
        setsliderImages(response.data.slider_array)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  const getPreLovedStores = () => {
    axios
      .get(Global.GET_ALL_STORES_TOP_ITEM)
      .then(response => {
        let stores = response.data.stores.filter(
          (Industrydata: any, idx: any) => idx < 5,
        );
        setPreLovesStoresList(stores)
      })
      .catch(function (error) {
        console.log('preloves stores', error)
      });
  }

  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.id + '/' + slugify(data.name), [data]);
  }

  const move = (data: any) => {
    history.push('/StoreDetail', [data]);
  }

  const movetoAllProducts = (data: any) => {
    if (data.name == '@ALL# -Dresses/ ^ & + * $ =') {
      history.push('/luxury-products', ['subCategoryProducts', data.category_id, data.sub_category_id]);
    } else if (data.name == '@DAILY# -DEALS/') {
      history.push('/luxury-products', ['SaleOnAllProducts']);
    } else if (data.name == '@ALL# -Accessories/') {
      history.push('/luxury-products', ['categoryProducts', data.category_id]);
    } else if (data.name == '-50% OFF/ ^ALL BAGS& +UPTO*') {
      history.push('/luxury-products', ['AllSaleSpecificPercentage', 50, data.category_id]);
    } else if (data.name == '<SALE> $ALL SHOES=') {
      history.push('/luxury-products', ['categoryProductsWithSale', data.category_id]);
    } else if (data.name == '@ALL# -Beauty/ ') {
      history.push('/luxury-products', ['categoryProducts', data.category_id]);
    }
  }

  const NewsLetter = (data: any) => {
    if (mailformat.test(data)) {
      axios
        .post(Global.NewsLetter + '?email=' + data)
        .then(response => {
          if (response.data.status == 200) {
            setShowThanksmsg('Thank you for Subscribing')
            setTimeout(() => {
              setShowThanksmsg('')
            }, 10000);
          } else {
            setShowThanksmsg('Please try again latter')
            setTimeout(() => {
              setShowThanksmsg('')
            }, 10000);
          }
        })
        .catch(function (error) {
          alert(error)
        });
    } else {
      setShowThanksmsg('Please add a valid email address')
      setTimeout(() => {
        setShowThanksmsg('')
      }, 10000);
    }
  }

  return (
    <div>
      <HeaderForLuxury />
      <div style={{ zIndex: -1, }} >
        {sliderImages.length >= 1 ? (
          <Carousel>
            {sliderImages?.map((images, index) => (
              <Carousel.Item key={index} >
                <img onClick={() => history.push('/luxury-products', ['allLuxuryProducts'])} className="header-image-styling touchAble img-responsive" src={images} alt="First slide" />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <div>
            <ShimmerC smallshimer='no' />
          </div>
        )}
      </div>
      <div className='CategoryDiv' >
        {categoryCards.map((data: any) =>
          <div onClick={() => movetoAllProducts(data)} className='col-md-4 paddings  col-6' style={{}} >
            <Category imageheight={categoryImgHeight} dataofCategory={data} />
          </div>
        )
        }
      </div>
      <div className='contentStyle page-left-padding page-right-padding cm-t-30' >
        <div className='main-font mostPopularFont' >MOST POPULAR</div>
        <div className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
          <label onClick={() => history.push('/popular-products', ['Luxury'])} className="f-15 cp-l-20 main-font cp-r-20 touchAble hoverUnderLine  main-font label" style={{ color: 'white' }} >View All</label>
        </div>
      </div>
      <div>
        <div className='cm-r-40 cm-l-75' >
          {popularProduct.length >= 1 ? (
            <div className='row webdisplay'>
              {popularProduct.map((person: any, index) => (
                <div key={person.id} style={{ width: '18%', marginRight: '2%' }} onClick={() => moveToDetailScreen(person)} >
                  <StoreItemsFive data={person} />
                </div>

              ))}
            </div>
          ) : (
            <div className='row '>
              <ShimmerC homeproducts='yes' />
            </div>
          )}

        </div>
        <div className='mobiledisplay' >
          {popularProduct.length >= 1 ? (
            <div className='cp-r-13 cp-l-13  d-flex' style={{overflow:'scroll'}}>
              {popularProduct.map((person: any, index) => (
                <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)} >
                  <StoreItemsFive heigth={150} data={person} />
                </div>

              ))}
            </div>
          ) : (
            <div className='row mobiledisplay '>
              <ShimmerC homeproducts='yes' />
            </div>
          )}
        </div>
        {token !== '' ? (
          <div onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} style={{ marginTop: 60 }} >
            <img className="touchAble" alt='img' style={{ width: '100vw' }} src={banner} ></img>
          </div>
        ) : (
          <div onClick={() => history.push('./login')} style={{ marginTop: 60 }} >
            <img className="touchAble" alt='img' style={{ width: '100vw' }} src={banner} ></img>
          </div>
        )}

        <div className='cp-t-30 cp-b-30' style={{ justifyContent: 'center', display: 'flex', backgroundColor: '#F3F3F3' }} >
          <label className=" mostPopularFont label main-font" style={{ textAlign: 'center' }} >FEATURED ITEMS</label>
        </div>
        <div className='webdisplay' >

          {featuredItems.length >= 1 ? (
            <div className='cp-r-40 cp-l-75 cp-b-50 ' style={{ backgroundColor: '#F3F3F3' }} >
              <div className='row '>
                {featuredItems.map((person: any) => (
                  <div key={person.id} style={{ width: '18%', marginRight: '2%', marginBottom: '2%' }} onClick={() => moveToDetailScreen(person)}>
                    <StoreItemsFive data={person} />
                  </div>
                ))
                }
              </div>
            </div>
          ) : (
            <div className='cp-b-50' style={{ backgroundColor: '#F3F3F3' }} >
              <div className='row  page-left-padding page-right-padding' >
                <ShimmerC homeproducts='yes' />
              </div>
              <div className='row  page-left-padding page-right-padding cm-t-20' >
                <ShimmerC homeproducts='yes' />
              </div>
            </div>
          )}
        </div>
        <div className='mobiledisplay' >
          {featuredItems.length >= 1 ? (
            <div className='cp-r-13 cp-l-13  d-flex flex-wrap'>
              {featuredItems.map((person: any) => (
                <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)}>
                  <StoreItemsFive heigth={150} data={person} />
                </div>
              ))
              }
            </div>
          ) : (
            <div className='cp-b-50' style={{ backgroundColor: '#F3F3F3' }} >
              <div className='row  page-left-padding page-right-padding' >
                <ShimmerC homeproducts='yes' />
              </div>
              <div className='row  page-left-padding page-right-padding cm-t-20' >
                <ShimmerC homeproducts='yes' />
              </div>
            </div>
          )}
        </div>

        <div className='contentStyle page-left-padding page-right-padding'>
          <div className='mostPopularFont main-font'>
            PRELOVED STORES
          </div>
          <div className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
            <label onClick={() => history.push('/stores', ['Luxury Stores'])} className="f-15 main-font touchAble hoverUnderLine cp-l-20 cp-r-20  main-font label" style={{ color: 'white' }} > View All</label>
          </div>
        </div>
        {PreLovesStoresList.length >= 1 ? (
          <div className='webdisplay cp-r-40 cp-l-75 cp-b-50' >
            <div className='row '>
              {PreLovesStoresList.map((person: any) => (
                <div onClick={() => move(person.id)} style={{ width: '18%', marginRight: '2%' }} key={person.id} >
                  <Storeitem DataofProduct={person} FeaturedStore={'No'} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='row page-left-padding page-right-padding cp-b-60  webdisplay'>
            <ShimmerC homeproducts='yes' />
          </div>
        )}
         <div className='mobiledisplay ' >
        {PreLovesStoresList.length >= 1 ? (
         <div className='cp-r-13 cp-l-13  d-flex' style={{overflow:'scroll'}}>
            {PreLovesStoresList.map((person: any) => (
              <div onClick={() => move(person.id)} key={person.id} className='col-6 paddingsMobile '>
                <Storeitem height={150} DataofProduct={person} FeaturedStore={'No'} />
              </div>
            ))}
          </div>
        ) : (
          <div className='row page-left-padding page-right-padding cp-b-60 '>
            <ShimmerC homeproducts='yes' />
          </div>
        )}
        </div>


        <div className='mostPopularDiv  flex-wrap ' style={{ display: 'flex', marginTop: 20 }} >
          {ProdutCategory.map((data: any) =>
            <div onClick={() => history.push('/luxury-products', ['categoryProducts', data.id])} className='col-md-3 col-6 paddingwws cp-t-10' >
              <Category imageheight={categoryImgHeight2} text7={data.name} dataofCategory={data} />
            </div>
          )}
        </div>
        {Brands.length >= 1 &&
          <div className="cp-t-60 cp-b-20 " >
            <div className='mainHeading d-flex main-font justify-content-center label'>TOP BRANDS</div>
            <Categoryitem Dataof={Brands} brands={true} border={false} />
          </div>
        }
      </div>
      <JoinOurNewsLetter methode={(data: any) => NewsLetter(data)} text={ShowThanksmsg} />
      <div ref={getTheApp} className="c-border-top-light">
        < CFooter />
      </div>
    </div  >
  );
}

export default LuxuryHome;
