import { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { useHistory, Link } from 'react-router-dom'
import CFooter from '../../app-components/CFooter';
import { BiPhoneCall } from "react-icons/bi";
import { RiWhatsappLine } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import Alert from 'react-bootstrap/Alert'
import { globleAPI as Global } from '../../globals'
import { MdChatBubble } from "react-icons/md";
import { FaBirthdayCake, FaUserAlt } from "react-icons/fa";
import { RiCalendarEventFill, RiFileUserLine } from "react-icons/ri";
import { BsFillBellFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { AiFillEye } from "react-icons/ai";
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function SettingsScreen(props: any) {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [repassword, setrepassword] = useState('')
    const [toggle, settoggle] = useState<any>([])
    const [passwordvalid, setpasswordvalid] = useState(false)
    const [show_input, setshow_input] = useState(false)
    const [show_inputOldPasswords, setshow_inputOldPasswords] = useState(false)
    const [show_inputnewPasswords, setshow_inputnewPasswords] = useState(false)
    const [password, setpassword] = useState('')
    const [showAlert, setshowAlert] = useState(false)
    const [ChatVisible, setChatVisible] = useState(false)
    const [Oldpassword, setOldpassword] = useState('')
    const [retypePasswordvalid, setretypePasswordvalid] = useState(false)
    const [status, setstatus] = useState('')
    const [token] = useState(localStorage.getItem('token') || '')
    const history = useHistory();
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    useEffect(() => {
        window.scrollTo(0, 0)
        GetUserNotifications()
    }, [])

    const GetUserNotifications = () => {
        axios
            .post(Global.GET_USER_MUTE_NOTIFICATIONS + token)
            .then(response => {
                settoggle(response.data)
            })
            .catch(function (error) {
                console.log('reposne of api', error)
            });
    }

    const birthdayFuction = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '2')
    }

    const chatFunction = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '1')
    }

    const apiFunction = (urls: any, type: any) => {
        let formdata = new FormData();
        formdata.append("notification_type", type);
        axios({
            method: "post",
            url: urls,
            data: formdata,
        })
            .then(response => {
                console.log('reposne of api', response)
                setstatus(response.data.message)
                setOldpassword('')
                setpassword('')
                setrepassword('')
                setshowAlert(true)
                setTimeout(() => {
                    setshowAlert(false)
                }, 5000);
                GetUserNotifications()

            })
            .catch(function (error) {
                console.log('reposne of api', error)
            });
    }

    const eventFucntion = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '3')
    }

    const FriendFucntion = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '4')
    }

    const GroupFucntion = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '8')
    }

    const actionFucntion = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '7')
    }

    const everythingFucntion = (notification: any) => {
        let urlofapi = notification == false ? Global.ADD_MUTE_NOTIFICATION + token : Global.UNMUTE_NOTIFICATIONS + token
        apiFunction(urlofapi, '5')
    }

    const passwordFunc = () => {
        if (password.length < 6) {
            setpasswordvalid(true)
        } else {
            setpasswordvalid(false)
        }
    }

    const repasswordFunc = () => {
        if (repassword !== password || repassword.length < 6) {
            setretypePasswordvalid(true)
        } else {
            setretypePasswordvalid(false)
        }
    }

    const changePassword = () => {
        if (Oldpassword !== '' && password !== '') {
            let formdata = new FormData();

            formdata.append("oldPassword", Oldpassword);
            formdata.append("newPassword", password);


            axios({
                method: "post",
                url: Global.CHANGE_PASSWORD + token,
                data: formdata,
            })
                .then(response => {
                    setstatus(response.data.message)
                    setOldpassword('')
                    setpassword('')
                    setrepassword('')
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);

                })
                .catch(function (error) {
                    console.log('groupscreate', error)
                });
        } else {
            setstatus('Please provide everythig')
            setshowAlert(true)

            setpasswordvalid(true)
            setretypePasswordvalid(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        }

    }

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            {showAlert == true ? (
                <div className='d-flex justify-content-center' >
                    <Alert style={{ marginTop: '30vh', position: 'absolute', zIndex: 1, width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="primary" show={showAlert}  >
                        <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
                        <p style={{}} >{status}</p>
                    </Alert>
                </div>
            ) : null}
            <div className="padding cp-t-30" >
                <label className=" main-font  secHeading  primary-color" >Settings</label>
                <div className="  d-flex" >
                    <label className=" main-font  labelText  cp-r-30" >Notifications Settings</label>
                    <Link style={{ color: 'black' }} className="main-font labelText  cp-r-30" to="/term-of-services">Terms and Conditions</Link>
                    <Link style={{ color: 'black' }} className="main-font labelText  cp-r-30" to="/privacy-policy">Privacy Policy</Link>
                    <label className=" main-font  labelText  cp-r-30 touchAble hoverUnderLine" onClick={() => executeScroll()} >Change Password</label>
                </div>
                <div className="col-md-8 col-12" >
                    <label className=" main-font  labelHeading  primary-color" >Notifications Settings</label>
                    <div className="cp-l-20 cp-r-20" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <MdChatBubble size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Chat</label>
                                    <label className=" text-muted pText main-font" > {ChatVisible == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.chats} onChange={() => chatFunction(toggle?.chats)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <FaBirthdayCake size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Birthday</label>
                                    <label className=" text-muted pText main-font" > {toggle?.birthday == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.birthday} onChange={() => birthdayFuction(toggle?.birthday)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <RiCalendarEventFill size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Event Invitation</label>
                                    <label className=" text-muted pText main-font" > {toggle?.events == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.events} onChange={() => eventFucntion(toggle?.events)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <FaUserAlt size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Friend Requests</label>
                                    <label className=" text-muted pText main-font" > {toggle?.friend_requests == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.friend_requests} onChange={() => FriendFucntion(toggle?.friend_requests)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <HiUserGroup size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Groups</label>
                                    <label className=" text-muted pText main-font" > {toggle?.all_groups == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.all_groups} onChange={() => GroupFucntion(toggle?.all_groups)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <RiFileUserLine size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >Actions</label>
                                    <label className=" text-muted pText main-font" > {toggle?.actions == false ? 'Disabled' : 'Enabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.actions} onChange={() => actionFucntion(toggle?.actions)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="cp-l-20 cp-r-20  cp-t-15" >
                        <div className="d-flex  justify-content-between" >
                            <div className=" d-flex">
                                <div className="cp-t-2" >
                                    <BsFillBellFill size="25" color="gray" />
                                </div>
                                <div className="d-flex flex-column cp-l-10"  >
                                    <label className="label labelText main-font" >All Notifications</label>
                                    <label className=" text-muted pText main-font" > {toggle?.all_notifications == true ? 'Enabled' : 'Disabled'} </label>
                                </div>
                            </div>
                            <div>
                                <label className="switch label">
                                    <input type="checkbox" checked={toggle?.all_notifications} onChange={() => everythingFucntion(toggle?.all_notifications)} ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={myRef} className="col-md-8 col-12" >
                    <form autoComplete="off">
                        <label className=" main-font  labelHeading  primary-color cp-t-60" >Change Password</label>
                        <div className="cp-t-30">
                            <div className="d-flex justify-content-between  cp-l-20 cp-r-20  cp-t-15 border-bottom" >
                                <input className=" border-0" onBlur={() => passwordFunc()} onChange={(text: any) => setOldpassword(text.target.value)} placeholder="Old Password" type={show_inputOldPasswords ? 'text' : 'password'} value={Oldpassword} />
                                <div> </div>
                                <div className="touchAble" onClick={() => setshow_inputOldPasswords(!show_inputOldPasswords)} ><AiFillEye size="20" color="gray" /></div>
                            </div>
                            <div className="d-flex justify-content-between cp-l-20 cp-r-20  cp-t-15" >
                                <div className="text-muted f-12 main-font" >Previous Password </div>
                                <div className="text-muted f-12 main-font" >0/30</div>
                            </div>
                            {passwordvalid == true ? (<p className='label text-danger f-13 main-font cp-l-20 cp-r-20  cp-t-15' >Must be at least 6 characters</p>) : null}
                        </div>
                        <div className="cp-t-30" >
                            <div className="d-flex justify-content-between cp-l-20 cp-r-20  cp-t-15 border-bottom" >
                                <input className=" border-0" onBlur={() => passwordFunc()} onChange={(text: any) => setpassword(text.target.value)} placeholder="Password" type={show_inputnewPasswords ? 'text' : 'password'} value={password} />
                                <div> </div>
                                <div className="touchAble" onClick={() => setshow_inputnewPasswords(!show_inputnewPasswords)} ><AiFillEye size="20" color="gray" /></div>
                            </div>
                            <div className="d-flex justify-content-between cp-l-20 cp-r-20  cp-t-15" >
                                <div className="text-muted f-12 main-font" >Chose wisely </div>
                                <div className="text-muted f-12 main-font" >0/30</div>
                            </div>
                            {passwordvalid == true ? (<p className='label text-danger f-13 main-font cp-l-20 cp-r-20  cp-t-15' >Must be at least 6 characters</p>) : null}
                        </div>
                        <div className="cp-t-30" >
                            <div className="d-flex justify-content-between cp-l-20 cp-r-20  cp-t-15 border-bottom" >
                                <input className=" border-0" onBlur={() => repasswordFunc()} value={repassword} onChange={(text: any) => setrepassword(text.target.value)} placeholder="Retype Password" type={show_input ? 'text' : 'password'} />
                                <div> </div>
                                <div className="touchAble" onClick={() => setshow_input(!show_input)} ><AiFillEye size="20" color="gray" /></div>
                            </div>
                            <div className="d-flex justify-content-between cp-l-20 cp-r-20  cp-t-15" >
                                <div className="text-muted f-12 main-font" >Retype Password </div>
                                <div className="text-muted f-12 main-font" >0/30</div>
                            </div>
                            {retypePasswordvalid == true ? (<p className='label text-danger f-13 main-font cp-l-20 cp-r-20  cp-t-15' >password should be matched</p>) : null}
                        </div>
                        <div className="justify-content-center d-flex cp-t-30 cp-b-60" >
                            <div onClick={() => changePassword()} className='cm-t-20 cp-7 text-center touchAble col-12 col-md-6 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                <p className='label labelText  touchAble   ' style={{ color: 'white' }}>CHANGE PASSWORD</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='d-flex justify-content-start padding  cp-t-10 cp-b-7  flex-row align-items-center' style={{ backgroundColor: 'lightgray' }} >
                <label className=' text-uppercase f-14 font-weight-bold  label cp-l-5 cp-r-15  main-font'>Need help?</label>
                <div className=" d-flex flex-row" >
                    <div className="d-flex  justify-content-center align-self-center " >
                        <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="tel:+971588241922"><BiPhoneCall size='17' />Call us</a>
                    </div>
                    <div className="d-flex justify-content-center align-self-center" >
                        <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0"><RiWhatsappLine size='17' />WhatsApp</a>
                    </div>
                    <div className="d-flex justify-content-center align-self-center" >
                        <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"><GoMail size='17' /> Email</a>
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default SettingsScreen;
