function PopOver(props: any) {

    const MoveData = (data: any) => {
        props.action(data)
    };

    return (
        <div className=" d-flex flex-row-reverse cm-r-30" >
            <div className='  main-font label   cp-10    pointer f-13  text-color   '>
                <div className="  flex-column-reverse " style={{ width: '100%' }} >
                    {props.options.map((data: any) =>
                        <div className=' d-flex flex-column cp-l-15 cp-r-15' >
                            <label className="primary-color hoverUnderLine labelText" onClick={() =>MoveData(data)}  > {data} </label>
                        </div>
                    )}
                </div>
                
                
            </div>
        </div>
    );
}

export default PopOver;
