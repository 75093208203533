import './Storeitem.scss';
import { globleAPI as Global } from '../globals'
function StoreItemsFive(props: any) {

    return (

        <div style={{backgroundColor:'transparent '}} >
            <div key={props.data.id} className='div-box-shadow' style={{}} >
                <div className="containesssr">
                    <div style={{backgroundColor:'white',height:props.heigth||220,width:'100%',justifyContent:'center',alignItems:'center',display:'flex'}} >
                    <img src={Global.IMAGE_PATH + props.data.image} style={{maxHeight:props.heigth||220,maxWidth:'100%'}} />
                    </div>
                    <div className="topleft">{props.data.is_bundle === 1 ? (
                        <div className='' style={{ position: 'absolute' }}>
                            <label className="bg-color-primary main-font page-color pText cp-t-5 cp-b-5 cp-l-10 cp-r-10 " >Bundle</label>
                        </div>
                    ) : null}</div>
                    <div className="bottomright">{props.data.discount !== null && props.data.discount !== 0 ? (
                        <div className='' style={{ display: 'flex', flexDirection: 'row-reverse', height: '100%', position: 'relative' }}>
                            <div className='' style={{ height: '100%', flexDirection: 'column-reverse', display: 'flex' }} >
                                <label className="bg-color-primary main-font label page-color pText cp-t-8 cp-b-5 cp-l-10 cp-r-10  " > {props.data.discount}% OFF</label>
                            </div>
                        </div>
                    ) : null}</div>
                </div>

                {/* <div style={{ justifyContent: 'center', alignContent: 'center', display: 'flex', backgroundColor: 'white' }} >
                                            <div style={{ position: 'relative' }} >
                                                <img className='img-rounded' style={{ maxWidth: '100%' }} height='200' src={Global.IMAGE_PATH + props.data.image} ></img>
                                            </div>
                                            <div className='' style={{ position: 'absolute', width: '17%', height: 200 }}   >
                                                {props.data.is_bundle === 1 ? (
                                                    <div className='' style={{position:'absolute'}}>
                                                        <label className="bg-color-primary main-font page-color pText cp-t-5 cp-b-5 cp-l-10 cp-r-10 " >Bundle</label>
                                                    </div>
                                                ) : null}
                                                {props.data.discount !== null && props.data.discount !== 0 ? (
                                                    <div className='' style={{display:'flex',flexDirection:'row-reverse',height:'100%',position:'relative'}}>
                                                        <div className='cm-t-8' style={{height:'100%',flexDirection:'column-reverse',display:'flex'}} >
                                                        <label className="bg-color-primary main-font page-color pText cp-t-8 cp-b-5 cp-l-10 cp-r-10  " > {props.data.discount}% OFF</label>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>

                                        </div> */}
                <div className="cp-r-5 cp-l-5 touchAble" style={{ backgroundColor: 'white', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} >
                    <div className='labelText main-font touchAble text-center  primary-color cp-t-5 text-truncate'  >
                        {props.data?.name}{props.data?.store_name}
                    </div>
                    <div className=' text-center touchAble labelText'>
                        {props.data.manufacturer_name}
                    </div>
                    {props.data.discount === null || props.data.discount === 0 ? (
                        <div>
                            <div className='main-font touchAble text-center text-muted pText cp-b-5'>
                                AED {props.data.unit_price?.toFixed(2)}
                            </div>
                            <div className='pText main-font text-center cp-b-5 ' style={{ color: 'transparent' }}  >
                                .
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='touchAble main-font text-center pText primary-color '>
                                AED {props.data.discounted_price?.toFixed(2)}
                            </div>
                            <div className='touchAble main-font text-center pText text-muted line-through cp-b-10'>
                                AED {props.data.unit_price}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default StoreItemsFive;
