import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals'
import Orders from '../../app-components/Orders';
import OrderDetailC from '../../app-components/OrderDetailC';
import { NeedHelp } from '../../app-components/SmallComponents';
import OffersDetailPage from '../../app-components/OffersDetailPage';
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function Purchases(props: any) {

    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')
    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const [detailviewData, setdetailviewData] = useState<any>([])
    const [Satus, setSatus] = useState<any>('')
    const [offerdetail, setofferdetail] = useState<any>(false)
    const [Offer, setOffer] = useState<any>(false)
    const [INprogressOffers, setINprogressOffers] = useState<any>([])
    const [finishedOffers, setfinishedOffers] = useState<any>([])
    const [purchse, setpurchse] = useState<any>(true)
    const history = useHistory();
    const [orderid, setorderid] = useState('')
    const [msgs, setmsgs] = useState('')
    const [orderdetail, setorderdetail] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        getOffersRequestfinished()
        getOffersRequestINprogress()
        if (props.location.state?.status == 4) {
            setpurchse(false)
            setOffer(true)
            setofferdetail(false)
        }

    }, [])
    const getOffersRequestfinished = () => {
        let formdata = new FormData();
        formdata.append("status[0]", JSON.stringify(26));
        formdata.append("status[1]", JSON.stringify(8));
        axios({
            method: "post",
            url: Global.GetCustomerOffersV1 + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setfinishedOffers(response.data.offer_logs)
                console.log('responsesss', response.data.offer_logs)
            })
            .catch(function (error) {
                console.log('responsesss', error)
            });
    }

    const getOffersRequestINprogress = () => {
        let formdata = new FormData();
        formdata.append("status[0]", JSON.stringify(24));
        formdata.append("status[1]", JSON.stringify(10));
        formdata.append("status[2]", JSON.stringify(25));
        axios({
            method: "post",
            url: Global.GetCustomerOffersV1 + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setINprogressOffers(response.data.offer_logs)
                console.log('responseffff', response.data.offer_logs)
            })
            .catch(function (error) {
                console.log('response', error)

            });
    }

    const move = (data: any) => {
        if (data == 'offer') {
            setpurchse(false)
            setOffer(true)
            setofferdetail(false)
        } else {
            setpurchse(true)
            setorderdetail(false)
            setOffer(false)
        }
    }

    const sendsomeData = (data: any, data2: any) => {
        if (data == 'orderDetail') {
            setorderid(data2)
            setpurchse(false)
            setorderdetail(true)
        }
    };

    const sendingDataToParrentComponnent = (data: any, data2: any, data3: any) => {
        setOffer(false)
        setdetailviewData(data)
        setSatus(data2)
        setmsgs(data3)
        setofferdetail(true)
    }

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="page-left-padding page-right-padding bg-color-light"  >
                <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-20"   >
                    <label className="label secHeading primary-color" >My Purchases</label>
                    <div className="d-flex" >
                        <label onClick={() => history.push('/')} className=" hoverUnderLine touchAble label labelText cp-r-35 cp-t-10 cp-b-10 "  >Buy</label>
                        <label onClick={() => move('pruchase')} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Purchase History</label>
                        <label onClick={() => move('offer')} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Price Offer Request</label>
                    </div>
                    {offerdetail == true && <OffersDetailPage data={detailviewData} data2={Satus} massages={msgs} />}
                    {Offer == true && <div>
                        {INprogressOffers.length >= 1 &&
                            <div style={{ backgroundColor: '#e0bcb0', width: '45vw' }} >
                                <div className="d-flex justify-content-between cp-15" >
                                    <label className=" main-font labelHeading " >In Progress</label>
                                    <label className=" main-font labelHeading " >1</label>
                                </div>
                                {INprogressOffers.map((data: any) =>
                                    <div className="  cp-15 " >
                                        <div className="cp-15" style={{ backgroundColor: 'white' }} >
                                            <div className="d-flex" >
                                                <div className="d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                                                    <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + data.image} ></img>
                                                </div>
                                                <div className=" cp-l-15 d-flex  flex-column " >
                                                    <label className=" main-font labelText primary-color label" >{data.name}</label>
                                                    <label className=" main-font text-muted pText label" >{data.manufacturer_name}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex cp-l-15 cp-r-15" >

                                                <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>

                                                {data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by !== null &&
                                                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%' }} > <label style={{}} ><span dangerouslySetInnerHTML={{ __html: userid == data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by ? data.creator_message : data.receiver_message }} /></label>  </div>
                                                }
                                                {data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by == null &&
                                                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%' }} > <label style={{}} ><span dangerouslySetInnerHTML={{ __html: userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message }} /></label>  </div>
                                                }

                                            </div>
                                            <div onClick={() => sendingDataToParrentComponnent(data, 'inprogress', userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message)} className="d-flex  flex-row-reverse" >
                                                <label className="main-font touchAble label pText cp-t-5 cp-b-5 cp-l-20 cp-r-20 bg-color-primary" style={{ borderRadius: 100, color: 'white' }} >View</label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        <div>
                            {finishedOffers.length >= 1 &&
                                <div className="cm-t-15" style={{ backgroundColor: '#e0bcb0', width: '45vw' }} >
                                    <div className="d-flex justify-content-between cp-15" >
                                        <label className=" main-font labelHeading " >Finished</label>
                                        <label className=" main-font labelHeading " >1</label>
                                    </div>
                                    {finishedOffers.map((data: any) =>
                                        <div className="cp-15">
                                            <div className="cp-15" style={{ backgroundColor: 'white' }} >
                                                <div className="d-flex" >
                                                    <div className="d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                                                        <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + data.image} ></img>
                                                    </div>
                                                    <div className=" cp-l-15 d-flex  flex-column " >
                                                        <label className=" main-font labelText primary-color label" >{data.name}</label>
                                                        <label className=" main-font text-muted pText label" >{data.manufacturer_name}</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex cp-l-15 cp-r-15" >
                                                    <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>
                                                    <div className="d-flex cp-l-15 cp-r-15" >
                                                        <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>
                                                        <div className="d-flex cp-l-15 cp-r-15" >
                                                            <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>
                                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} > <label style={{ width: '60%', }} ><span dangerouslySetInnerHTML={{ __html: userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message }} /></label>  </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div onClick={() => sendingDataToParrentComponnent(data, 'finished', userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message)} className="d-flex flex-row-reverse" >
                                                    <label className="main-font label pText cp-t-5 cp-b-5 cp-l-20 cp-r-20 bg-color-primary" style={{ borderRadius: 100, color: 'white' }} >View</label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    }
                    {purchse == true &&
                        <div>
                            <label className='secHeading primary-color cp-t-10'  > Purchase History</label>
                            <Orders data="purchase" sendsomeData={sendsomeData} />
                        </div>
                    }
                    {orderdetail == true && <OrderDetailC data="purchases" orderId={orderid} />}
                </div>
            </div>
            <NeedHelp />
            <CFooter />
        </div>
    );
}

export default Purchases;
