import { useEffect, useState, useRef } from 'react';
import './MySales.scss'
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { NeedHelp } from '../../app-components/SmallComponents'
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import { AiOutlineCamera } from "react-icons/ai";
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals'
import Alertss from '../../app-components/Alertss';
import InputField from '../../app-components/InputField';
import cartIcon from '../../assets/1635143278432_Sell Image.png'
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import MultiselectDropDown from '../../app-components/MultiselectDropDown';
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import { useModal } from 'react-hooks-use-modal';
import 'reactjs-popup/dist/index.css';

function AddProduct(props: any) {
  
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });
  const [token] = useState(localStorage.getItem('token') || '')
  const override = css` display: block; margin: 0 auto; border-color: red;`;
  let [color] = useState("#732F3E");
  const [AllCategories, setAllCategories] = useState<any>([])
  const [AllSubCategories, setAllSubCategories] = useState<any>([])
  const [AllBrands, setAllBrands] = useState<any>([])
  const [AllSizes, setAllSizes] = useState<any>([])
  const [AllColors, setAllColors] = useState<any>([])
  const [Title, setTitle] = useState('');
  const [Colors, setColors] = useState<any>([]);
  const [Sizes, setSizes] = useState<any>([]);
  const [alertType, setalertType] = useState('success');
  const [EstimateWeight, setEstimateWeight] = useState('');
  const [EstimateWeightNo, setEstimateWeightNo] = useState('');
  const [Brand, setBrand] = useState<any>('');
  const [ProductHeight, setProductHeight] = useState('');
  const [ProductHeightNo, setProductHeightNo] = useState('');
  const [SellingProce, setSellingProce] = useState('');
  const [DiscountProce, setDiscountProce] = useState('');
  const [ProductWidth, setProductWidth] = useState('');
  const [ProductWidthNo, setProductWidthNo] = useState('');
  const [Condition, setCondition] = useState<any>('');
  const [imagesFile, setimagesFile] = useState<any>([]);
  const [ProductLength, setProductLength] = useState('');
  const [ProductLengthNo, setProductLengthNo] = useState('');
  const [Description, setDescription] = useState('');
  const [Quantity, setQuantity] = useState('');
  const [PictureURLs, setPictureURLs] = useState<any>([])
  const [Category, setCategory] = useState<any>([]);
  const [images, setimages] = useState<any>([]);
  const [SubCategory, setSubCategory] = useState<any>([]);
  const [ImagesError, setImagesError] = useState(false);
  const [DescriptionError, setDescriptionError] = useState(false);
  const [AllowTomakeOffer, setAllowTomakeOffer] = useState(false);
  const [Return, setReturn] = useState<any>(0);
  const [LuxuryOption, setLuxuryOption] = useState<any>(false);
  const [stepone, setstepone] = useState(true)
  const [BUndle, setBUndle] = useState<any>(false)
  const [stepthree, setstepthree] = useState(false)
  let [loading, setLoading] = useState(false);
  const [steptwo, setsteptwo] = useState(false)
  const [urls, seturls] = useState<any>([]);
  const [status, setstatus] = useState('')
  const [showAlert, setshowAlert] = useState(false)
  const [blurOption, setblurOption] = useState(false)
  const history = useHistory();
  const ref = useRef(null);
  const conditions = [{ value: 'New', label: 'New' }, { value: 'Used', label: 'Used' }]
  const weight = [{ value: 1, label: "kg" }, { value: 2, label: "lbs" }]
  const size = [{ value: 1, label: "inch" }, { value: 2, label: "cm" }]
  let fileArray: any[] = [...urls]

  useEffect(() => {
    window.scrollTo(0, 0)
    if (history.location.state != undefined) {
      getproductDetail()
    }
    getAllCategories(0, 1)
  }, [])

  const getproductDetail = () => {
    axios.get(Global.CLASSIFIED_AD_DETAIL + token + "&product_id=" + props.location.state[0].id)
      .then(response => {
        editFunction(response.data.product_details[0], response.data)
      })
      .catch(function (error) {
        console.log('data of product id =ffffff rtertewrtew', error)
      });
  }

  const editFunction = (data: any, data2: any) => {
    console.log('this is revoked urls',data2,data)

    setimagesFile(data2.product_pics)
    setReturn(data.is_returnable == 0 ? false : true)
    setLuxuryOption(data.is_luxury == 0 ? false : true)
    setBUndle(data.is_bundle == 0 ? false : true)
    setAllowTomakeOffer(data.allow_to_make_offer == 0 ? false : true)
    setCategory({ 'value': data.parent_category_id, 'label': data.parent_category })
    setSubCategory({ 'value': data.category_id, 'label': data.category_name })
    SubCatFunction({ 'value': data.category_id, 'label': data.category_name })
    setBrand({ 'value': data.manufacturer_name, 'label': data.manufacturer_name })
    setQuantity(data.quantity_per_unit)
    setEstimateWeightNo(data.weight)
    setProductHeightNo(data.height)
    setProductWidthNo(data.width)
    setProductLengthNo(data.length)
    setSellingProce(data.unit_price)
    setDiscountProce(data.discount)
    setDescription(data.description)
    setimages(data2.product_pics)
    setTitle(data.name)
    getSubCategory(data)
    if (LuxuryOption) {
      getProductSizes(1, data.category_id)
      GetColorsList(1)
    } else {
      getProductSizes(0, data.category_id)
      GetColorsList(0)
    }
    const sizes = data2?.product_sizes_id.map((data: any) => ({ label: data.size, value: data.id }))
    const colors = data2?.product_colors.map((data: any) => ({ label: data, value: data }))
    const condition = { label: data.product_condition, value: data.product_condition }
    setCondition(condition)
    setSizes(sizes)
    setColors(colors)

    console.log('this is revoked urls',data2.product_pics)
    const arr = [...data2.product_pics[0]];
    for (let i = 0; i < arr.length; i++) {
      console.log('this is revoked urls',URL.revokeObjectURL(data2.product_pics[0]))
      // fileArray.push(URL.revokeObjectURL(arr[i]))
    }

 
  }

  const getAllCategories = (data: any, data2: any) => {
    axios.get(Global.getAllCategoriesAddProduct + token + "&luxury=" + data + "&regular=" + data2)
      .then(response => {
        response.data.categories.forEach((item: any) => (item.value = item.id, item.label = item.name));
        setAllCategories(response.data.categories)
      })
      .catch(function (error) {
        console.log('respons =', error)
      });
  }

  const getSubCategory = (data: any) => {
    const value: any = data.value !== undefined ? data.value : data.parent_category_id
    axios.get(Global.getAllSubCategoriesAddProduct + token + "&parent_category=" + value)
      .then(response => {
        response.data.subcategories.forEach((item: any) => (item.value = item.id, item.label = item.name));
        setAllSubCategories(response.data.subcategories)
        if (LuxuryOption == true) {
          getBrandsFilter(1, 0)
        } else {
          getBrandsFilter(0, 1)
        }
      })
      .catch(function (error) {
        console.log('respons =', error)
      });
  }

  const getBrandsFilter = (data: any, data2: any) => {
    axios.get(Global.getBrandsFilterAddProduct + token + "&luxury=" + data + "&regular=" + data2)
      .then(response => {
        response.data.brands.forEach((item: any) => (item.value = item.id, item.label = item.name));
        setAllBrands(response.data.brands)
      })
      .catch(function (error) {
        console.log('respons =', error)
      });
  }

  const getProductSizes = (data: any, data2: any) => {
    const category_id: any = data2.value !== undefined ? data2.value : data2
    axios.get(Global.GetProductSizes + token + "&category_id=" + category_id + "&is_luxury=" + data)
      .then(response => {
        response.data.product_sizes.forEach((item: any) => (item.value = item.id, item.label = item.name));
        setAllSizes(response.data.product_sizes)
      })
      .catch(function (error) {
        console.log('resccccccpofosd =', error)
      });
  }

  const GetColorsList = (data: any) => {
    axios.get(Global.GetColorsListAddProduct + token + "&is_luxury=" + data)
      .then(response => {
        response.data.colors.forEach((item: any) => (item.value = item.id, item.label = item.name));
        setAllColors(response.data.colors)
      })
      .catch(function (error) {
        console.log('resccccccpofosd =', error)
      });
  }

  const firstForm = () => {
    window.scrollTo(0, 0)
    if (stepone) {
      if (Category.length <= 0 || SubCategory.length <= 0) {
        setblurOption(true)
        setTimeout(() => {
          setblurOption(false)
        }, 1);
      } else {
        setstepone(false)
        setsteptwo(true)
      }
    } else if (steptwo) {
      // if ((urls.length < 1 && images.length < 1)) {
      //   setImagesError(true)
      // }
      // (urls.length < 1 && images.length < 1) ||
      if ( Title == '' || Colors.length < 1 || Quantity == '' || Sizes.length < 1) {
        window.scrollTo(0, 0)
        // setImagesError(true)
        setblurOption(true)
        setTimeout(() => {
          setblurOption(false)
        }, 1);
      } else {
        setstepthree(true)
        setsteptwo(false)
      }
    } else if (stepthree) {
      if (Description == '' || Condition.length < 1 || SellingProce == '') {
        window.scrollTo(0, 0)
        setDescriptionError(true)
        setblurOption(true)
        setTimeout(() => {
          setblurOption(false)
        }, 1);
      } else {
        setDescriptionError(true)
        setLoading(true)
        AddProduct()
      }
    }
    else {
      setstepthree(true)
      setsteptwo(false)
      setstepone(false)
    }
  };

  const AddProduct = () => {

    console.log("name", imagesFile)
    console.log("category", SubCategory.label)
    console.log("category_id", SubCategory.value)
    console.log("parent_category", Category.label)
    console.log("parent_category_id", Category.value)
    console.log("unit_price", SellingProce)
    console.log("quantity_per_unit", Quantity)
    console.log("description", Description)
    console.log("weight", ProductHeightNo)
    console.log("height", ProductHeightNo)
    console.log("width", ProductWidthNo)
    console.log("length", ProductLengthNo)
    console.log("manufacturer_name", Brand.label)
    console.log("product_condition", Condition.value)
    console.log("isLuxury", LuxuryOption)
    console.log("is_bundle", BUndle)
    console.log("allow_to_make_offer", AllowTomakeOffer == true ? '1' : '0')
    console.log("is_returnable", Return)
    console.log("discount", DiscountProce)
    console.log("discount", Colors)
    console.log("discount", Sizes)
    const state: any = history.location.state
    const url = (state !== undefined ? Global.EditProduct + token + "&product_id=" + props.location.state[0].id : Global.POST_CLASSIFIED_AD + token)
    let formdata = new FormData();
    Colors.map(function (SelectedColor: any, index: any) {
      formdata.append("colors[" + index + "]", SelectedColor.value);
    });
    Sizes.map(function (SelectedSize: any, index: any) {
      formdata.append("sizes[" + index + "]", SelectedSize.value);
    });
    formdata.append("name", Title);
    formdata.append("category_id", SubCategory.value);
    formdata.append("parent_category_id", Category.value);
    formdata.append("unit_price", SellingProce);
    formdata.append("quantity_per_unit", Quantity);
    formdata.append("description", Description);
    formdata.append("weight", ProductHeightNo);
    formdata.append("height", ProductHeightNo);
    formdata.append("width", ProductWidthNo);
    formdata.append("length", ProductLengthNo);
    formdata.append("manufacturer_name", Brand.label);
    formdata.append("product_condition", Condition.value);
    formdata.append("category", SubCategory.label);
    formdata.append("parent_category", Category.label);
    formdata.append("isLuxury", LuxuryOption);
    formdata.append("is_bundle", BUndle);
    formdata.append("allow_to_make_offer", AllowTomakeOffer == true ? '1' : '0');
    formdata.append("is_returnable", Return);
    formdata.append("discount", DiscountProce)
    imagesFile.forEach((image: any, index: any) => {
      formdata.append("image[" + index + "]", image)
    })






    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(response => {
        setLoading(false)
        setshowAlert(true)
        setTimeout(() => {
          history.goBack()
        }, 3000);
        setstatus(response.data.message)
      })
      .catch(function (error) {
        console.log('response', error)
        // setalertType('danger')
        // setLoading(false)
        // setshowAlert(true)
        // setstatus(error)
      });
  };

  const ReturnPosible = (data: any) => {
    if (data == 'yes') {
      setReturn(1)
    } else {
      setReturn(0)
    }
  };

  const Bundle = (data: any) => {
    if (data == 'yes') {
      setBUndle(true)
    } else {
      setBUndle(false)
    }
  };

  const Luxury = (data: any) => {
    setAllCategories('')
    setAllSubCategories('')
    setAllBrands('')
    setCategory('')
    setSubCategory('')
    setBrand('')
    setSizes('')
    setAllSizes('')
    setColors('')
    setAllColors('')
    if (data == 'L') {
      setLuxuryOption(true)
      getAllCategories(1, 0)
    } else {
      setLuxuryOption(false)
      getAllCategories(0, 1)
    }
  };

  const removeFromBackendList = (data: any) => {
    let Picture = images.filter((finding: any) => finding.uri == data.uri ? console.log('yes it has ', finding.uri) : console.log('dont', finding.uri))
    if (Picture) {
      let changingInUrls = images.filter((finding: any) => finding.uri !== data.uri)
      if (changingInUrls) {
        setimages(changingInUrls)
      }
    }
  }

  const removeFromList = (data: any) => {
    let Picture = PictureURLs.filter((finding: any) => finding.name == data ? console.log('yes it has ', finding.name) : console.log('dont', finding.name))
    if (Picture) {
      remove(data)
    }
  }

  const remove = (data: any) => {
    let changingInUrls = urls.filter((finding: any) => finding !== data)
    if (changingInUrls) {
      seturls(changingInUrls)
    }
  }

  const onImageChange = (event: any) => {
    setImagesError(false)
    const arr = [...event.target.files];
    setimagesFile(arr)
    for (let i = 0; i < arr.length; i++) {
      fileArray.push(URL.createObjectURL(arr[i]))
    }
    const image = event.target.files;
    seturls(fileArray)
  }

  // const onImageChange = (event: any) => {
  //   setImagesError(false)
  //   const arr = [...event.target.files];
  //   setimagesFile(arr)
  //   for (let i = 0; i < arr.length; i++) {

  //     new Compressor(arr[i], {      
  //       quality: 0.3,
  //       success: (compressedResult) => {
  //         console.log('compressed images before',arr[0])
  //         console.log('compressed images after',compressedResult)
  //         urls.push(URL.createObjectURL(compressedResult))
  //         console.log('compressed images urls',URL.createObjectURL(compressedResult) )
  //         console.log('compressed images urls',URL.createObjectURL(arr[0]) )
  //         // compressedResult has the compressed file.
  //         // Use the compressed file to upload the images to your server.
  //         // setCompressedFile(res)
  //       },
  //     });



  //   }
  //   // const image = event.target.files;

  //   // seturls(fileArray)

  // }

  const CateFunction = (data: any) => {
    if (data !== '') {
      setCategory(data)
      setSubCategory('')
      getSubCategory(data)
    }
  }

  const SubCatFunction = (data: any) => {
    if (data !== '') {
      setSubCategory(data)
    }
    if (LuxuryOption) {
      getProductSizes(1, data)
      GetColorsList(1)
    } else {
      getProductSizes(0, data)
      GetColorsList(0)
    }
  }

  const EditDetails = (data: any) => {
    if (data == 1) {
      setstepone(true)
      setsteptwo(false)
      setstepthree(false)
    } else if (data == 2) {
      if (stepthree) {
        setstepthree(false)
        setsteptwo(true)
      }
    } else if (data == 3) {
      if (stepthree) {
        setstepthree(true)
      }
      
    }
    
    
    
  }
  
  return (
    <div>
      {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}

  <div>
      <Modal>
        <div style={{backgroundColor:'white',padding:20,borderRadius:5}} >
        <div style={{display:'flex',justifyContent:'space-between'}} >

          <label onClick={open} className="primary-color label main-font secHeading cp-b-21" >Photo Tips</label>
          <div style={{display:'flex',flexDirection:'row-reverse'}} >
          <label onClick={close} style={{color:'black',fontWeight:'bold'}} >X </label>
          </div>
          </div>
    <label className='main-font label' style={{fontSize:15,fontWeight:600}} >Take at least 3-4 photos</label>
          <p style={{color:'gray'}} > Good photos help you sell.</p>
          <label className='main-font label' style={{fontSize:15,fontWeight:600}} > Use good lighting</label>
          <p style={{color:'gray'}} > Look for areas with natural light, avoid dark and artificially lit rooms. </p>
          <label className='main-font label' style={{fontSize:15,fontWeight:600}} > Use a simple background</label>
          <p style={{color:'gray'}} >Try hanging the item on a clean wall or use a clean floor or tabletop. </p>
          <label className='main-font label' style={{fontSize:15,fontWeight:600}} > Get a picture wearing them</label>
          <p style={{color:'gray'}} >Do try get someone to take a picture of you wearing the item. </p>
          <label className='main-font label' style={{fontSize:15,fontWeight:600}} >Show multiple angles</label>
          <p style={{color:'gray'}} >Add photos of the front, sides, top, and bottom views. </p>
          <label className='main-font label' style={{fontSize:15,fontWeight:600}} >Show detail</label>
          <p style={{color:'gray'}} >Add photos of the label, material quality, and show any defects with the product. </p>
        </div>
      </Modal>
    </div>
      <div className="d-flex  flex-column   " style={{ backgroundColor: '#f5f5f5' }} >
        <div className="d-flex  bg-dark justify-content-center  " style={{ zIndex: 1 }} >
          {showAlert == true && <Alertss type={alertType} status={status} />}
        </div>
        <div className="cm-l-40 cm-r-40 cm-t-20  d-flex justify-content-center " >
          <div className="d-flex flex-column justify-content-center col-12 col-md-6  " >
            {stepone == false &&
              <div className=" d-flex justify-content-center " >
                <div onClick={() => EditDetails(1)} className='cm-t-20 cp-7 text-center touchAble col-4 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'white', }} >
                  <p className='label labelText font-weight-bold touchAble  ' style={{ color: '#732F3E' }}>Category</p>
                </div>
                <div onClick={() => EditDetails(2)} className='cm-t-20 cp-7 text-center touchAble col-4 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: steptwo == true ? '#732F3E' : 'white', }}  >
                  <p className='label labelText font-weight-bold touchAble  ' style={{ color: steptwo == true ? 'white' : '#732F3E' }}>Information</p>
                </div>
                <div onClick={() => EditDetails(3)} className='cm-t-20 cp-7 text-center touchAble col-4 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: stepthree == true ? '#732F3E' : 'white', }}  >
                  <p className='label labelText font-weight-bold touchAble  ' style={{ color: stepthree == true ? 'white' : '#732F3E' }}>Description & price</p>
                </div>
              </div>
            }
            {stepone &&
              <div>
                <div className="d-flex justify-content-center cm-t-25 cm-b-25" >
                  <img src={cartIcon} style={{ height: '26vh', width: 'auto' }} ></img>
                </div>
                <div className="" >
                  <p className='label mainHeading  touchAble text-center  ' style={{}}>Sell An Item</p>
                  <p className='label pText  touchAble text-muted text-center  ' style={{}}>Sell your item with us! Eithter you,re a retial or selling a preloved/brand new luxury items.</p>
                  <p className='label pText  touchAble text-muted text-center  ' style={{}}>List your item in less than 60 seconds.</p>
                  <p className='label pText  touchAble text-muted text-center c-border-bottom cp-b-20  ' style={{ borderColor: 'lightgray' }}>Read <label className="label pText  primary-color touchAble text-center "  >FAQs</label> to know more about buying and selling.</p>
                  <div className=" d-flex  justify-content-around " >
                    <div onClick={() => Luxury('L')} className='cm-t-20 cp-7 text-center touchAble col-4 align-self-center align-item-center align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: LuxuryOption == true ? '#732F3E' : 'white' }} >
                      {LuxuryOption == true ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label labelText font-weight-bold touchAble cp-l-8  ' style={{ color: LuxuryOption == true ? 'white' : '#732F3E' }}>Luxury Item</p>
                    </div>
                    <div onClick={() => Luxury('NotLuxury')} className='cm-t-20 cp-7 text-center touchAble  align-self-center align-item-center align-content-center d-flex  align-self-center col-4 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: LuxuryOption == false ? '#732F3E' : 'white', }}  >
                      {LuxuryOption == false ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label labelText font-weight-bold touchAble cp-l-8  ' style={{ color: LuxuryOption == false ? 'white' : '#732F3E', }}>Not Luxury</p>
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%' }} >
                  <MultiselectDropDown value={Category} multiselect='false' DropDownData={AllCategories} placeholder="Category" securityRequired='true' blur={blurOption} methode={(data: any) => CateFunction(data)} />
                  <MultiselectDropDown value={SubCategory} multiselect='false' DropDownData={AllSubCategories} placeholder="Sub Category" securityRequired='true' blur={blurOption} methode={(data: any) => SubCatFunction(data)} />
                  <MultiselectDropDown value={Brand} multiselect='false' DropDownData={AllBrands} placeholder="Brand Name" methode={(data: any) => setBrand(data)} />
                  <div className="d-flex justify-content-center cm-b-60" >
                    <div onClick={() => firstForm()} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                      <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Continue</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {steptwo &&
              <div>
                <div className="d-flex cm-t-20 flex-row-reverse" >
                  <label onClick={open} className="primary-color label main-font pText" >Photo Tips</label>
                </div>
                <div className=" d-flex " >
                  <div onChange={(text: any) => onImageChange(text)} className='d-flex align-self-center   cm-l-20 cm-r-20 align-content-center align-item-center justify-content-center col-3' style={{}}>
                    <span className="btn  flex-column d-flex justify-content-center align-self-center align-content-center btn-file" style={{ height: '100px', width: '100px', borderRadius: 5, backgroundColor: 'white' }} >
                      <div>
                        <AiOutlineCamera size="2.6rem " className="" style={{ height: '40px', width: '40px' }} color="#732F3E" />  <input type="file" accept="image/*" multiple   ></input>
                        <label className=" label main-font pText f-600" >Add Photo</label>
                      </div>
                    </span>
                  </div>
                  <div className="d-flex  overflow-auto" >
                    {urls?.map((data: any) =>
                      <div className=" bg-light cm-10 position-relative" >
                        <div className="d-flex flex-row-reverse overflow-auto" >
                          <label className="   cp-r-10 touchAble label position-absolute  " onClick={() => removeFromList(data)} style={{ zIndex: 1 }}  >X </label>
                        </div>
                        <img style={{ height: '100px', width: '100px' }} src={data}  ></img>
                      </div>
                    )}
                    {images?.map((data: any) =>
                      <div className=" bg-light cm-10 position-relative" >
                        <div className="d-flex flex-row-reverse overflow-auto" >
                          <label className="   cp-r-10 touchAble label position-absolute  " onClick={() => removeFromBackendList(data)} style={{ zIndex: 1 }}  >X </label>
                        </div>
                        <img style={{ height: '100px', width: '100px' }} src={data.uri}  ></img>
                      </div>
                    )}
                  </div>
                </div>
                {ImagesError && <label className="cm-20 label text-danger pText main-font" >Please provide pictures greater then 1 or less then 8</label>}
                <div className="cm-l-20 cm-r-20 " >
                  <div className="d-flex cm-t-10  bg-color-primary justify-content-center text-center align-self-center align-content-center" >
                    <p className=" cp-5  label" style={{ color: 'white' }} >You can upload upto 8  pictures</p>
                  </div>
                  <InputField type="text" value={Title} name="text" placeholder="title" security='true' blur={blurOption} methode={(data: any) => setTitle(data)} />
                  <MultiselectDropDown value={Colors} multiselect='true' placeholder="Colors" DropDownData={AllColors} securityRequired='true' blur={blurOption} methode={(data: any) => setColors(data)} />
                  <MultiselectDropDown value={Sizes} multiselect='true' placeholder="Sizes" DropDownData={AllSizes} securityRequired='true' blur={blurOption} methode={(data: any) => setSizes(data)} />
                  <InputField type="text" onlyNumber="true" value={Quantity} name="text" placeholder="Quantity" security='true' blur={blurOption} methode={(data: any) => setQuantity(data)} />
                  <div className=" d-flex  justify-content-around " >
                    <div onClick={() => ReturnPosible('yes')} className='cm-t-30 cp-7 text-center touchAble col-5 align-self-center align-item-center align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: Return == 1 ? '#732F3E' : 'white' }} >
                      {Return == 1 ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label pText font-weight-bold touchAble cp-l-2  ' style={{ color: Return == 1 ? 'white' : '#732F3E' }}>Eligible For Return</p>
                    </div>
                    <div onClick={() => ReturnPosible('no')} className='cm-t-20 cp-7 text-center touchAble  align-self-center align-item-center align-content-center d-flex  align-self-center col-5 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: Return == 0 ? '#732F3E' : 'white', }}  >
                      {Return == 0 ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label pText font-weight-bold touchAble cp-l-2  ' style={{ color: Return == 0 ? 'white' : '#732F3E', }}>Not Eligible For Return</p>
                    </div>
                  </div>
                  <div className=" d-flex  justify-content-around " >
                    <div onClick={() => Bundle('yes')} className='cm-t-20 cp-7 text-center touchAble col-5 align-self-center align-item-center align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: BUndle == true ? '#732F3E' : 'white' }} >
                      {BUndle == true ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label pText font-weight-bold touchAble cp-l-2  ' style={{ color: BUndle == true ? 'white' : '#732F3E' }}>Bundle</p>
                    </div>
                    <div onClick={() => Bundle('no')} className='cm-t-20 cp-7 text-center touchAble  align-self-center align-item-center align-content-center d-flex  align-self-center col-5 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: BUndle == false ? '#732F3E' : 'white', }}  >
                      {BUndle == false ? (
                        <CgRadioChecked size="20" color="white" />
                      ) : (
                        <CgRadioCheck size="20" color="#732F3E" />
                      )}
                      <p className='label pText font-weight-bold touchAble cp-l-2  ' style={{ color: BUndle == false ? 'white' : '#732F3E', }}>Not Bundle</p>
                    </div>
                  </div>
                  <div className='cp-t-50 ' >
                    <div className="" ></div>
                    <div className="d-flex col-12 center align-items-center " >
                      <input onClick={() => setAllowTomakeOffer(!AllowTomakeOffer)} checked={AllowTomakeOffer} type="checkbox" className='largerCheckbox touchAble primary-color' id="new" name="new" value="new" />
                      <label className="form-check-label f-14  cp-l-15 label main-font" style={{ color: 'gray' }} >Check if you want to allow buyer "To Make An Offer"</label>
                    </div>
                    <div className="d-flex  justify-content-center cm-t-50 cm-b-60 " >
                      <div onClick={() => firstForm()} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                        <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Continue</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {stepthree &&
              <div>
                <div className=" d-flex position-absolute justify-content-center" style={{ width: '100%' }} > <ScaleLoader color={color} loading={loading} css={override} height={40} width={6} /></div>
                <p className='label primary-color text-capitalize labelText cp-t-20 cp-b-10 f-600 main-font' >Product Description</p>
                <textarea name="textarea" value={Description} onChange={(text: any) => setDescription(text.target.value)} className="text-muted" style={{ width: '100%', height: '120px', borderColor: 'lightgray' }}></textarea>
                {DescriptionError && <p className='label text-danger f-13 main-font' >Required</p>}
                <p className='label primary-color text-capitalize labelText cp-t-20 cp-b-10 f-600 main-font' >Measurements:</p>
                <div className="d-flex justify-content-around" >
                  <div className='col-6 ' >
                    <InputField type="text" onlyNumber="true" value={EstimateWeightNo} name="text" hints="Estimated Weight" methode={(data: any) => setEstimateWeightNo(data)} />
                  </div>
                  <div className="col-6  cp-b-10"   >
                    <MultiselectDropDown DropDownData={weight} multiselect='false' placeHolder="kg" methode={(data: any) => setEstimateWeight(data)} />
                  </div>
                </div>
                <div className="d-flex justify-content-around" >
                  <div className='col-6 ' >
                    <InputField type="text" onlyNumber="true" value={ProductHeightNo} name="text" hints="Product Height" methode={(data: any) => setProductHeightNo(data)} />
                  </div>
                  <div className="col-6  cp-b-10"   >
                    <MultiselectDropDown DropDownData={size} multiselect='false' placeHolder="inch" methode={(data: any) => setProductHeight(data)} />
                  </div>
                </div>
                <div className="d-flex justify-content-around" >
                  <div className='col-6 ' >
                    <InputField type="text" onlyNumber="true" value={ProductWidthNo} name="text" hints="Product Width" methode={(data: any) => setProductWidthNo(data)} />
                  </div>
                  <div className="col-6  cp-b-10"   >
                    <MultiselectDropDown DropDownData={size} multiselect='false' placeHolder="inch" methode={(data: any) => setProductWidth(data)} />
                  </div>
                </div>
                <div className="d-flex justify-content-around" >
                  <div className='col-6 ' >
                    <InputField type="text" onlyNumber="true" value={ProductLengthNo} name="text" hints="Product Length" methode={(data: any) => setProductLengthNo(data)} />
                  </div>
                  <div className="col-6  cp-b-10"   >
                    <MultiselectDropDown DropDownData={size} multiselect='false' placeHolder="inch" methode={(data: any) => setProductLength(data)} />
                  </div>
                </div>
                <InputField type="text" onlyNumber="true" value={SellingProce} name="text" placeholder="Selling Price(AED)" security='true' blur={blurOption} methode={(data: any) => setSellingProce(data)} />
                <InputField type="text" maxlengt="2" onlyNumber="true" value={DiscountProce} name="text" placeholder="% Discounted Price(optional)" methode={(data: any) => setDiscountProce(data)} />
                <MultiselectDropDown value={Condition} multiselect='false' securityRequired='true' blur={blurOption} DropDownData={conditions} placeholder="Condition" methode={(data: any) => setCondition(data)} />
                <div className="d-flex  justify-content-center cm-t-50 cm-b-60 " >
                  <div onClick={() => firstForm()} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                    <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Add Product</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <NeedHelp />
      <CFooter />
    </div>
  );
}
export default AddProduct;