import { useEffect, useState } from 'react';
import axios from "axios";
import { globleAPI as Global } from '../globals'
import Alert from 'react-bootstrap/Alert'

function Redeem(props: any) {
    const [RedemValue, setRedemValue] = useState<any>('')
    const [Ballance, setBallance] = useState<any>('')
    const [status, setstatus] = useState('')
    const [showAlert, setshowAlert] = useState(false)
    const [token] = useState(localStorage.getItem('token') || '')

    useEffect(() => {
        balanceApi()
    }, [])

    const balanceApi = () => {
        window.scrollTo(0, 0)
        axios
            .get(Global.GET_WALLET_AMOUNT + token)
            .then(response => {
                setBallance(response.data.balance)
            })
            .catch(function (error) {
                console.log('response if the api ifdsfdfs', error)
            });
    }

    const redeemrequest = () => {
        if (RedemValue == '' || RedemValue == undefined) {
            setstatus('Please enter a digit numbers')
            setshowAlert(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        }
        else if (RedemValue > Ballance) {
            setstatus('The entered amount is greater than your ballance')
            setshowAlert(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        }
        else {
            let formdata = new FormData();
            formdata.append("amount", RedemValue);
            axios({
                method: "post",
                url: Global.SEND_REDEEM + token,
                data: formdata,
            })
                .then(response => {
                    setstatus(response.data.message)
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                    balanceApi()
                })
                .catch(function (error) {
                    console.log('groupscreate', error)
                });
        }

    }

    return (
        <div>
            {showAlert == true ? (
                <div className='d-flex justify-content-center' >
                    <Alert style={{ marginTop: '1%', position: 'absolute', width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="danger" show={showAlert}  >
                        <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
                        <p>{status}</p>
                    </Alert>
                </div>
            ) : null}
            <div className=" cm-t-10 cp-30" style={{ backgroundColor: 'white', width: '45vw' }} >
                <div className="d-flex justify-content-center align-item-center" style={{ backgroundColor: 'rgb(218, 95, 115)', height: '30vw', borderRadius: 10 }} >
                    <div className="d-flex flex-column" >
                        <label className="secHeading label main-font " style={{ color: 'white' }}>Current Balance is</label>
                        <label className="labelText label main-font text-center " style={{ color: 'white' }} >AED</label>
                        <label className=" mainHeading label main-font text-center " style={{ color: 'white' }} >{Ballance}</label>
                    </div>
                </div>
                <div className="cp-20" >
                    <input type="text" className="form-control" placeholder="Enter the amount you want to redeem." onChange={(text: any) => setRedemValue(text.target.value)} />
                </div>
                <label className="labelText  text-muted label main-font text-center " >Note: Amount will be transferred into your account after 10 days once redeemed.</label>
                <div onClick={() => redeemrequest()} className='cm-t-20 cm-b-20 cp-7  text-center touchAble  align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                    <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>REEDEM</p>
                </div>
            </div>
        </div>
    );
}

export default Redeem;

