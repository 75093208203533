import '../screens/HomeScreens/StoreDetail.scss';
import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import ShimmerC from '../app-components/ShimmerC';
import Productitem from '../app-components/Productitem';
import Alert from 'react-bootstrap/Alert'
import storeBlack from '../assets/storeBlack.png'
import girl from '../assets/emojiwindle.png';
import { globleAPI as Global } from '../globals'

function StoreDetails(props: any) {

    const [storesData] = useState<any>([])
    const [store_details, setstore_details] = useState<any>([])
    const [showAlert, setshowAlert] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [noProductStatus, setnoProductStatus] = useState(false)
    const [FollowStore, setFollowStore] = useState<any>()
    const [status, setstatus] = useState('')
    const [total_products, settotal_products] = useState('')
    const [storeOtherData, setstoreOtherData] = useState<any>([])
    const [store_followers, setstore_followers] = useState<any>([])
    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        if (props.userid !== undefined) {
            getStoreDetail(1)
        } else {
            getMyStoreDetail(1)
        }
    }, [])

    const getMyStoreDetail = (data: any) => {
        axios
            .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + data)
            .then(response => {
                if (response.data.AllProducts.total == 0) {
                    setLoading(false)
                    setnoProductStatus(true)
                } else {
                    setLoading(false)
                }
                setFollowStore(response.data.is_followed)
                let arrayData = response.data.AllProducts.data
                storesData.push(...arrayData)
                settotal_products(response.data.total_products)
                setstore_details(response.data.store_details[0])
                setstoreOtherData(response.data.AllProducts)
                setstore_followers(response.data.store_followers.length)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getStoreDetail = (data: any) => {
        axios
            .get(token == '' ? Global.WebGetStoreDetailsV2 + "?user_id=" + props.userid + "&page=" + data : Global.WebGetStoreDetailsV3 + token + "&user_id=" + props.userid + "&page=" + data)
            .then(response => {
                console.log('follow stauts', response.data)
                if (response.data.AllProducts.total == 0) {
                    setLoading(false)
                    setnoProductStatus(true)
                } else {
                    setLoading(false)
                }
                setFollowStore(response.data.is_followed)
                let arrayData = response.data.AllProducts.data
                storesData.push(...arrayData)
                settotal_products(response.data.total_products)
                setstore_details(response.data.store_details[0])
                setstoreOtherData(response.data.AllProducts)
                setstore_followers(response.data.store_followers.length)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const followStore = (data: any, id: any) => {
        if (token == "") {
            setshowAlert(true)
            setstatus('Please login first to follow the store')
            setTimeout(() => {
                setshowAlert(false)
                setstatus('')
            }, 3000);
        } else {
            let formdata = new FormData();
            formdata.append("id", id);
            axios({
                method: "post",
                url: Global.FOLLOW_STORE + token,
                data: formdata,
            })
                .then(response => {
                    window.location.reload()
                })
                .catch(function (error) {
                    console.log('groupscreate', error)
                });
        }
    }

    const movetoStoreEdit = () => {
        history.push('edit_store')
    }

    return (
        <div>
            {showAlert == true ? (
                <div className='d-flex justify-content-center' >
                    <Alert style={{ marginTop: '30vh', position: 'absolute', zIndex: 1, width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="primary" show={showAlert}  >
                        <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
                        <p style={{}} >{status}</p>
                    </Alert>
                </div>
            ) : null}
            <div >
                <div>
                    <div style={{ backgroundColor: 'white' }} >
                        <div className="cp-20 d-flex flex-row" >
                            <div className="store-img-container col-md-3 " style={{ backgroundColor: 'white' }} >
                                {store_details?.store_pic !== null ? (
                                    <img className="store-image" alt='img' src={Global.IMAGE_PATH + store_details?.store_pic} ></img>
                                ) : (
                                    <img className="store-image" alt='img' src={storeBlack} ></img>
                                )}
                            </div>
                            <div className="d-flex justify-content-between col-md-9">
                                <div>
                                    <label className="f-22 cp-t-15 cp-b-15 font-weight-bold main-font primary-color" >{store_details?.store_name}</label>
                                    <br />
                                    {userid != store_details.id &&
                                        <label onClick={() => followStore(storeOtherData.current_page, store_details.id)} className=" labelHeading cp-t-10 cp-b-10 cp-l-20 touchAble cp-r-20 font-weight-bold main-font  bg-color-primary" style={{ color: 'white', borderRadius: 5 }} > {FollowStore == true ? 'Unfollow ' : 'Follow'}</label>
                                    }
                                </div>
                                <div className="d-flex flex-column  align-self-center" >
                                    <label className=" labelHeading  font-weight-bold main-font " >{total_products} Products</label>
                                    {userid == store_details.id &&
                                        <label onClick={() => movetoStoreEdit()} className="labelHeading  touchAble main-font primary-color" style={{ color: 'white', borderRadius: 5 }} >Edit Store</label>
                                    }

                                    <label className="labelHeading main-font primary-color" style={{ color: 'white', borderRadius: 5 }} >{store_followers} Followers</label>
                                </div>
                            </div>
                        </div>
                        <div className="border-top border-bottom" >
                            <label className="f-18 font-weight-bold main-font label cp-20 cp-l-50 " style={{ borderRadius: 5 }} >{store_details.store_description}</label>
                        </div>
                        {storesData.length >= 1 &&
                            <div className='row cp-26 '>
                                {storesData.map((stores: any) => (
                                    <div className='col-md-3 col-6  '>
                                        <Productitem DataofProduct={stores} edit={props?.edit} />
                                    </div>
                                ))
                                }
                            </div>
                        }
                        {Loading == true &&
                            <div className='' style={{ height: '100vh' }}>
                                <ShimmerC allproducts='yes' />
                                <ShimmerC allproducts='yes' />
                            </div>
                        }
                        {noProductStatus == true &&
                            <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
                                <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                                <label className="text-muted text-center" style={{ width: '30%' }} >No Product found</label>
                            </div>
                        }
                        {storeOtherData.last_page > storeOtherData.current_page &&
                            <div onClick={() => getStoreDetail(storeOtherData.current_page + 1)} className="d-flex justify-content-center" >
                                <label className="f-17 cp-b-60 font-weight-bold main-font primary-color hoverUnderLine" >See more</label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StoreDetails;
