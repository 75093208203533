import React,{useState} from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CFooter from '../app-components/CFooter';
import { NeedHelp } from '../app-components/SmallComponents';
import HeaderforRetail from '../app-components/HeaderforRetail';
import HeaderForLuxury from '../app-components/HeaderForLuxury';

function ReturnPolicy() {
  
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  window.scrollTo(0, 0) 

  return (
    <div>
            {Selected == 'Luxury'?  <HeaderForLuxury   />:<HeaderforRetail  />}
      <div className="padding" >
        <div className="cm-t-30 cm-b-30" >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">Return Policy</Typography>
          </Breadcrumbs>
        </div>
        <div className="d-flex flex-column" >
          <label className="secHeading main-font  primary-color " >Return Policy</label>
          <div className=" d-flex flex-column" >
            <label className="cp-t-15 cp-b-15 label labelHeading font-weight-bold primary-color" >How to make a return?</label>
            <label className="   labelText" >A return request can typically take our team 2-5 business days to process. Please note that the length of time to review a case can vary depending on the proof we have on hand, as well as the response time it takes for a buyer or a seller to get back to us. </label>
            <label className="  cp-t-10  labelText" >Kindly send us an email at <label className="primary-color label" >support@lesfemmes.net</label> for the reason of the return and attached a photo if required depending on the reason if it’s damaged item, wrong item received, missing item, or description not match on the order received. </label>
            <label className=" cp-t-10   labelText" >When we have proof that the item was misrepresented, we will send the buyer a return label and have them ship the item back to the seller. Once the order is delivered back to the seller, we will begin to process the buyer’s refund, which is the full amount they paid; this includes shipping that was charged for the purchase. Please note questions on the item can be asked on the app before purchase </label>
          </div>
          <div className=" d-flex flex-column cm-50 cp-50" >
            <label className="cp-t-15 cp-b-15 label secHeading text-center" >Further questions?</label>
            <Link className="main-font primary-color text-center labelText" to="/faqs">Click here to read the Frequently asked questions </Link>
            <div className='cm-t-20 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
              <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Start Selling</p>
            </div>
          </div>
        </div>
      </div>
      <NeedHelp />
      <CFooter />
    </div>
  );
}
export default ReturnPolicy
