import './Storeitem.scss';
import {globleAPI as Global} from '../globals'
function Storeitem(props: any) {

  return (
    <div>
      {props.FeaturedStores === 'yes' ? (
        <div>
          <div className='touchAble cm-t-1rem cm-b-1rem border-radius div-box-shadow' style={{borderRadius:13}}>
            <div className='product-img-container'>
              <img className='product-image' src={Global.IMAGE_PATH+props.DataofProduct.image} ></img>
            </div>
            {props.DataofProduct.discount !== null && props.DataofProduct.discount !== 0 ? (
              <div className='off-contianer cm-r-4 cm-t-17'>
                <label className="bg-color-primary main-font page-color pText cp-t-8 cp-b-5 cp-l-10 cp-r-10 " > {props.DataofProduct.discount}% OFF</label>
              </div>
            ) : null}
            {props.DataofProduct.is_bundle === 1 ? (
              <div className='bundle-contianer cm-l-4 cm-t-16'>
                <label className="bg-color-primary main-font page-color pText cp-t-5 cp-b-5 cp-l-10 cp-r-10 " >Bundle</label>
              </div>
            ) : null}
            <div className="cp-r-5 cp-l-5" >
              <div className='labelText main-font text-center  primary-color cp-t-5 text-truncate'  >
                {props.DataofProduct?.name}{props.DataofProduct?.store_name}
              </div>
              <div className=' text-center labelText'>
                {props.DataofProduct.manufacturer_name}
              </div>
              {props.DataofProduct.discount === null || props.DataofProduct.discount === 0 ? (
                <div>
                  <div className='main-font  text-center text-muted pText cp-b-5'>
                    AED {props.DataofProduct.unit_price?.toFixed(2)}
                  </div>
                  <div className='pText main-font text-center  cp-b-10' style={{ color: 'transparent' }}  >
                    .
                  </div>
                </div>
              ) : (
                <div>
                  <div className=' main-font text-center pText primary-color '>
                    AED {props.DataofProduct.discounted_price?.toFixed(2)}
                  </div>
                  <div className=' main-font text-center pText text-muted line-through cp-b-10'>
                    AED {props.DataofProduct.unit_price}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className=' touchAble position-relative  border-radius div-box-shadow' >
          <div className='' style={{height:props.height||220 ,width:'100%',justifyContent:'center',alignSelf:'center',display:'flex'}} >
            <img className='product-image' style={{}} alt="img" src={Global.IMAGE_PATH+props.DataofProduct.store_pic} ></img>
          </div>
          <div className="cp-r-8 cp-l-8" >
            <div className='cp-t-1rem  text-center main-font labelText primary-color text-truncate cp-b-5 '>
              {props.DataofProduct?.store_name}
            </div>
            <div className='cp-b-1rem labelText main-font text-muted text-truncate  text-center '>
              {props.DataofProduct.store_description}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Storeitem;
