import React, {useState} from 'react';

function MultiSelect(props: any) {

  const [brandsList, setbrandsList] = useState([])
  const [dataofmulti] = React.useState<any>([]);

  const checkFunction = (data: any) => {
      props.method(data, props.for)
  }

  const checkmultiselect = (color: any) => {
    let selectedBrand: any;
    selectedBrand = dataofmulti.find((item:any)=> item.name === color.name)
    if(selectedBrand){
     
        selectedBrand.checked = !selectedBrand.checked;
        dataofmulti.push(selectedBrand);
        setbrandsList(brandsList)
 
      let brandFilters: any = [];
      dataofmulti.map((item: any) => {
        if (item.checked) {
          brandFilters.push(item.name)
        }
                checkFunction(brandFilters)

      }
      )
      console.log('check data ',brandFilters)
    }else{
      let selectedBrand: any;
      selectedBrand = props.data.find((item: any) => item.name === color.name);
      if (selectedBrand) {
        selectedBrand.checked = !selectedBrand.checked;
        dataofmulti.push(selectedBrand);
        setbrandsList(brandsList)
      }
      let brandFilters: any = [];
      dataofmulti.map((item: any) => {
        if (item.checked) {
          brandFilters.push(item.name)
        }
        checkFunction(brandFilters)
      })
    }
   
  }

  return (
    <div>
      {props?.for === 'colors' ? (
        <div>
          {props?.data.map((colors: any) =>
            <div className="form-check  align-content-center cp-t-4 cp-b-4" >
              <input onClick={() => { checkmultiselect(colors); }} className="form-check-input largerCheckbox touchAble"
                type="checkbox" id="defaultCheck1" />
              <div className="d-flex flex-row align-self-center cp-l-10" style={{}} >
                <div className="d-flex align-self-center border" style={{ backgroundColor: colors.color_code, height: '20px', width: '20px', borderRadius: 100 }} > </div>
                <div>
                  <label className="form-check-label f-13  cp-l-15  main-font" style={{ color: 'gray' }} >{colors.name}</label>
                  <label className="form-check-label f-13  cp-l-5  main-font" style={{ color: 'gray' }} >({colors.count})</label>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {props?.data.map((brand: any) =>
            <div className="form-check  align-content-center cp-t-4 cp-b-4" >
              <input onClick={() => { checkmultiselect(brand); }} className="form-check-input largerCheckbox touchAble"
                type="checkbox" defaultChecked={brand.checked} id="defaultCheck1" />
              <label className="form-check-label f-13  cp-l-15 label main-font" style={{ color: 'gray' }} >{brand.name}</label>
              {brand.count >= 1 ? (<label className="form-check-label f-13  cp-l-5 label main-font" style={{ color: 'gray' }} >({brand.count})</label>) : null}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default MultiSelect