import './AllProducts.scss';
import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory, Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ShimmerC from '../../app-components/ShimmerC';
import CFooter from '../../app-components/CFooter';
import Pagination from 'react-bootstrap/Pagination'
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import StoreItemsFive from '../../app-components/StoreItemsFive';

function PopularProduct(props: any) {

  const [PopularProducts, setPopularProducts] = useState([])
  const [Pagedata, setPagedata] = useState<any>('')
  const [Luxury, setLuxury] = useState(1);
  const [lastPageCondition, setlastPageCondition] = useState(false);
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (props.location.state[0] == 'Luxury') {
      getAllPopularProducts(1, 1)
      setLuxury(1)
    } else {
      getAllPopularProducts(1, 0)
      setLuxury(0)
    }

  }, [])

  const RiChecke = (page: any) => {
    window.scrollTo(0, 0)
    let data
    if (page == 'NextPage') {
      if (Pagedata.last_page > Pagedata.current_page) {
        data = Pagedata.current_page + 1
        console.log('next page is = ', data)
        getAllPopularProducts(data, Luxury)
      } else {
        data = Pagedata.last_page
        setlastPageCondition(true)
      }
    } else if (page == 'PreviousPage') {
      let dataofnextPage: any = Pagedata.current_page;
      dataofnextPage = dataofnextPage - 1
      if (dataofnextPage >= 1) {
        console.log('while previous = ', dataofnextPage)
        getAllPopularProducts(dataofnextPage, Luxury)
      } else {
        console.log('reached 1st page')
      }
      data = dataofnextPage
    } else {
      getAllPopularProducts(page, Luxury)
    }
  }

  const getAllPopularProducts = (data: any, data2: any) => {
    axios
      .get(Global.WebGetAllPopularProducts + "?page=" + data + '&is_luxury=' + data2)
      .then(response => {
        setPopularProducts(response.data.AllProducts.data)
        setPagedata(response.data.AllProducts)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.id + '/' + slugify(data.name));
  }

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light"  >
        <div className="page-left-padding page-right-padding  cp-t-40 cp-b-60"   >
          <div className="cp-b-30" >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
              <Typography className="primary-color main-font" color="textPrimary">Popular Products</Typography>
            </Breadcrumbs>
          </div>
          <label className='main-font  f-25 cp-b-30 ' style={{ fontWeight: 'bold' }} >Popular Products</label>
        <div style={{ marginLeft: '2%' }} >
              {PopularProducts.length >= 1 ? (
                <div className='row webdisplay'  >
                  {PopularProducts.map((person: any) => (
                    <div key={person.id} style={{ width: '18%', marginRight: '2%', paddingTop: '2%' }} onClick={() => moveToDetailScreen(person)} >
                      <StoreItemsFive data={person} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className='row '>
                  <ShimmerC homeproducts='yes' />
                </div>
              )}
            </div>

            <div className='mobiledisplay' >

            {PopularProducts.length >= 1 ? (
                <div className='cp-r-13 cp-l-13  d-flex flex-wrap '>
                {PopularProducts.map((person: any) => (
                  <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)} >
                    <StoreItemsFive heigth={150} data={person} />
                  </div>

))}
              </div>
            ) : (
              <div className='row '>
                <ShimmerC homeproducts='yes' />
              </div>
            )}
          </div>
          {lastPageCondition == true ? (
            <div>
              <p className='d-flex justify-content-center f-500 f-20 cm-t-50' >Last page Reached</p>
            </div>
          ) : null}
          <div className=' d-flex justify-content-lg-end  cp-40'  >
            <Pagination >
              <Pagination.Prev onClick={() => RiChecke('PreviousPage')} />
              <Pagination.Item onClick={() => RiChecke(1)} >{1}</Pagination.Item>
              {Pagedata.last_page > '1' ? (<Pagination.Item onClick={() => RiChecke(2)} >{2}</Pagination.Item>) : null}
              {Pagedata.last_page > '2' ? (<Pagination.Item onClick={() => RiChecke(3)} >{3}</Pagination.Item>) : null}
              {Pagedata.last_page > '3' ? (<Pagination.Item onClick={() => RiChecke(4)} >{4}</Pagination.Item>) : null}
              {Pagedata.last_page > '4' ? (<Pagination.Ellipsis />) : null}
              {Pagedata.last_page > '5' ? (<Pagination.Item onClick={() => RiChecke(Pagedata.last_page)} >{Pagedata.last_page}</Pagination.Item>) : null}
              {Pagedata.last_page > '5' ? (<Pagination.Next onClick={() => RiChecke('NextPage')} />) : null}
            </Pagination>
          </div>
        </div>
      </div>
      <CFooter />
    </div>
  );
}
export default PopularProduct;
