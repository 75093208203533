
import { useEffect, useState } from 'react';
import axios from "axios";
import { globleAPI as Global } from '../globals'
import { BiChevronRight } from "react-icons/bi"

function RedeemHistory(props: any) {
    const [Credithistory, setCredithistory] = useState<any>([])
    const [showHistory, setshowHistory] = useState<any>(false)
    const [Credit, setCredit] = useState<any>('')
    const [token] = useState(localStorage.getItem('token') || '')
    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let date = mm + '/' + dd + '/' + yyyy;


    useEffect(() => {
        redeemHistory()
    }, [])

    const redeemHistory = () => {
        window.scrollTo(0, 0)
        axios
            .get(Global.GET_REDEEM + token)
            .then(response => {
                setCredit(response.data)
                setCredithistory(response.data.history)
            })
            .catch(function (error) {
                console.log('response if the api ifdsfdfs', error)
            });
    }

    return (
        <div>
            <div className=" cm-t-10 " style={{ backgroundColor: 'white', width: '45vw', minHeight: "40vw" }} >
                <div className="d-flex justify-content-between" style={{ backgroundColor: '#e0bcb0' }} >
                    <label className="main-font label cp-7 labelHeading " style={{ color: 'white' }}  >Credit Balance</label>
                    <label className="main-font label cp-7 labelHeading  primary-color"  >AED {Credit.credit}</label>
                </div>
                <div className="d-flex justify-content-between cm-t-15" style={{ backgroundColor: '#e0bcb0' }} >
                    <label className="main-font label cp-7  labelHeading" style={{ color: 'white' }}  >Pending</label>
                    <label className="main-font label cp-7  primary-color labelHeading "  >{Credit.pending}</label>
                </div>
                <div onClick={() => setshowHistory(!showHistory)} className="d-flex touchAble justify-content-between cm-t-15" style={{ backgroundColor: '#e0bcb0' }} >
                    <label className="main-font label cp-7  labelHeading " style={{ color: 'white' }} >Redeemtion History</label>
                    <div onClick={() => setshowHistory(!showHistory)} className="d-flex align-self-center" >
                        <BiChevronRight size="22" color="rgb(218, 95, 115)" />
                    </div>
                </div>
                {showHistory == true && <div>
                    {Credithistory.map((data: any) =>
                        <div className="d-flex justify-content-between c-border-top" style={{ backgroundColor: '#e0bcb0' }} >
                            <div className="d-flex flex-column align-item-center" >
                                <label className="main-font label cp-7  labelText" style={{ color: 'white' }}  >Date</label>
                                <label className="main-font label cp-7  labelText" style={{ color: 'white' }}  >{date}</label>
                            </div>
                            <div className="d-flex flex-column align-item-center">
                                <label className="main-font label cp-7  labelText" style={{ color: 'white' }}  >{data.status_id == '21' ? 'Amount Transferred' : 'Amount Redeeem'}</label>
                                <label className="main-font label cp-7  labelText" style={{ color: 'white' }}  >{data.amount_requested}</label>
                            </div>
                        </div>
                    )}
                </div>
                }
            </div>
        </div>
    );
}

export default RedeemHistory;

