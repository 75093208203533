import {useState } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CFooter from '../../app-components/CFooter';
import StoreDetails from '../../app-components/StoreDetails';
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function StoreDetail(props: any) {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')

  return (
    <div>
      {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light"  >
        <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-60"   >
          <div className="cp-b-40" >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
              <Typography className="primary-color main-font" color="textPrimary">All Retail Stores</Typography>
              <Typography className="primary-color main-font" color="textPrimary">Store Profile</Typography>
            </Breadcrumbs>
          </div>
          <StoreDetails userid={props.location.state[0]} />
        </div>
      </div>
      <CFooter />
    </div>
  );
}
export default StoreDetail;
