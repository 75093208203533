import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory} from 'react-router-dom'
import { FiChevronDown } from "react-icons/fi";
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import { BiPhoneCall} from "react-icons/bi";
import {RiWhatsappLine } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function SellerOrderDetail(props: any) {
  
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [token] = useState(localStorage.getItem('token') || '')
  const [orderDetail, setorderDetail] = useState<any>([])
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
    getOrderDetail()
  }, [])

  const  getOrderDetail = () =>{
    axios
    .get(Global.GET_ORDER_DETAIL + token + '&order_id=' + props.location.state[0])
    .then(response => {
      setorderDetail(response.data.my_orders)
    })
    .catch(function (error) {
      console.log('response if the api ifdsfdfs', error)
    });
  }

  const move = () => {
    history.push('/purchases',);
  }

  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.product_id + '/' + slugify(data.name), [data]);
  }

  const gotoShopAll = () => {
    history.push('/',);
  };

  return (
    <div>
      {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light" style={{ paddingBottom: '10%' }}  >
        <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-20"   >
          <label className="label secHeading primary-color" >My seelrre</label>
          <div className="d-flex" >
            <label onClick={() => gotoShopAll()} className=" hoverUnderLine touchAble label labelText cp-r-35 cp-t-10 cp-b-10 "  >Buy</label>
            <label onClick={() => move()} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Purchase History</label>
            <label onClick={() => move()} className="label labelText cp-r-35 cp-t-10 cp-b-10 hoverUnderLine touchAble "  >Price Offer Request</label>
          </div>
          <div>
            <label className="label secHeading primary-color cp-t-30 " >Purchase History</label>
            <div className="d-flex justify-content-end cp-b-10" >
              <label className="label labelText text-muted primary-color cp-7 cp-r-10  " >Sort Orders   </label>
              <label className="label pText text-muted primary-color cp-t-8  cp-l-10 cp-r-2 " style={{ backgroundColor: 'white' }} >By Date <label className="cp-l-20" >  <FiChevronDown size='20' className=" border-left cp-l-5" /></label></label>
            </div>
            <div className="" >
              {orderDetail.map((data: any) => (
                <div className=' cp-t-30  cp-l-17 cp-b-20 cm-b-40  ' style={{ backgroundColor: 'white' }} >
                  <div className='d-flex flex-row' >
                    <div className='d-flex flex-row col-6'>
                      <img className='carosal-image-Web' style={{ height: 90, width: 90 }} src={Global.IMAGE_PATH + data.image} alt="First slide" />
                      <div className='d-flex flex-column cp-l-10 ' >
                        <label className='labelHeading label primary-color   '   >{data.name}</label>
                        <label className='pText f-600 label text-muted'  >{data.manufacturer_name}</label>
                        <label className='labelHeading label cp-t-15 text-muted'  >{data.status} on <label className=" labelText primary-color" > {data.created_at.substring(0, 10)}</label></label>
                        {data.is_returnable == 0 &&
                          <label className="labelText label text-muted" style={{ color: 'black' }} >* This item is <label style={{ color: 'black' }} > NOT</label> eligible for return</label>
                        }
                        <label className='labelText label cp-t-5 text-muted'  >Sold by: <label className="labelText label" style={{ color: 'black' }} >{data.store_name}</label> </label>
                      </div>
                    </div>
                    <div className='col-4 d-flex justify-content-end  ' >
                      <div className='d-flex flex-column' >
                        <div>
                          <label className='d-flex justify-content-center labelHeading primary-color label'  >AED {data.price?.toFixed(2)}</label>
                          <label className='d-flex  labelHeading primary-color text-muted'  >QTY: {data.quantity}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.status == 'Delivered' &&
                    <div className=" border-top cm-t-20 cp-t-18   " onClick={() => moveToDetailScreen(data)} >
                      <label className="label labelText touchAble   cp-t-7 cp-b-7 cp-l-35 cp-r-35  bg-color-primary cm-l-15  " style={{ color: 'white' }} >Write A Review</label>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-start padding  cp-t-10 cp-b-7  flex-row align-items-center ' style={{ backgroundColor: 'white' }} >
        <label className=' text-uppercase f-14 font-weight-bold  label cp-l-5 cp-r-15  main-font'>Need help?</label>
        <div className=" d-flex flex-row" >
          <div className="d-flex  justify-content-center align-self-center " >
            <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="tel:+971588241922"><BiPhoneCall size='17' />Call us</a>
          </div>
          <div className="d-flex justify-content-center align-self-center" >
            <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0"><RiWhatsappLine size='17' />WhatsApp</a>
          </div>
          <div className="d-flex justify-content-center align-self-center" >
            <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"><GoMail size='17' /> Email</a>
          </div>
        </div>
      </div>
      <CFooter />
    </div>
  );
}

export default SellerOrderDetail;




