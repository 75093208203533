import { useEffect, useState, useRef } from 'react';
import '../screens/sellers-side/MySales.scss'
import axios from "axios";
import 'react-date-picker/dist/DatePicker.css';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { globleAPI as Global } from '../globals'
import Alertss from '../app-components/Alertss';

function HolidayModescreen(props: any) {

  const [token] = useState(localStorage.getItem('token') || '')
  const [HolidayMode, setHolidayMode] = useState<any>('')
  const [askOption, setaskOption] = useState(false);
  const [status, setstatus] = useState('')
  const [showAlert, setshowAlert] = useState(false)
  const [holidayEnabled, setholidayEnabled] = useState(false)
  const [toggle, settoggle] = useState(false)
  const [value, onChange] = useState<any>(new Date());
  const [endDate, onChangeendDate] = useState<any>();
  const ref = useRef(null);

  useEffect(() => {
    getHolidayMode()

  }, [])
  const getHolidayMode = () => {
    axios
      .get(Global.GetHolidayMode + token)
      .then(response => {
        console.log('responseresponse', response.data.user)
        setHolidayMode(response.data.user)
      })
      .catch(function (error) {
        console.log('response if the api ifdsfdfs', error)
      });
  }

  const functiontrue = () => {
    if (HolidayMode?.user_on_holiday == 1) {
      window.scrollTo(0, 0)
      setaskOption(true)
    } else {
      setholidayEnabled(true)
    }

  };

  const ToggleFun = ()=>{
settoggle(!toggle)
setholidayEnabled(!holidayEnabled)
  }

  const endHolidayMode = () => {
    axios
      .post(Global.EndHolidayMode + token)
      .then(response => {
        console.log(response)
        setstatus(response.data.message)
        setshowAlert(true)
        setTimeout(() => {
          setshowAlert(false)
        }, 5000);
        getHolidayMode()
        setaskOption(false)
      })
      .catch(function (error) {
        console.log('response if the api ifdsfdfs', error)
      });
  };

  const enableHolidaymode = () => {
    let formdata = new FormData();
    console.log(value,endDate)
    formdata.append("user_on_holiday", '1');
    formdata.append("holiday_start_date", value);
    formdata.append("holiday_end_date", endDate);
    axios({
      method: "post",
      url: Global.StartHolidayMode + token,
      data: formdata,
    })
      .then(response => {
        console.log(response)
        setstatus(response.data.message)
        setholidayEnabled(false)
        getHolidayMode()
        setshowAlert(true)
        setTimeout(() => {
          setshowAlert(false)
        }, 5000);

      })
      .catch(function (error) {
        console.log('response if the api ifdsfdfs', error)
      });
  };


  return (
    <div>
  {showAlert == true && <Alertss  type='success' status={status} />}
      <div className="d-flex justify-content-center  " >
        {askOption == true && <div className=" d-flex flex-column justify-content-center align-self-center align-content-center position-absolute " style={{ width: '40%', height: '30vh', backgroundColor: 'white', marginTop: '10%' }} >
          <label className="main-font label labelHeading cp-10 text-center bg-color-primary" style={{ width: '100%', color: 'white' }} >Alert</label>
          <div className="d-flex flex-column  justify-content-center" >
            <label className="main-font label labelText primary-color cp-10 text-center" >Back from your break?</label>
            <label className="main-font label pText  text-center" >Get ready to sell and ship again!</label>
            <div className="d-flex justify-content-around cm-t-10" >
              <label className="main-font label labelText  cp-5 cp-r-10 cp-l-10 text-center bg-color-primary touchAble" style={{ color: 'white' }} onClick={() => setaskOption(false)} >Cancel</label>
              <label className="main-font label  labelText bg-color-primary cp-5 text-center cp-r-10 cp-l-10 touchAble" style={{ color: 'white' }} onClick={() => endHolidayMode()} >Confirm</label>
            </div>
          </div>
        </div>
        }
      </div>
 <div className=" cm-t-10   " style={{ backgroundColor: 'white', width: '45vw', minHeight: "40vw" }} >
        <div className="cp-60" >
          <label className=" main-font labelText  label" >Planning to go on a holiday and worried you won’t be able to ship in under 3 days? Turn on your holiday mode to ley your community know! </label>
          <label className=" main-font labelText  label cp-t-10 label " ><label className=" main-font labelHeading label" >Note:</label>Buyers will be able see your items, but won’t be able to purchase them.</label>
        </div>
        <div className="  c-border-bottom  c-border-top" style={{ borderColor: 'lightgray' }} >
          <div className="cp-l-60 cp-r-60  d-flex justify-content-between align-content-center align-item-center" >
            <label className=" main-font labelText cp-t-10 cp-b-10  label" >Holiday Mode</label>
            <label  className="switch label cp-t-10 cp-b-10 ">
              {holidayEnabled && HolidayMode?.user_on_holiday == 0 ?(
 <input  onClick={() => ToggleFun()} type="checkbox"   ></input>
              ):(
                <input onClick={() => functiontrue()} type="checkbox" checked={HolidayMode?.user_on_holiday == 1 ? true : false}  ></input>
              )}
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {HolidayMode?.user_on_holiday == 1 &&
          <div className="cp-l-60 cp-r-60  d-flex justify-content-between align-content-center c-border-bottom align-item-center" style={{ borderColor: 'lightgray' }}>
            <label className=" main-font labelText cp-10 label" > Holiday Start Date</label>
            <label className=" main-font labelHeading primary-color  cp-10 label" >{HolidayMode?.holiday_start_date}</label>
          </div>
        }
        {holidayEnabled == true &&
          <div>
            <div className="cp-l-60 cp-r-60  c-border-bottom" style={{ borderColor: 'lightgray' }} >
              <label className=" main-font pText  label text-muted" >Start Date</label>
              <DatePicker
                className='datee'
                clearIcon={null}
                calendarIcon={null}
                onChange={onChange}
                value={value}
              />
            </div>
            <div className="cp-l-60 cp-r-60  c-border-bottom" style={{ borderColor: 'lightgray' }} >
              <label className=" main-font pText  label text-muted" >End Date</label>
              <DatePicker
                className='datee'
                clearIcon={null}
                calendarIcon={null}
                onChange={onChangeendDate}
                value={endDate}
              />
            </div>
            <div className="d-flex justify-content-center" >
              <div onClick={() => enableHolidaymode()} className='cm-t-20 cp-7 text-center touchAble col-6 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Save</p>
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
}

export default HolidayModescreen;

