import { useEffect, useState } from "react";
import './ProductDetail.scss';
import { AiOutlineGift } from "react-icons/ai";
import { FaShippingFast, FaHeart } from "react-icons/fa";
import { HiOutlineHeart } from "react-icons/hi";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import girl from '../../assets/emojiwindle.png';
import { RiShareForwardLine, RiFlag2Line } from "react-icons/all";
import axios from "axios";
import { useModal } from 'react-hooks-use-modal';
import CFooter from "../../app-components/CFooter";
import Carousel from 'react-bootstrap/Carousel'
import Alert from 'react-bootstrap/Alert'
import ShimmerC from "../../app-components/ShimmerC";
import ReactImageMagnify from 'react-image-magnify';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Rating } from 'react-simple-star-rating'
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import { TwitterIcon, FacebookShareButton, FacebookIcon, WhatsappIcon, EmailShareButton, EmailIcon, LineShareButton, LinkedinShareButton, LivejournalShareButton, MailruShareButton, OKShareButton, PinterestShareButton, PocketShareButton, RedditShareButton, TelegramShareButton, TumblrShareButton, TwitterShareButton, ViberShareButton, VKShareButton, WhatsappShareButton, WorkplaceShareButton } from "react-share";
import HeaderforRetail from "../../app-components/HeaderforRetail";
import HeaderForLuxury from "../../app-components/HeaderForLuxury";
import StoreItemsFive from "../../app-components/StoreItemsFive";

function ProductDetail(props: any) {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });
  const { id } = useParams<any>();
  const [producatdetail, setproducatdetail] = useState<any>([])
  const [showAlert, setshowAlert] = useState(false)
  const [status, setstatus] = useState('')
  const [Comment, setComment] = useState('')
  const [Selectedsize, setSelectedsize] = useState('')
  const [SelecetedColor, setSelecetedColor] = useState('')
  const [MoreFromSeller, setMoreFromSeller] = useState<any>([])
  const [vendor_details, setvendor_details] = useState<any>([])
  const [more_to_like, setmore_to_like] = useState<any>([])
  const [sliderImages, setsliderImages] = useState<any>([])
  const [ProductColors, setProductColors] = useState<any>([])
  const [productRating, setproductRating] = useState<any>([])
  const [prouctratingLess, setprouctratingLess] = useState<any>([])
  const [productRating3, setproductRating3] = useState<any>([])
  const [producatdetailsss, setproducatdetailsss] = useState<any>([])
  const [ProductSizes, setProductSizes] = useState([])
  const [cartUpdate, setcartUpdate] = useState(false)
  const [ShowShare, setShowShare] = useState(false)
  const [SeeAll, setSeeAll] = useState(false)
  const [reportError, setreportError] = useState(false)
  const [productWishListCheck, setproductWishListCheck] = useState('0')
  const [shareUrl, setshareUrl] = useState('')
  const [MainImage, setMainImage] = useState('')
  const [token] = useState(localStorage.getItem('token') || '')
  const [userid] = useState(localStorage.getItem('userid') || '')
  const [rating, setRating] = useState(0)
  const [ReportText, setReportText] = useState('')
  const history = useHistory();
  const title = 'GitHub';
  const location = useLocation();

  useEffect(() => {

    // console.log(http://lesfemmes.page.link/?link=https%3A%2F%2Flesfemmes.net%2Fproduct%2F'+id+"&apn=com.lesfemmes&ibi=com.lesfemmes.dominioninc);
    setshareUrl('http://lesfemmes.page.link/?link=https%3A%2F%2Flesfemmes.net%2Fproduct%2F' + id + "&apn=com.lesfemmes&ibi=com.lesfemmes.dominioninc")
    history.listen((location, action) => {
      if (action === 'POP') {
        window.location.reload();

      } else {
        window.location.reload();
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }
    })
    if (id != null) {
      getproductDetail()
    }
  }, [])

  const movetoStoreDetail = (data: any) => {
    window.scrollTo(0, 0)
    history.push('/StoreDetail', [data]);
  }

  const getproductDetail = () => {
    let apiurl = token != '' ? Global.CLASSIFIED_AD_DETAIL + token + "&product_id=" + id : Global.WebGetProductDetailV1 + "?product_id=" + id
    axios.get(apiurl)
      .then(response => {
        let industry = response.data.product_ratings.filter(
          (Industrydata: any, idx: any) => idx < 3,
        );
        setproductRating3(industry)
        setproductRating(response.data.product_ratings)
        if (response.data.product_ratings.length > 3) {
          setSeeAll(true)
        }
        setProductSizes(response.data.product_sizes)
        setProductColors(response.data.product_colors)
        setproducatdetail(response.data.product_details[0])
        console.log('response.data.product_details[0]', response.data)
        setproductWishListCheck(response.data.product_details[0].wishlistcheck)
        setMoreFromSeller(response.data.more_from_this_seller)
        setvendor_details(response.data.vendor_details[0])
        setmore_to_like(response.data.more_to_like)
        setsliderImages(response.data.product_pics)
        setMainImage(response.data.product_pics[0].uri)
        window.scrollTo(0, 0)
      })
      .catch(function (error) {
        console.log('data of product id =ffffff rtertewrtew', error)
      });
  }

  const addtoCart = (productdetails: any, data: any) => {
    window.scrollTo(0, 0)
    if ((ProductSizes.length > 1 && Selectedsize == '') || (ProductColors.length > 1 && SelecetedColor == '')) {
      setstatus('please select a size and color first')
      setshowAlert(true)
      setTimeout(() => {
        setshowAlert(false)
      }, 5000);
    } else {
      if (data == 'add') {
        setproducatdetailsss(productdetails)
        axios.post(Global.PRODUCT_ADD_TO_CART + token, {
          product_id: productdetails.id,
          customer_id: userid,
          quantity: '1',
          color: SelecetedColor,
          size: Selectedsize,
        })
          .then(response => {
            console.log('after data response', response)
            setcartUpdate(true)
            setTimeout(() => {
              setcartUpdate(false)
            }, 2000);
            setstatus(response.data.message)
            setshowAlert(true)
            setTimeout(() => {
              setshowAlert(false)
            }, 5000);
          })
          .catch(function (error) {
            console.log('response if the api is errror =', error)
          });
      } else {
        setproducatdetailsss(productdetails)
        axios.post(Global.PRODUCT_ADD_TO_CART + token, {
          product_id: productdetails.id,
          customer_id: userid,
          quantity: '1',
          color: SelecetedColor,
          size: Selectedsize,
        })
          .then(response => {
            if (response.data.status == '400') {
              setstatus(response.data.message)
              setshowAlert(true)
              setTimeout(() => {
                setshowAlert(false)
              }, 5000);
            } else {
              history.push('/cart')
              setcartUpdate(true)
              setTimeout(() => {
                setcartUpdate(false)
              }, 2000);
            }
          })
          .catch(function (error) {
            console.log('response if the api is errror =', error)
          });
      }
    }


  }

  const move = () => {
    history.push('/login')
  }

  const imageindex = (imageUrl: any) => {
    setMainImage(imageUrl)
  }

  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.id + '/' + slugify(data.name))
  }

  const moving = (data: any) => {
    history.push('/all-products', [data, 'Home']);
  };

  const reportItem = (data: any, data2: any) => {
    window.scrollTo(0, 0)
    close()
    if (data2.length <= 6) {
      setreportError(!reportError)
    } else {
      axios.post(Global.REPORT + token, {
        product_id: data.id,
        feedback: data2,
      })
        .then(response => {
          setstatus('Your Feedback has been sent successfully')
          setshowAlert(true)
          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    }
  };

  const addTowishList = (data: any) => {
    axios.post(Global.ADD_AD_TO_WISH_LIST + token + '&product_id=' + data.id)
      .then(response => {
        getproductDetail()
        setstatus(response.data.message)
        setshowAlert(true)
        setTimeout(() => {
          setshowAlert(false)
        }, 5000);

      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const Ratings = () => {
    if (rating == 0) {
      setshowAlert(true)
      setstatus('Please select start st')
      setTimeout(() => {
        setshowAlert(false)
      }, 5000);
    } else {
      axios.post(Global.POST_REVIEWS + token + "&user_id=" + userid + "&product_id=" + producatdetail.id + "&review=" + Comment + "&rating=" + 3,)
        .then(response => {
          setstatus(response.data.message)
          setshowAlert(true)
          setRating(0)
          setComment('')
          getproductDetail()

          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
          console.log('reeposndjfjskdfjsdf', response)
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    }
  }

  const handleRating = (rate: any) => {
    setRating(rate)
    console.log('ratinfsssss', rate)
  }

  const SelecetedSize = (rate: any) => {
    setSelectedsize(rate)
    console.log('ratinfsssss', rate)
  }

  const SelecetedColors = (rate: any) => {
    setSelecetedColor(rate)
    console.log('ratinfsssss', rate)
  }

  const movetoMakeOffer = (data: any) => {
    history.push('/make-an-offer/' + data.id + '/' + slugify(data.name), [data])
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
        <Modal>
          <div style={{ backgroundColor: 'white', width: '500px', paddingBottom: 20 }} >
            <div className="" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, marginLeft: 20, marginRight: 20 }} >
              <label style={{ fontSize: 22, color: 'black', fontWeight: 'bold' }} > Report An Item</label>
              <label onClick={close} className="touchAble" style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }} >X</label>
            </div>
            <div style={{ padding: 10, backgroundColor: '#ffd6e9', marginLeft: 20, marginRight: 20 }} >
              <label style={{ color: 'black' }} > Examples of inappropriate items include fake items, stolen items,
                items not the same on the photo, hazardous items, an obscene
                photo, or items that encourage illegal activity such as violence
                or intolerance.</label>
              <label className="label" style={{ color: 'black' }} >Please visit Les Femmes' full list of <label onClick={() => history.push('/prohibited-items')} className="label hoverUnderLine" style={{ color: '#9a1e4a' }}>Prohibited Items</label> for further information. </label>
            </div>
            <input style={{ width: '92%', marginLeft: 20, marginRight: 20, paddingLeft: 10, marginTop: 20, borderBottomColor: 'gray', borderBottomWidth: 1 }} placeholder='Tell us how this Item is  Inappropriate?' onChange={(text: any) => setReportText(text.target.value)} />
            {(reportError && ReportText.length < 6) && <p className='label text-danger f-13 main-font cm-20' >More Than 6 Chracters Required</p>}
            <div onClick={() => reportItem(producatdetail, ReportText)} className='col-12 cm-t-20' >
              <button type="button" style={{ width: '100%', backgroundColor: '#732F3E', color: 'white' }} className="btn  border-0"  >Send</button>
            </div>
          </div>
        </Modal>

      </div>
      {showAlert == true ? (
        <div className='d-flex justify-content-center' >
          <Alert style={{ marginTop: '30vh', position: 'absolute', zIndex: 1, width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="primary" show={showAlert}  >
            <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
            <p style={{}} >{status}</p>
          </Alert>
        </div>
      ) : null}

      {Selected == 'Luxury' ? <HeaderForLuxury cartcheck={cartUpdate} /> : <HeaderforRetail cartcheck={cartUpdate} />}
      <div className='padding' >
        <div className="cm-t-30 cm-b-30 " >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} to="/">Home</Link>
            <Link style={{ color: 'black' }} to="/all-products">Shop All</Link>
            <Typography style={{ color: 'black' }} >Product Detail</Typography>
            <Typography style={{ color: 'black' }} >{producatdetail?.parent_category}</Typography>
            <Typography style={{ color: 'black' }} >{producatdetail?.category_name}</Typography>
            <Typography style={{ color: 'black' }} >{producatdetail?.name}</Typography>
          </Breadcrumbs>
        </div>
        <div className='product' >
          {sliderImages.length >= 1 ? (

            <div className='overflow-auto  d-flex flex-column align-item-center ' style={{ maxHeight: '500px', width: '400px' }} >
              {sliderImages?.map((images: any) => (
                <img className='carosal-image-Web touchAble webdisplay' onClick={() => imageindex(images.uri)} style={{ height: 150, width: 150, paddingTop: 10 }} src={images.uri} alt="First slide" />
              ))}
            </div>

          ) : (
            <div className='productcarosal webdisplay col-2' >
              <ShimmerC productdetailIMG='yes' />
            </div>

          )}
          {MainImage != '' ? (
            <div className='webdisplay'  >
              <div className='carosal d-flex flex-column ' style={{ maxHeight: '500px', backgroundColor: '#f5f5f5', padding: 10 }}  >
                <div className='d-flex  justify-content-center align-self-center  ' >
                  <ReactImageMagnify style={{ display: 'inline-block', maxHeight: '500px', width: '10' }} {...{
                    smallImage: {
                      alt: 'Wristwatch by Ted Baker London',
                      isFluidWidth: false,
                      width: 400,
                      height: 450,
                      src: MainImage
                    },
                    enlargedImageContainerStyle: { position: 'absolute', zIndex: 1 },
                    largeImage: {
                      src: MainImage,
                      width: 1000,
                      height: 1000,
                    }
                  }} />
                </div>
                <div className='d-flex justify-content-around' >
                  <div onClick={open} >
                    <RiFlag2Line size='20' />
                    <label className='f-14  touchAble cp-l-10'>Report this Item</label>
                  </div>
                  <div className="" onClick={() => setShowShare(!ShowShare)} >
                    <RiShareForwardLine className="touchAble" size='22' />
                    <label className='f-14  touchAble cp-l-10'>Share this Item</label>
                  </div>


                </div>

              </div>
              {ShowShare &&
                <div className="d-flex flex-row-reverse" style={{ backgroundColor: 'white' }} >
                  <div className="cp-r-10">
                    <TwitterShareButton
                      url={shareUrl}
                      // title={title}
                      className="cm-r-10"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url={shareUrl}
                      // title={title}
                      className="cm-r-10"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <WhatsappShareButton
                      url={shareUrl}
                      // title={title}
                      className="cm-r-10 "
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    <EmailShareButton
                      url={shareUrl}
                      // title={title}
                      className="cm-r-10"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>


                    <div className="Demo__some-network__share-count">&nbsp;</div>
                  </div>

                </div>
              }
            </div>
          ) : (
            <div className='webdisplay  col-md-5  '  >
              <ShimmerC productdetailIMG='yes' />
            </div>
          )}
          <div className='mobiledisplay mobilecarosal' style={{ height: '50vh', backgroundColor: '#f5f5f5' }} >
            <Carousel >
              {sliderImages?.map((images: any) => (
                <Carousel.Item  >
                  <img className="carosal-image" src={images.uri} alt="First slide" />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className='productdetail container ' style={{}}>
            <div className='productdetailcolumn container-fluid  ' >
              <label className='label f-18 f-500  text-uppercase primary-color main-font' >{producatdetail?.name}{producatdetail?.store_name}</label>
              <label className='label  labelText  cp-t-5 main-font' >{producatdetail.manufacturer_name}</label>
              {producatdetail.discount == 0 || producatdetail.discount == null ? (<label className='label f-18 f-500 cp-t-5 text-uppercase primary-color main-font' > {producatdetail?.unit_price?.toFixed(2)} AED </label>)
                : (
                  <div className='d-flex flex-column' >
                    <label className='label f-18 f-500 cp-t-5 text-uppercase primary-color main-font' >{producatdetail?.discounted_price?.toFixed(1)} AED </label>
                    <label className='label pText cp-t-5 text-uppercase text-muted main-font line-through' >{producatdetail.unit_price}  AED  <label className=' cp-l-10 pText' style={{ color: 'black' }} >-{producatdetail.discount}%</label></label>
                  </div>
                )
              }
              <label className='label f-15  cp-t-5 text-uppercase primary-color main-font' > {producatdetail.product_condition}</label>
              <label className='label f-17 f-500 cp-t-15 main-font' >Availble Sizes</label>
              <div className='' >
                {ProductSizes.length >= 1 ? (
                  <div>
                    {ProductSizes.map((sizes: any) => (
                      <label onClick={() => SelecetedSize(sizes.value)} className=' c-border  label pText   cp-3 cm-t-5 cm-r-10  cp-l-10 cp-r-10 main-font' style={{ color: Selectedsize === sizes.value ? 'white' : 'black', backgroundColor: Selectedsize === sizes.value ? 'rgb(218, 95, 115)' : 'white', }} >{sizes.value}</label>
                    ))
                    }
                  </div>
                ) : (
                  <label className='label f-15  cp-t-5 border cp-5   cp-l-10 cp-r-10 ' style={{ color: 'white', borderRadius: 5, backgroundColor: 'rgb(233, 120, 139)' }} >one size available</label>
                )}
              </div>
              <label className='label f-17 f-500 cp-t-5 main-font' >Availble Colors</label>
              <div>
                {ProductColors.length >= 1 ? (
                  <div className="d-flex flex-row" >
                    {ProductColors.map((colors: any) => (
                      <div onClick={() => SelecetedColors(colors)} className=" cm-4 d-flex align-self-center c-border" style={{ backgroundColor: colors, height: '30px', width: '30px', borderRadius: 100, borderColor: SelecetedColor == colors ? 'rgb(233, 120, 139)' : 'black', borderWidth: SelecetedColor == colors ? 5 : 0.5, }} > </div>
                    ))
                    }
                  </div>
                ) : (
                  <label className='label f-15  cp-t-5 border cp-5   cp-l-10 cp-r-10 ' style={{ color: 'white', borderRadius: 5, backgroundColor: 'rgb(233, 120, 139)' }} >one color available</label>
                )}
              </div>
              <label className='label f-19 f-500 cp-t-25' >Description</label>
              <label className='label f-16  cp-t-5 ' style={{ color: 'gray' }} > {producatdetail.description}</label>
              <div className='d-flex flex-row cp-t-30  cp-b-30' >
                <div className="d-flex align-self-center" >
                  <AiOutlineGift color='#732F3E' size='50' />
                </div>
                <div className="d-flex align-self-center" >
                  <label className='label f-14 f-500 cp-t-2 primary-color main-font' >  <strong>15% discount</strong> with code <strong>LFAPP15</strong>  for first time order worth 150 AED and above</label>
                </div>
              </div>
              <div className='d-flex flex-row cm-t-10' style={{ position: 'relative' }} >
                {producatdetail.quantity_per_unit <= 0 ? (
                  <div className='col-6 text-center border cp-8 text-uppercase  '><label className='primary-color label f-13 f-500' >Out Of Stock</label> </div>
                ) : (
                  <div className='col-6 text-center border cp-8 text-uppercase touchAble '>
                    <div onClick={() => token != '' ? addtoCart(producatdetail, 'add') : move()}><label className="label touchAble primary-color pText main-font" >Add To Cart</label></div>
                  </div>
                )}
                <div className='col-6 text-center cp-8 c-border' onClick={() => token !== '' && producatdetail.quantity_per_unit > 0 ? addtoCart(producatdetail, 'buy') : move()} style={{ backgroundColor: '#732F3E', borderColor: '#732F3E' }} ><label className="label touchAble pText  main-font" style={{ color: 'white' }} >Buy Now</label></div>
              </div>
              <div className='d-flex flex-row cm-t-10' >
                {/* {producatdetail.allow_to_make_offer == 1 && 
                
                } */}
                {producatdetail?.allow_to_make_offer == 1 &&
                  producatdetail?.offercheck == 0 &&
                  producatdetail?.supplier_id != userid &&
                  producatdetail?.quantity_per_unit > 0 && (
                    <div onClick={() => movetoMakeOffer(producatdetail)} className='col-12 text-center border cp-8 ' style={{ backgroundColor: '#e6e3e3' }} ><label className='label pText text-uppercase touchAble main-font'>Make an offer </label></div>
                  )}

              </div>
              {producatdetail.is_returnable == 1 ? (
                <label className='label pText cp-t-25  touchAble main-font'> Return within 7 days of delivery </label>
              ) : (
                <label className='label pText cp-t-25  touchAble main-font'> This item is NOT eligible for return. </label>
              )
              }
              <div>
                {token !== '' ? (
                  <div className='d-flex flex-row cm-t-15 ' onClick={() => addTowishList(producatdetail)} >
                    <div className='touchAble'>
                      {productWishListCheck == '0' ? (
                        <HiOutlineHeart color='black' size='27' />
                      ) : (
                        <FaHeart color='red' size='27' />
                      )}
                    </div>
                    <label className='label f-14  cp-t-2  cp-l-10 main-font touchAble' > {productWishListCheck == '0' ? 'Move to Wishlist' : 'Remove from Wishlist'}  </label>
                  </div>
                ) : (
                  <div className='d-flex flex-row cm-t-15 ' onClick={() => move()} >
                    <HiOutlineHeart color='black' size='27' />
                    <label className='label f-14  cp-t-2  cp-l-10 main-font touchAble' >  Add to wishlist</label>
                  </div>
                )
                }
              </div>

              <div className='d-flex flex-row cm-t-15 ' >
                <div className='touchAble'>
                  <FaShippingFast color='black' size='30' />
                </div>
                <label className='label f-13  cp-t-2  cp-l-10 main-font' >Free Delivery for order`s above 100 AED and above in the UAE </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mobilePaddings">
          <div className=' d-flex cm-t-35 cm-b-20 '  >
            <div className='flex-column' style={{ width: '100%' }}  >
              <p className='f-20  f-500 text-capitalize label cp-t-8 cp-b-6 primary-color main-font' >Rate product</p>
              <div style={{ flexDirection: 'column-reverse' }}>
                <Rating onClick={handleRating} size={40} ratingValue={rating} />
              </div>
              <div className='d-flex  flex-row' style={{ width: '100%' }} >
                <input type="text" className="form-control" style={{ width: '100%' }} onChange={(text: any) => setComment(text.target.value)} placeholder="Comment" />
              </div>
              <div className='d-flex justify-content-center cm-t-20 '   >
                <div onClick={() => Ratings()} className='d-flex justify-content-center cp-7 touchAble col-md-3 col-8' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                  <label className='label touchAble main-font' style={{ color: 'white' }} >Write a comment</label>
                </div>
              </div>
            </div>
          </div>
          <div className='cm-t-55 cm-b-80' >
            {MoreFromSeller.length >= 1 ? (
              <div>
                {MoreFromSeller.length >= 1 ? (
                  <div>
                    <div className=' cp-t-2rem  cp-b-1rem display-flex content-between align-item-center'>
                      <div className='f-20 text-capitalize  primary-color font-weight-bold text-color main-font text-capitalize'>
                        more from this seller
                      </div>
                      {MoreFromSeller.length >= 6 ? (
                        <div onClick={() => moving('Popular Products')} className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
                          <label onClick={() => history.push('/popular-products', ['Luxury'])} className="f-15 cp-l-20 main-font cp-r-20 touchAble hoverUnderLine  main-font label" style={{ color: 'white' }} >View All</label>
                        </div>
                      ) : null}
                    </div>
                    <div className='row webdisplay'>
                      {MoreFromSeller.map((data: any) => (
                        <div onClick={() => moveToDetailScreen(data)} style={{ width: '18.2%', marginLeft: '1.5%' }}>
                          <StoreItemsFive data={data} />
                        </div>
                      ))}
                    </div>
                    <div className='mobiledisplay  d-flex flex-wrap'>
                      {MoreFromSeller.map((data: any) => (
                        <div onClick={() => moveToDetailScreen(data)} className='col-6 paddingsMobile'>
                          <StoreItemsFive data={data} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : MoreFromSeller.length == 0 ? (
              <div></div>
            ) : (
              <ShimmerC homeproducts='yes' />
            )}
            <div>
              <div className='f-20  text-capitalize primary-color cp-t-2rem  cp-b-1rem  cp-r-2rem font-weight-bold text-color main-font'>
                About seller
              </div>
              <div onClick={() => movetoStoreDetail(vendor_details.id)} className=' cp-t-1rem  display-flex align-item-center touchAble'> <div>

                {vendor_details.store_pic !== 'null' ? (
                  <img className='about-Seller-img' src={Global.IMAGE_PATH + vendor_details.store_pic}></img>
                ) : (
                  <img alt="img" className="border" style={{ height: 40, width: 40, borderRadius: 100, marginRight: 2 }} src={girl}></img>
                )}

              </div>
                <div className='flex-column display-flex  cp-l-1rem'>
                  <p className='f-18 main-font label ' >{vendor_details.store_name}</p>
                  <p className='f-15 main-font text-muted Venderdescription' style={{ width: '100%' }} > {vendor_details.store_description}</p>
                </div>
              </div>
            </div>
            {more_to_like.length >= 1 ? (
              <div>
                {more_to_like.length >= 1 ? (
                  <div id='abc' >
                    <div className=' cp-t-2rem display-flex content-between align-item-center'>
                      <div className='f-17 primary-color text-capitalize font-weight-bold text-color main-font text-capitalize'>
                        you may also like
                      </div>
                      {more_to_like.length >= 6 ? (
                        <div onClick={() => moving('Popular Products')} className='  d-flex touchAble  page-color f-15 main-font f-500 border-radius cp-t-6 cp-b-6 cp-l-10 cp-r-10 ' style={{ backgroundColor: '#732F3E' }}>
                          <Link className="f-15 cp-l-20 main-font cp-r-20 touchAble hoverUnderLine  main-font label" to="/all-products" style={{ color: 'white' }} >     View All</Link>
                        </div>
                      ) : null}
                    </div>
                    <div className='row webdisplay'>
                      {more_to_like.map((data: any) => (
                        <div onClick={() => moveToDetailScreen(data)} style={{ width: '18.2%', marginLeft: '1.5%' }}>
                          <StoreItemsFive data={data} />
                        </div>
                      ))}
                    </div>
                    {/* <div className='cp-r-13 cp-l-13  d-flex flex-wrap'>
              {featuredItems.map((person: any) => (
                <div key={person.id} className='col-6 paddingsMobile' onClick={() => moveToDetailScreen(person)}>
                  <StoreItemsFive heigth={150} data={person} />
                </div>
              ))
              }
            </div> */}

                    <div className='mobiledisplay   d-flex flex-wrap'>
                      {more_to_like.map((data: any) => (
                        <div onClick={() => moveToDetailScreen(data)} className='col-6 paddingsMobile'>
                          <StoreItemsFive heigth={150} data={data} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : more_to_like.length == 0 && more_to_like.length != '' ? (
              <div>
              </div>
            ) : (
              <ShimmerC homeproducts='yes' />
            )}

            {productRating3.length >= 1 ? (
              <div>
                <div className=" cp-t-10 cp-b-10 cm-t-50 f-500 main-font" style={{ backgroundColor: '#e6e3e3' }} >
                  Ratings {"&"} Reviews of {producatdetail?.name}
                </div>
                <div className="d-flex flex-row bg-light" >
                  <div className="col-md-3 paddingOFmd3 bg-light cp-t-25 cp-b-25" >
                    <div className="d-flex flex-column" >
                      <label className="f-30 " > 4.6  <label className="f-12 " style={{ color: 'gray' }} >/5</label></label>
                      <Rating onClick={handleRating} size={40} ratingValue={4} />
                    </div>
                  </div>
                </div>
                <div className=" cp-t-10 cp-b-10 f-500 main-font border" style={{}} >
                  Product Reviews
                </div>
                {productRating3.map((data: any) =>
                  <div className=" cp-10 cp-t-24 main-font d-flex flex-column border-bottom" >
                    <Rating onClick={handleRating} size={20} ratingValue={data?.rating} />
                    <div className="d-flex justify-content-between" >
                      <label className="text-muted" >{data?.first_name} </label>
                      <label className="text-muted" >{data.updated_at.substring(0, 10)}</label>
                    </div>
                    <label className="" >{data.review}</label>
                  </div>
                )}
              </div>
            ) : null}

            {SeeAll == true &&
              <div className="d-flex flex-row-reverse" >
                <label onClick={() => {
                  setprouctratingLess(productRating3)
                  setproductRating3(productRating)
                  setSeeAll(false)
                }} className=" cp-5 border cm-t-5 touchAble" style={{ backgroundColor: 'rgb(218, 95, 115)', color: 'white', borderRadius: 5 }} >See Less Review</label>
              </div>
            }
            {(productRating3.length > 3 && SeeAll == false) &&
              <div className="d-flex flex-row-reverse" >
                <label onClick={() => {
                  setproductRating3(prouctratingLess)
                  setSeeAll(true)
                }} className=" cp-5 border cm-t-5 touchAble" style={{ backgroundColor: 'rgb(218, 95, 115)', color: 'white', borderRadius: 5 }} >See All Review</label>
              </div>
            }
          </div>
        </div>
      </div>
      <CFooter />
    </div>

  );
}

export default ProductDetail;
