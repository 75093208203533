import './Productitem.scss';
import { useState } from 'react';
import { globleAPI as Global } from '../globals'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useHistory } from 'react-router-dom'
import PopOver from './PopOver';
import Alertss from '../app-components/Alertss';
import axios from "axios";
import slugify from 'react-slugify';

function Productitem(props: any) {
  const [activePopOver, setactivePopOver] = useState(false)
  const [token] = useState(localStorage.getItem('token') || '')
  let Options = ['Delete', 'Edit']
  const [status, setstatus] = useState('')
  const [showAlert, setshowAlert] = useState(false)
  const history = useHistory();

  const moveToDetailScreen = (data: any) => {
    history.push('/product-detail/' + data.id + '/' + slugify(data.name))
  }

  const action = (data: any) => {
    setactivePopOver(!activePopOver)
    if (data == 'Delete') {
      deletproduct(props.DataofProduct.id)
    } else {
      history.push('/sale-product', [props.DataofProduct, 'edit']);
    }
    console.log(data, props.DataofProduct)
  }

  const deletproduct = (data: any) => {
    let formdata = new FormData();
    formdata.append("product_id", data);
    axios({
      method: "post",
      url: Global.DELETE_VENDOR_SIDE_PRODUCT + token,
      data: formdata,
    })
      .then(response => {
        window.location.reload()
        setstatus('Please login first to follow the store')
        setTimeout(() => {
          setshowAlert(false)
          setstatus('')
        }, 3000);
      })
      .catch(function (error) {
        console.log('groupscreate', error)
      });

  }

  return (
    <div   >

      <div>
        <div className='touchAble cm-t-1rem cm-b-1rem border-radius div-box-shadow '>
          {showAlert == true && <Alertss type='success' status={status} />}
          {props.edit == 'true' &&
            <div className="d-flex  flex-column " >
              <div className="d-flex justify-content-end">
                <HiOutlineDotsVertical onClick={() => setactivePopOver(!activePopOver)} size="23" />
              </div>
              <div className="d-flex justify-content-end" >
                <div className="d-flex justify-content-end position-absolute cm-10" style={{ backgroundColor: 'white' }} >
                  {activePopOver && <PopOver options={Options} action={action} />}
                </div>
              </div>
            </div>}
          {props.DataofProduct.status == '23' ? (
            <div className='product-img-container'>
              <img className='product-image' alt="img" src={Global.IMAGE_PATH + props.DataofProduct.image} ></img>
              <div className='off-contianerInReview   ' style={{ width: '95%' }} >
                <label className="cp-5 pText bg-dark text-center main-font " style={{ width: '100%', color: 'white', opacity: 0.6 }} >IN REVIEW</label>
              </div>
            </div>
          ) : (
            <div>
              <div style={{backgroundColor:'white',height:props.heigth||220,width:'100%',justifyContent:'center',alignItems:'center',display:'flex'}} >
                <img className='product-image' alt="img" src={Global.IMAGE_PATH + props.DataofProduct.image} ></img>
              </div>
              {props.DataofProduct.discount !== null && props.DataofProduct.discount !== 0 ? (
                <div className='off-contianer cm-r-15 cm-t-20'>
                  <label className="bg-color-primary page-color main-font pText cp-t-5 cp-b-1 cp-l-10 cp-r-10 " style={{}} > {props.DataofProduct.discount}% OFF</label>
                </div>
              ) : null}
              {props.DataofProduct.is_bundle === 1 ? (
                <div className='bundle-contianer cm-l-15 cm-t-16'>
                  <label className="bg-color-primary page-color main-font pText cp-t-4 cp-b-4 cp-l-10 cp-r-10 " >Bundle</label>
                </div>
              ) : null}
            </div>
          )}

          <div onClick={() => moveToDetailScreen(props.DataofProduct)} className="cp-r-5 cp-l-5" >
            <div className='labelText text-center primary-color main-font cp-t-5 text-truncate'  >
              {props.DataofProduct.name}
            </div>
            {props.DataofProduct.manufacturer_name === null ? (
              <div className='text-center labelText ' style={{ color: 'white' }}>
                f
              </div>
            ) : (
              <div className=' text-center main-font labelText text-truncate'>
                {props.DataofProduct.manufacturer_name}
              </div>
            )}
            {props.DataofProduct.discount === null || props.DataofProduct.discount === 0 ? (
              <div>
                <div className='  text-center main-font pText cp-b-5 text-muted'>
                  AED {props.DataofProduct?.unit_price?.toFixed(2)}
                </div>
                <div className=' text-center main-font pText cp-b-5' style={{ color: 'white' }}  >
                  .
                </div>
              </div>
            ) : (
              <div>
                <div className=' text-center main-font  pText primary-color'>
                  AED {props.DataofProduct.discounted_price?.toFixed(2)}
                </div>
                <div className='text-center main-font pText text-muted line-through cp-b-10'>
                  AED {props.DataofProduct.unit_price}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productitem;
