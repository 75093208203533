import React, { useEffect, useState, useRef } from 'react'
import CheaderHalf from '../../app-components/CheaderHalf'
import luxuryImage from '../../assets/luxury.png'
import retailImage from '../../assets/retail.png'
import comunnityImage from '../../assets/comunnity.png'
import banner from '../../assets/getTheApp.png'
import banner2 from '../../assets/banner.jpg'
import google from '../../assets/google-app (1).png'
import appstore from '../../assets/appstore.png'
import telephone from '../../assets/telephone.png'
import whatsapp from '../../assets/whatsapp.png'
import emaiddl from '../../assets/email.png'
import './Home.scss';
import axios from "axios";
import { globleAPI as Global } from '../../globals'
import { useHistory } from 'react-router-dom'
import CFooter from '../../app-components/CFooter'

export default function Home(props: any) {

    const [email, setemail] = useState('')
    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')
    const [NoStore, setNoStore] = useState<any>('')
    const [ShowThanksmsg, setShowThanksmsg] = useState<any>('')
    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const executeScroll = () => scrollToRef(myRef)
    const history = useHistory();

    var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    useEffect(() => {
        localStorage.removeItem('SelectedOption');
        getMyStoreDetail()

        if (props.location.state == 'gettheApp') {
            executeScroll()
        } else {
            window.scrollTo(0, 0)
        }

    }, [])

    const getMyStoreDetail = () => {
        if (token) {
            axios
                .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
                .then(response => {
                    if (response.data.store_details[0].store_address == undefined) {
                        setNoStore('yes')
                    } else {
                        setNoStore('no')
                    }
                })
                .catch(function (error) {
                    console.log('response if the api is errror =', error)
                });
        } else {
            setNoStore('yes')
        }

    }
    const MoveToLuxury = () => {
        history.push('/luxury')
        localStorage.setItem('SelectedOption', 'Luxury');
    }
    const NewsLetter = () => {
        if (mailformat.test(email)) {
            axios
                .post(Global.NewsLetter + '?email=' + email)
                .then(response => {
                    if (response.data.status == 200) {
                        setShowThanksmsg('Thank you for Subscribing')
                        setTimeout(() => {
                            setShowThanksmsg('')
                        }, 10000);
                    } else {
                        setShowThanksmsg('Please try again latter')
                        setTimeout(() => {
                            setShowThanksmsg('')
                        }, 10000);
                    }
                })
                .catch(function (error) {
                    alert(error)
                });
        } else {
            setShowThanksmsg('Please add a valid email address')
            setTimeout(() => {
                setShowThanksmsg('')
            }, 10000);
        }
    }
    const gettheApp = (data: any) => {
        if (data == 'get the app') {
            executeScroll()
        }
    }

    return (
        <div>
            <CheaderHalf methode={(data: any) => gettheApp(data)} />
            <div className='mainPagePaddings' style={{}} >
                <div className='mainOption' style={{}} >
                    <div className='col-md-6 col-12 backgrounImg' style={{ background: `url(${luxuryImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <label className='touchAble main-font' style={{ color: 'white', fontSize: '50px', fontWeight: 300, paddingBottom: 5 }} >Preloved</label>
                            <label className='touchAble main-font' style={{ color: 'white', fontSize: '70px', lineHeight: 0.5, fontWeight: 600 }} >LUXURY</label>
                            <label className='touchAble italic-font' style={{ color: 'white', fontSize: '60px', lineHeight: 1.3, fontWeight: 400, fontStyle: 'italic' }} >Items</label>
                            <label onClick={() => MoveToLuxury()} className='touchAble main-font shopNow' >SHOP NOW</label>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 backgrounImg' style={{ background: `url(${retailImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <label className='touchAble' style={{ color: 'transparent', fontSize: '50px', fontWeight: 300, paddingBottom: 5 }} >dddd</label>
                            <label className='touchAble main-font' style={{ color: 'white', fontSize: '70px', lineHeight: 0.5, fontWeight: 600 }} >RETAIL</label>
                            <label className='touchAble italic-font' style={{ color: 'white', fontSize: '60px', lineHeight: 1.3, fontWeight: 400, fontStyle: 'italic' }} >Brands</label>
                            <label onClick={() => history.push('/retail')} className='touchAble main-font shopNow'  >SHOP NOW</label>
                        </div>
                    </div>
                </div>
                <div className='comunityDiv' style={{}} >
                    <div className='comunityImg' style={{ background: `url(${comunnityImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 35 }} >
                            <label className='main-font comunityText' style={{}} >JOIN OUR COMMUNITY</label>
                            <label className='italic-font comuntiyDetailText' style={{}} >Sell your pre-owned item or Sell as a retail</label>
                            {token !== '' ? (
                                <div onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} style={{}} >
                                    <label className='touchAble hoverUnderLine main-font sellWithus'  >SELL WITH US</label>
                                </div>
                            ) : (
                                <div onClick={() => history.push('./login')} style={{}} >
                                    <label className='touchAble hoverUnderLine main-font sellWithus'  >SELL WITH US</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div ref={myRef} className='webdisplay' style={{ display: 'flex', alignItems: 'center', background: `url(${banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '65vh', width: '100%', backgroundColor: 'white' }} >
                    <div className='col-md-6' style={{ marginBottom: 60, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                        <label className='label' style={{ color: '#7F1D36', fontSize: '45px', fontWeight: 700, lineHeight: 1, }} >GET 15% OFF</label>
                        <label className='label ' style={{ color: 'black', fontSize: '28px', textAlign: 'center', lineHeight: 1.3 }} >on your First App Order <br /> Enter code <label className='' style={{ color: '#7F1D36', fontSize: '28px', fontWeight: 700, }}>LFAPP15</label>  at checkout</label>
                        <div style={{ marginTop: 25 }}>
                            <a href="https://play.google.com/store/apps/details?id=com.lesfemmes&hl=en" target="_blank" >  <img className="touchAble" alt='img' height="60rem" src={google} ></img></a>
                            <a href="https://apps.apple.com/pk/app/lesfemmes/id1396175773" target="_blank">  <img className="touchAble" alt='img' height="60rem" src={appstore} ></img></a>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'red' }} >
                    </div>
                </div>
                <div className='mobiledisplay' style={{ paddingTop: '15px' }} >
                    <img alt='img' width={'100%'} src={banner2} ></img>
                </div>
                <div className='joinNewsLetterBox' style={{}} >
                    <div className='col-md-6' style={{ display: 'flex', paddingLeft: '10%' }} >
                        <div className='cp-t-2rem  cp-b-2rem '>
                            <div className='secHeading main-font'>
                                Join Our NewsLetter
                            </div>
                            <div className='cp-b-1rem main-font'>
                                Sign up for our newsletter and don't miss a thing!
                            </div>
                            <input style={{ backgroundColor: 'white' }} id="email" onChange={(text: any) => setemail(text.target.value)} className='subscriptinput' placeholder='Enter your email address'></input>
                            <span onClick={() => NewsLetter()} className='sub-btn capitalize font-weight-bold' style={{}}>Subscribe</span>
                            <div className='cp-b-1rem main-font' style={{ color: '#7F1D36' }}>
                                {ShowThanksmsg}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 needHelp' >
                        <label className='secHeading' style={{ marginLeft: 40 }} >NEED HELP?</label>
                        <div className='' style={{ display: 'flex' }} >
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginRight: 20, alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="tel:+971588241922"> <div className='touchAble' style={{ backgroundColor: '#5C5C5C', padding: 15, borderRadius: 10 }} >
                                    <img alt='img' height="42rem" src={telephone} ></img>
                                </div></a>
                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="tel:+971588241922"> CALL US</a>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginRight: 20, alignContent: 'center', display: 'flex', flexDirection: 'column' }} >
                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0"> <div className='touchAbl' style={{ backgroundColor: '#5C5C5C', padding: 15, borderRadius: 10 }} >
                                    <img alt='img' height="42rem" src={whatsapp} ></img>
                                </div></a>
                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971588241922&text&app_absent=0">WHATSAPP</a>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }} >

                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"> <div className='touchAble' style={{ backgroundColor: '#5C5C5C', padding: 15, borderRadius: 10 }} >
                                    <img alt='img' height="42rem" src={emaiddl} ></img>
                                </div></a>
                                <a className='f-12' style={{ borderRadius: 100, color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"> EMAIL</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    )
}