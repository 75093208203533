import './Categoryitem.scss';
import {globleAPI as Global} from '../globals'
import { useHistory } from 'react-router-dom'
function Categoryitem(props: any) {

  const history = useHistory();

  const checkFunction = (data:any) =>{
          props.movetoAllProducts(data)
}
  const movingWithBrands = (data:any,data2:any) =>{
    if(props.retail){
      history.push('/StoreDetail', [data2.id] )
    }else{

      history.push('/luxury-products', [data, data2.brand]);
    }
}


  return (
    <div>
      {props.type === 'largecategory' ? (
        <div  className=' touchAble cm-1rem '>
          <img className='category-item-image border-radius' alt="img" src={Global.IMAGE_PATH+props.DataofProduct.image} ></img>
          <div className='cp-t-1rem secHeading  text-center primary-color'>
            {props.DataofProduct.name}
          </div>
        </div>
      ) : (
        <div  className=' cm-t-2rem touchAble d-flex flex-row flex-wrap justify-content-center' >
          {props.Dataof.map((person: any) => (
            <div  key={person.id} className=' cp-l-1rem cp-r-1rem display-flex col-flex content-center align-item-center'>
              <div style={{}}>
                {props.brands === true?(
                  <img onClick={()=>movingWithBrands('BrandsProducts', person)} className='imgSize'style={{borderRadius:100,borderColor:props.border ? '#D05F73':'transparent',borderWidth:'0.1px',borderStyle:'solid'}} alt="img" src={Global.IMAGE_PATH+person.image} ></img>
                ):(
                  <img onClick={()=>checkFunction(person)} className='category-image' alt="img" src={'https://lesfemmestesting.dominioninc.org/public/uploads/shortcuts/' + person.picture} ></img>
                ) }
              </div>
              <div onClick={()=>checkFunction(person)} className=' text-center main-font pText category-text'>
                {person.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>

  );
}
export default Categoryitem;
