
import './Category.scss'
export default function Category(props:any) {

  let text1 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("@") + 1,
       props?.dataofCategory?.name.lastIndexOf("#")
     );
     let text2 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("-") + 1,
       props?.dataofCategory?.name.lastIndexOf("/")
     );
     let text3 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("^") + 1,
       props?.dataofCategory?.name.lastIndexOf("&")
     );
     let text4 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("+") + 1,
       props?.dataofCategory?.name.lastIndexOf("*")
     );
     let text5 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("$") + 1,
       props?.dataofCategory?.name.lastIndexOf("=")
     );
     let text6 = props?.dataofCategory?.name.substring(
       props?.dataofCategory?.name.lastIndexOf("<") + 1,
       props?.dataofCategory?.name.lastIndexOf(">")
     );

  return (
     <div className=''  style={{justifyContent:'center',display:'flex',alignContent:'center',alignSelf:'center',alignItems:'center',background: `url(${ 'https://dominioninc.blob.core.windows.net/lesfemmes/shortcuts/' + props?.dataofCategory?.picture})`,backgroundRepeat: 'no-repeat', backgroundSize:'cover',height:props?.imageheight,width:'100%' }} >
    <div  style={{display:'flex',flexDirection:'column',alignItems:'center'}} >
    {text4 !== undefined &&
               <label className='touchAble main-font outline font4'>{text4}</label>
        }
        {(text1 !== undefined && text1 == 'DAILY') &&
        <label className='touchAble main-font outline text1D'  >{text1}</label>
        }
        {(text1 !== undefined && text1 !== 'DAILY') &&
        <label className='touchAble main-font outline text1' >{text1}</label>
        }
        {text6 !== undefined &&
               <label className='touchAble main-font outline text6' >{text6}</label>
        }
         {text5 !== undefined &&
               <label  className='touchAble main-font outline text5 ' >{text5}</label>
        }
        {(text2 !== undefined && text2 == 'Accessories') ?(

          <label className='touchAble main-font outline textAccesory' >{text2}</label>
          ):(
            <label className='touchAble main-font outline text2' >{text2}</label>
          )
        }
        {text3 !== undefined &&
               <label className='touchAble main-font outline text3' >{text3}</label>
        }
        {(props.text7 !== undefined && props.text7 !== 'Accessories') &&
 <label className='touchAble main-font cp-b-60 uppercase  outline text7' >{props.text7}</label>
        }
        {(props.text7 !== undefined && props.text7 == 'Accessories')&&

<label className='touchAble main-font outline textAccesory cp-b-60' >{props.text7}</label>
}
    </div>
        </div>
  );
}
