import './CHeader.scss';
import { AiOutlineSearch, AiOutlineShoppingCart,AiOutlineBell } from "react-icons/ai";
import logo from '../assets/clogo.png';
import Girls from '../assets/lshopall1.jpg';
import shoppall2 from '../assets/lshopall2.jpg';
import sale1 from '../assets/sale1L.jpg';
import sale2 from '../assets/sale2L.jpg';
import sale3 from '../assets/sale3L.jpg';
import clothing1 from '../assets/clothL1.jpg';
import clothing2 from '../assets/clothL2.jpg';
import shoes from '../assets/Shoes lux1.jpg';
import bags1 from '../assets/bagL1.jpg';
import bags2 from '../assets/bagL2.jpeg';
import kids from '../assets/kidsL.jpg';
import jewl1 from '../assets/jewlL1.jpg';
import jewl2 from '../assets/jewlL2.jpg';
import brands1 from '../assets/brands1L.jpg';
import brands2 from '../assets/brands2L.jpg';
import { Link, useHistory } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react';
import { HiMenu } from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { globleAPI as Global } from '../globals'
import girl from '../assets/emojiwindle.png';
import UserOptionsPopOver from './UserOptionsPopOver';
import NotificationSystem from './NotificationSystem';

function HeaderForLuxury(props: any) {
  const [userid] = useState(localStorage.getItem('userid') || '')
  const [notificationData, setnotificationData] = useState([]);
  const [menu, setmenu] = useState(false);
  const [notification, setnotification] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [SugestionBox, setSugestionBox] = useState(false);
  const [NoStore, setNoStore] = useState('');
  const [DropdownHeading, setDropdownHeading] = useState('');
  const [SearchedValue, setSearchedValue] = useState('');
  const [DropDownData, setDropDownData] = useState<any>([]);
  const [SuggestionData, setSuggestionData] = useState<any>([]);
  const [token] = useState(localStorage.getItem('token') || '')
  const [imageProfile] = useState(localStorage.getItem('imageProfile') || '')
  const [show, setShow] = useState(false);
  const [SubCategoriesClothes, setSubCategoriesClothes] = useState([]);
  const [categoryid, setcategoryid] = useState<any>();
  const [SelectedBrands, setSelectedBrands] = useState([]);
  const [SubCategoriesShoes, setSubCategoriesShoes] = useState([]);
  const [SubCategoriesBags, setSubCategoriesBags] = useState([]);
  const [SubCategoriesJew, setSubCategoriesJew] = useState([]);
  const [SubCategoriesKids, setSubCategoriesKids] = useState([]);
  const [target, setTarget] = useState(null);
  const [Count, setCount] = useState(0);
  const [ItemonSAle, setItemonSAle] = React.useState<any>([]);
  const ref = useRef(null);
  const [cart, setcart] = useState('')
  const history = useHistory();
  let categories = [
    {
      "id": 30,
      "name": "Luxury Bags",
      "is_luxury": 1,
      "count": 17
    },
    {
      "id": 31,
      "name": "Luxury Jewellery",
      "is_luxury": 1,
      "count": 14
    },
    {
      "id": 32,
      "name": "Luxury Clothing",
      "is_luxury": 1,
      "count": 7
    },
    {
      "id": 33,
      "name": "Luxury Shoes",
      "is_luxury": 1,
      "count": 11
    },
    {
      "id": 34,
      "name": "Luxury Watches",
      "is_luxury": 1,
      "count": 1
    },
    {
      "id": 35,
      "name": "Luxury Accessories",
      "is_luxury": 1,
      "count": 10
    },
    {
      "id": 202,
      "name": "Luxury Beauty",
      "is_luxury": 1,
      "count": 10
    },
    {
      "id": 203,
      "name": "Luxury Kids",
      "is_luxury": 1,
      "count": 10
    }
  ]
  useEffect(() => {
    getCartCount()
    GetSaleFilters()
    getMyStoreDetail()
    getsubCategoryClothes()
    getsubCategoryShoes()
    getsubCategoryBags()
    getsubCategoryJew()
    getsubCategoryKids()
    getSelectedBrands()
    getAllNotification()
  }, [])

  const getAllNotification = () => {
    if (token) {
      axios
        .post(Global.showAllNotification + token)
        .then(response => {
          setnotificationData(response.data.notification_data)
          let data= response.data.notification_data
          data.map((data:any)=> data.status == 3? setCount(Count => Count+1):null)
        })
        .catch(function (error) {
          console.log('notifications', error)
        });
    } else {
      setNoStore('yes')
    }

  }

  const getSelectedBrands = () => {
    axios
      .get(Global.getSelectedBrands + "?is_luxury=1")
      .then(response => {
        console.log('responseresponseresponse',response)
        setSelectedBrands(response.data.brands)
      })
      .catch(function (error) {
        console.log('responseresponseresponse',error)
        console.log('dafadfasdfsdf', error)
      });
  }

  const getsubCategoryClothes = () => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=32")
      .then(response => {
        setSubCategoriesClothes(response.data.subcategories)
      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getsubCategoryShoes = () => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=33")
      .then(response => {
        setSubCategoriesShoes(response.data.subcategories)
      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getsubCategoryBags = () => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=30")
      .then(response => {
        setSubCategoriesBags(response.data.subcategories)
      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getsubCategoryJew = () => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=31")
      .then(response => {
        setSubCategoriesJew(response.data.subcategories)
      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getsubCategoryKids = () => {
    axios
      .get(Global.GET_ALL_SUB_CATEGORIES + "?parent_category=125")
      .then(response => {
        setSubCategoriesKids(response.data.subcategories)
      })
      .catch(function (error) {
        console.log('responnnse of cate', error)
      });
  }

  const getMyStoreDetail = () => {
    axios
      .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
      .then(response => {
        if (response.data.store_details[0].store_address == undefined) {
          setNoStore('yes')
        } else {
          setNoStore('no')
        }
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  const GetSaleFilters = () => {

    axios
      .get(Global.GetSaleFilters)
      .then(response => {
        setItemonSAle(response.data.store_filters)
      })
      .catch(function (error) {
        console.log('response of the api ', error)
      });
  }

  const getCartCount = () => {
    axios
      .get(Global.cartCount + token)
      .then(response => {
        setcart(response.data.cart_count)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }

  if (props.cartcheck === true) {
    getCartCount()
  }

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  const mobileMenu = (event: any) => {
    setmenu(!menu);
    setTarget(event.target);
  };

  const move = (data: any) => {
    if (data === 'home') {
      history.push('/', [data])
    } else if (data === 'cart') {
      history.push('/cart')
    }

  };

  const showDropDown = (data: any) => {
    if (data === 'shopall') {
      setDropDownData(categories)
      setIsShown(true)
      setDropdownHeading('SHOP ALL')
    } else if (data === 'Luxury Brands') {
      setDropDownData(SelectedBrands)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Sale') {
      setDropDownData(categories)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Clothing') {
      setcategoryid(32)
      setDropDownData(SubCategoriesClothes)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Shoes') {
      setcategoryid(33)
      setDropDownData(SubCategoriesShoes)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Jewellery') {
      setcategoryid(31)
      setDropDownData(SubCategoriesJew)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Kids') {
      setcategoryid(203)
      setDropDownData(SubCategoriesKids)
      setIsShown(true)
      setDropdownHeading(data)
    } else if (data === 'Bags') {
      setcategoryid(30)
      setDropDownData(SubCategoriesBags)
      setIsShown(true)
      setDropdownHeading(data)
    }
  };

  const dropDownClose = () => {
    setDropDownData('')
    setIsShown(false)
    setDropdownHeading('')
  };

  const moving = (data: any, data2: any) => {
    history.push('/luxury-products', [data, data2]);
    dropDownClose()
  };

  const movingWithSaleAll = (data: any, data2: any) => {
    history.push('/luxury-products', [data, data2]);
    dropDownClose()
  };

  const saleOnAllCategory = () => {
    history.push('/luxury-products', ['SaleOnAllProducts']);
    dropDownClose()
  };

  const movingWithSale = (data: any, data2: any) => {
    history.push('/luxury-products', [data, data2]);
    dropDownClose()
  };

  const searchedDataState = () => {
    if (SearchedValue === '') {
      alert('please add keyword')
    } else {
      history.push('/luxury-products', ['searchData', SearchedValue]);
    }
  };

  const searchedData = (data: any) => {
    history.push('/luxury-products', ['searchData', data]);
  };

  const itemonsale = (data: any) => {
    if (data.name == 'See All') {
      history.push('/luxury-products', ['usedItems']);
    } else {
      history.push('/luxury-products', ['AllSalePercentage', data.discount]);
    }
  };

  const suggestion = (searchedKeyword: any) => {
    if (searchedKeyword == '') {
      setSuggestionData('')
      setSugestionBox(false)
      setSearchedValue('')
    } else {
      setSearchedValue(searchedKeyword)
      window.scrollTo(0, 0)
      axios
        .post(Global.WebSearchFromAllProducts + "?search_keyword=" + searchedKeyword + "&is_luxury=1",)
        .then(response => {
          console.log('response of the api ', response)
          let Allsuggestion = response.data.AllProducts.data.filter((data: any, id: any) => id < 5)
          setSuggestionData(Allsuggestion)
          setSugestionBox(true)
        })
        .catch(function (error) {
          console.log('response of the api ', error)
        });
    }
  };

  const SubCategoryProducts = (data: any, data2: any, data3: any) => {
    history.push('/luxury-products', [data, data2, data3]);
  }

  const goDown = () => {
    if (window.location.pathname == '/') {
      props.methode('get the app')
      setTimeout(() => {
        props.methode('')
      }, 1);
    } else {
      history.push('/', ['gettheApp']);
      setTimeout(() => {
        history.push('/', ['']);
      }, 1);
    }
  };

  const changereadstatus = (data: any) => {

    axios.post(Global.NOTIFICATION_STATUS + token +"&notification_id="+data)
      .then((response) => {
        getAllNotification()    
      }, (error) => {
        alert(error)
      });
}


  return (
    <div>
      <div className='webdisplay' style={{ height: '55px', backgroundColor: 'rgba(115, 47, 62, 0.24)', display: 'flex', alignItems: 'center', alignSelf: 'center' }} >
        <div className=' col-md-12' style={{ display: 'flex', justifyContent: 'center' }} >
          <div className='main-font' style={{ paddingRight: 20, fontSize: 14 }} >
            AUTHENTICATION GUARANTEED
          </div>
          <div className='main-font' style={{ paddingRight: 20, fontSize: 14 }} >
            <li>
              WORLDWIDE DELIVERY & EASY RETURNS
            </li>
          </div>
          <div className='main-font' style={{ paddingRight: 20, fontSize: 14 }} >
            <li>
              AFFORDABLE LUXURY ITEMS
            </li>
          </div>
        </div>
      </div>
      <div className='mobiledisplay col-12' >
        <div className='f-30 d-flex flex-row' style={{ width: '100%', backgroundColor: 'white', height: '75px', justifyContent: 'space-between', alignItems: 'center' }}>
          {userid!== ''?(
            <div className="pointer material-icons " onClick={handleClick}>
            <HiMenu size="2.1rem" color={'#732F3E'} />
          </div>
          ):(
            <div className="pointer material-icons " onClick={mobileMenu}>
            <HiMenu size="2.1rem" color={'#732F3E'} />
          </div>
          )}
       <div>
            <img className="touchAble" onClick={() => move('home')} alt='img' height="50rem" src={logo} ></img>
          </div>
          <div onClick={() => move('cart')} className="pointer  material-icons">
            <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: 'rgb(197, 77, 97)',fontSize:14 }} className=' cp-l-6 cp-r-6 ' >{cart}</p>
            <AiOutlineShoppingCart size="2rem" color={'#732F3E'} />
          </div>
        </div>
      </div>
      {menu &&
           <UserOptionsPopOver show={menu} target={target} />
      }

      <div className='col-12 webdisplay' >
        <nav className="navbar navbar-expand" >
          <div className="container-fluid ">
            <div className='col-4'>
              <div className='' style={{ width: '85%' }}  >
                <div className=' primary-color c-border ' style={{ paddingLeft: 10, borderWidth: 2, borderRadius: 10, borderColor: '#C4C4C4' }}>
                  <AiOutlineSearch size='1.8rem' color='#732F3E' onClick={() => searchedDataState()} />
                  <input className='c-searchfield pText f-14 mian-font ' onChange={(text: any) => suggestion(text.target.value)} placeholder='Search by brand, item, name...' style={{ padding: 15, width: '18vw' }}></input>
                </div>
                {SugestionBox === true ? (
                  <div className=" d-flex flex-column position-absolute  cp-l-50 cp-r-50 cp-b-10 cm-l-25  " style={{ marginTop: '4%', zIndex: 1, backgroundColor: 'white', width: '70%' }} >
                    {SuggestionData.map((data: any) =>
                      <label className='touchAble' onClick={() => searchedData(data.name)} >{data.name}</label>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-4' >
              <div className='webdisplay  main-font primary-color  ' style={{ display: 'flex', justifyContent: 'center' }}>
                <img className="touchAble" onClick={() => move('home')} width="300px" alt='img' src={logo} ></img>
              </div>
            </div>
            <div className='col-4' style={{ display: 'flex', flexDirection: 'row-reverse' }} >
              <div style={{ display: 'flex' }} className=' webdisplay'>
                <div onClick={() => goDown()} style={{ height: 35, borderRadius: 10 }} className='pointer cm-t-5  cm-r-1rem  bg-color-secondary page-color  main-font f-200 border-radius cp-l-15 cp-r-15 d-flex align-item-center'>
                  <label className="f-12 label touchAble hoverUnderLine" >
                    GET THE APP
                  </label>
                </div>
                <div style={{ height: 35, borderRadius: 10 }} className='pointer cm-t-5  cm-r-1rem  bg-color-secondary page-color  main-font f-200 border-radius cp-l-15 cp-r-15 d-flex align-item-center'>
                {token !== '' ? (
                    <label className="f-12 label touchAble hoverUnderLine" onClick={() => history.push(NoStore == 'yes' ? "/edit_store" : "/sale-product")} style={{}} >
                    SELL
                    </label>
                  ) : (
                    <Link to="/login" className="f-12 label touchAble hoverUnderLine"  style={{ color: 'white', border: 0 }} >SELL</Link>
                  )}
                </div>
                <div >
                  {userid !== '' ? (
                    <div className='d-flex'>
                      <img alt="img" className="border" style={{ height: 40, width: 40, borderRadius: 100, marginRight: 2 }} src={imageProfile !== 'null' ? Global.IMAGE_PATH + imageProfile : girl}></img>
                      <div className="cp-r-10 d-flex align-self-center" >
                        <FiChevronDown onClick={handleClick} className='touchAble' size='1.6rem' />
                      </div>
                      <UserOptionsPopOver show={show} target={target} />
                    </div>
                  ) : (
                    <div className="d-flex flex-row" >
                    <div className='cp-t-9  cp-l-8 cp-r-8 f-15 main-font label'>
                      <Link to="/signup" className="link" style={{ color: 'rgb(27, 27, 27)' }} >Sign Up </Link>
                    </div>
                    <div className='cp-t-9  cp-l-8 cp-r-8 f-15 main-font label'>
                      <Link to="/login" className="link" style={{ color: 'rgb(27, 27, 27)', border: 0 }} > Login</Link>
                    </div>
                  </div>
                  )}
                </div>
                <div onClick={() => setnotification(!notification)} className="pointer  cp-t-5 material-icons primary-color cp-r-8 cp-l-8">
                  <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: '#732F3E', fontSize: 11 }} className=' cp-l-4 f-13 cp-r-4 ' >{notificationData?.length > 0 && Count}</p>
                  <AiOutlineBell color={'#732F3E'} size="1.9rem" />
                </div>
                <div onClick={() => move('cart')} className="pointer  cp-t-5 material-icons primary-color">
                  <p style={{ position: 'absolute', borderColor: 'black', borderRadius: 100, color: 'white', backgroundColor: '#732F3E', fontSize: 11 }} className=' cp-l-4 f-13 cp-r-4 ' >{cart}</p>
                  <AiOutlineShoppingCart color={'#732F3E'} size="1.9rem" />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {(notificationData.length >= 1 && notification) &&
 <NotificationSystem data={notificationData} methode={(data:any)=>changereadstatus(data)} />
      }
      <div onMouseEnter={() => dropDownClose()} className=' Sansation-Regular  cp-3 page-color text-center ' style={{ backgroundColor: '#732F3E', height: '65px', justifyContent: 'center', display: 'flex', alignItems: 'center' }} >
        <label className='label freeSHipingText main-font ' style={{ fontStretch: 'ultra-expanded', letterSpacing: 4 }} > FREE SHIPPING FOR ORDERS ABOVE 100 AED</label>
      </div>
      <div className='webdisplay display-flex justify-content-between padding cp-t-15 cp-b-12 label' >
        <div onMouseEnter={() => dropDownClose()} onClick={() => move('home')} className=' main-font  pointer f-14  text-color'  >
          <label className='d  main-font label  touchAble label' >Home</label>
        </div>
        <div className=' pointer f-14  text-color myDIV' style={{ color: DropdownHeading === 'SHOP ALL' ? 'rgb(218, 95, 115)' : 'black' }} onMouseEnter={() => showDropDown('shopall')} >
          <label className='   main-font label touchAble' style={{}} > Shop All</label>
        </div>
        <div className='  pointer f-14 text-color    dropdown' >
          <div className=' pointer f-14 text-color myDIV' style={{ color: DropdownHeading === 'Sale' ? 'rgb(218, 95, 115)' : 'black' }} onMouseEnter={() => showDropDown('Sale')} >
            <label className='  main-font label  touchAble ' style={{}} >Sale</label>
          </div>
        </div>
        <div className=' pointer f-14 text-color    dropdown' >
          <div className=' pointer f-14 text-color myDIV' style={{ color: DropdownHeading === 'Luxury Brands' ? 'rgb(218, 95, 115)' : 'black' }} onMouseEnter={() => showDropDown('Luxury Brands')} >
            <label className='  main-font label  touchAble ' style={{}} >All Brands</label>
          </div>
        </div>
        <div className=' pointer f-14 text-color    dropdown' >
          <div className=' pointer f-14 text-color myDIV' style={{ color: 'black' }}  >
            <label onClick={() => moving('categoryProducts', 32)} onMouseEnter={() => showDropDown('Clothing')} className='  main-font label hoverUnderLine  touchAble ' style={{}} >Clothing </label>
          </div>
        </div>
        <div className='main-font label   pointer f-14 text-color'>
          <label onClick={() => moving('categoryProducts', 30)} onMouseEnter={() => showDropDown('Bags')} className='  main-font touchAble hoverUnderLine label'  >Bags </label>
        </div>
        <div className=' main-font  pointer f-14 text-color    '>
          <label onClick={() => moving('categoryProducts', 33)} onMouseEnter={() => showDropDown('Shoes')} className='  main-font hoverUnderLine touchAble  label' > Shoes </label>
        </div>
        <div onClick={() => moving('categoryProducts', 31)} onMouseEnter={() => showDropDown('Jewellery')} className=' main-font  pointer f-14 text-color'>
          <label className='  main-font hoverUnderLine touchAble label' > Jewellery </label>
        </div>
        <div onClick={() => moving('categoryProducts', 125)} onMouseEnter={() => showDropDown('Kids')} className=' main-font  pointer f-14 text-color    '>
          <label className='  main-font hoverUnderLine touchAble label' > Kids</label>
        </div>
        <div className=' main-font  pointer f-14 text-color    '>
          {token !== '' ? (
            <Link onMouseEnter={() => dropDownClose()} to={NoStore == 'yes' ? "/edit_store" : "/sale-product"} className='  main-font touchAble label' style={{ color: 'black', border: 0 }} >SELL NOW</Link>
          ) : (
            <Link to="/login" onMouseEnter={() => dropDownClose()} className='  main-font touchAble label' style={{ color: 'black', border: 0 }} >SELL NOW</Link>
          )}
        </div>
        <div onClick={() => saleOnAllCategory()} className=' main-font  pointer f-14 text-color    '>
          <label className='  main-font hoverUnderLine touchAble label' >Pre-Owned</label>
        </div>
      </div>
      {isShown && (
        <div className="padding border-top border-danger cp-b-5" style={{ zIndex: 1, backgroundColor: 'white', }} onMouseLeave={() => dropDownClose()} >
          <div className="  d-flex flex-column" style={{flexWrap: 'wrap', maxHeight: '60vh' }} >
            {DropdownHeading === 'Sale' ? (
              <div>
                <div className="d-flex flex-row  justify-content-md-start"  >
                  <div className="col-md-2  col d-flex flex-row"  >
                    <div className="col-12  col"  >
                      <label className=" labelHeading  text-capitalize cp-t-20 main-font" style={{ color: '#ababab' }} >SALE</label><br></br>
                      <label onClick={() => moving('SaleOnAllProducts', '')} className=" label pText hoverColor touchAble main-font" style={{ flexWrap: 'wrap' }}   >All Sale Top Brands</label>
                      {DropDownData.map((data: any) =>
                        <div className="flex-wrap " style={{ flexWrap: 'wrap' }} >
                          <label onClick={() => movingWithSaleAll('categoryProductsWithSale', data.id)} className=" label pText hoverColor touchAble main-font" style={{ flexWrap: 'wrap' }}  >{data?.label}{data?.name}</label>
                        </div>
                      )}
                    </div>
                
                  </div>
                  <div className="col-md-3" >
                    <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab' }} >PERCENTAGE</label>
                    <div className="flex-wrap d-flex flex-column " style={{ flexWrap: 'wrap' }} >
                      {ItemonSAle.length >= 1 &&
                        <div className="d-flex flex-column" >
                          {ItemonSAle.map((data: any) =>
                            <label onClick={() => itemonsale(data)} className=" label pText hoverColor touchAble main-font" style={{ flexWrap: 'wrap' }}  >{data.name}</label>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col col-md-7' style={{justifyContent:'end',display:'flex'}}  >
                    <div>
                    <div style={{ display: 'flex' }} >
                      
                      <img style={{ height: '210px', paddingTop: 20, paddingRight: 10 }} onClick={() => move('home')} alt='img' src={sale1} ></img>
                      <img style={{ height: '210px', paddingTop: 20, paddingRight: 10 }} onClick={() => move('home')} alt='img' src={sale2} ></img>
                      <img style={{ height: '210px', paddingTop: 20 }} onClick={() => move('home')} alt='img' src={sale3} ></img>
                    </div>
                    <label className="label pText  flex-wrap main-font cp-t-15" style={{ flexWrap: 'wrap', fontWeight: 600 }}  >Don't miss out on an extra 15% off on your first order.Code: <label style={{ color: '#732F3E' }} >LFAPP15</label></label>
                    <div className='  d-flex touchAble cp-t-6 cp-b-6 cp-l-10 cp-r-10 cm-b-10 ' style={{ backgroundColor: 'black', width: 150, justifyContent: 'center' }}>
                      <label onClick={() => saleOnAllCategory()} className="main-font f-14  label touchAble hoverUnderLine" style={{ color: 'white' }} >SHOP NOW</label>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : DropdownHeading === 'Luxury Brands' ? (
              <div className=" d-flex "  >
                <div className=" d-flex flex-column col-md-7 col ">
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >EDITOR'S PICK</label>
                  <div className="d-flex flex-column " style={{ maxHeight: '40vh', flexWrap: 'wrap', }} >
                    {DropDownData.map((data: any) =>
                      <div onClick={() => data.brand == 'All Brands' ? moving('allLuxuryProducts', data.id) : movingWithSale('BrandsProducts', data.brand)}    >
                        <label className=" label pText  hoverColor touchAble flex-wrap main-font " style={{ flexWrap: 'wrap' }}  >{data?.brand}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-5  col'  style={{justifyContent:'end',display:'flex'}}>
                  <div style={{ display: 'flex', paddingBottom: 30 }} >
                    <img style={{ height: '240px', paddingTop: 20, paddingRight: 12 }} onClick={() => move('home')} alt='img' src={brands1} ></img>
                    <img style={{ height: '240px', paddingTop: 20 }} onClick={() => move('home')} alt='img' src={brands2} ></img>
                  </div>
                </div>
              </div>
            ) : DropdownHeading === 'SHOP ALL' ? (
              <div style={{ display: 'flex' }} >
                <div className='col-md-4' >
                  <div style={{display:'flex',flexDirection:'column'}} >
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  <label onClick={()=>moving('allLuxuryProducts','')} className="label pText  hoverColor touchAble flex-wrap main-font" style={{ flexWrap: 'wrap' }}  >See All</label>
                  </div>
                  {DropDownData.map((data: any) =>
                    <div className=" " style={{ flexWrap: 'wrap', display: 'flex' }} onClick={() => moving('categoryProducts', data.id)} >
                      <label className="label pText  hoverColor touchAble flex-wrap main-font" style={{ flexWrap: 'wrap' }}  >{data?.name}{data?.label}</label>
                    </div>
                  )}
                </div>
                <div className='col col-md-8' style={{justifyContent:'end',display:'flex'}}  >
                  <div style={{ display: 'flex', paddingBottom: 30 }} >
                    <img style={{ height: '270px', paddingTop: 20, paddingRight: 12 }} onClick={() => move('home')} alt='img' src={Girls} ></img>
                    <img style={{ height: '270px', paddingTop: 20 }} onClick={() => move('home')} alt='img' src={shoppall2} ></img>
                  </div>
                </div>

              </div>
            ) : (DropdownHeading === 'Clothing') ? (
              <div className=" d-flex ">
                <div className=" d-flex flex-column col-md-6 col ">
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  <div className="d-flex flex-column " style={{ maxHeight: '40vh', flexWrap: 'wrap', }} >
                    {DropDownData.map((data: any) =>
                      <div onClick={() => SubCategoryProducts('subCategoryProducts', categoryid, data.id)}    >
                        <label className=" label pText  hoverColor touchAble flex-wrap main-font " style={{ flexWrap: 'wrap' }}  >{data?.name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col col-md-6' style={{justifyContent:'end',display:'flex'}}  >
                <img style={{ height: '270px', paddingTop: 25, paddingBottom: 20, paddingRight: 40 }} onClick={() => move('home')} alt='img' src={clothing1} ></img>
                <img style={{ height: '270px', paddingTop: 25, paddingBottom: 20 }} onClick={() => move('home')} alt='img' src={clothing2} ></img>
        </div>
              </div>
            ) : (DropdownHeading === 'Shoes') ? (
              <div className=" d-flex ">
                <div className=" d-flex flex-column col-md-5 col">
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  <div className="d-flex flex-column " style={{ maxHeight: '40vh', flexWrap: 'wrap', }} >
                    {DropDownData.map((data: any) =>
                      <div onClick={() => SubCategoryProducts('subCategoryProducts', categoryid, data.id)}    >
                        <label className=" label pText  hoverColor touchAble flex-wrap main-font " style={{ flexWrap: 'wrap' }}  >{data?.name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col col-md-7' style={{justifyContent:'end',display:'flex'}}>
                <div  style={{display:'flex',flexDirection:'column'}}   >
                  <img style={{ height: '220px', paddingTop: 25, }} onClick={() => move('home')} alt='img' src={shoes} ></img>
                  <label className="label pText  flex-wrap main-font" style={{ flexWrap: 'wrap', fontWeight: 600 }}  >Comfortable luxury shoes</label>
                  <div className='  d-flex touchAble cp-t-6 cp-b-6 cp-l-10 cp-r-10 cm-b-15' style={{ backgroundColor: 'black', width: 150, justifyContent: 'center' }}>
                    <label onClick={() => moving('categoryProducts', 33)} className="main-font f-14  label touchAble hoverUnderLine" style={{ color: 'white' }} >SHOP NOW</label>
                  </div>
                </div>
                </div>
              </div>
            ) : (DropdownHeading === 'Bags') ? (
              <div className=" d-flex ">
                <div className=" d-flex flex-column col-md-6  col ">
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  <div className="d-flex flex-column " style={{ maxHeight: '40vh', flexWrap: 'wrap', }} >
                    {DropDownData.map((data: any) =>
                      <div onClick={() => SubCategoryProducts('subCategoryProducts', categoryid, data.id)}    >
                        <label className=" label pText  hoverColor touchAble flex-wrap main-font " style={{ flexWrap: 'wrap' }}  >{data?.name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col col-md-6' style={{justifyContent:'end',display:'flex'}}  >
                  <div style={{ width: '250px',paddingRight:20 }}>
                    <img style={{ height: '220px', paddingTop: 25 }} onClick={() => move('home')} alt='img' src={bags1} ></img>
                    <label className="label pText main-font" style={{ fontWeight: 600 }}  >Comfortable luxury shoes</label>
                    <div className='d-flex touchAble cp-t-6 cp-b-6 cp-l-10 cp-r-10 cm-b-15' style={{ backgroundColor: 'black', width: 150, justifyContent: 'center' }}>
                      <label onClick={() => moving('categoryProducts', 30)} className="main-font f-14  label touchAble hoverUnderLine" style={{ color: 'white' }} >SHOP NOW</label>
                    </div>
                  </div>
                  <img style={{ height: '300px', paddingTop: 25, paddingBottom: 20}} onClick={() => move('home')} alt='img' src={bags2} ></img>
                </div>
              </div>
            ) : (DropdownHeading === 'Kids') ? (
              <div className=" d-flex ">
                <div className="d-flex flex-column col-md-9 " style={{ maxHeight: '40vh', flexWrap: 'wrap' }} >
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  {DropDownData.map((data: any) =>
                    <div onClick={() => SubCategoryProducts('subCategoryProducts', categoryid, data.id)}    >
                      <label className=" label pText  hoverColor touchAble flex-wrap main-font" style={{ flexWrap: 'wrap' }}  >{data?.name}</label>
                    </div>
                  )}
                </div>
                <div className='col col-md-3' style={{justifyContent:'end',display:'flex'}}>
                  <img style={{ height: '200px', paddingTop: 25, paddingBottom: 20 }} onClick={() => move('home')} alt='img' src={kids} ></img>
                </div>
              </div>
            ) : (DropdownHeading === 'Jewellery') ? (
              <div className=" d-flex ">
                <div className=" d-flex flex-column col-md-6 col ">
                  <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                  <div className="d-flex flex-column " style={{ maxHeight: '40vh', flexWrap: 'wrap', }} >
                    {DropDownData.map((data: any) =>
                      <div onClick={() => SubCategoryProducts('subCategoryProducts', categoryid, data.id)}    >
                        <label className=" label pText  hoverColor touchAble flex-wrap main-font " style={{ flexWrap: 'wrap' }}  >{data?.name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col col-md-6' style={{justifyContent:'end',display:'flex'}}>
                  <img style={{ height: '260px', paddingTop: 25, paddingBottom: 20, paddingRight: 20 }} onClick={() => move('home')} alt='img' src={jewl1} ></img>
                  <img style={{ height: '260px', paddingTop: 25, paddingBottom: 20}} onClick={() => move('home')} alt='img' src={jewl2} ></img>
                </div>
              </div>
            ) : (
              <div>
                <label className="labelHeading text-capitalize cp-t-20 main-font" style={{ color: '#ababab', }} >{DropdownHeading}</label>
                {DropDownData.map((data: any) =>
                  <div className="flex-wrap " style={{ flexWrap: 'wrap' }} >
                    <label className="label pText  hoverColor touchAble flex-wrap main-font" style={{ flexWrap: 'wrap' }}  >{data?.name}{data?.label}</label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderForLuxury;
