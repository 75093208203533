import React, { useState } from 'react';
import CFooter from '../app-components/CFooter';
import Banner1 from '../assets/About Us - Banner 1.jpg'
import Banner2 from '../assets/About Us - Banner 2.jpg'
import Banner3 from '../assets/About Us - Banner 3.jpg'
import Banner4 from '../assets/About Us - Banner 4.jpg'
import HeaderForLuxury from '../app-components/HeaderForLuxury';
import HeaderforRetail from '../app-components/HeaderforRetail';

function AboutUS() {
    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')

    window.scrollTo(0, 0)

    return (
        <div>
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div >
                <div className="d-flex justify-content-center" >
                    <label className="mainHeading main-font  primary-color headingShort cp-b-40" >Welcome to Les Femmes</label>
                </div>
                <img alt='img' className="img-responsive" style={{ height: '110vh', }} src={Banner1} ></img>
                <div className="  cp-t-20  cp-b-20" >
                    <div className="stepers cp-30 " >
                        <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                            <label className="secHeading main-font  label primary-color" >Who We Are</label>
                            <label className=" main-font cp-t-15 labelText" > <label className=" primary-color label" > <strong>Les Femmes</strong></label> is the UAE's first Buy and Sell Networking platform for desirable women's pre-loved and new luxury fashion. It is also dedicated to bringing local retails together by transforming the fashion industry for a more sustainable future.
                                <label className=" main-font cp-t-20 labelText" >  It is also a platform where you can freely share your thoughts and ideas with each other. Create groups and events. And share them in different communities.</label>
                                <label className=" main-font cp-t-20 labelText" >  Create your Les Femmes store and sell your preloved or new items. We provide <strong>FREE AUTHENTICATION</strong>  for your luxury clothes, bags, shoes, accessories, jewelry, etc. Sign up as a regular user or as a retail (a seller who has a business license in the UAE.)</label></label>
                        </div>
                        <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex align-item-center " >
                            <img alt='img' className="img-responsive" style={{ height: '300px' }} src={Banner2} ></img>
                        </div>
                    </div>
                    <div className="stepers cp-30 " >
                        <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                            <img alt='img' className="img-responsive" style={{ height: '500px' }} src={Banner3} ></img>
                        </div>
                        <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                            <label className="secHeading main-font  label primary-color" >What We Do</label>
                            <label className=" main-font labelText cp-t-15" ><label className=" primary-color label" ><strong>Les Femmes</strong></label> aims to provide an accessible platform exclusively for women where individuals of similar can come together and build networks and expand networks of their business. When women support each other incredible things happen.
                                <label className=" main-font labelText cp-t-20 "  >We aim to provide women a community space that is secured and private. Through Les Femmes, women can create meaningful conversation in a positive and supportive environment through groups and support each other businesses. </label>
                                <label className=" main-font labelText cp-t-20 "  >Anyone can create their store and sell their preloved or new items. We also have the option for small business (retail owner that has a business license in the UAE) to create their store as well. Manage your sales on the go with Les Femmes App wherever you are. You will receive notifications of your sales & offers from potential buyers, and keep in contact with your customers. </label>
                            </label>
                        </div>
                    </div>
                    <div className="stepers cp-30 " >
                        <div className=" d-flex flex-column  justify-content-center col-md-4  col-10  "  >
                            <label className="secHeading main-font  label primary-color" >About Us</label>
                            <p className=" main-font labelText cp-t-15" >Launched in UAE in 2020, <label className="font-weight-bold label  primary-color" >Les Femmes</label>  has an office in Dubai, UAE, and will be expanding business in other countries soon. Find out more by downloading the app, visiting <label className="font-weight-bold  label primary-color" >lesfemmes.net</label> , and following <label className="font-weight-bold  label primary-color" >@lesfemmes.ae</label>  on Instagram and Facebook.</p>
                        </div>
                        <div className="steperImg cp-l-25 col-md-4  justify-content-center d-flex " >
                            <img alt='img' className="img-responsive" style={{ height: '300px' }} src={Banner4} ></img>
                        </div>
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default AboutUS