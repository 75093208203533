
var TestServer = "https://lesfemmestesting.dominioninc.org/public";
var LiveServer = "https://dominioninc.org/lesfemmes/public";
var Cloudserver = "https://lesfemmes.net";
var BlobStorage = "https://dominioninc.blob.core.windows.net/";
var sabir = "http://192.168.100.11/lesfemmes_testing_without_public_26_jan_2022/public";
var sabirlink ="https://lesfemmestesting.dominioninc.org/public";

const  linkk = Cloudserver

export const globleAPI = {

  FONT_FAMILY: "Sansation-Regular",
  FONT_FAMILY1: "Sansation-Bold",
  FONT_WEIGHT: "500",
  GET_EVENTS_DATES: linkk + "/api/categoryWiseEvent?token=",
  shorCutImages: linkk + "https://dominioninc.blob.core.windows.net/lesfemmes/shortcuts/",
  CUSTOMER_CHECK_OUT: linkk + "/api/CustomerCheckout?token=",
  REMOVE_ITEM_FROM_CART: linkk + "/api/RemoveFromCart?token=",
  showAllNotification: linkk + "/api/showAllNotification?token=",
  GetCountersData: linkk + "/api/GetCountersData",
  INCREMENT_OR_DECREMENT: linkk + "/api/IncreamentDecreamentV1?token=",
  GET_ALL_PRODUCT_FROM_CART: linkk + "/api/GetAllItemsOfCart?token=",
  WebGetCountriesListV2: linkk + "/api/WebGetCountriesListV2",
  getSelectedBrands: linkk + "/api/SelectedBrands",
  GET_WALLET_AMOUNT: linkk + "/api/GetWalletBalance?token=",
  GetAppShortcutCards: linkk + "https://dominioninc.blob.core.windows.net/lesfemmes/shortcuts/",
  WebGetAppShortcutCards: linkk + "/api/WebGetAppShortcutCards",
  PRODUCT_ADD_TO_CART: linkk + "/api/AdToCartV1?token=",
  FORGET_PASSWORD_USER: linkk + "/api/UpdatePassword",
  GetPopularProductsV1: linkk + "/api/GetPopularProductsV1",
  GetFeturedItems: linkk + "/api/GetFeturedItems",
  GetTopBrands: linkk + "/api/GetTopBrands",
  GetCategory: linkk + "/api/GetCategory",
  ADMIN_IMAGES_PATH: linkk + "/uploads/adminpictures/", //GLOBAL = require('./globals'); && GLOBAL.IMAGE_PATH
  // ADMIN_IMAGES_PATH: BlobStorage + "lesfemmes/adminpictures/", //GLOBAL = require('./globals'); && GLOBAL.IMAGE_PATH
  CHANGE_STATUS: linkk + "/api/changeStatus?token=",
  GetColorsListAddProduct: linkk + "/api/GetColorsList?token=",
  GET_ALL_COLOR: linkk + "/api/GetColorsList",
  IMAGE_PATH: BlobStorage + "lesfemmes/images/", //GLOBAL = require('./globals'); && GLOBAL.IMAGE_PATH
  IMAGE_PATH2: linkk + "/uploads/announcements/",
  THUMBNAIL_PATH: BlobStorage + "lesfemmes/thumbnails/", //GLOBAL = require('./globals'); && GLOBAL.IMAGE_PATH
  FIREBASE_SHORT_LINK_CONVERTER:
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA3KMurQ8cStIZjWxpu0d5bH5QsKGWUu00",
  // IMAGE_PATH: BlobStorage + "lesfemmes/images/",
  // THUMBNAIL_PATH: BlobStorage + "lesfemmes/thumbnails/",
  VIDEO_PATH: linkk + "/uploads/videos/", //GLOBAL = require('./globals'); && GLOBAL.VIDEO_PATH
  SHOW_USER_ALL_POST: linkk + "/api/showUserAllPost?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_USER_ALL_POST
  LIKE_POST: linkk + "/api/likePost?token=", //GLOBAL = require('./globals'); && GLOBAL.LIKE_POST
  UNLIKE_POST: linkk + "/api/unLikePost?token=", //GLOBAL = require('./globals'); && GLOBAL.UNLIKE_POST
  POST_COMMENTS: linkk + "/api/postComments?token=", //GLOBAL = require('./globals'); && GLOBAL.POST_COMMENTS
  SHOW_ALL_POST_COMMENT: linkk + "/api/showAllPostComment?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_POST_COMMENT
  JOIN_EVENT: linkk + "/api/joinEvent?token=", //GLOBAL = require('./globals'); && GLOBAL.JOIN_EVENT
  LEAVE_JOIN_EVENT: linkk + "/api/leaveJoinEvent?token=", //GLOBAL = require('./globals'); && GLOBAL.LEAVE_JOIN_EVENT
  SHOW_ALL_POST_LIKE: linkk + "/api/showAllPostLike?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_POST_LIKE
  SHOW_ALL_REQUEST_MEMBER: linkk + "/api/showAllRequestMember?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_REQUEST_MEMBER
  APPROVE_MEMBER_REQUEST: linkk + "/api/approveMemberRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.APPROVE_MEMBER_REQUEST
  CANCEL_MEMBER_REQUEST: linkk + "/api/cancelMemberRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_MEMBER_REQUEST
  SHOW_ALL_APPROVE_MEMBER: linkk + "/api/showAllApproveMember?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_APPROVE_MEMBER
  DELETE_SIMPLE_POST: linkk + "/api/deleteSimplePost?token=", //GLOBAL = require('./globals'); && GLOBAL.DELETE_SIMPLE_POST
  ALL_BUSINESS_ADVERT_POST: linkk + "/api/AllBusinessAdvertPost", //GLOBAL = require('./globals'); && GLOBAL.ALL_BUSINESS_ADVERT_POST
  USER_SELECT_COMMUNITIES: linkk + "/api/userSelectCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.USER_SELECT_COMMUNITIES
  SHOW_ALL_APPROVE_USER: linkk + "/api/showAllApproveUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_APPROVE_USER
  GET_USER_ALL_GROUPS: linkk + "/api/GetUserAllGroups?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_USER_ALL_GROUPS
  BUSINESS_ADVERTS_POST: linkk + "/api/busniessAdvertsPost?token=", //GLOBAL = require('./globals'); && GLOBAL.BUSINESS_ADVERTS_POST
  PAYMENT_API: linkk + "/api/paymentApi?token=",
  ADD_EVENT_TO_Calandar: linkk + "/api/AddEventToCalandar?token=",
  GET_CALANDER_EVENTS: linkk + "/api/categoryWiseSpecificDateEvent?token=",
  EDIT_CALENDAR: linkk + "/api/EditCalandar?token=",
  GetProductSizes: linkk + "/api/GetProductSizesOfSpecificCatgory?token=",
  GET_ALL_SIZES: linkk + "/api/GetProductSizesOfSpecificCatgory",
  //GLOBAL = require('./globals'); && GLOBAL.PAYMENT_API
  JOIN_COMMUNITIES: linkk + "/api/joinCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.JOIN_COMMUNITIES
  LEAVE_COMMUNITIES: linkk + "/api/leaveCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.LEAVE_COMMUNITIES
  CHANGE_PASSWORD: linkk + "/api/changePassword?token=", //GLOBAL = require('./globals'); && GLOBAL.CHANGE_PASSWORD
  SHOW_ALL_NOTIFICATION: linkk + "/api/showAllNotification?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_NOTIFICATION
  CANCEL_REQUEST: linkk + "/api/cancelRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  MakeAnOffer: linkk + "/api/MakeAnOffer?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  MakeAnOfferV1: linkk + "/api/MakeAnOfferV1?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  MakeCounterOfferV1: linkk + "/api/MakeCounterOfferV1?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  GetCustomerOffers: linkk + "/api/GetCustomerOffers?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  GetCustomerOffersV1: linkk + "/api/GetCustomerOffersV1?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  GetVendorOffers: linkk + "/api/GetVendorOffers?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  GetVendorOffersV1: linkk + "/api/GetVendorOffersV1?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  EditProduct: linkk + "/api/EditProduct?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_REQUEST
  COUNT_COMMUNITY_MEMBER: linkk + "/api/countCommunityMemeber", //GLOBAL = require('./globals'); && GLOBAL.COUNT_COMMUNITY_MEMBER
  UPCOMMING_EVENTS: linkk + "/api/UpCommingEvent", //GLOBAL = require('./globals'); && GLOBAL.UPCOMMING_EVENTS
  SHOW_USER_POST: linkk + "/api/showUserPost?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_USER_POST
  REPORT: linkk + "/api/report?token=", //GLOBAL = require('./globals'); && GLOBAL.REPORT
  DEEP_LINK: "https://lesfemmes.page.link/sZZL", //GLOBAL = require('./globals'); && GLOBAL.DEEP_LINK
//GLOBAL = require('./globals'); && GLOBAL.PRODUCT_DEEP_LINK
  GET_GROUP_DETAIL: linkk + "/api/GetGroupDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_GROUP_DETAIL
  GET_GROUP_DETAIL_FROM_DEEPLINK:
    linkk + "/api/GetGroupDetailsFromDeeplink?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_GROUP_DETAIL_FROM_DEEPLINK
  GROUP_JOIN_REQUEST: linkk + "/api/GroupJoinRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.GROUP_JOIN_REQUEST
  ADD_MEMBERS_TO_GROUP: linkk + "/api/AddMembersToGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.ADD_MEMBERS_TO_GROUP
  LEAVE_GROUP: linkk + "/api/LeaveGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.LEAVE_GROUP
  GET_POST_DETAIL_FROM_DEEPLINK:
    linkk + "/api/GetPostDetailsFromDeeplink?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_POST_DETAIL_FROM_DEEPLINK
  EDIT_BUSINESS_ADVERTS: linkk + "/api/editBusinessAdverts?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_BUSINESS_ADVERTS
  EDIT_EVENT_POST: linkk + "/api/editEventPost?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_EVENT_POST
  EDIT_SIMPLE_POST: linkk + "/api/editSimplePost?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_SIMPLE_POST
  GET_ALL_COMMUNITIES: linkk + "/api/GetAllCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_COMMUNITIES
  EDIT_GROUP: linkk + "/api/EditGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_GROUP
  EDIT_BANK_DETAIL: linkk + "/api/EditBankDetails?token=",
  GET_ALL_DATA_SOLD: linkk + "/api/GetVendorAllSales?token=",
  GET_ALL_STORES_TOP_ITEM: linkk + "/api/GetStoresTopTenList",
  GET_ALL_LUXURY_PRODUCTS: linkk + "/api/WebGetAllLuxuryProducts",
  GET_ALL_RETAIL_STORES_TOP_ITEM: linkk + "/api/GetStoresTopTenListRetail",
  GET_ALL_STORES: linkk + "/api/GetStoresList",
  WebGetStoresListV1: linkk + "/api/WebGetStoresListV1",
  GET_RETAIL_ALL_STORES_V1: linkk + "/api/GetRetailStoresListV1?token=",
  CHANDE_ORDER_STATUS_FROM_ADMIN_SIDE: linkk + "/api/CancelOrder?token=",
  ADMIN_VIEW_ALL_ORDERS: linkk + "/api/GetAllStoreOrders?token=",
  GetCustomerOfferLogs: linkk + "/api/GetCustomerOfferLogs?token=",
  GET_ORDER_DETAIL: linkk + "/api/Get_Order_Details?token=",
  ChangeOfferStatus: linkk + "/api/ChangeOfferStatus?token=",
  ChangeOfferStatusV1: linkk + "/api/ChangeOfferStatusV1?token=",
  APPROVE_REQUEST: linkk + "/api/approveRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.APPROVE_REQUEST
  FORGOT_PASSWORD: linkk + "/api/forgotPassword", //GLOBAL = require('./globals'); && GLOBAL.FORGOT_PASSWORD
  SHOW_ALL_CONTACT: linkk + "/api/showAllContact?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_CONTACT
  GET_ALL_TRENDING_GROUPS_OF_SPECIFIC_COMMUNITY:
    linkk + "/api/GetAllTrendingGroupsOfSpecificCommunity?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_TRENDING_GROUPS_OF_SPECIFIC_COMMUNITY
  GET_ALL_GROUPS_OF_SPECIFIC_COMMUNITY:
    linkk + "/api/GetAllGroupsOfSpecificCommunity?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_GROUPS_OF_SPECIFIC_COMMUNITY
  CREATE_GROUP: linkk + "/api/CreateGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.CREATE_GROUP
  DELETE_GROUP: linkk + "/api/DeleteGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.DELETE_GROUP
  GET_ALL_EVENTS_OF_SPECIFIC_GROUP:
    linkk + "/api/GetAllEventsOfSpecificGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_EVENTS_OF_SPECIFIC_GROUP
  GET_ALL_POSTS_OF_SPECIFIC_GROUP:
    linkk + "/api/GetAllPostsOfSpecificGroup?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_POSTS_OF_SPECIFIC_GROUP
  GET_ALL_GROUP_JOIN_REQUEST: linkk + "/api/GetAllGroupJoinRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_GROUP_JOIN_REQUEST
  ACCEPT_GROUP_JOIN_REQUEST: linkk + "/api/AcceptGroupJoinRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.ACCEPT_GROUP_JOIN_REQUEST
  REJECT_GROUP_JOIN_REQUEST: linkk + "/api/RejectGroupJoinRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.REJECT_GROUP_JOIN_REQUEST
  GET_ALL_TRENDING_GROUPS_FROM_ALL_COMMUNITIES:
    linkk + "/api/GetAllTrendingGroupsFromAllCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_TRENDING_GROUPS_FROM_ALL_COMMUNITIES
  SHOW_ALL_CHAT_USER: linkk + "/api/showAllChatUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ALL_CHAT_USER
  SEND_REQUEST: linkk + "/api/sendRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.SEND_REQUEST
  LOGIN: linkk + "/api/loginV1", //GLOBAL = require('./globals'); && GLOBAL.LOGIN
  SAVE_FCM_TOKEN: linkk + "/api/saveFcm_token?token=", //GLOBAL = require('./globals'); && GLOBAL.SAVE_FCM_TOKEN
  COUNT_NOTIFICATION: linkk + "/api/countNotification?token=", //GLOBAL = require('./globals'); && GLOBAL.COUNT_NOTIFICATION
  COUNT_CONTACT_REQUEST: linkk + "/api/countContactRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.COUNT_CONTACT_REQUEST
  LOGOUT: linkk + "/api/logout?token=", //GLOBAL = require('./globals'); && GLOBAL.LOGOUT
  EVENT_POST: linkk + "/api/eventPost?token=", //GLOBAL = require('./globals'); && GLOBAL.EVENT_POST
  NOTIFICATION_STATUS: linkk + "/api/ReadNotification?token=", //GLOBAL = require('./globals'); && GLOBAL.NOTIFICATION_STATUS
  EDIT_USER_PROFILE: linkk + "/api/editUserProfile?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_USER_PROFILE
  SHOW_PROFILE: linkk + "/api/showProfile?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_PROFILE
  SIMPLE_POST: linkk + "/api/simplePost?token=", //GLOBAL = require('./globals'); && GLOBAL.SIMPLE_POST
  GetStoreDetails: linkk + "/api/GetStoreDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.SIMPLE_POST
  GetCountriesListV2: linkk + "/api/GetCountriesListV2?token=", //GLOBAL = require('./globals'); && GLOBAL.SIMPLE_POST
  REGISTER: linkk + "/api/registerV1", //GLOBAL = require('./globals'); && GLOBAL.REGISTER
  GOOGLE_IOS_CLIENT_ID:
    "937559180864-o3j2hv8tpctucmedhvg4a9irh56o1v78.apps.googleusercontent.com",
  GOOGLE_ANDROID_CLIENT_ID:
    "937559180864-6ebdfg9nl585e58vuh0du2398n1pclkg.apps.googleusercontent.com",
  GOOGLE_WEB_CLIENT_ID:
    "937559180864-0arkpdc3mleqviaiiirnotau029j00mo.apps.googleusercontent.com", //GLOBAL = require('./globals'); && GLOBAL.GOOGLE_WEB_CLIENT_ID
  SUPPORT_EMAIL: linkk + "/api/supportEmail?token=", //GLOBAL = require('./globals'); && GLOBAL.SUPPORT_EMAIL
  CHAT_SEND_REQUEST: linkk + "/api/chatSendRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.CHAT_SEND_REQUEST
  ALL_USERS: linkk + "/api/allUser?token=", //GLOBAL = require('./globals'); && GLOBAL.ALL_USERS
 //GLOBAL = require('./globals'); && GLOBAL.TERMS_OF_USE
  SEARCH_USER: linkk + "/api/searchUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SEARCH_USER
  SEARCH_PRODUCT: linkk + "/api/GetVendorAllProducts?token=", //GLOBAL = require('./globals'); && GLOBAL.SEARCH_USER
  GET_VENDOR_PRODUCTS: linkk + "/api/GetVendorAllProductsLists?token=",
  CHECK_REQUEST_STATUS: linkk + "/api/CheckRequestStatus?token=", //GLOBAL = require('./globals'); && GLOBAL.CHECK_REQUEST_STATUS
  REMOVE_CONTACT_REQUEST: linkk + "/api/removeContactRequest?token=", //GLOBAL = require('./globals'); && GLOBAL.REMOVE_CONTACT_REQUEST
  CREATE_CONVERSATION: linkk + "/api/CreateConversation?token=", //GLOBAL = require('./globals'); && GLOBAL.CREATE_CONVERSATION ---post
  GET_USER_CONVERSATION_IDS: linkk + "/api/GetUserConversations?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_USER_CONVERSATION_IDS ---post request
  LEAVE_CONVERSATION: linkk + "/api/LeaveConversation?token=", //GLOBAL = require('./globals'); && GLOBAL.LEAVE_CONVERSATION ---post request
  EDIT_CONVERSATION: linkk + "/api/UpdateConversation?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_CONVERSATION ---post request
  DELETE_CONVERSATION: linkk + "/api/DeleteConversation?token=", //GLOBAL = require('./globals'); && GLOBAL.DELETE_CONVERSATION ---post request
  ADD_MUTE_NOTIFICATION: linkk + "/api/AddMuteNotification?token=", //GLOBAL = require('./globals'); && GLOBAL.ADD_MUTE_NOTIFICATION ---post request
  UNMUTE_NOTIFICATIONS: linkk + "/api/UnMuteNotification?token=", //GLOBAL = require('./globals'); && GLOBAL.UNMUTE_NOTIFICATIONS ---post request
  GET_USER_MUTE_NOTIFICATIONS: linkk + "/api/GetUserMuteNotifications?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_USER_MUTE_NOTIFICATIONS ---post request
  SHARE_POST: linkk + "/api/sharepost?token=", //GLOBAL = require('./globals'); && GLOBAL.SHARE_POST ---post request
  //THREE OF THESE ARE GET REQUESTS PASS user_id IN URL...
  SHOW_SIMPLE_POSTS_OF_A_USER: linkk + "/api/ShowSimplePostsOfAUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_SIMPLE_POSTS_OF_A_USER ---post request
  SHOW_EVENTS_POSTS_OF_A_USER: linkk + "/api/ShowEventsPostsOfAUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_EVENTS_POSTS_OF_A_USER ---post request
  SHOW_ADVERTS_POSTS_OF_A_USER: linkk + "/api/ShowAdvertsPostsOfAUser?token=", //GLOBAL = require('./globals'); && GLOBAL.SHOW_ADVERTS_POSTS_OF_A_USER ---post request
  ACCEPT_INVITATION: linkk + "/api/AcceptInvitation?token=", //GLOBAL = require('./globals'); && GLOBAL.ACCEPT_INVITATION ---post request
  CANCEL_INVITATION: linkk + "/api/CancelInvitation?token=", //GLOBAL = require('./globals'); && GLOBAL.CANCEL_INVITATION ---post request
  RESET_COUNTER: linkk + "/api/resetCounter?token=", //GLOBAL = require('./globals'); && GLOBAL.RESET_COUNTER ---post request
  //------THREE OF ABOVE ENDS HERE. ,
  UPDATE_UNSEEN_MESSAGE_COUNTER:
    linkk + "/api/UpdateUnseenMessageCounter?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_UNSEEN_MESSAGE_COUNTER ---post request
  COUNT_CHAT_UNSEEN_MSG_COUNTER:
    linkk + "/api/CountUnseenMessagesFromAllConversations?token=", //GLOBAL = require('./globals'); && GLOBAL.COUNT_CHAT_UNSEEN_MSG_COUNTER ---post request
  VARIFY_USER: linkk + "/api/verifyUser", //GLOBAL = require('./globals'); && GLOBAL.GOOGLE_LOGIN ---get request
  GOOGLE_LOGIN: linkk + "/api/googleRegister", //GLOBAL = require('./globals'); && GLOBAL.GOOGLE_LOGIN ---post request
  FACEBOOK_LOGIN: linkk + "/api/facebookRegister", //GLOBAL = require('./globals'); && GLOBAL.FACEBOOK_LOGIN ---post request NotificationController
  APPLE_LOGIN: linkk + "/api/appleRegister", //GLOBAL = require('./globals'); && GLOBAL.FACEBOOK_LOGIN ---post request NotificationController
  GET_ALL_GROUPS_OF_POST: linkk + "/api/GetAllgroupsOfPost?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_GROUPS_OF_POST ---post
  GET_PACKAGE_LIST: linkk + "/api/GetPackagesList?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_PACKAGE_LIST ---post
  ADVERT_DURATION: linkk + "/api/GetBusinessAdvertDurationList?token=", //GLOBAL = require('./globals'); && GLOBAL.ADVERT_DURATION ---post
  GET_BUSINESS_ADVERTS_FOR_DASHBOARD: linkk + "/api/GetBusienssAdverts?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_BUSINESS_ADVERTS_FOR_DASHBOARD ---post
  GET_UNPAID_ADVERT: linkk + "/api/getUnpaidAdvert?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_BUSINESS_ADVERTS_FOR_DASHBOARD ---post
  WebGetAllGroupsFromAllCommunities: linkk + "/api/WebGetAllGroupsFromAllCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_BUSINESS_ADVERTS_FOR_DASHBOARD ---post
  WebGetUserAllGroups: linkk + "/api/WebGetUserAllGroups?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_BUSINESS_ADVERTS_FOR_DASHBOARD ---post
  WebGetAllTrendingGroupsFromAllCommunities: linkk + "/api/WebGetAllTrendingGroupsFromAllCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_BUSINESS_ADVERTS_FOR_DASHBOARD ---post

  CHANGE_POST_STATUS: linkk + "/api/ChangePostStatus?token=", //GLOBAL = require('./globals'); && GLOBAL.CHANGE_POST_STATUS ---post
  GET_ALL_GROUPS_FROM_ALL_COMMUNITIES:
    linkk + "/api/GetAllGroupsFromAllCommunities", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_GROUPS_FROM_ALL_COMMUNITIES ---post
  SEARCH_ALL_GROUPS_FROM_ALL_COMMUNITIES:
    linkk + "/api/SearchAllGroupsFromAllCommunities?token=", //GLOBAL = require('./globals'); && GLOBAL.SEARCH_ALL_GROUPS_FROM_ALL_COMMUNITIES ---post
  ADMIN_CREATE_POST: linkk + "/api/LesFemmesCreatePost?token=", //GLOBAL = require('./globals'); && GLOBAL.ADMIN_CREATE_POST ---post
  GET_LESFEMMES_POST_DEDAILS: linkk + "/api/GetLesFemmesPostDetail?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_LESFEMMES_POST_DEDAILS ---post
  GET_ALL_BROADCAST_FILTERS: linkk + "/api/GetAllBroadcastfilters?token=",
  GET_ALL_BROADCAST_MEDIUM: linkk + "/api/GetAllBroadcastMediums?token=",
  COUNT_ONE_IMPRESSION: linkk + "/api/AdvertImpressionMet?token=",
  SEARCH_BUSINESS_ADVERT: linkk + "/api/SearchBusinessAdvert?token=", //GLOBAL = require('./globals'); && GLOBAL.SEARCH_BUSINESS_ADVERT ---post
  RENEW_BUSINESS_ADVERT: linkk + "/api/RenewBusinessAdvert?token=", //GLOBAL = require('./globals'); && GLOBAL.RENEW_BUSINESS_ADVERT ---post
  DELETE_EVENT: linkk + "/api/DeleteCalendarEvent?token=", //GLOBAL = require('./globals'); && GLOBAL.RENEW_BUSINESS_ADVERT ---post
  UPDATE_USER_PROPERTIES: linkk + "/api/UpdateUserProperties?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_USER_PROPERTIES ---post
  GET_ALL_CATEGORIES: linkk + "/api/getAllCategories",
  getAllCategoriesAddProduct: linkk + "/api/WebgetAllCategories?token=",
  getAllSubCategoriesAddProduct: linkk + "/api/getAllSubCategories?token=",
  GET_ALL_SUB_CATEGORIES: linkk + "/api/getAllSubCategories",
  UPDATE_USER_FCM: linkk + "/api/UpdateUserFcm?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_USER_FCM ---post
  //-------------> DOWN NOTES ACCORDING MYSTORE API'S-=-=-=-=-=-=-=-=-=- MY STORE API'S ---->>
  ADS_CATEGORIES: linkk + "/api/GetProductCategoriesListV1?token=", //GLOBAL = require('./globals'); && GLOBAL.ADS_CATEGORIES ---post
  //SEND user_id to get relevent ads of that user and this is get request....
  CLASSIFIED_ADS_OF_A_USER:
    linkk + "/api/GetAllClassifiedAdsOfSpecificUser?token=", //GLOBAL = require('./globals'); && GLOBAL.CLASSIFIED_ADS_OF_A_USER ---post
  //PAGINATED AND IS GET REQUEST
  Get_Wallet_Balance: linkk + "/api/GetWalletBalance?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_CLASSIFIED_ADS ---post
  GET_ALL_CLASSIFIED_ADS: linkk + "/api/GetAllProducts?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_CLASSIFIED_ADS ---post
  GET_ALL_CLASSIFIED_ADSV1: linkk + "/api/GetAllProductsV1?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_CLASSIFIED_ADS ---post
  GET_BANK_DETAIL: linkk + "/api/GetVendorBankDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_CLASSIFIED_ADS ---post
  //POST REQUEST

  WebGetAllPopularProducts: linkk + "/api/WebGetAllPopularProducts", //delete Conversations...///
  DELETE_CONVERSATION: linkk + "/api/DeleteConversation?token=", //delete Conversations...///
  POST_CLASSIFIED_AD: linkk + "/api/AddProduct?token=", //GLOBAL = require('./globals'); && GLOBAL.POST_CLASSIFIED_AD ---post
  DELETE_COMMENT: linkk + "/api/deleteComment?token=", //GLOBAL = require('./globals'); && GLOBAL.DELETE_COMMENT ---post
  EDIT_COMMENT: linkk + "/api/editComment?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_COMMENT ---post
  WORLD_CURRENCY_LIST: linkk + "/api/CurrencyList?token=", //GLOBAL = require('./globals'); && GLOBAL.WORLD_CURRENCY_LIST ---post
  CLASSIFIED_AD_DETAIL: linkk + "/api/GetProductDetailV1?token=", //GLOBAL = require('./globals'); && GLOBAL.CLASSIFIED_AD_DETAIL ---post
  WebGetProductDetailV1: linkk + "/api/WebGetProductDetailV1", //GLOBAL = require('./globals'); && GLOBAL.CLASSIFIED_AD_DETAIL ---post
  PLACE_ORDER: linkk + "/api/ClassifiedAdPlaceOrder?token=", //GLOBAL = require('./globals'); && GLOBAL.PLACE_ORDER ---post
  SOLD_ITEMS: linkk + "/api/ClassifiedAdGetSoldItems?token=", //GLOBAL = require('./globals'); && GLOBAL.SOLD_ITEMS ---post
  EDIT_STORE_INFO: linkk + "/api/RegisterStore?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_STORE_INFO ---post
  GET_STORE_INFO: linkk + "/api/GetStoreDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_STORE_INFO ---GET /
  ADS_BY_CATEGORY: linkk + "/api/GetAllProductsOfSpecificCategory?token=", //GLOBAL = require('./globals'); && GLOBAL.ADS_BY_CATEGORY ---post
  ADD_AD_TO_WISH_LIST: linkk + "/api/AdToWishList?token=", //GLOBAL = require('./globals'); && GLOBAL.ADD_AD_TO_WISH_LIST ---post
  GET_WISH_LIST: linkk + "/api/GetWishList?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_WISH_LIST ---post
  REMOVE_ITEM_FROM_WISHLIST: linkk + "/api/RemoveFromWishList?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_WISH_LIST ---post
  PURCHASE_HISTORY: linkk + "/api/GetMyOrders?token=", //GLOBAL = require('./globals'); && GLOBAL.PURCHASE_HISTORY ---post
  TOP_PICKS: linkk + "/api/TopPicks?token=", //GLOBAL = require('./globals'); && GLOBAL.TOP_PICKS ---post
  TRENDING: linkk + "/api/Trending?token=", //GLOBAL = require('./globals'); && GLOBAL.TRENDING ---post
  SUGGESTED: linkk + "/api/Suggested?token=", //GLOBAL = require('./globals'); && GLOBAL.SUGGESTED ---post
  CATEGORY_IMAGES_BY_ID: linkk + "/api/CategoryImagesById", //GLOBAL = require('./globals'); && GLOBAL.CATEGORY_IMAGES_BY_ID ---post
  CATEGORY_IMAGES: linkk + "/api/CategoryImages", //GLOBAL = require('./globals'); && GLOBAL.CATEGORY_IMAGES ---post
  UPDATE_ORDER_STATUS: linkk + "/api/UpdateOrderStatus?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_ORDER_STATUS ---post
  ALL_EVENTS_FROM_ALL_GROUPS: linkk + "/api/GetAllEventsFromAllGroups?token=", //GLOBAL = require('./globals'); && GLOBAL.ALL_EVENTS_FROM_ALL_GROUPS ---post
  ALL_CLASSIFIED_ADS_OF_USER: linkk + "/api/GetVendorAllProducts?token=", //GLOBAL = require('./globals'); && GLOBAL.ALL_CLASSIFIED_ADS_OF_USER ---post
  EDIT_AD_INFO: linkk + "/api/UpdateAdInfo?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_AD_INFO ---post
  EDIT_BANK_DETAIL: linkk + "/api/EditBankDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.EDIT_BANK_INFOR ---post
  GET_ALL_ORDERS: linkk + "/api/GetVendorAllOrders?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_ORDERS ---post
  GET_PRODUCT_FROM_PRODUCT_ID: linkk + "/api/GetProductDetails?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_ORDERS ---post
  GET_ALL_SALES: linkk + "/api/GetVendorAllSales?token=", //GLOBAL = require('./globals'); && GLOBAL.GET_ALL_SALES ---post
  ADD_BANK_DETAIL: linkk + "/api/AddBankAccountDetails?token=",
  EDIT_PRODUCT: linkk + "/api/EditProduct?token=",
  PAYMENT_FOR_STRIPE: linkk + "/api/stripe_post?token=",
  GET_JOB_DETAILS: linkk + "/api/GetJobDetails?token=",
  VERIFY_PAYMENT_STATUS:
    linkk + "/api/UpdateOrderStatusAndPaymentDetails?token=",
  DELETE_VENDOR_SIDE_PRODUCT: linkk + "/api/DeleteProduct?token=", //GLOBAL = require('./globals'); && GLOBAL.delete vendoer side product ---post
  GET_ALL_JOB_CATE: linkk + "/api/GetAllJobCategories?token=", //GLOBAL=SELECT_JOB_CATEGORY
  POST_JOB: linkk + "/api/PostJob?token=", //POST_USER_JOB
  GET_MY_POSTED_JOBS: linkk + "/api/GetMyPostedJobs?token=",
  GET_ALL_JOBS: linkk + "/api/GetAllJobs?token=", //GET_JOB_DETAILS
  GET_EDIT_JOB_SUBMIT: linkk + "/api/EditJob?token=",
  GET_JOBS: linkk + "/api/GetJobs?token=", //POST_USER_JOB
  GET_EDIT_JOB: linkk + "/api/GetJobDetails?token=", //POST_USER_JOB
  UPDATE_AD_STATUS: linkk + "/api/ChangeProductStatus?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_AD_STATUS ---post
  DELETE_POSTED_JOB: linkk + "/api/DeleteJob?token=",
  GET_PENDING_ORDER_COUNT: linkk + "/api/GetPendingOrdersCount?token=", //GLOBAL = require('./globals'); && GLOBAL.UPDATE_AD_STATUS ---post
  POST_BLOCK_USER: linkk + "/api/blockUnblockUser?token=",
  USER_AVAILABILITY: linkk + "/api/getUserAvailability?token=",
  CALENDAR_EVENT_CATEGORIES: linkk + "/api/calendarEventCategories?token=",
  GET_LATEST_EVENTS: linkk + "/api/GetTopRecentEvents?token=",
  GET_NEAR_BY_FRIENDS_LOCATION: linkk + "/api/GetNearbyUsers?token=", // GET Request Form Near By Friends..///

  POST_CHECKOUT_API_ITEMS: linkk + "/api/CustomerCheckoutV1?token=",
  POST_CHECKOUT_API_ITEMS2: linkk + "/api/CustomerCheckoutV2?token=",
  POST_CHECKOUT_API_ITEMS3: linkk + "/api/CustomerCheckoutSucceeded?token=",
  POST_CHECKOUT_API_ITEMS4: linkk + "/api/CustomerCheckoutFailed?token=",
  GET_LASTEST_PRODUCT: linkk + "/api/GetLatestProducts?token=",
  GET_POPULAR_PRODUCTS: linkk + "/api/GetPopularProducts",
  GET_STORES_WITH_PRODUCTS: linkk + "/api/GetStoresWithProducts",
  GET_BRAND_NAME: linkk + "/api/GetProductBrands?token=",
  getBrandsFilterAddProduct: linkk + "/api/getBrandsFilter?token=",
  GET_BRAND_FILTER: linkk + "/api/getBrandsFilter",
  GET_LUXURY_PRODUCTS: linkk + "/api/GetLuxuryProducts",
  GET_LUXURY_CATEGORIES: linkk + "/api/GetLuxuryCategories",
  GET_SUB_CATEGORIES: linkk + "/api/SubCategoryImagesById",
  GET_ITEMS_SUB_CATEGORY: linkk + "/api/GetItemsBySubCategoryID",
  WebGET_LUXURY_PRODUCTS_SPECIFIC_CATEGORY:linkk + "/api/WebGetProductsOfSpecificCategoryV1",
  GetLesfemmesBanner: linkk + "/api/GetLesfemmesBanner",
  GET_PRODUCT_CONDITION: linkk + "/api/GetProductConditions?token=",
  GET_POPULAR_BRANDS: linkk + "/api/GetPopularBrands",
  Web_GET_ALL_BRANDS: linkk + "/api/WebGetAllBrands",
  GET_STORE_DETAILSV1: linkk + "/api/GetStoreDetailsV2?token=",
  WebGetStoreDetailsV2: linkk + "/api/WebGetStoreDetailsV2",
  NewsLetter: linkk + "/api/NewsLetter",
  WebGetStoreDetailsV3: linkk + "/api/WebGetStoreDetailsV3?token=",
  WebGetRetailStoresListV1: linkk + "/api/WebGetRetailStoresListV1",
  cartCount: linkk + "/api/GetCartCount?token=",
  GET_UPDATE: linkk + "/api/getUpdateNotification?token=",
  GetSaleFilters: linkk + "/api/GetSaleFilters",
  POST_REVIEWS: linkk + "/api/SaveProductRating?token=",
  POST_REVIEWS_V1: linkk + "/api/saveReviewReply?token=",
  RESENDEAIL: linkk + "/api/ResendEmail?email=",
  GET_ALL_COUNTRIES: linkk + "/api/GetCountriesList?email=",
  GET_ALL_COUNTRIES_V1: linkk + "/api/GetCountriesListV1",
  GET_ALL_COUNTRIES_V2: linkk + "/api/GetCountriesListV2",
  GET_ALL_CITIES: linkk + "/api/GetCitiesList",
  SEARCH_BRAND: linkk + "/api/GetAllProductsOfSpecificBrand?token=",

  SEND_REDEEM: linkk + "/api/sendRadeemRequest?token=",
  PROMO_CODE: linkk + "/api/applyPromoCode?token=",
  GetCitiesList: linkk + "/api/GetCitiesList?token=",
  GET_REDEEM: linkk + "/api/getAllRadeemRequests?token=",
  RegisterStore: linkk + "/api/RegisterStore?token=",

  GET_BRAND_SEARCH: linkk + "/api/GetProductsOfSpecificBrand?token=",
  GetHolidayMode: linkk + "/api/GetHolidayMode?token=",
  EndHolidayMode: linkk + "/api/EndHolidayMode?token=",
  StartHolidayMode: linkk + "/api/StartHolidayMode?token=",
  ADS_BY_POPULAR_PRODUCTS: linkk + "/api/GetAllPopularProducts?token=", //GLOBAL = require(‘./globals’); && GLOBAL.ADS_BY_CATEGORY ---post
  SEARCH_FROM_ALL_PRODUCTS: linkk + "/api/SearchFromAllProducts?token=", //GLOBAL = require(‘./globals’); && GLOBAL.ADS_BY_CATEGORY ---post
  WebSearchFromAllProducts: linkk + "/api/WebSearchFromAllProducts", //GLOBAL = require(‘./globals’); && GLOBAL.ADS_BY_CATEGORY ---post
  WebGetAllProductsV1: linkk + "/api/WebGetAllProductsV1", //GLOBAL = require(‘./globals’); && GLOBAL.ADS_BY_CATEGORY ---post
  GET_SHIPPING_CHARGES: linkk + "/api/CalculateShipping?token=",
  FOLLOW_STORE: linkk + "/api/followStore?token=",
  STORE_ALL_FOLLOWERS: linkk + "/api/allFollowersOfStore?token=",
  GET_APP_SHORTCUT_ICONS: linkk + "/api/GetAppShortcutIcons",
  
 
};






