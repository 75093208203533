import './CFooter.scss';
import React,{useState} from 'react';
import facebook from '../assets/FB.png';
import isnta from '../assets/YT.png';
import app from '../assets/google-app (1).png';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import apple from '../assets/google-app (2).png';
import youtube from '../assets/IG.png';
import { Link, useHistory } from 'react-router-dom'

function CFooter() {

  const history = useHistory();
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')

  const movingWithSaleAll = (data: any) => {
    if(Selected =='Luxury'){
      history.push('/luxury-products', ['usedItems']);
    }else{
      history.push('/all-products', ['AllSale']);
    }
  };

  const whyLesfems = (data:any) => {
      history.push('/why-lesfemmes', [data]);
  };
  const faqs = (data:any) => {
      history.push('/faqs',[data]);
  };

  return (
    <div className='container-fluid cp-t-20 cp-b-30 border-top background'>
      <div  >
        <div className='row'>
          <div className='col-md-1' ></div>
          <div className='col-md-2 col-sm-6 col-6 mobileFooter'>
            <div className='f-18  f-600 capitalize cp-t-1rem cp-b-10 textColor'>
              SHOP
            </div>
            <div className='pText  textColor'>
              <Link style={{ color: 'white' }} className=" f-16" to="/categories">Categories</Link>
            </div>
            <div className='pText   f-16 textColor'>
              <a style={{ color: 'white'}} href="https://my.ccs-uae.com/track">Order Tracking</a>
            </div>
            <div className='pText   f-16  textColor'>
              <Link style={{ color: 'white' }} className="" to="/orders-and-shipping">Orders & Shipping</Link>
            </div>
            <div className='pText  f-16  textColor'>
              <Link style={{ color: 'white' }} className="" to="/all-brands">Brands</Link>
            </div>
            <div onClick={() => movingWithSaleAll('AllSale')} className='pText f-16    textColor hoverUnderLine'>
              <label className="hoverUnderLine touchAble " >Deals</label>
            </div>
          </div>
          <div className='col-md-2 col-sm-6 col-6 mobileFooter'>
            <div className='f-18  f-600 capitalize cp-t-1rem cp-b-10 textColor'>
              SELL
            </div>
            <div className='pText   textColor' onClick={() => whyLesfems('how')}>
              <label style={{ color: 'white' }} className=" f-16  touchAble hoverUnderLine label" >How to Sell</label>
            </div>
            <div className='pText  f-16 textColor'>
            <Link style={{ color: 'white' }} className="" to="/sale-product">Sell An Item</Link>
            </div>
            <div className='pText   textColor' onClick={() => whyLesfems('why')}>
              <label style={{ color: 'white' }} className=" touchAble  f-16 hoverUnderLine" >Why Les Femmes</label>
            </div>
          </div>
          <div className='col-md-2 col-sm-6 col-6 mobileFooter'>
            <div className='f-18  f-16 f-600 capitalize cp-t-1rem cp-b-10 textColor'>
              COMPANY
            </div>
            <div className='pText   f-16 textColor'>
              <Link style={{ color: 'white' }} className="" to="/about-us">About Us</Link>
            </div>
            <div  className='pText   textColor'>
            <Link style={{ color: 'white' }} className=" f-16" to="/community">Our Community</Link>
            </div>
            <div className='pText  f-16 textColor' onClick={() => whyLesfems('how')}>
              <label style={{ color: 'white' }} className=" touchAble  hoverUnderLine label" >How It Works</label>
            </div>
            <div className='pText f-16   textColor' onClick={() => faqs('customer')}>
              <label style={{ color: 'white' }} className=" touchAble  hoverUnderLine label" >Customer Support</label>
            </div>
            <div className='pText  f-16 textColor' onClick={() => faqs('faqs')}>
              <label style={{ color: 'white' }} className=" touchAble  hoverUnderLine label" >FAQs</label>
            </div>
            <div className='pText  f-16 textColor'>
              <label className=' ' >
              Blog
              </label>
            </div>
          </div>
          <div className='col-md-2 col-sm-6  col-6 mobileFooter'>
            <div className='f-18  f-600 f-16 capitalize cp-t-1rem cp-b-10 textColor'>
              POLICY CENTER
            </div>
            <div className='pText  f-16  textColor'>
              <Link style={{ color: 'white' }} className="" to="/return-policy">Return Policy</Link>
            </div>
            <div className='pText  f-16 textColor'>
              <Link style={{ color: 'white' }} className="" to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className='pText  f-16 textColor'>
              <Link style={{ color: 'white' }} className="" to="/term-of-services">Terms of Service</Link>
            </div>
            <div className='pText  f-16 textColor'>
              <Link style={{ color: 'white' }} className="" to="/prohibited-items">Prohibited Items</Link>
            </div>
          </div>
          <div className='col-md-2  col-12 mobileFooter'>
            <div>
              <div className='f-18  f-600 f-16 cp-t-1rem cp-b-10 textColor uppercase' style={{ textAlign:'center'}}>buy and sell on app</div>
              <div className='pText   textColor'>
                <div className='d-flex flex-row' >
                  <a href="https://play.google.com/store/apps/details?id=com.lesfemmes&hl=en" target="_blank" >  <img className='img-responsive' style={{ height: '38px' }} alt='img' src={app} ></img></a>
                  <a href="https://apps.apple.com/pk/app/lesfemmes/id1396175773" target="_blank">  <img className=' cp-l-10 img-responsive' style={{ height: '38px' }} alt='img' src={apple} ></img></a>
                </div>
              </div>
            </div>
            <div>
              <div className='f-18  f-600 capitalize cp-t-1rem cp-b-10 textColor'> FOLLOW US </div>
              <div className='pText   textColor'>
                <div className='d-flex flex-row' >
                  <div>
                   <a href="https://www.facebook.com/lesfemmes.ae" target="_blank" > <img className='Social-links' alt='img' src={facebook} ></img></a>
                   </div>
                  <div style={{ marginLeft: '5%' }} ></div>
                  <a href="https://www.youtube.com/channel/UCXxg8Py0xgz36_5SCarN_eQ/featured" target="_blank" > <img className='Social-links ' alt='img' src={isnta} ></img></a>
                  <div style={{ marginLeft: '5%' }} ></div>
                  <a href="https://www.instagram.com/lesfemmes.ae/" target="_blank" ><img className='Social-links ' alt='img' src={youtube} ></img></a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1' ></div>
        </div>
      </div>
      <div className='mobileFooter cp-t-10 '>
        <div className='col-md-1' ></div>
        <div className=' pText textColor d-flex align-self-center cp-l-3'>
          <div >
            <AiOutlineCopyrightCircle size="18" />  
            </div>
            <div className='cp-l-10  cp-t-1'>
            Les Femmes 2021. All Right Reserved.
              </div> 
                 </div>
      </div>
    </div>
  );
}
export default CFooter;
