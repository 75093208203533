import './LoginScreen.scss';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import CheaderHalf from '../../app-components/CheaderHalf';
import { GoogleLogin } from 'react-google-login';
import { globleAPI as Global } from '../../globals'
import Alertss from '../../app-components/Alertss';
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

function LoginScreen() {

  const history = useHistory();
  const override = css` display: block; margin: 0 auto; border-color: red;`;
  let [color] = useState("rgb(218, 95, 115)");
  const [password, setpassword] = useState('')
  const [ALertType, setALertType] = useState('')
  let [loading, setLoading] = useState(false);
  const [passwordvalid, setpasswordvalid] = useState(false)
  const [status, setstatus] = useState<any>()
  const [showAlert, setshowAlert] = useState(false)
  const [email, setemail] = useState('')
  const [emailN, setemailN] = useState(false)
  const [token] = useState(localStorage.getItem('token') || '')
  var mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

 useEffect(() => {
if(history.location.state == 'singupalert' ) {
  setstatus('A verification email has been sent to your email address.')
  setshowAlert(true)
  setTimeout(() => {
    setshowAlert(false)
  }, 10000);
}
if (token !== '') {
  history.push('/')
  }
  }, [])

  const Login = () => {

    if (email === '' || password === '' || password.length < 6) {
      emailF()
      passwordFunc()
    } else {
      setLoading(true)
      axios.post(Global.LOGIN, {
        email: email,
        password: password,
      })
        .then((response) => {
          setLoading(false)
          if (response.data.status === 200) {
            localStorage.setItem('userid', response.data.user.id);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('first_name', response.data.user.first_name);
            localStorage.setItem('last_name', response.data.user.last_name);
            localStorage.setItem('imageProfile', response.data.user.profile_pic);
            localStorage.setItem('UserEmail', response.data.user.email);
            history.goBack()
          } else {
            setstatus(response.data.message)
            setshowAlert(true)
            setALertType('danger')
            setTimeout(() => {
              setshowAlert(false)
            }, 5000);
          }
        }, (error) => {
          alert(error)
          setLoading(false)
        });
    }
  }

  const emailF = () => {
    if (mailformat.test(email)) {
      console.log('email is match')
      setemailN(false)
    } else {
      setemailN(true)
    }
  }

  const passwordFunc = () => {
    if (password.length < 6) {
      setpasswordvalid(true)
    } else {
      setpasswordvalid(false)
    }
  }

  const responseGoogle = (data: any) => {
    console.log('errors',data)
    saveGoogleLoginData(data.profileObj)
  }

  const saveGoogleLoginData = (data: any) => {
    console.log(data.givenName)
    console.log(data.familyName)
    console.log(data.email)

    axios.post(Global.GOOGLE_LOGIN, {
      first_name: data.givenName,
      last_name: data.familyName,
      email: data.email,
    })
      .then((response) => {
        if (response.data.status == 200) {
          localStorage.setItem('userid', response.data.user.id);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('first_name', response.data.user.first_name);
          history.push('/')
          setALertType('success')
          setstatus(response.data.message)
          setshowAlert(true)
          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
        } else {
           setstatus(response.data.message)
           setALertType('danger')
          setshowAlert(true)
          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
        }
      }, (error) => {
        console.log('login api response error', error);
      });
  }

  const responseGoogleFail = (data: any) => {
    console.log('errors',data)
    setstatus('There is a problem please try again later')
    setALertType('danger')
          setshowAlert(true)
          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
  }
  const  handleKeyDown = (e:any)=>{
    if (e.key === 'Enter') {
      Login()
    }
  }

  return (
    <div >
      <CheaderHalf />
      {showAlert == true && <Alertss type={ALertType} status={status} /> }
      <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
          <p className='  f-15  main-font' style={{ color: 'gray' }} >Remember everything Important</p>
          <div>
            <GoogleLogin
              clientId="937559180864-0arkpdc3mleqviaiiirnotau029j00mo.apps.googleusercontent.com"
              buttonText="Continue with google"
              onSuccess={()=>responseGoogle}
              onFailure={()=>responseGoogleFail}
            />
          </div>
         <div className=" d-flex position-absolute justify-content-center" style={{ width: '100%',height:'100%' }} > <ScaleLoader color={color} loading={loading} css={override} height={40} width={6} /></div>
          <div style={{ marginTop: '1%' }}>
            <label className='main-font'>──────── or ────────</label>
          </div>
        </ div>
        <form >
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
            <div className='col-md-3 col-lg-3 col-8 d-flex cm-t-10 flex-column' >
              <input id="email" onBlur={() => emailF()} type="text" className="form-control" name="email" placeholder="Email" value={email} onChange={(text: any) => setemail(text.target.value)} />
              {emailN == true ? (<p className='label text-danger f-13 main-font' >Email is not valid</p>) : null}
            </div>
          </div>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
            <div className='col-md-3 col-lg-3 col-8  flex-column d-flex cm-t-10'  >
              <input onBlur={() => passwordFunc()} type="password"onKeyDownCapture={(e)=>handleKeyDown(e)} className="form-control" placeholder="Password" value={password} onChange={(text: any) => setpassword(text.target.value)} />
              {passwordvalid == true ? (<p className='label text-danger f-13 main-font' >Must be at least 6 characters</p>) : null}
            </div>
          </ div>
        </form>
        
        <div className='margintop' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <div className='col-md-3 col-8 col-xl-3' >
            <div className='col-12' >
              <button type="button" style={{ width: '100%' }} onClick={() => Login()}  className="btn btn-primary border-0"  >Log In</button>
            </div>
          </ div>
        </div>
        <div className='margintop' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <div >
            <div className='col-12 d-flex' >
            <p className='text-center f-15  main-font' style={{ color: 'gray' }} >Don't you have an account?</p>
              <label  style={{  }} onClick={() =>history.push('/signup')}  className=" border-0 cp-l-8 text-center primary-color hoverUnderline main-font  touchAble"  >Create Account</label>
            </div>
          </ div>
        </div>
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <div >
            <div className='col-12' >
              <label  onClick={() => history.push('/forgot-password')} className=" border-0 cp-l-8 text-center primary-color hoverUnderline main-font  touchAble"  >Forgot Password</label>
            </div>
          </ div>
        </div>
        </div>
        <div>
        <label className='text-center primary-color f-15 cp-l-5  main-font  justify-content-center d-flex  hoverUnderLine touchAble' ></label>
        </div>
      </div>
  );
}

export default LoginScreen;
