import React, { useEffect ,useState} from 'react';
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useHistory } from 'react-router-dom'
import CFooter from '../app-components/CFooter';
import HeaderForLuxury from './HeaderForLuxury';
import HeaderforRetail from './HeaderforRetail';

function CardThanks() {

    const [TrueThanks, setTrueThanks] = React.useState(false);
    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const history = useHistory();

    useEffect(() => {
        if (window.location.search === undefined || window.location.search === '') {
            setTrueThanks(true)
        } else {
            setTrueThanks(false)
        }
        window.scrollTo(0, 0)
    }, []);

    const gotoShopAll = () => {
        history.push('/all-products', ['', '118']);
    };

    return (
        <div>
            {Selected == 'Luxury'?  <HeaderForLuxury  />:<HeaderforRetail />}
         {TrueThanks === true ? (
                <div style={{ height: '80vh', }} ></div>
            ) : (
                <div className=" col-12  justify-content-center" style={{ height: '80vh', }} >
                    <label className=" justify-content-center d-flex align-item-center secHeading primary-color  " style={{ height: '40%' }}  >Thanks for shopping!</label>
                    <div className="d-flex  justify-content-center   align-item-center" >
                        <div className="d-flex flex-column" >
                            <div className="d-flex flex-column cp-b-20 cp-t-20 " >
                                <div className="d-flex justify-content-center" >
                                    <RiCheckboxCircleFill color="rgb(218, 95, 115)" size="35" />
                                </div>
                                <p className=' labelHeading label cm-t-10 main-font primary-color' style={{ textAlign: 'center' }} >Your order was completed successfully</p>
                                <div className=" cp-t-10 d-flex justify-content-center" >
                                    <p className=" label main-font primary-color" style={{ textAlign: 'center', width: '50%' }} >An email recieved including the details about your order has been sent to the email address provided. Please keep it for your records.</p>
                                </div>
                                <div className='cm-t-30   cp-7   text-center touchAble col-12 col-md-8 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                    <p className='label main-font labelText  touchAble ' onClick={() => gotoShopAll()} style={{ color: 'white' }}>Continue Shopping</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <CFooter />
        </div>
    );
}
export default CardThanks