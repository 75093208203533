import './MyAccount.scss';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { AiOutlineCamera, AiOutlineSearch } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import CFooter from '../../app-components/CFooter';
import girl from '../../assets/emojiwindle.png';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import React, { useState, useEffect, useRef } from 'react';
import Alert from 'react-bootstrap/Alert'
import 'react-image-picker/dist/index.css'
import { globleAPI as Global } from '../../globals'
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import Mapss from '../../app-components/Mapss';


function MyAccount(props: any) {

  const [token] = useState(localStorage.getItem('token') || '')
  const [showAlert, setshowAlert] = useState(false)
  const [allGroups, setallGroups] = useState([])
  const [agree, setagree] = useState(false)
  const [PictureURLs, setPictureURLs] = useState('')
  const [GroupName, setGroupName] = useState('')
  const [GroupDescription, setGroupDescription] = useState('')
  const [GroupVisible, setGroupVisible] = useState('p')
  const [displayUi, setdisplayUi] = useState('contacts')
  const [selectedCommunityy, setselectedCommunityy] = useState<any>([])
  const [status, setstatus] = useState('')
  const [CommunityData, setCommunityData] = useState([])
  const [TrendingGroups, setTrendingGroups] = useState([])
  const [show, setShow] = useState(false);
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [showAverts, setshowAverts] = useState(false);
  const [target, setTarget] = useState(null);
  const [TargetAdvert, setTargetAdvert] = useState(null);
  const [UnpaidAdverts, setUnpaidAdverts] = useState([]);
  const [calendarCategories, setcalendarCategories] = useState([]);
  const [AllApproveUser, setAllApproveUser] = useState([]);
  const [dataofmulti] = React.useState<any>([]);
  const [requestedUser, setrequestedUser] = React.useState<any>([]);
  const ref = useRef(null);
  const history = useHistory();

  useEffect(() => {
    GetAllCommunities()
    GetAllTrendingGroups()
    GetUserAllGroups()
    GetAllGroupsFromAllCommunities()
    GetUnpaidAdvert()
    calendarEventCategories()
    showAllApproveUser()
  }, [])


  const showAllApproveUser = () => {
    axios.get(Global.SHOW_ALL_APPROVE_USER + token,)
      .then(response => {
        response.data.totalContact && response.data.totalContact.forEach((item: any) => item.twitter_profile_link = false);
        setAllApproveUser(response.data.totalContact)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const calendarEventCategories = () => {
    axios.get(Global.CALENDAR_EVENT_CATEGORIES + token)
      .then(response => {
        setcalendarCategories(response.data.categories)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const GetUnpaidAdvert = () => {
    axios.get(Global.GET_BUSINESS_ADVERTS_FOR_DASHBOARD + token + "&post_status=0",)
      .then(response => {
        setUnpaidAdverts(response.data.business_adverts)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const CreateGroup = () => {
    console.log('dataof multi =', dataofmulti, PictureURLs)
    let formdata = new FormData();
    let members_arr = dataofmulti;

    members_arr.map((mem_data: any, index: any) => {
      formdata.append("members[" + index + "]", mem_data.id);
    });
    formdata.append("groupname", GroupName);
    formdata.append("community", selectedCommunityy.id);
    formdata.append("visibility", GroupVisible);
    formdata.append("description", GroupDescription);
    formdata.append("groupimage", PictureURLs);

    axios({
      method: "post",
      url: "https://lesfemmestesting.dominioninc.org/public/api/CreateGroup?token=" + token,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(response => {
        setstatus(response.data.message)
        setshowAlert(true)
        setTimeout(() => {
          setshowAlert(false)
        }, 5000);
        setGroupName('')
        setselectedCommunityy('')
        setGroupVisible('')
        setGroupDescription('')
        setPictureURLs('')
      })
      .catch(function (error) {
        console.log('groupscreate', error)
      });
  };

  const GetUserAllGroups = () => {
    axios.post(Global.WebGetUserAllGroups + token)
      .then(response => {
        setallGroups(response.data.groups.data)
      })
      .catch(function (error) {
        console.log('respons =', error)
      });
  };

  const GetAllGroupsFromAllCommunities = () => {
    axios.get(Global.WebGetAllGroupsFromAllCommunities + token + "&page=" + 1,)
      .then(response => {
        setallGroups(response.data.groups.data)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const GetAllCommunities = () => {
    axios.post(Global.GET_ALL_COMMUNITIES + token)
      .then(response => {
        setCommunityData(response.data.communities_data)
      })
      .catch(function (error) {
        console.log('responseresponseresponseresponse', error)
      });
  };

  const GroupJoinRequest = (data: any) => {
    if (token != '') {
      axios.get('https://lesfemmestesting.dominioninc.org/public/api/GroupJoinRequest?token=' + token + "&group_id=" + data.group_id + "&creater_id=" + data.creater_id,)
        .then(response => {
          setstatus(response.data.message)
          setshowAlert(true)
          setTimeout(() => {
            setshowAlert(false)
          }, 5000);
        })
        .catch(function (error) {
          console.log('dfasfasfasfdasfdassfdads', error)
        });
    } else {
      setshowAlert(true)
      setTimeout(() => {
        setshowAlert(false)
      }, 5000);
      setstatus('please login first to join the group')
    }

  };

  const GetAllTrendingGroups = () => {
    axios.post(Global.WebGetAllTrendingGroupsFromAllCommunities + token)
      .then(response => {
        setTrendingGroups(response.data.groups)
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  };

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClickAdverts = (event: any) => {
    setshowAverts(!showAverts);
    setTargetAdvert(event.target);
  };

  const selectedComunity = (data: any) => {
    setselectedCommunityy(data)
    setagree(false)
  }

  const PictureUrl = (data: any) => {
    setPictureURLs(data)
  }

  const Visible = (data: any) => {
    if (data == 'p') {
      setGroupVisible('c')
    } else {
      setGroupVisible('p')
    }
  }

  const checkmultiselect = (selectedUser: any) => {
    let selectedData: any;
    selectedData = AllApproveUser.find((item: any) => item.first_name === selectedUser.first_name);
    if (selectedData) {
      selectedData.twitter_profile_link = !selectedData.twitter_profile_link;
      dataofmulti.push(selectedData);
      setAllApproveUser(AllApproveUser)
    }

    let brandFilters: any = [];
    dataofmulti.map((item: any) => {
      if (item.twitter_profile_link) {
        brandFilters.push(item.brand)
      }
      console.log('setrequestedUser', dataofmulti)
      if (dataofmulti.twitter_profile_link == true) {
        setrequestedUser(dataofmulti)
        console.log('setrequestedUser', requestedUser)
      }
    })
  }

  return (
    <div>
      {showAlert == true ? (
        <div className='d-flex justify-content-center' >
          <Alert style={{ marginTop: '30vh', position: 'absolute', zIndex: 1, width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="primary" show={showAlert}  >
            <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
            <p style={{}} >{status}</p>
          </Alert>
        </div>
      ) : null}
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="padding" >
        <label className="cp-t-30  main-font  secHeading  primary-color" >My Account</label>
        <div className='AccountOptions' >
          <div className='  main-font label   pointer f-13  text-color   '>
            <label onClick={() => { setdisplayUi('contacts') }} className=' main-font  labelText  cp-r-30 hoverUnderLine' >Contacts  </label>
          </div>
          <div className='  main-font label   pointer f-13  text-color   '>
            <label onClick={handleClick} className=' main-font  labelText  cp-r-30 hoverUnderLine'> My Group </label>
            <Overlay
              show={show}
              target={target}
              placement="bottom"
              container={ref.current}
              containerPadding={50}
            >
              <Popover id="popover-contained">
                <Popover.Content >
                  <div className='cp-b-10 d-flex flex-column cp-10' >
                    <label onClick={() => {
                      setdisplayUi('groups');
                      setShow(!show)
                    }} > <a className="primary-color f-15   main-font touchAble hoverUnderLine" >Groups</a>   </label>
                    <label onClick={() => {
                      setdisplayUi('groupdiscover');
                      setShow(!show)
                    }}  > <a className="primary-color f-15   main-font touchAble hoverUnderLine" >Discover</a>  </label>
                    <label onClick={() => {
                      setdisplayUi('groupcreate');
                      setShow(!show)
                    }}  > <a className="primary-color f-15   main-font touchAble hoverUnderLine" >Create</a> </label>
                  </div>
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
          <div className='  main-font label   pointer f-13  text-color   '>
            <label onClick={handleClickAdverts} > <a className='main-font  labelText  cp-r-30 hoverUnderLine' style={{ color: 'black' }} >Manage Adverts</a> </label>
            <Overlay
              show={showAverts}
              target={TargetAdvert}
              placement="bottom"
              container={ref.current}
              containerPadding={50}
            >
              <Popover id="popover-contained">
                <Popover.Content >
                  <div className='cp-b-10 d-flex flex-column cp-10' >
                    <label onClick={() => {
                      setdisplayUi('Running');
                      setshowAverts(!showAverts)
                    }} ><a className="primary-color f-15   main-font touchAble hoverUnderLine" >Running</a>    </label>
                    <label onClick={() => {
                      setdisplayUi('draft');
                      setshowAverts(!showAverts)
                    }}  ><a className="primary-color f-15   main-font touchAble hoverUnderLine" >Draft</a> </label>
                    <label onClick={() => {
                      setdisplayUi('groupcreate');
                      setshowAverts(!showAverts)
                    }}  ><a className="primary-color f-15   main-font touchAble hoverUnderLine" >Create</a> </label>
                  </div>
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
          <div className='  main-font label   pointer f-13  text-color ' onClick={() => { setdisplayUi('NearMe') }}  >
            <label className='main-font  labelText  cp-r-30 hoverUnderLine' >Les Femmes Near Me</label>
          </div>
          <div className='  main-font label   pointer f-13  text-color   ' onClick={() => { setdisplayUi('calendar') }} >
            <label className='main-font  labelText  cp-r-30 hoverUnderLine' >Les Femmes Calender</label>
          </div>
        </div>
        {displayUi == 'contacts' ? (
          <div className="cp-t-30 cp-b-30">
            <label className="f-19 f-600   label  main-font" >Contacts</label>
            <div className='border cp-5 cm-b-10 cm-t-10 ' style={{ borderRadius: 5, paddingRight: 30 }} >
              <AiOutlineSearch size="22" color="gray" />
              <input style={{ paddingLeft: 10 }} className='inputsearch' type="text" placeholder="Search..." name="search" />
            </div>
            <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
              <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
              <label className="text-muted text-center" style={{ width: '30%' }} >Your Friend List is empty, Please add your friends first</label>
            </div>
          </div>
        ) : displayUi == 'groups' ? (
          <div className="cp-t-30 cp-b-30">
            <label className="f-19 f-600   label  main-font" >Groups</label>
            <div className="border cm-t-15" ></div>
            <div className='border cp-5 cm-b-10 cm-t-10 ' style={{ borderRadius: 5, paddingRight: 30 }} >
              <AiOutlineSearch size="22" color="gray" />
              <input style={{ paddingLeft: 10 }} className='inputsearch' type="text" placeholder="Search..." name="search" />
            </div>
            <div className=" d-flex flex-row"  >
              <div className=" d-flex flex-row cm-10 flex-wrap" style={{ justifyContent: 'space-evenly' }}  >
                {allGroups.map((data: any) =>
                  <div className="d-flex flex-column   align-item-center" style={{ width: '150px' }} >
                    {data.group_pic !== '' || data.group_pic !== null ? (
                      <img className=' cm-10 ' style={{ height: '100px', width: '100px', borderRadius: 100 }} alt='img' src={Global.THUMBNAIL_PATH + data.group_pic} ></img>
                    ) : (
                      <img className=' cm-10 ' style={{ height: '100px', width: '100px', borderRadius: 100 }} alt='img' src={girl} ></img>
                    )}

                    <label className="text-center  text-truncate" style={{ width: '130px' }}>{data.name}</label>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : displayUi == 'groupdiscover' ? (
          <div className="cp-t-30 cp-b-30">
            <label className="f-19 f-600   label  main-font " >Discovers</label>
            <div className="border cm-t-15" ></div>
            <div className="d-flex flex-row overflow-auto">
              {CommunityData.map((data: any) =>
                <div >
                  <img className='cp-10 ImageStl' src={Global.IMAGE_PATH + data.community_pic} ></img>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between cp-12 border-bottom" >
              <label className="label" >Suggested Groups </label>
              <label className="label">View All </label>
            </div>
            {TrendingGroups.map((data: any) =>
              <div className="d-flex flex-row border-bottom"  >
                <div className="col-md-1.5" >
                  <img className='cp-10 groupImgStl' src={Global.THUMBNAIL_PATH + data.group_pic} ></img>
                </div>
                <div className="d-flex justify-content-between cp-12  col-md-11" >
                  <div className="d-flex flex-column align-self-center" >
                    <label className="label f-15 " >{data.name} </label>
                    {data.group_type == 'p' ? (
                      <label className="label f-12 " > Public Group {data.total_members} members</label>
                    ) : null}
                  </div>
                  <div onClick={() => GroupJoinRequest(data)} className="d-flex flex-column align-self-center" >
                    <label className=" border f-600 cp-r-11 cp-l-11 cp-t-3 cp-b-3 border-radius label  main-font" >Join</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : displayUi == 'groupcreate' ? (
          <div>
            {token !== '' ? (
              <div className="cp-t-30 cp-b-30">
                <label className="f-19 f-600   label  main-font" >Create</label>
                <div className="border cm-t-15" ></div>
                <div className="d-flex flex-row cp-t-20 cp-b-20 "  >
                  <div className="" style={{ width: '250%' }} >
                    <div className='  d-flex align-self-center align-content-center align-item-center justify-content-center' style={{ height: '100px', width: '140px' }}>
                      <span className="btn  btn-file  d-flex  align-item-center" >
                        <AiOutlineCamera size="2.6rem " className="border cp-30" style={{ height: '110px', width: '110px', borderRadius: 100, }} color="gray" />  <input type="file" accept="image/*" onChange={(text: any) => PictureUrl(text.target.files[0])} ></input>
                      </span>
                    </div>
                  </div>
                  <input type="file"></input>
                  <div className="d-flex justify-content-between align-self-center cp-12  col-md-11 border-bottom" >
                    <input style={{ paddingLeft: 10 }} onChange={(text: any) => setGroupName(text.target.value)} className='inputsearch' type="text" placeholder="Name" />
                  </div>
                </div>
                <div className="cp-t-20 cp-b-20"  >
                  <div className="d-flex justify-content-between align-self-center cp-12  col-md-12 border-bottom" >
                    <input style={{ paddingLeft: 10 }} className='inputsearch' onChange={(text: any) => setGroupDescription(text.target.value)} type="text" placeholder="Description" />
                  </div>
                  <div className="d-flex flex-row-reverse" >
                    <label className=" text-muted" >0/200</label>
                  </div>
                </div>
                <div onClick={() => setagree(!agree)} className="cp-10 border-top border-bottom border-dark d-flex align-self-center justify-content-between flex-row touchAble " style={{ borderWidth: '39%' }} >
                  <label className="label touchAble" >Community</label>
                  {selectedCommunityy != '' ? (
                    <label className="touchAble" >{selectedCommunityy.name}</label>
                  ) : (
                    <FiChevronDown size="22" />
                  )}
                </div>
                {agree == true ? (
                  <div className=' cm-t-20' >
                    <div className=' ' style={{ maxHeight: '300px', overflow: 'auto' }}>
                      {CommunityData.map((data: any) => (
                        <div onClick={() => selectedComunity(data)} className="d-flex flex-row border-bottom"   >
                          <div className="col-md-1.5" >
                            <img className='cp-10' style={{ height: '80px', width: '80px', borderRadius: 100 }} src={Global.IMAGE_PATH + data.community_pic} ></img>
                          </div>
                          <div className="d-flex align-self-center cp-12  col-md-11" >
                            <label className="label f-15 touchAble " >{data.name} </label>
                          </div>
                        </div>
                      ))
                      }
                    </div>
                  </div>
                ) : null}
                <div className="cp-10 border-bottom border-dark d-flex align-self-center justify-content-between flex-row" style={{ borderWidth: '39%' }} >
                  <label className="label" >Group Visibilty</label>
                  <label className="switch label">
                    <input type="checkbox" onChange={() => Visible('c')} ></input>
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className=' border-bottom cp-13 cm-b-10  ' style={{ borderRadius: 5, paddingRight: 30 }} >
                  <AiOutlineSearch size="22" color="gray" />
                  <input style={{ paddingLeft: 10 }} className='inputsearch' type="text" placeholder="Search..." name="search" />
                </div>
                <div className="cp-t-30 cp-b-30">
                  {AllApproveUser.map((data: any) =>
                    <div>
                      {data.first_name != '' ? (
                        <div className="d-flex flex-row border-bottom"  >
                          {data.user_image != '' && data.user_image != null ? (
                            <div className="col-md-1.5" >
                              <img className='cp-10' style={{ height: '100px', width: '100px', borderRadius: 100 }} src={Global.THUMBNAIL_PATH + data.user_image} ></img>
                            </div>
                          ) : (
                            <div className="col-md-1.5" >
                              <img className='cp-10' style={{ height: '100px', width: '100px', borderRadius: 100 }} src={girl} ></img>
                            </div>
                          )}
                          <div className="d-flex justify-content-between cp-12  col-md-11" >
                            <div className="d-flex flex-column align-self-center" >
                              <label className="label f-15 " >{data.first_name}{data.last_name} </label>
                            </div>
                            <div className="d-flex flex-column align-self-center" >
                              <input onClick={() => checkmultiselect(data)} className="form-check-input largerCheckbox touchAble"
                                type="checkbox" id="defaultCheck1" />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center cm-b-30 cm-t-60 " >
                  <label className="cp-l-60 cp-r-60 cp-t-5 cp-b-5 label f-19   bg-color-primary main-font touchAble" style={{ borderRadius: 5, color: 'white' }} onClick={() => CreateGroup()} >Create</label>
                </div>
              </div>
            ) : (
              <div className="cp-t-30 cp-b-30">
                <label className="f-19 f-600   label  main-font" >Create Group</label>
                <div className="border cm-t-15" ></div>
                <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
                  <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                  <label className="text-muted text-center" style={{ width: '30%' }} >Please login First to  use this feature</label>
                </div>
              </div>
            )
            }

          </div>
        ) : displayUi == 'Running' ? (
          <div>

            {token !== '' ? (
              <div className="cp-t-30 cp-b-30">
                <label className="f-19 f-600   label  main-font" >Running</label>
                <div className="border cm-t-15" ></div>
                <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
                  <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                  <label className="text-muted text-center" style={{ width: '30%' }} >No Advert Found</label>
                </div>
              </div>
            ) : (
              <div className="cp-t-30 cp-b-30">
                <label className="f-19 f-600   label  main-font" >Create Group</label>
                <div className="border cm-t-15" ></div>
                <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh' }} >
                  <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                  <label className="text-muted text-center" style={{ width: '30%' }} >Please login First to  use this feature</label>
                </div>
              </div>
            )
            }
          </div>
        ) : displayUi == 'draft' ? (
          <div className="cp-t-30 cp-b-30" style={{ minHeight: '55vh' }}>
            <label className="f-19 f-600   label  main-font" >Draft</label>
            <div className="border cm-t-15" ></div>
            <div className="" >
              {UnpaidAdverts.map((data: any) =>
                <div className=" d-flex  flex-row cm-50"  >
                  <div className="col-md-5" >
                    <img className='' style={{ width: '95%', height: '100%' }} alt='img' src={Global.IMAGE_PATH + data.post_image} ></img>
                  </div>
                  <div className="col-md-7 cp-t-40 cp-b-10" >
                    <div className="d-flex justify-content-between" >
                      <label className="label main-font" >{data.name}</label>
                      <label className="label main-font text-danger">Waiting For Payments</label>
                    </div>
                    <label className="label main-font text-muted">{data.name}</label>
                    <div className="d-flex justify-content-between cp-t-20" >
                      <label className="label main-font" >Package:</label>
                      <label className="label main-font text-muted">{data.package} </label>
                    </div>
                    <div className="d-flex justify-content-between" >
                      <label className="label main-font" >Expiry Date:</label>
                      <label className="label main-font">{data?.expiry_date}-</label>
                    </div>
                    <div className="d-flex justify-content-between" >
                      <label className="label main-font" >Created at:</label>
                      <label className="label main-font text-muted">{data.created_at}</label>
                    </div>
                    <div className="d-flex justify-content-center cm-b-30 cm-t-60 " >
                      <label className="cp-l-50 cp-r-50 cp-t-5 cp-b-5 label f-19   bg-color-primary main-font touchAble" style={{ borderRadius: 3, color: 'white' }} onClick={() => CreateGroup()} >Edit</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : displayUi == 'NearMe' ? (
          <div className="cp-t-30 cp-b-30">
            <label className="f-19 f-600   label  main-font" >LesFemmes Near Me</label>
            <div style={{ height: '80vh' }} className="cm-t-20"  >
              <Mapss />
            </div>
          </div>
        ) : displayUi == 'calendar' ? (
          <div className="cp-t-30 cp-b-30 ">
            <label className="f-19 f-600   label  main-font " >Event Categories</label>
            <div className="flex-wrap d-flex flex-row  cm-t-15 border-top border-dark"  >
              {calendarCategories.map((data: any) =>
                <div className="cp-10 " style={{ width: '50%' }} >
                  <div className="">
                    <div style={{ right: 0, bottom: 0 }} className="d-flex justify-content-center page-left-padding page-right-padding cm-r-50 cm-l-50" >
                      <div style={{ backgroundColor: "rgba(218, 95, 115)", opacity: 0.6, padding: 3, width: '37.3vw', position: 'absolute', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <p className="label cp-5" style={{ color: "white", textAlign: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>{data.name}</p>
                      </div>
                    </div>
                    <div >
                      <img style={{ height: '300px', width: '100%', borderRadius: 10 }} className=' img-responsive' alt='img' src={Global.IMAGE_PATH + data.image} ></img>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <CFooter></CFooter>
    </div>
  );
}

export default MyAccount;

