import { useEffect, useState } from "react";
import axios from "axios";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function Mapss(props) {
  const google = window.google;
  const [token] = useState(localStorage.getItem("token") || "");
  const [Markersss, setMarkersss] = useState([]);
  const [latitude, setlatitude] = useState("");
  const [Longitude, setLongitude] = useState("");

  useEffect(() => {
    getLocation();
    GetNearbyUsers();
  }, []);

  const mapStyles = {
    width: "70%",
    height: "70%",
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setlatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  const GetNearbyUsers = () => {
    axios
      .get(
        "https://lesfemmestesting.dominioninc.org/public/api/GetNearbyUsers?token=" +
          token
      )
      .then((response) => {
        setMarkersss(response.data.userdata);
      })
      .catch(function (error) {
        console.log("response if the api is errror =", error);
      });
  };

  const displayMarkers = () => {
    return Markersss.map((store, index) => {
      return (
        <Marker
          name={"Current location"}
          key={index}
          id={index}
          position={{
            lat: store.latitude,
            lng: store.longitude,
          }}
          title={"The marker`s title will appear as a tooltip."}
          icon={{
            url: store.user_image
              ? "https://lesfemmestesting.dominioninc.org/public/uploads/thumbnail/" +
                store.user_image
              : "https://png.pngitem.com/pimgs/s/146-1468281_profile-icon-png-transparent-profile-picture-icon-png.png",
            anchor: new google.maps.Point(32, 32),
            scaledSize: new google.maps.Size(40, 40),
          }}
          onClick={() => console.log("You clicked me!")}
        ></Marker>
      );
    });
  };

  return (
    <div>
      {Longitude !== "" ? (
        <Map
          google={props.google}
          style={mapStyles}
          zoom={12}
          initialCenter={{ lat: latitude, lng: Longitude }}
        >
          {displayMarkers()}
        </Map>
      ) : (
        <label>wait we are fetching your location</label>
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA3KMurQ8cStIZjWxpu0d5bH5QsKGWUu00",
})(Mapss);


// import { useEffect, useState } from "react";
// import axios from "axios";
// import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

// function Mapss(props:any) {

//    var google: any;

//   const googles = window.google;
//   const [token] = useState(localStorage.getItem("token") || "");
//   const [Markersss, setMarkersss] = useState([]);
//   const [latitude, setlatitude] = useState<any>("");
//   const [Longitude, setLongitude] = useState<any>("");

//   useEffect(() => {
//     getLocation();
//     GetNearbyUsers();
//   }, []);

//   const mapStyles = {
//     width: "70%",
//     height: "70%",
//   };

//   const getLocation = () => {
//     navigator.geolocation.getCurrentPosition(function (position) {
//       setlatitude(position.coords.latitude);
//       setLongitude(position.coords.longitude);
//     });
//   };

//   const GetNearbyUsers = () => {
//     axios
//       .get(
//         "https://lesfemmestesting.dominioninc.org/public/api/GetNearbyUsers?token=" +
//           token
//       )
//       .then((response) => {
//         setMarkersss(response.data.userdata);
//       })
//       .catch(function (error) {
//         console.log("response if the api is errror =", error);
//       });
//   };

//   // const displayMarkers = () => {
//   //   return Markersss.map((store:any, index:any) => {
//   //     return (
//   //       <Marker
//   //         name={"Current location"}
//   //         key={index}
//   //         id={index}
//   //         position={{
//   //           lat: store.latitude,
//   //           lng: store.longitude,
//   //         }}
//   //         title={"The marker`s title will appear as a tooltip."}
//   //         icon={{
//   //           url: store.user_image
//   //             ? "https://lesfemmestesting.dominioninc.org/public/uploads/thumbnail/" +
//   //               store.user_image
//   //             : "https://png.pngitem.com/pimgs/s/146-1468281_profile-icon-png-transparent-profile-picture-icon-png.png",
//   //           anchor: new google.maps.Point(32, 32),
//   //           scaledSize: new google.maps.Size(40, 40),
//   //         }}
//   //         onClick={() => console.log("You clicked me!")}
//   //       ></Marker>
//   //     );
//   //   });
//   // };

//   return (
//     <div>
//       {Longitude !== "" ? (
//         <Map
//           google={props.google}
//           style={mapStyles}
//           // zoom={12}
//           initialCenter={{ lat: latitude, lng: Longitude }}
//         >
//           {/* {displayMarkers()} */}
//         </Map>
//       ) : (
//         <label>wait we are fetching your location</label>
//       )}
//     </div>
//   );
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyA3KMurQ8cStIZjWxpu0d5bH5QsKGWUu00",
// })(Mapss);
