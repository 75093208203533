import './Storeitem.scss';
import React, { useState, useRef,useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { Link, useHistory,useLocation } from 'react-router-dom'
import axios from "axios";
import { globleAPI as Global } from '../globals'

function UserOptionsPopOver(props: any) {

  const ref = useRef(null);
  const location = useLocation();
  const [first_name] = useState(localStorage.getItem('first_name') || '')
  const [userid] = useState(localStorage.getItem('userid') || '')
  const [last_name] = useState(localStorage.getItem('last_name') || '')
  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [NoStore, setNoStore] = useState('');
  const [token] = useState(localStorage.getItem('token') || '')
  const history = useHistory();

  useEffect(() => {
    getMyStoreDetail()
  }, [])

  const logOut = () => {
    if(location.pathname  == '/'){
      localStorage.removeItem('userid');
      localStorage.removeItem('token');
      window.location.reload()
    }else{
      localStorage.removeItem('userid');
      localStorage.removeItem('token');
      history.push('/')
    }
  
  };
  const moving = (data: any, data2: any) => {
    history.push('/all-products', [data, data2]);
  };

  const move = (data: any) => {
      history.push('/', [data])
  };
  const getMyStoreDetail = () => {
    axios
      .get(Global.WebGetStoreDetailsV3 + token + "&user_id=" + userid + "&page=" + 1)
      .then(response => {
        if (response.data.store_details[0].store_address == undefined) {
          setNoStore('yes')
        } else {
          setNoStore('no')
        }
      })
      .catch(function (error) {
        console.log('response if the api is errror =', error)
      });
  }
  const saleOnAllCategory = () => {
    history.push('/luxury-products', ['SaleOnAllProducts']);
  };

  const movingLuxury = (data: any, data2: any) => {
    history.push('/luxury-products', [data, data2]);
  };

  return (
    <div>
      <Overlay
        show={props.show}
        target={props.target}
        placement="bottom"
        container={ref.current}
        // containerPadding={50}
      >
        <Popover style={{ width: '70%', marginTop: '1.5%' }} id="popover-contained">
          <Popover.Content >
          {token !== '' && 
                       <div className='d-flex flex-row border-bottom cp-b-10 ' >
                       <div className='cp-l-10 cp-t-10' >
                         <label className=" secHeading label  main-font" >{first_name} {last_name}</label><br></br>
                         <label className=" pText " >  <Link to="/MyAccount" className="link primary-color pText font-weight-bold  label main-font " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >View Your Profile</Link> </label>
                       </div>
                     </div>
            }         
<div className='mobiledisplay' >
{Selected == 'Luxury'?  (<div  className=' mobiledisplay border-bottom cp-b-10 d-flex flex-column cp-10' >
{token == '' && (
  <div className='border-bottom ' >
<label className=" pText " >  <Link to="/login" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >Login</Link> </label>
  <label className=" pText cp-l-10 cp-r-10 " >  /  </label>              
  <label className=" pText " >  <Link to="/signup" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0}} >Sign Up </Link> </label>              
     </div>
  )}
          <label  onClick={() => move('home')} className=" pText cp-t-10 " >Home</label>
  <label onClick={() => history.push('/luxury-products', ['allLuxuryProducts'])} className=" pText " style={{}} > Shop All</label>
          <label onClick={()=> history.push('/luxury-products', ['usedItems'])} className="pText " style={{}} >Sale</label>
            <label onClick={() => history.push('/luxury-products', ['allLuxuryProducts'])} className=" pText " style={{}} >All Brands</label>
        <label onClick={() => movingLuxury('categoryProducts', 32)} className=" pText " style={{}} >Clothing </label>
          <label onClick={() => movingLuxury('categoryProducts', 30)}  className=" pText "  >Bags </label>
 <label onClick={() => movingLuxury('categoryProducts', 33)} className=" pText " > Shoes </label>
       <label onClick={() => movingLuxury('categoryProducts', 31)}  className=" pText " > Jewellery </label>
         <label onClick={() => movingLuxury('categoryProducts', 125)}  className=" pText " > Kids</label>
    {token !== '' ? (
            <Link to={NoStore == 'yes' ? "/edit_store" : "/sale-product"} className=" pText " style={{ color: 'black', border: 0 }} >SELL NOW</Link>
          ) : (
            <Link to="/login" className=" pText " style={{ color: 'black', border: 0 }} >SELL NOW</Link>
            )}
  
          <label onClick={() => saleOnAllCategory()} className=" pText cp-t-7 " >Pre-Owned</label>
       
      </div>):(
        <div className=' mobiledisplay border-bottom cp-b-10 d-flex flex-column cp-10' >
  {token == '' && (
    <div className='border-bottom ' >
<label className=" pText " >  <Link to="/login" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >Login</Link> </label>
  <label className=" pText cp-l-10 cp-r-10 " >  /  </label>              
  <label className=" pText " >  <Link to="/signup" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0}} >Sign Up </Link> </label>              
     </div>
  )}
<label  className=" pText " >Home</label>
<label onClick={()=>moving('AllProducts', '')}  className=" pText " style={{}} > Shop All</label>
<label onClick={()=> history.push('/all-products', ['AllSale'])} className=" pText " style={{}} >Sale</label>
<label onClick={()=>moving('AllProducts', '')} className=" pText " style={{}} >All Brands</label>
 <label onClick={() => moving('itemCat', 121)} className=" pText " style={{}} >Clothing </label>
<label onClick={() => moving('itemCat', 119)} className=" pText "  >Bags </label>
<label onClick={() => moving('itemCat', 122)} className=" pText " > Shoes </label>
<label onClick={() => moving('itemCat', 120)}  className=" pText "> Jewellery </label>
<label  className=" pText " onClick={() => moving('itemCat', 125)}   > Kids</label>
<label className=" pText " onClick={() => moving('itemCat', 26)}  > Beauty</label>
{token !== '' ? (
  <label className=" pText " >  <Link to={NoStore == 'yes' ? "/edit_store" : "/sale-product"} className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >SELL NOW</Link> </label>
  ) : (
    <label className=" pText " >  <Link to="/login" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >SELL NOW</Link> </label>
    )}
  </div>
)}
              </div>
{token !== '' && (
  <div>
        
            <div className='border-bottom cp-b-10 d-flex flex-column cp-10' >
              <label className=" pText " >  <Link to="/MyAccount" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >My Account</Link> </label>
              <label className=" pText " >  <Link to="/my-sales" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >My Sales</Link> </label>
              <label className=" pText " >  <Link to="/purchases" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >My Purchases</Link> </label>
            </div>
            <div className='cp-b-10 d-flex border-bottom flex-column cp-10' >
              <label className=" pText " >  <Link to="/setting" className="link " style={{ color: 'rgb(27, 27, 27)', border: 0 }} >Settings</Link> </label>
              <label onClick={() => history.push('/faqs', ['customer'])} className=" pText hoverUnderLine touchAble " > Help Center </label>
              <label onClick={() => logOut()} className='pText label touchAble hoverUnderLine' >Log Out </label>
            </div>
            </div>
                        )}

          
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}

export default UserOptionsPopOver;


