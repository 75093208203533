import { globleAPI as Global } from '../../globals'
import '../sellers-side/EditStore.scss';
import axios from "axios";
import { useEffect, useState, useRef } from 'react';
import InputField from '../../app-components/InputField';
import { useHistory } from "react-router-dom";
import CFooter from '../../app-components/CFooter';
import Alertss from '../../app-components/Alertss';
import { AiOutlineCamera } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import MultiselectDropDown from '../../app-components/MultiselectDropDown';
import dummyPic from '../../assets/emojiwindle.png';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';
import HeaderforRetail from '../../app-components/HeaderforRetail';

function EditStore(props: any) {

    const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token') || '')
    const [AllCities, setAllCities] = useState<any>([])
    const [AllCountries, setAllCountries] = useState<any>([])
    const [storeName, setstoreName] = useState<any>('');
    const [AccountTitle, setAccountTitle] = useState<any>('');
    const [Iban, setIban] = useState<any>('');
    const [AccountNumber, setAccountNumber] = useState<any>('');
    const [BankName, setBankName] = useState<any>('');
    const [BankAddress, setBankAddress] = useState<any>('');
    const [YourContact, setYourContact] = useState<any>('');
    const [AgreeToConditions, setAgreeToConditions] = useState<any>(false);
    const [blurOption, setblurOption] = useState(false);
    const [sendedPath, setsendedPath] = useState<any>();
    const [PictureURLs, setPictureURLs] = useState('')
    const ref = useRef(null);
    const [agreeError, setagreeError] = useState(false);
    const [DescriptionError, setDescriptionError] = useState(false);
    const [StoreDescription, setStoreDescription] = useState(true);
    const [newpicture, setnewpicture] = useState<any>('');
    const [alertType, setalertType] = useState<any>('success');
    const [showAlert, setshowAlert] = useState(false)
    const [storefilled, setstorefilled] = useState(false)
    const [NewUser, setNewUser] = useState(false)
    const [status, setstatus] = useState('')
    const [Description, setDescription] = useState('');
    const [Phonenumber, setPhonenumber] = useState('');
    const [Country, setCountry] = useState<any>();
    const [City, setCity] = useState<any>();
    const [Address, setAddress] = useState('');

    useEffect(() => {
        GetStoreDetails()
        GetCountriesListV2()
        GET_BANK_DETAIL()
    }, [])

    const countryFunction = (data: any) => {
        setCountry(data)
        GetCitiesList(data.value)
    }

    const addStoreDetails = () => {
        let formdata = new FormData();
        formdata.append("store_name", storeName);
        formdata.append("store_description", Description);
        formdata.append("phone_number", Phonenumber);
        formdata.append("country", Country.label);
        formdata.append("store_country_id", Country.value);
        formdata.append("city", City);
        formdata.append("store_address", Address);
        formdata.append("store_pic", sendedPath);
        axios({
            method: "post",
            url: Global.RegisterStore + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setalertType('success')
                setshowAlert(true)
                setTimeout(() => {
                    setshowAlert(false)
                }, 5000);
                setstatus('Store Details Updated')
                GetStoreDetails()
            })
            .catch(function (error) {
            });
    };

    const addBankDetails = () => {
        const url = NewUser ? Global.ADD_BANK_DETAIL + token : Global.EDIT_BANK_DETAIL + token
        let formdata = new FormData();
        formdata.append("account_title", AccountTitle);
        formdata.append("iban", Iban);
        formdata.append("account_number", AccountNumber);
        formdata.append("bank_name", BankName);
        formdata.append("bank_address", BankAddress);
        formdata.append("phone_number", YourContact);
        axios({
            method: "post",
            url: url,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                GET_BANK_DETAIL()
                history.goBack()
            })
            .catch(function (error) {
                console.log('response of the edit bank details', error)
            });
    };

    const GET_BANK_DETAIL = () => {
        axios.get(Global.GET_BANK_DETAIL + token)
            .then(response => {
                if (response.data.status == 200) {
                    editBankDetails(response.data.VendorBankDetails)

                } else {
                    setNewUser(true)
                }
            })
            .catch(function (error) {
                console.log('data of product id =fffffftew', error)
            });

    }

    const editBankDetails = (data: any) => {
        setAccountTitle(data.account_title)
        setAccountNumber(data.account_number)
        setIban(data.iban)
        setBankAddress(data.bank_address)
        setBankName(data.bank_name)
        setYourContact(data.phone_number)
    }

    const GetCitiesList = (data: any) => {
        axios.get(Global.GetCitiesList + token + '&country_id=' + data)
            .then(response => {
                let data = response.data.cities.map((data: any) => ({ label: data.name, value: data.id }))
                setAllCities(data)
            })
            .catch(function (error) {
                console.log('data of product id =ffffff rtertewrtew', error)
            });

    }

    const GetCountriesListV2 = () => {
        axios.get(Global.WebGetCountriesListV2)
            .then(response => {
                setAllCountries(response.data.countires)
            })
            .catch(function (error) {
                console.log('data of product id =ffffff rtertewrtew', error)
            });
    }

    const GetStoreDetails = () => {
        axios.get(Global.GetStoreDetails + token)
            .then(response => {
                console.log(response)
                editfunction(response.data.store_details[0])
                checktickFunction(response.data.store_details[0])
            })
            .catch(function (error) {
                console.log('data of product id =ffffff rtertewrtew', error)
            });
    }

    const editfunction = (data: any) => {
        setstoreName(data.store_name)
        setDescription(data.store_description)
        setPhonenumber(data.phone_number)
        setAddress(data.store_address)
        setCountry({ label: data.country, value: data.country })
        setCity({ label: data.city, value: data.city })
        setPictureURLs(data.store_pic)
    }

    const PictureUrl = (data: any) => {
        let imagesrc = URL.createObjectURL(data)
        setsendedPath(data)
        setnewpicture(imagesrc)
    }

    const Form = (data: any) => {
        if (data == 'bank') {
            // BankAddress !== '' && BankName !== '' && AccountTitle !== '' && AccountNumber !== '' && Iban !== '' && YourContact !== ''&& 
            if (AgreeToConditions !== false) {
                addBankDetails()
                addStoreDetails()
                window.scrollTo(0, 0)
            } else {
                // setblurOption(true)
                // setTimeout(() => {
                //     setblurOption(false)
                // }, 1);
                if (AgreeToConditions == false) {
                    setagreeError(true)
                }
            }

        } else {
            if (storeName !== null && Description !== null && Phonenumber !== null && Address !== null) {
                setStoreDescription(false)
                window.scrollTo(0, 0)
            } else {
                if (Description == null) {
                    setDescriptionError(true)
                }
                setblurOption(true)
                setTimeout(() => {
                    setblurOption(false)
                }, 1);
            }
        }


    };

    const checktickFunction = (data: any) => {
        if (data.store_name == null && data.store_description == null && data.store_address == null) {
            setstorefilled(false)
        } else {
            console.log('no null')
            setstorefilled(true)
        }
    };

    return (
        <div className=" bg-color-light" >
            {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
            <div className="padding">
                {showAlert == true && <Alertss type={alertType} status={status} />}
                <div className="cp-t-40" >
                    <label className="label secHeading primary-color" >Edit Store</label>
                </div>
                <div className="flexadjustments col-12  " style={{ backgroundColor: 'white' }} >
                    <div className="col-md-4  " >
                        <div className=" cm-30" >
                            <div onClick={() => setStoreDescription(true)} className=" touchAble d-flex flex-row align-item-center" style={{ backgroundColor: StoreDescription == true ? '#f5f5f5' : 'white' }} >
                                {storefilled == true ? (
                                    <IoCheckmarkDoneCircleSharp className="touchAble label cp-l-10" size={29} color={'green'} />
                                ) : (
                                    <RiErrorWarningFill className="touchAble label cp-l-10" size={29} color={StoreDescription == true ? 'yellow' : 'gray'} />
                                )
                                }
                                <label className="cp-t-10 cp-l-10 touchAble cp-b-10 label" style={{ color: StoreDescription == true ? 'black' : 'gray' }} >Store Information</label>
                            </div>
                            <div onClick={() => setStoreDescription(false)} className="touchAble d-flex flex-row cm-t-10 align-item-center " style={{ backgroundColor: StoreDescription == true ? 'white' : '#f5f5f5' }} >
                                <div onClick={() => setStoreDescription(true)} className=" touchAble d-flex flex-row align-item-center" style={{ backgroundColor: StoreDescription == true ? 'white' : '#f5f5f5' }} >
                                    {NewUser == false ? (
                                        <IoCheckmarkDoneCircleSharp className="touchAble label cp-l-10" size={29} color={'green'} />
                                    ) : (
                                        <RiErrorWarningFill className="touchAble label cp-l-10" size={29} color={StoreDescription == true ? 'gray' : 'yellow'} />
                                    )}
                                    <label className="cp-t-10 cp-l-10 touchAble cp-b-10 label" style={{ color: StoreDescription == true ? 'gray' : 'black' }} >Transfer Method</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" >
                        {StoreDescription == true &&
                            <div>
                                <div className="cm-t-30 cm-b-30" >
                                    <div className=" d-flex cp-10 flex-column " style={{ backgroundColor: 'lightpink' }} >
                                        <label className=" label labelText font-weight-bold " >Make sure your details are correct</label>
                                        <label className=" label" >Create your store information to start selling on Les Femmes.</label>
                                    </div>
                                </div>
                                {PictureURLs !== '' || sendedPath !== '' ? (
                                    <div className='position-relative  d-flex align-self-center align-content-center align-item-center justify-content-center' style={{ height: '100px', width: '100px', borderRadius: 100 }}>
                                        {(newpicture !== '' || PictureURLs !== null) ? (
                                            <img src={newpicture !== '' ? newpicture : Global.IMAGE_PATH + PictureURLs} style={{ height: 110, width: 110, borderRadius: 100 }} ></img>
                                        ) : (
                                            <img className="" src={dummyPic} style={{ height: 110, width: 110, borderRadius: 100 }} ></img>
                                        )}
                                        <div className=" d-flex position-absolute  flex-row-reverse  " style={{ width: 120, top: 70 }}>
                                            <span className="btn  btn-file  d-flex  align-item-center" >
                                                <AiOutlineCamera size="2.8rem " className=" primary-color cp-10" style={{ borderRadius: 100, backgroundColor: 'white' }} />  <input type="file" accept="image/*" onChange={(text: any) => PictureUrl(text.target.files[0])} ></input>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="" style={{ width: '250%' }} >
                                        <div className='  d-flex align-self-center align-content-center align-item-center justify-content-center' style={{ height: '100px', width: '140px' }}>
                                            <span className="btn  btn-file  d-flex  align-item-center" >
                                                <AiOutlineCamera size="2.6rem " className="border cp-30" style={{ height: '110px', width: '110px', borderRadius: 100, }} color="gray" />  <input type="file" accept="image/*" onChange={(text: any) => PictureUrl(text)} ></input>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <InputField type="text" value={storeName} name="text" placeholder="Store Name" security='true' blur={blurOption} methode={(data: any) => setstoreName(data)} />
                                <p className='label primary-color text-capitalize labelText cp-t-20 cp-b-10 f-600 main-font' >Description</p>
                                <textarea name="textarea" value={Description} onChange={(text: any) => setDescription(text.target.value)} className="text-muted" style={{ width: '100%', height: '120px', borderColor: 'lightgray' }}></textarea>
                                {DescriptionError && <p className='label text-danger f-13 main-font' >Required</p>}
                                <InputField type="text" value={Phonenumber} name="text" placeholder="Phone Number" security='true' blur={blurOption} methode={(data: any) => setPhonenumber(data)} />
                                <MultiselectDropDown value={Country} placeholder="Country" DropDownData={AllCountries} securityRequired='true' blur={blurOption} methode={(data: any) => countryFunction(data)} />
                                <MultiselectDropDown value={City} placeholder="City" DropDownData={AllCities} securityRequired='true' blur={blurOption} methode={(data: any) => setCity(data)} />
                                <InputField type="text" value={Address} name="text" placeholder="Address" security='true' blur={blurOption} methode={(data: any) => setAddress(data)} />
                                <div className="d-flex justify-content-center cm-b-60 cm-t-30" >
                                    <div onClick={() => Form('store')} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                        <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Continue</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {StoreDescription == false &&
                            <div>
                                <div className="cm-t-30 cm-b-30" >
                                    <div className=" d-flex cp-10 flex-column " style={{ backgroundColor: 'lightpink' }} >
                                        <label className=" label labelText font-weight-bold " >Add Bank Account Info</label>
                                        <label className=" label" >To get your payment processed. we need a few personal details from you</label>
                                    </div>
                                </div>
                                <InputField type="text" value={AccountTitle} name="text" placeholder="Account Title" security='true' blur={blurOption} methode={(data: any) => setAccountTitle(data)} />
                                <InputField type="text" value={Iban} onlyNumber="true" name="text" placeholder="IBAN" security='true' blur={blurOption} methode={(data: any) => setIban(data)} />
                                <InputField type="text" value={AccountNumber} onlyNumber="true" name="text" placeholder="Account Number" security='true' blur={blurOption} methode={(data: any) => setAccountNumber(data)} />
                                <InputField type="text" value={BankName} name="text" placeholder="Bank Name" security='true' blur={blurOption} methode={(data: any) => setBankName(data)} />
                                <InputField type="text" value={BankAddress} name="text" placeholder="Bank Address" security='true' blur={blurOption} methode={(data: any) => setBankAddress(data)} />
                                <InputField type="text" value={YourContact} onlyNumber="true" name="text" placeholder="Your Contact No" security='true' blur={blurOption} methode={(data: any) => setYourContact(data)} />
                                <div className="d-flex  center align-items-center cm-t-40 " >
                                    <input onClick={() => setAgreeToConditions(!AgreeToConditions)} type="checkbox" className='largerCheckbox touchAble primary-color' id="new" name="new" value="new" />
                                    <label className="form-check-label f-14  cp-l-15 label main-font" style={{ color: 'gray' }} >I've read and agree to Terms and Conditions</label>
                                </div>
                                {agreeError && <p className='label text-danger f-13 main-font' >Please agree first to our terms and condtions</p>}
                                <div className="d-flex justify-content-center cm-b-60 cm-t-30" >
                                    <div onClick={() => Form('bank')} className='cm-t-20 cm-b-60 cp-7 text-center touchAble col-3 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: 'rgb(218, 95, 115)', borderRadius: 5 }} >
                                        <p className='label labelText font-weight-bold touchAble  ' style={{ color: 'white' }}>Submit</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default EditStore;
