import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter} from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyCSizubRCO52wqrxiFCkfJHQNXbxQIeFLY",
  authDomain: "lesfemess.firebaseapp.com",
  projectId: "lesfemess",
  storageBucket: "lesfemess.appspot.com",
  messagingSenderId: "345444053821",
  appId: "1:345444053821:web:c7b8b6fcf598042a20cb54",
  measurementId: "G-VFXXP5XCDH"
};
    
    // <!-- End Google Tag Manager -->

ReactDOM.render(

    <BrowserRouter>
    <App />
    </BrowserRouter>
,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
