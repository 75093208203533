import { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ShimmerC from '../../app-components/ShimmerC';
import Productitem from '../../app-components/Productitem';
import CFooter from '../../app-components/CFooter';
import girl from '../../assets/emojiwindle.png';
import { globleAPI as Global } from '../../globals'
import slugify from 'react-slugify';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function LuxuryProductsCategory(props: any) {

    const [LuxuryProductsCategory, setLuxuryProductsCategory] = useState([])
    const [LuxuryProducts, setLuxuryProducts] = useState([])
    const [catName, setcatName] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllPopularProducts()
    }, [])

    const getAllSubCatData = (data: any) => {
        setcatName(data.name)
        axios
            .get(Global.WebGET_LUXURY_PRODUCTS_SPECIFIC_CATEGORY + "?category_id=" + data.id + "&parent_category_id=" + props.location.state[0].id + "&page=" + 1,)
            .then(response => {
                setLuxuryProducts(response.data.AllProducts.data)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getAllPopularProducts = () => {
        axios
            .get(Global.GET_SUB_CATEGORIES + "?id=" + props.location.state[0].id)
            .then(response => {
                setLuxuryProductsCategory(response.data.sub_categories)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const moveToDetailScreen = (data: any) => {
        window.open('/product-detail/' + data.id + '/' + slugify(data.name));
    }

    return (
        <div>
        <HeaderForLuxury/>
            <div className="page-left-padding page-right-padding bg-color-light"  >
                <div className="page-left-padding page-right-padding  cp-t-40 cp-b-60"   >
                    <div className="cp-b-30" >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
                            <Typography className="primary-color main-font" color="textPrimary">Luxury Products</Typography>
                        </Breadcrumbs>
                    </div>
                    <label className='main-font  f-25 cp-b-30 ' style={{ fontWeight: 'bold' }} >Luxury Products</label>
                    <div style={{ backgroundColor: 'white' }}>
                        {LuxuryProductsCategory.length >= 1 ? (
                            <div className="d-flex flex-row  overflow-auto"  >
                                {LuxuryProductsCategory.map((data: any) =>
                                    <div className='category-container'   >
                                        <div onClick={() => getAllSubCatData(data)} className='cm-t-2rem cm-b-2rem cp-l-1rem cp-r-1rem display-flex col-flex content-center align-item-center'>
                                            <img className='category-image touchAble' src={Global.IMAGE_PATH+data.image} ></img>
                                            <div className='f-14 text-center touchAble f-500 main-font text-color category-text'>
                                                {data.name}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <ShimmerC smallshimer='yes' />
                        )}
                        {catName !== '' &&
                            <div style={{ backgroundColor: 'white' }} >
                                <label className='main-font cp-40 f-20   label' style={{ fontWeight: 'bold', backgroundColor: 'white' }} >Luxury {catName}</label>
                            </div>
                        }
                        {LuxuryProducts.length >= 1 ? (
                            <div className='row cp-l-20 cp-r-20  ' >
                                {LuxuryProducts.map((data: any) => (
                                    <div className='col-md-2 col-lg-2 col-sm-6 col-6' onClick={() => moveToDetailScreen(data)}   >
                                        <Productitem DataofProduct={data} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>
                                {catName !== '' ? (
                                    <div>
                                        <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh', backgroundColor: 'white' }} >
                                            <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                                            <label className="text-muted text-center" style={{ width: '30%' }} >No item Found</label>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh', backgroundColor: 'white' }} >
                                            <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                                            <label className="text-muted text-center" style={{ width: '30%' }} >Please select a category first</label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <CFooter />
        </div>
    );
}
export default LuxuryProductsCategory;
