import './Cart.scss';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { MdPayment, MdDone } from "react-icons/md";
import { BiPhoneCall, BiArrowBack } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaWpforms, FaRegCreditCard, FaHeart } from "react-icons/fa";
import { HiOutlineHeart } from "react-icons/hi";
import { RiDeleteBinLine, RiWhatsappLine, RiCheckboxCircleFill } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import PhoneInput from 'react-phone-input-2'
import visa from '../../assets/visa.png';
import deleivery from '../../assets/deleivery.png';
import mastercard from '../../assets/master.png';
import CFooter from '../../app-components/CFooter';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import CheaderHalf from '../../app-components/CheaderHalf';
import clsx from "clsx";
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Alert from 'react-bootstrap/Alert'
import { globleAPI as Global } from '../../globals'
import Select from 'react-select'
import cartIcon from '../../assets/pexels-suzy-hazelwood-2536965.png'
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

function Cart(props: any) {
    const [phoneNumber, setphoneNumber] = useState('')
    let [loading, setLoading] = useState(false);
    let [color] = useState("#732F3E");
    const [phoneN, setphoneN] = useState(false)
    const [FirstName, setFirstName] = useState('')
    const [WalletBalance, setWalletBalance] = useState('')
    const [cartUpdate, setcartUpdate] = useState(false)
    const [firstN, setfirstN] = useState(false)
    const [PromoDiscount, setPromoDiscount] = useState<any>(0)
    const [LastName, setLastName] = useState('')
    const [lastN, setlastN] = useState(false)
    const [CountryCheck, setCountryCheck] = useState(false)
    const [CityCheck, setCityCheck] = useState(false)
    const [DeliveryCheck, setDeliveryCheck] = useState(false)
    const [City, setCity] = useState('')
    const [selectedCOuntry, setselectedCOuntry] = useState<any>([])
    const [DeliveryAddress, setDeliveryAddress] = useState('')
    const [PostalCode, setPostalCode] = useState('')
    const [Cartmsg, setCartmsg] = useState('')
    const [productsInfo, setproductsInfo] = useState<any>([])
    const [totalSum, settotalSum] = useState<any>([])
    const [Country, setCountry] = useState<any>([])
    const [agree, setagree] = useState('no')
    const [methodeOfBuy, setmethodeOfBuy] = useState('')
    const [PromoCode, setPromoCode] = useState<any>()
    const [ShipingCharges, setShipingCharges] = useState<any>(0)
    const [orderId, setorderId] = useState('')
    const [Toggle, setToggle] = useState(false)
    const [cartEmptyStatus, setcartEmptyStatus] = useState(false)
    const [token] = useState(localStorage.getItem('token') || '')
    const [showAlert, setshowAlert] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [first_name] = useState(localStorage.getItem('first_name') || '')
    const [last_name] = useState(localStorage.getItem('last_name') || '')
    const [UserEmail] = useState(localStorage.getItem('UserEmail') || '')
    const [FinalPrice, setFinalPrice] = useState<any>()
    const steps = getSteps();
    const override = css` display: block; margin: 0 auto; border-color: red;`;
    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        root: { width: '100%' },
        backButton: { marginRight: theme.spacing(1) },
        instructions: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
    }));
    const style = { color: 'black', fontSize: 11 };
    const classes = useStyles();

    function getSteps() {
        return ['My Cart', 'Delivery address', 'Payment', 'Thanks'];
    }

    useEffect(() => {
        getCartdata()
        getCountry()
        GetWalletBalance()
    }, [])

    const deleiveryss = (method: any) => {
        if (method == 'cash') { setmethodeOfBuy(method) }
        else { setmethodeOfBuy(method) }
    };

    const gotoShopAll = () => {
        history.push('/');
    };

    const handleNext = () => {
        if (activeStep < 3) { setActiveStep((prevActiveStep) => prevActiveStep + 1) }
        else { alert('np  mkore steps') }
    };

    const GetShippingCharges = (data: any) => {
        axios
            .get(Global.GET_SHIPPING_CHARGES + token + "&country_id=" + data + '&subtotal=' + totalSum)
            .then(response => {
                console.log('shipping charges', response)
                setShipingCharges(parseInt(response.data.Shipping_Charges))
                setFinalPrice((totalSum - PromoDiscount + (parseInt(response.data.Shipping_Charges))).toFixed(2))
            })
            .catch(function (error) {
                console.log('shipping charges', error)
            });
    }

    const increasProductQuantity = (data: any, data2: any) => {
        if (data2 == 'negative' && data.quantity == 1) {

        } else {
            let formdata = new FormData();
            formdata.append("cart_id", data.cart_id);
            formdata.append("customer_id", data.id);
            formdata.append("quantity", data2 == 'plus' ? data.quantity + 1 : data.quantity - 1);
            axios({
                method: "post",
                url: Global.INCREMENT_OR_DECREMENT + token,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    console.log('response form the cart', response)
                    if (response.status == 200) {
                        setshowAlert(true)
                        setTimeout(() => {
                            setshowAlert(false)
                        }, 5000);
                        setCartmsg(response.data.message)
                        getCartdata()
                    } else {
                        setshowAlert(true)
                        setTimeout(() => {
                            setshowAlert(false)
                        }, 5000);
                        setCartmsg(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log('groupscreate', error)
                });
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const firstName = () => {
        if (FirstName.length < 1) { setfirstN(true) }
        else { setfirstN(false) }
    }

    const lastNa = () => {
        if (LastName.length < 1) { setlastN(true) }
        else { setlastN(false) }
    }

    const PhoneNo = () => {
        if (phoneNumber.length < 1) { setphoneN(true) }
        else { setphoneN(false) }
    }

    const CountryF = () => {
        if (selectedCOuntry.length < 1) { setCountryCheck(true) }
        else { setCountryCheck(false) }
    }

    const CityF = () => {
        if (City.length < 1) { setCityCheck(true) }
        else { setCityCheck(false) }
    }

    const DeliveryAddressF = () => {
        if (DeliveryAddress.length < 1) { setDeliveryCheck(true) }
        else { setDeliveryCheck(false) }
    }

    const delievry = () => {
        if (FirstName == '' || LastName == '' || phoneNumber == '' || selectedCOuntry == '' || City == '' || DeliveryAddress == '') {
            firstName()
            lastNa()
            PhoneNo()
            CountryF()
            CityF()
            DeliveryAddressF()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    }

    const getCartdata = () => {
        axios
            .get(Global.GET_ALL_PRODUCT_FROM_CART + token)
            .then(response => {
                setproductsInfo(response.data.AllProducts)
                if (response.data.AllProducts.length == 0) {
                    setcartEmptyStatus(true)
                }
                settotalSum(response.data.sum?.toFixed(2))
                setFinalPrice(response.data.sum?.toFixed(2))
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const getCountry = () => {
        axios
            .get(Global.WebGetCountriesListV2)
            .then(response => {
                setCountry(response.data.countires)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }
    const GetWalletBalance = () => {
        axios
            .get(Global.Get_Wallet_Balance + token)
            .then(response => {
                setWalletBalance(response.data.balance)
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const RemoveFromCart = (productid: any) => {
        axios
            .post(Global.REMOVE_ITEM_FROM_CART + token + "&product_id=" + productid.id)
            .then(response => {
                if (response.status == 200) {
                    setcartUpdate(true)
                    setTimeout(() => {
                        setcartUpdate(false)
                    }, 2000);
                    setCartmsg(response.data.message)
                    getCartdata()
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                }
                else {
                    console.log('dataaaaresponse of aojfkdsa', response)
                }
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    }

    const selectCountry = (data: any) => {
        console.log('selcted data', data)
        setselectedCOuntry(data)
        GetShippingCharges(data.value)
        setagree('no')
    }

    const applyPromoCode = () => {
        if (PromoCode == undefined) {
            setCartmsg('Enter Promocode First')
            setshowAlert(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        } else {
            let data = {
                'promo_code': PromoCode,
                'total': totalSum,
            }
            axios.post(Global.PROMO_CODE + token, data)
                .then((response) => {
                    console.log('promocode ', response)
                    if (response.data.status == 200) {
                        setCartmsg(response.data.message)
                        setshowAlert(true)
                        setTimeout(() => {
                            setshowAlert(false)
                        }, 5000);
                        setPromoDiscount(response.data.discount_amount)
                        setFinalPrice((totalSum + ShipingCharges - (response.data.discount_amount)).toFixed(2))
                    } else {
                        setCartmsg(response.data.message)
                        setshowAlert(true)
                        setTimeout(() => {
                            setshowAlert(false)
                        }, 5000);
                    }

                }, (error) => {
                    console.log('promocode', error);
                });

        }

    }

    const ConfirmShoping = () => {
        if (methodeOfBuy == '') {
            setshowAlert(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        } else {
            if (methodeOfBuy == 'credit') {
                console.log(FinalPrice, phoneNumber, DeliveryAddress, ShipingCharges, selectedCOuntry.value, selectedCOuntry.label)
                let formdata = new FormData();
                formdata.append("total_price", FinalPrice)
                formdata.append("phone_numbers", phoneNumber);
                formdata.append("shipping_address", DeliveryAddress);
                formdata.append("promo_code", PromoCode);
                formdata.append("promo_discount", PromoDiscount);
                formdata.append("shipping_charges", ShipingCharges);
                formdata.append("country_id", selectedCOuntry.value);
                formdata.append("country_name", selectedCOuntry.label);
                axios({
                    method: "post",
                    url: Global.POST_CHECKOUT_API_ITEMS2 + token,
                    data: formdata,
                })
                    .then(response => {
                        console.log('groupscreatessss', response.data)
                        // setorderId(response.data.order_details.order_id)
                        // window.open('https://secure.telr.com/gateway/order.json'+'ivp_method'+'create','ivp_amount'+'100'+'ivp_currency'+'AED'+'ivp_desc'+'heeelloo'+'return_auth'+'www.google.com/'+' your store ID'+'123')
                        window.open("http://www.workgenies.com/api/telrpaymentforlesfemmes/" + first_name + "" + last_name + "/" + token + "/null/null/null/null/" + UserEmail + "/" + response.data.order_details.order_id + "/" + FinalPrice + "/" + "null", "_self")

                    })
                    .catch(function (error) {
                        console.log('groupscreate', error)
                    });
            }
            else {
                setLoading(true)
                let formdata = new FormData();
                formdata.append("total_price", FinalPrice)
                formdata.append("phone_numbers", phoneNumber);
                formdata.append("shipping_address", DeliveryAddress);
                formdata.append("promo_code", PromoCode);
                formdata.append("promo_discount", PromoDiscount);
                formdata.append("shipping_charges", ShipingCharges);
                formdata.append("country_id", selectedCOuntry.value);
                formdata.append("country_name", selectedCOuntry.label);
                axios({
                    method: "post",
                    url: Global.POST_CHECKOUT_API_ITEMS + token,
                    data: formdata,
                })
                    .then(response => {
                        console.log('groupscreatessss', response.data)
                        if (response.data.status == 200 || response.data.status == 201) {
                            setorderId(response.data.order_details.order_id)
                            UpdateOrderStatusAndPaymentDetails(response.data.order_details.order_id, '3')
                        } else {
                            setCartmsg(response.data.message)
                            setshowAlert(true)
                            setTimeout(() => {
                                setshowAlert(false)
                            }, 5000);

                        }
                    })
                    .catch(function (error) {
                        console.log('groupscreatessss', error)
                        setLoading(false)
                        // console.log('groupscreate', )
                    });
            }
        }
    }

    const paywithLesFemmesWallet = () => {
        if (WalletBalance < FinalPrice) {
            setCartmsg('Low Account Balance')
            setshowAlert(true)
            setTimeout(() => {
                setshowAlert(false)
            }, 5000);
        } else {
            setToggle(true)


        }
    }

    const ConfirmShopingWallet = () => {
        setLoading(true)
        let formdata = new FormData();
        formdata.append("total_price", FinalPrice)
        formdata.append("phone_numbers", phoneNumber);
        formdata.append("shipping_address", DeliveryAddress);
        formdata.append("promo_code", PromoCode);
        formdata.append("promo_discount", PromoDiscount);
        formdata.append("shipping_charges", ShipingCharges);
        formdata.append("country_id", selectedCOuntry.value);
        formdata.append("country_name", selectedCOuntry.label);
        
        axios({
            method: "post",
            url: Global.POST_CHECKOUT_API_ITEMS + token,
            data: formdata,
        })
            .then(response => {
                if (response.data.status == 200 || response.data.status == 201) {
                    setorderId(response.data.order_details.order_id)
                    UpdateOrderStatusAndPaymentDetails(response.data.order_details.order_id, 6)
                    setToggle(false)
                } else {
                    setCartmsg(response.data.message)
                    setshowAlert(true)
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);

                }
            })
            .catch(function (error) {
                setLoading(true)
                console.log('groupscreate', error)
            });
    }

    const UpdateOrderStatusAndPaymentDetails = (data: any, data2: any) => {
        let formdata = new FormData();
        formdata.append("payment_mode", data2);
        formdata.append("order_id", data);
        axios({
            method: "post",
            url: Global.VERIFY_PAYMENT_STATUS + token,
            data: formdata,
        })
            .then(response => {
                if (response.data.status == 200) {
                    if (activeStep == 2) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setLoading(false)
                    }

                }
            })
            .catch(function (error) {
                console.log('groupscreate', error)
            });


    }

    const ColorlibConnector = withStyles({
        alternativeLabel: {
            top: 22
        },
        active: {
            "& $line": {
                backgroundImage:
                    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
            }
        },
        completed: {
            "& $line": {
                backgroundImage:
                    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
            }
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: "#eaeaf0",
            borderRadius: 1
        }
    })(StepConnector);

    function ColorlibStepIcon(props: any) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons: any = {
            1: <AiOutlineShoppingCart size={20} />,
            2: <FaWpforms size={20} />,
            3: <MdPayment size={20} />,
            4: <MdDone size={20} />
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: "#ccc",
            zIndex: 1,
            color: "#fff",
            width: 30,
            height: 30,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center"
        },
        active: {
            backgroundImage:
                "linear-gradient( 136deg, #732F3E 0%,#732F3E 50%, #732F3E 100%)",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
        },
        completed: {
            backgroundImage:
                "linear-gradient( 136deg, #732F3E 0%, #732F3E 50%, #732F3E 100%)"
        }
    });

    const addTowishList = (data: any) => {
        axios.post(Global.ADD_AD_TO_WISH_LIST + token + '&product_id=' + data.product_id)
            .then(response => {
                getCartdata()
                setCartmsg(response.data.message)
                setshowAlert(true)
                setTimeout(() => {
                    setshowAlert(false)
                }, 5000);
            })
            .catch(function (error) {
                console.log('response if the api is errror =', error)
            });
    };


    function getStepContent(stepIndex: any) {
        switch (stepIndex) {
            case 0:
                return (
                    <div>

                        {productsInfo.length >= 1 &&
                            <div>
                                <div>
                                    <div className='cp-b-1rem  mainHeading text-color main-font'>  My Cart</div>
                                    <div className='d-flex justify-content-between cp-b-10'  >
                                        <label className='  labelText text-capitalize cp-t-10'  >Product</label>
                                        <label className='  labelText text-capitalize cp-t-10 cp-l-100' >QTY</label>
                                        <label className='  labelText text-capitalize cp-t-10'  >Amount</label>
                                    </div>
                                </div>
                                {productsInfo.map((data: any) => (
                                    <div className=' border-top cp-t-20 cp-b-20' >
                                        <div className='d-flex flex-row' >
                                            <div className='d-flex flex-row col-6'>
                                                <img className='carosal-image-Web' style={{ height: 70, width: 70 }} src={Global.IMAGE_PATH + data.image} alt="First slide" />
                                                <div className='d-flex flex-column cp-l-10 ' >
                                                    <label className='labelText label primary-color   '   >{data.name}</label>
                                                    <label className='pText  label '  >{data.product_condition}</label>
                                                    <label className='pText label'  >{data.manufacturer_name}</label>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row col-2 border-bottom' style={{ height: 25, borderColor: 'pink' }} >
                                                <label onClick={() => increasProductQuantity(data, 'plus')} className='f-15 f-500 cp-l-10 cp-r-10 primary-color font-weight-bold touchAble '  >+</label>
                                                <label className=' pText cp-l-10 cp-r-10 '  >{data.quantity}</label>
                                                <label onClick={() => increasProductQuantity(data, 'negative')} className=' cp-l-10 cp-r-10  primary-color font-weight-bold touchAble '  >─</label>
                                            </div>
                                            <div className='col-4 d-flex justify-content-end  ' >
                                                <div className='d-flex flex-column' >
                                                    {data.discount == null || data.discount == 0 ? (
                                                        <div>
                                                            <label className='d-flex justify-content-center pText primary-color'  >AED {data.unit_price?.toFixed(2)}</label>
                                                        </div>
                                                    ) : null}
                                                    {data.discount !== null && data.discount > 0 ? (
                                                        <div>
                                                            <label className='d-flex justify-content-center f-14 line-through'  >AED {data.unit_price?.toFixed(2)}</label>
                                                            <label className='d-flex justify-content-center f-15 f-500 primary-color'  >AED {(data.unit_price * ((100 - data.discount) / 100)).toFixed(2)}</label>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-row ' >
                                            <div className='d-flex flex-row cp-t-10 ' >
                                                <div style={{ height: 20, width: 95 }} > </div>
                                                <div onClick={() => addTowishList(data)} className='cp-r-10 d-flex align-content-center' >
                                                    {/* <FiHeart size='17' style={{}} /> */}
                                                    {data.wishlistcheck == '0' ? (
                                                        <HiOutlineHeart color='black' size='17' />
                                                    ) : (
                                                        <FaHeart color='red' size='17' />
                                                    )}

                                                    <label className='f-13 underline label cp-l-5 touchAble'  >{data.wishlistcheck == '0' ? 'Add to Wishlist' : 'Added to Wishlist'}</label>
                                                </div>
                                                <div onClick={() => RemoveFromCart(data)} className='cp-l-10 d-flex align-content-center' >
                                                    <RiDeleteBinLine size='17' />
                                                    <label className='f-13 underline  label cp-l-5 touchAble'  >Remove</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {(cartEmptyStatus == true || token == '') && <div className="d-flex  justify-content-center  align-item-center" style={{ height: '60vh', }} >
                            <div className="d-flex flex-column" >
                                <img src={cartIcon} style={{ height: '25vh' }} ></img>
                                <p className=' labelHeading label cm-t-10 main-font' style={{ textAlign: 'center' }} >Your cart is empty</p>
                                <label className=" label main-font " style={{ textAlign: 'center' }}>Add something to make me happy</label>
                                <div onClick={() => gotoShopAll()} className='cm-t-10 cp-7  text-center touchAble col-12 col-md-6 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                                    <p className='label main-font labelText  touchAble ' style={{ color: 'white' }}>Buy Now</p>
                                </div>
                            </div>
                        </div>
                        }
                    </div>);
            case 1:
                return (
                    <div >
                        <div className='f-25  cp-b-2rem f-500 text-color main-font'>Delivery Address</div>
                        <div className='d-flex' >
                            <div className='col-md-6 col-lg-6 col-6' >
                                <label className='label  f-13' >First Name*</label>
                                <input onBlur={() => firstName()} style={{ fontSize: 14 }} type="text" className=" input form-control" required={true} placeholder="Enter your first Name" onChange={(text: any) => setFirstName(text.target.value)} />
                                {firstN == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                            </div>
                            <div className='col-md-6 col-lg-6 col-6' >
                                <label className='label  f-13' >Last Name*</label>
                                <input onBlur={() => lastNa()} style={{ fontSize: 14 }} type="text" className=" input form-control" required={true} placeholder="Enter your last Name" onChange={(text: any) => setLastName(text.target.value)} />
                                {lastN == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                            </div>
                        </div>
                        <div className='d-flex flex-column cm-16 ' >
                            <label className='label  f-13' >Phone Number*</label>
                            <div className='' style={{ width: '100%' }} >
                                <PhoneInput
                                    inputStyle={{ width: '100%', padding: 18, paddingLeft: 60 }}
                                    country={'ae'}
                                    value={phoneNumber}
                                    onChange={phone => setphoneNumber(phone)}
                                    onBlur={() => PhoneNo()}
                                />
                            </div>
                            {phoneN == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                        </div>
                        <div className='d-flex' >
                            <div className='col-md-6 col-lg-6 col-6' >
                                <label className='label  f-13' >Country*</label>
                                <Select
                                    name="form-field-name"
                                    isSearchable={true}
                                    options={Country}
                                    onChange={selectCountry}
                                    tabSelectsValue={true}
                                />
                                {CountryCheck == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                            </div>
                            <div className='col-md-6 col-lg-6 col-6' >
                                <label className='label  f-13' >City*</label>
                                <input onBlur={() => CityF()} style={{ fontSize: 14 }} type="text" className=" input form-control" required={true} placeholder="Enter your area / city" onChange={(text: any) => setCity(text.target.value)} />
                                {CityCheck == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                            </div>
                        </div>
                        <div className='d-flex flex-column cm-16 ' >
                            <label className='label  f-13' >Postal Code</label>
                            <div className='' style={{ width: '100%' }} >
                                <input style={{ fontSize: 14 }} type="text" className=" input form-control" required={true} placeholder="Enter your postel code" onChange={(text: any) => setPostalCode(text.target.value)} />
                            </div>
                        </div>
                        <div className='d-flex flex-column cm-16 ' >
                            <label className='label  f-13' >Delivery Address*</label>
                            <div className='' style={{ width: '100%' }} >
                                <input onBlur={() => DeliveryAddressF()} style={{ fontSize: 14 }} type="text" className=" input form-control" required={true} placeholder="House number / Building name / Street" onChange={(text: any) => setDeliveryAddress(text.target.value)} />
                                {DeliveryCheck == true ? (<p className='label text-danger f-13' >Should not be empty</p>) : null}
                            </div>
                        </div>
                    </div>
                )
            case 2:
                return (<div className='border-dark cp-20 border-bottom' >
                    <div className='f-25  cp-b-2rem f-500 text-color main-font'>Payment Methods</div>
                    <div>
                        <div className='d-flex flex-row  border cm-t-10 cm-b-15 cp-2 ' >
                            <label className="switch label cm-t-5">
                                <input onClick={() => paywithLesFemmesWallet()} type="checkbox" checked={Toggle}  ></input>
                                <span className="slider round"></span>
                            </label>
                            <div className='d-flex flex-column' >
                                <label className='label  cp-l-50  text-capitalize pText '>pay with les femmes wallet</label>
                                <label className='label  cp-l-50  text-muted label' style={{ fontSize: 11 }}>Available Balance:{WalletBalance}</label>
                            </div>
                        </div>
                        <div className='d-flex flex-row ' >
                            {methodeOfBuy == 'credit' ? (
                                <div className='border cp-6 col-6 align-self-center touchAble' style={{ backgroundColor: 'darkgray', color: 'white' }} >
                                    <FaRegCreditCard color='black' size='20' />
                                    <label className='label  cp-l-10  touchAble' style={{ fontSize: 11, color: 'white' }}>CREDIT CARD</label>
                                </div>
                            ) : (
                                <div onClick={() => deleiveryss('credit')} className='border cp-6 col-6 align-self-center touchAble' >
                                    <FaRegCreditCard color='black' size='20' />
                                    <label className='label  cp-l-10  touchAble' style={{ fontSize: 11 }}>CREDIT CARD</label>
                                </div>
                            )}
                            {methodeOfBuy == 'cash' ? (
                                <div className='border cp-6 col-6  align-self-center touchAble data' style={{ backgroundColor: 'darkgray', color: 'white' }} >
                                    <label className='label    border border-dark cp-2 touchAble  ' style={{ fontSize: 11, color: 'black' }} >Cash</label>
                                    <label className='label  cp-l-10  touchAble' style={{ fontSize: 11, color: 'white' }} >ON DELIVERY</label>
                                </div>
                            ) : (
                                <div className='border cp-6 col-6  align-self-center touchAble data' >
                                    {selectedCOuntry.value == 229 ? (
                                        <div style={style} onClick={() => deleiveryss('cash')} >
                                            <label className='label    border border-dark cp-2 touchAble  '  >Cash</label>
                                            <label className='label  cp-l-10   touchAble' style={{ fontSize: 11 }} >ON DELIVERY</label>
                                        </div>
                                    ) : (
                                        <div style={style} >
                                            <label className='label    border border-dark cp-2 touchAble  '  >Cash</label>
                                            <label className='label  cp-l-10  ' style={{ fontSize: 11 }} >cash on  delievery available only in <b>UAE</b></label>
                                        </div>
                                    )
                                    }
                                </div>
                            )}
                        </div>
                        {methodeOfBuy == 'credit' ? (
                            <div className='cp-t-40 d-flex flex-column ' >
                                <label className='labelText main-font'>Credit Card/Debit Card</label>
                                <label className=' pText font-weight-bold main-font'>Please Note: <label className='pText'>In case of Returns, we will refund via Credit/Debit Card registered on your account.</label></label>
                            </div>
                        ) : (
                            <div className='cp-t-40 d-flex flex-column ' >
                                <label className='labelText main-font'>Cash on Delivery</label>
                                <label className='pText font-weight-bold main-font'>Please Note: <label className='pText'>In case of Returns, we will refund via Les Femmes  wallet</label></label>
                            </div>
                        )
                        }


                    </div>
                </div>
                )
            case 3:
                return (
                    <div className=" col-12 d-flex flex-row " style={{ height: '60vh' }} >
                        <div className="col-md-6" >

                        </div>
                        <div className="col-md-6 " >
                            <div className=" justify-content-center d-flex align-item-center secHeading primary-color  " style={{ height: '40%' }} >
                                <label >Thanks for shopping!</label>
                            </div>
                            <div className="  d-flex  justify-content-center   align-item-center" style={{ borderTopRightRadius: 30, borderTopLeftRadius: 30, backgroundColor: 'white' }}  >
                                <div className="d-flex flex-column" >
                                    <div className="d-flex flex-column cp-b-20 cp-t-20 " >
                                        <div className="d-flex justify-content-center" >
                                            <RiCheckboxCircleFill color="#732F3E" size="35" />
                                        </div>
                                        <p className=' labelHeading label cm-t-10 main-font primary-color' style={{ textAlign: 'center' }} >Your order was completed successfully</p>
                                        <div className=" cp-t-10 d-flex justify-content-center" >
                                            <p className=" label main-font primary-color" style={{ textAlign: 'center' }} >An email recieved including the details about your order has been sent to the email address provided. Please keep it for your records.</p>
                                        </div>
                                        <div className='cm-t-30   cp-7   text-center touchAble col-12 col-md-8 align-content-center d-flex  align-self-center justify-content-center' style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                                            <p className='label main-font labelText  touchAble ' onClick={() => gotoShopAll()} style={{ color: 'white' }}>Continue Shopping</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                );
        }
    }

    return (
        <div>
            <CheaderHalf cartcheck={cartUpdate} />
            {showAlert == true ? (
                <div className='d-flex justify-content-center' style={{ position: 'relative', zIndex: 1 }} >
                    <Alert style={{ marginTop: '6%', position: 'absolute', width: 'auto', paddingLeft: '10%', paddingRight: '10%' }} variant="danger" show={showAlert}  >
                        <Alert.Heading style={{ textAlign: 'center' }} ></Alert.Heading>
                        {Cartmsg != '' ? (
                            <label className='main-font' >{Cartmsg}</label>
                        ) : (
                            <label className='main-font' >Please select payment methode 1st</label>
                        )
                        }
                    </Alert>
                </div>
            ) : null}

            <div className='  page-left-padding' >
                {activeStep == 1 || activeStep == 2 || activeStep == 3 ? (
                    <BiArrowBack onClick={() => handleBack()} />
                ) : null}
            </div>
            <div>

                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<ColorlibConnector />}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}  > <label className="pText" >{label}</label></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div className=" d-flex position-absolute justify-content-center" style={{ width: '100%' }} > <ScaleLoader color={color} loading={loading} css={override} height={40} width={6} /></div>
            <div className='cp-t-30 mobileView' >
                <div className='d-flex flex-column col-md-8 col-lg-8 ' >
                    {activeStep !== steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                        </div>
                    ) : null}
                </div>
                {activeStep == 0 || activeStep == 1 || activeStep == 2 ? (
                    <div className='col-12 col-sm-12 col-md-4 col-lg-4' style={{}} >
                        <div className='d-flex justify-content-end label cp-t-10 flex-row align-items-center webdisplay'>
                            <label className='label text-uppercase f-14 font-weight-bold  cp-l-5 cp-r-15  main-font'>Need help?</label>
                            <div className="d-flex  justify-content-center align-self-center " >
                                <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="tel:+971588241922"><BiPhoneCall size='17' />Call us</a>
                            </div>
                            <div className="d-flex justify-content-center align-self-center" >
                                <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="https://api.whatsapp.com/send/?phone=971522858796&text&app_absent=0"><RiWhatsappLine size='17' />WhatsApp</a>
                            </div>
                            <div className="d-flex justify-content-center align-self-center" >
                                <a className='label  f-13   cp-r-15 main-font ' style={{ color: 'black' }} target="_blank" href="mailto:support@lesfemmes.net"><GoMail size='17' /> Email</a>
                            </div>
                        </div>
                        <div className='cp-20 ' style={{ backgroundColor: '#f4f4f4 ' }}>
                            {activeStep == 1 ? (
                                <div>
                                    <div className='d-flex justify-content-between' >
                                        <label className='f-17 f-500 main-font'>Order Summary</label>
                                        <label onClick={() => setActiveStep(0)} className='main-font touchAble underline f-12 cp-t-5' >EDIT</label>
                                    </div>
                                    <div className='div-box-shadow cp-20 cm-b-20' style={{ backgroundColor: 'white' }}>
                                        {productsInfo.map((data: any) => (
                                            <div className='d-flex flex-row justify-content-between cp-10' >
                                                <div className='d-flex flex-row' >
                                                    <img className='carosal-image-Web' style={{ height: 60, width: 60 }} src={Global.IMAGE_PATH + data.image} alt="First slide" />
                                                    <div className='d-flex flex-column cp-l-10 ' >
                                                        <label className='f-13 f-500 label primary-color  main-font'   >{data.name}</label>
                                                        <label className='f-12  label main-font'  >{data.product_condition}</label>
                                                        <label className='f-12 f-500 label main-font'  >{data.manufacturer_name}</label>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column' >
                                                    {data.discount == null || data.discount == 0 ? (
                                                        <div>
                                                            <label className='d-flex justify-content-center f-14 primary-color'  >AED {data.unit_price}</label>
                                                        </div>
                                                    ) : null}
                                                    {data.discount !== null && data.discount > 0 ? (
                                                        <div>
                                                            <label className='d-flex justify-content-center f-12 line-through'  >AED {data.unit_price}</label>
                                                            <label className='d-flex justify-content-center f-14 f-500 primary-color'  >AED {(data.unit_price * ((100 - data.discount) / 100)).toFixed(2)}</label>
                                                        </div>
                                                    ) : null}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                            {activeStep == 0 ? (
                                <div>
                                    <label className='label main-font labelText'  > Promo Code /Gift Card</label>
                                    <div className='  cp-b-5 d-flex flex-row ' >
                                        <div className='' style={{ width: '70vw' }}  >
                                            <input onChange={(text: any) => setPromoCode(text.target.value)} className='subscriptinput' style={{ backgroundColor: 'white', height: 40, fontSize: 14, width: '100%' }} ></input>
                                        </div>
                                        <div onClick={() => applyPromoCode()} className=' text-center d-flex justify-content-center align-item-center  ' style={{ backgroundColor: '#732F3E', width: '30vw', height: 40 }} >
                                            <label className='label touchAble  pText ' style={{ paddingLeft: 32, paddingRight: 24, color: 'white', }} >Apply</label>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {activeStep == 2 ? (
                                <label className='f-17 f-500 main-font'>Order Summary</label>
                            ) : null}
                            {activeStep == 0 || activeStep == 2 || activeStep == 1 ? (
                                <div className='div-box-shadow cp-20' style={{ backgroundColor: 'white' }}>
                                    <div className='d-flex justify-content-between ' >
                                        <label className='label  labelText'>Subtotal</label>
                                        <label className='label pText primary-color f-500 main-font' >AED {totalSum}</label>
                                    </div>
                                    <div className='d-flex justify-content-between  cp-t-10' >
                                        <label className='label labelText main-font'>Shipping Fee</label>
                                        {selectedCOuntry == '' ? (<label className='label primary-color pText' >AED 0</label>) : (<label className='label primary-color main-font ' >{ShipingCharges}</label>)
                                        }
                                    </div>
                                    <div className='d-flex justify-content-between cp-t-10' >
                                        <label className='label labelText'>Promo Discount</label>
                                        {PromoDiscount != '' ? (
                                            <label className='label primary-color main-font f-14' >AED {PromoDiscount.toFixed(2)}</label>
                                        ) : (
                                            <label className='label primary-color main-font f-14'>AED 0</label>
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-between cp-t-10' >
                                        <label className='label font-weight-bold f-17'>Total Order</label>
                                        <label className='label primary-color font-weight-bold f-17 main-font' >AED  {FinalPrice}</label>
                                    </div>
                                </div>
                            ) : null}
                            {activeStep == 0 ? (
                                <div className='cm-t-20 cp-7 text-center touchAble' onClick={() => productsInfo.length >= 1 && handleNext()} style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                                    <label className='label labelText touchAble ' style={{ color: 'white' }}>Continue</label>
                                </div>
                            ) : activeStep == 1 ? (
                                <div className='cm-t-20 cp-7 text-center touchAble' onClick={() => delievry()} style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                                    <label className='label labelText touchAble ' style={{ color: 'white' }}>Submit Address</label>
                                </div>
                            ) : activeStep == 2 ? (

                                <div className='cm-t-20 cp-7 text-center touchAble' onClick={() => Toggle ? ConfirmShopingWallet() : ConfirmShoping()} style={{ backgroundColor: '#732F3E', borderRadius: 5 }} >
                                    <label className='label labelText font-weight-bold touchAble ' style={{ color: 'white' }}>Pay Now</label>
                                </div>
                            ) : null}
                        </div>
                        <div className='d-flex justify-content-center flex-column '>
                            <label className='d-flex justify-content-center  f-15 f-500 text-uppercase cp-t-30 label main-font'  >100%  secured  payment </label>
                            <div className='cp-10 d-flex justify-content-center flex-row' >
                                <img className='cm-t-7' height='50vh' width='90' src={deleivery} ></img>
                                <img className='cm-t-7' height='50vh' width='90' src={visa} ></img>
                                <img className='cm-t-7' height='50vh' width='90' src={mastercard} ></img>
                            </div>
                        </div>
                        {activeStep == 0 ? (
                            <div className=' border-top cp-t-30 cp-b-30' >
                                <div className=' cp-l-20 cp-r-50  d-flex flex-column ' style={{ backgroundColor: 'white' }} >
                                    <label className='  text-uppercase  label labelText font-weight-bold'  >Next Steps</label>
                                    <label className='  pText cp-t-10 d-flex flex-column'  >Your item will be:</label>
                                    <label className='  pText '  ><b>1. </b>Picked up to seller location if you order a luxury/designer items, it will be sent to our authentication center.</label>
                                    <label className='  pText  '  ><b>2. </b>All luxury/designer items are authenticated by our experts.</label>
                                    <label className='  pText '  ><b>3. </b>Then we ship your order to your location</label>
                                    <label className='  text-uppercase cp-t-20 labelText font-weight-bold label'  >Import duties and taxes</label>
                                    <label className='  pText cp-t-10 '  >international deliveries will applied import duties and taxes</label>
                                    <label className='  text-uppercase cp-t-20 labelText font-weight-bold label'  >delivery</label>
                                    <label className=' pText cp-t-10 cp-b-10 '  >Your seller will be asked to quickly send your item. we'll make sure delivery times are as short as possible Les Femmes will deliver your order within 2-3 days for luxury items it will be delivered in 2-5 days</label>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div className="c-border-top-light" style={{ marginTop: '5%' }}>
                < CFooter />
            </div>
        </div>
    );
}
export default Cart;
