import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory, Link } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Storeitem from '../../app-components/Storeitem';
import ShimmerC from '../../app-components/ShimmerC';
import CFooter from '../../app-components/CFooter';
import { globleAPI as Global } from '../../globals'
import HeaderforRetail from '../../app-components/HeaderforRetail';
import HeaderForLuxury from '../../app-components/HeaderForLuxury';

function PriceOfferRequest(props: any) {

  const [Selected] = useState(localStorage.getItem('SelectedOption') || '')
  const [storesData, setstoresData] = useState<any>([])
  const [storesPage, setstoresPage] = useState<any>('')
  const history = useHistory();
  var nofeaturedstore: string = 'No';

  useEffect(() => {
    getStoresItems(1)
    window.scrollTo(0, 0)
  }, [])


  const getStoresItems = (data: any) => {

    if (props.location.state[0] == 'retailStores') {
      console.log('retail store is ruuning', data)
      axios
        .get(Global.WebGetRetailStoresListV1 + '?page=' + data)
        .then(response => {
          setstoresData(response.data.stores.data)
          setstoresPage(response.data.stores)
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    } else {
      axios
        .get(Global.WebGetRetailStoresListV1)
        .then(response => {
          console.log('response if the api is', response.data.stores.data)
          setstoresData(response.data.stores.data)
        })
        .catch(function (error) {
          console.log('response if the api is errror =', error)
        });
    }
  }

  const move = (data: any) => {
    history.push('/StoreDetail', [data]);
  }

  return (
    <div>
      {Selected == 'Luxury' ? <HeaderForLuxury /> : <HeaderforRetail />}
      <div className="page-left-padding page-right-padding bg-color-light"  >
        <div className="page-left-padding page-right-padding cm-l-25 cm-r-25 cp-t-40 cp-b-20"   >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link style={{ color: 'black' }} className="main-font" to="/">Home</Link>
            <Typography className="primary-color main-font" color="textPrimary">All Retail Stores</Typography>
          </Breadcrumbs>
          {props.location.state[0] == 'retailStores' ? (
            <label className="f-23 cp-t-15 cp-b-15 font-weight-bold main-font primary-color" >All Retail Stores</label>
          ) : (
            <label className="f-23 cp-t-15 cp-b-15 font-weight-bold main-font primary-color" >All Preloved Stores</label>
          )
          }
          {storesData.length >= 1 ? (
            <div style={{ backgroundColor: 'white' }} className='row '>
              {storesData.map((stores: any) => (
                <div onClick={() => move(stores.id)} className='col-md-4 col-lg-3 col-sm-6 col-6  '>
                  <Storeitem DataofProduct={stores} FeaturedStore={nofeaturedstore} />
                </div>
              ))
              }
            </div>
          ) : (
            <div className='row ' style={{ backgroundColor: 'white', height: '100vh' }}>
              <ShimmerC allproducts='yes' />
              <ShimmerC allproducts='yes' />
            </div>
          )}
        </div>
        {storesPage.last_page > storesPage.current_page ? (
          <div onClick={() => getStoresItems(storesPage.current_page + 1)} className="d-flex justify-content-center" >
            <label className="f-17 cp-b-60 font-weight-bold main-font primary-color hoverUnderLine" >See more</label>
          </div>
        ) : null}
      </div>
      <CFooter />
    </div>
  );
}

export default PriceOfferRequest;
