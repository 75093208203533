import { useEffect, useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { globleAPI as Global } from '../globals'
import girl from '../assets/emojiwindle.png';

function OfferRequest(props: any) {

    const [token] = useState(localStorage.getItem('token') || '')
    const [userid] = useState(localStorage.getItem('userid') || '')
    const [INprogressOffers, setINprogressOffers] = useState<any>([])
    const [finishedOffers, setfinishedOffers] = useState<any>([])
    const history = useHistory();

    useEffect(() => {
        if (props.data == 'sales') {
            getsalesOfferRequestfinished()
            getsalesOfferRequestINprogress()
        } else {
            getvendorOfferRequestfinished()
            getvendorOfferRequestINprogress()
        }
    }, [])


    const getvendorOfferRequestfinished = () => {
        let formdata = new FormData();
        const n: number = 24;
        formdata.append("status[0]", JSON.stringify(25));
        formdata.append("status[1]", JSON.stringify(10));
        axios({
            method: "post",
            url: Global.GetCustomerOffers + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setfinishedOffers(response.data.offer_logs)
                console.log('response', response)
            })
            .catch(function (error) {
                console.log('response', error)
            });
    }

    const getsalesOfferRequestfinished = () => {
        let formdata = new FormData();
        formdata.append("status[0]", JSON.stringify(8));
        formdata.append("status[1]", JSON.stringify(26));
        axios({
            method: "post",
            url: Global.GetVendorOffersV1 + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setfinishedOffers(response.data.offer_logs)

                console.log('response of finished', response)
            })
            .catch(function (error) {
                console.log('response', error)
            });
    }

    const getsalesOfferRequestINprogress = () => {
        console.log(Global.GetVendorOffersV1 + token)
        let formdata = new FormData();
        formdata.append("status[0]", JSON.stringify(24));
        formdata.append("status[1]", JSON.stringify(25));
        formdata.append("status[2]", JSON.stringify(10));
        axios({
            method: "post",
            url: Global.GetVendorOffersV1 + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setINprogressOffers(response.data.offer_logs)
                console.log('response of accept', response.data)
            })
            .catch(function (error) {
                console.log('response', error)

            });
    }

    const getvendorOfferRequestINprogress = () => {
        let formdata = new FormData();
        formdata.append("status[0]", JSON.stringify(24));
        axios({
            method: "post",
            url: Global.GetCustomerOffers + token,
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                setINprogressOffers(response.data.offer_logs)
                // setINprogressOffersLogs(response.data.offer_logs.price_offer_logs[0])
                console.log('response of accept', response)
            })
            .catch(function (error) {
                console.log('response', error)

            });
    }

    const sendingDataToParrentComponnent = (data: any, data2: any, data3: any) => {
        props.sendDetailview(data, data2, data3)
    }

    return (
        <div>
            {INprogressOffers.length >= 1 &&
                <div style={{ backgroundColor: '#e0bcb0', width: '45vw' }} >
                    <div className="d-flex justify-content-between cp-15" >
                        <label className=" main-font labelHeading " >In Progress</label>
                        <label className=" main-font labelHeading " >1</label>
                    </div>
                    {INprogressOffers.map((data: any) =>
                        <div className=" cp-15 " style={{}} >
                            <div className="cp-15" style={{ backgroundColor: 'white' }} >
                                <div className="d-flex" >
                                    <div className="d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                                        <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + data.image} ></img>
                                    </div>
                                    <div className=" cp-l-15 d-flex  flex-column " >
                                        <label className=" main-font labelText primary-color label" >{data.name}</label>
                                        <label className=" main-font text-muted pText label" >{data.manufacturer_name}</label>
                                    </div>
                                </div>
                                <div className="d-flex cp-l-15 cp-r-15" >
                                    <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>

                                    {data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by !== null &&
                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%' }} > <label style={{}} ><span dangerouslySetInnerHTML={{ __html: userid == data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by ? data.creator_message : data.receiver_message }} /></label>  </div>
                                    }
                                    {data.price_offer_logs[data.price_offer_logs.length - 1].finalized_by == null &&
                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%' }} > <label style={{}} ><span dangerouslySetInnerHTML={{ __html: userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message }} /></label>  </div>
                                    }
                                </div>
                                <div onClick={() => sendingDataToParrentComponnent(data, 'inprogress', userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message)} className="d-flex flex-row-reverse " >
                                    <label className="main-font label pText cp-t-5 cp-b-5 cp-l-20 cp-r-20 bg-color-primary touchAble" style={{ borderRadius: 100, color: 'white' }} >View</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            {finishedOffers.length >= 1 &&
                <div className="cm-t-20" style={{ backgroundColor: '#e0bcb0', width: '45vw' }} >
                    <div className="d-flex justify-content-between cp-15" >
                        <label className=" main-font labelHeading " >Finished</label>
                        <label className=" main-font labelHeading " >1</label>
                    </div>
                    {finishedOffers.map((data: any) =>
                        <div className=" cp-15 " >
                            <div className="cp-15" style={{ backgroundColor: 'white' }} >
                                <div className="d-flex" >
                                    <div className="bg-dark  d-flex align-item-center" style={{ width: "125px", height: '125px' }} >
                                        <img style={{ width: "125px", height: '125px' }} src={Global.IMAGE_PATH + data.image} ></img>
                                    </div>
                                    <div className=" cp-l-15 d-flex  flex-column " >
                                        <label className=" main-font labelText primary-color label" >{data.name}</label>
                                        <label className=" main-font text-muted pText label" >{data.manufacturer_name}</label>
                                    </div>
                                </div>
                                <div className="d-flex cp-l-15 cp-r-15" >
                                    <div className="d-flex align-item-center" style={{ width: "125px" }} ></div>
                                    <div><label className=" main-font text-muted pText label" ><label className="pText f-600" style={{ color: 'black' }} > 2 days</label> to respond.</label></div>
                                </div>
                                <div className="d-flex flex-row-reverse" >
                                    <label onClick={() => sendingDataToParrentComponnent(data, 'finished', userid == data.price_offer_logs[data.price_offer_logs.length - 1].receiver_id ? data.receiver_message : data.creator_message)} className="main-font label pText cp-t-5 cp-b-5 cp-l-20 cp-r-20 bg-color-primary" style={{ borderRadius: 100, color: 'white' }} >View</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            {(finishedOffers.length <= 0 && INprogressOffers.length <= 0) &&
                <div className=" d-flex justify-content-center align-content-center align-item-center align-self-center flex-column" style={{ height: '70vh', backgroundColor: 'white' }} >
                    <img className=' ' style={{ height: '200px', width: '200px' }} alt='img' src={girl} ></img>
                    <label className="text-muted text-center" style={{ width: '30%' }} >No sales offer Requests</label>
                </div>
            }
        </div>
    );
}

export default OfferRequest;

